import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  getFulfilledOrdersTableFields = () => {
    let data = [

      { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'required', header: 'Ordered Inventory', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 100, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'created', header: 'Fulfilled On', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 75, field: 'invoiceNumber', header: 'Invoice Number', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return data;
  };
  componentDidMount = () => {
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    this.setState({
      screenPermissions: screenPermissions
    })
    console.log(screenPermissions)
  }
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": 'center',
        width: 45,
        selectionMode: 'multiple',
        field: 'New',
        filter: false,
        sortable: false,
        placeholder: 'Search'
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "productName",
        "mobile": true,
        "header": "Product Name",
        "filter": false,
        "fieldType": 'Link',
        "sortable": true,
        "displayInSettings": true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "description",
        "mobile": true,
        "header": "Description",
        "filter": false,
        "sortable": true,
        "displayInSettings": true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "concentration",
        "mobile": true,
        "header": "Volume",
        "filter": false,
        "sortable": true,
        "displayInSettings": true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 120,
        "field": "required",
        "mobile": true,
        "header": "Ordered Inventory",
        "filter": false,
        "sortable": true,
        "displayInSettings": true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "distributorEmail",
        "mobile": true,
        "header": "Distributor Email",
        "filter": false,
        "sortable": true,
        "displayInSettings": true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "cartName",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        "fieldStyle": "capitalize"

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 90,
        "field": "created",
        "mobile": true,
        "fieldType": "Date",
        "header": "Fulfilled On",
        "filter": false,
        "sortable": true,
        "displayInSettings": true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "invoiceNumber",
        "mobile": true,
        "header": "Invoice Number",
        "filter": false,
        "sortable": true,
        "displayInSettings": true

      },

      //       {
      //         "show": true,
      //         "textAlign": "left",
      //         "width": 180,
      //         "field": "email",
      //         "mobile": true,
      //         "header": "Description",
      //         "filter": false,
      //         "sortable": true,
      //       },
      //         {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active',"color":"success" }, { "label": "Pending", "value": 'Pending' ,"color":"warning"}, { "label": "Inactive", "value": 'Inactive',"color":"danger", },{ "label": "Dismissed", "value": 'Dismissed',"color":"primary", }],
      //   },
      //   {
      //     "show": true,
      //     "textAlign": "center",
      //     "width": 140,
      //     "field": "role",
      //     "mobile": true,
      //     "header": "Role",
      //     "filter": true,
      //     "sortable": true,
      //     "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //     "fieldType": 'Role',
      //   },


      //   {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //   },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "firstName",
        "label": "POC First Name",
        "id": "firstName",
        "placeholder": "FirstName",
        "type": "text",
        "required": true
      },
      {
        "value": "",
        "name": "lastName",
        "label": "LastName",
        "id": "lastName",
        "placeholder": "POC Last Name",
        "type": "text",
        "required": true
      },

      {
        "value": "",
        "name": "email",
        "label": "POC Email",
        "id": "email",
        "placeholder": "Email",
        "type": "email",
        "required": true
      },
      {
        "value": "",
        "name": "phone",
        "label": "Phone Number",
        "id": "phoneNumber",
        "placeholder": "Phone Number",
        "type": "text",
        "normalize": false,
        "required": true
      },
      {
        "value": "",
        "name": "status",
        "label": "Status",
        "id": "status",
        "placeholder": "Status",
        "type": "dropDown",
        "options": config.statusTypes,
        "required": true
      },

    ]);
  }


  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequired={false}
        deleteRequired={this.state.screenPermissions === 'edit' ? true : false}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={false}
        type="Fulfilled Orders"
        apiResponseKey='orders'
        apiUrl={api.FulfilledOrders}
        filterExtension={"type=fulfilled"}
        formUrl={'orders'}
        hasQueryInCall={true}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)