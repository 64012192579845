import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import DefaultInput from "../../shared/components/form/DefaultInput"
import showToasterMessage from "../UI/ToasterMessage/toasterMessage"
import fetch from "../../config/service"
import Loader from "../App/Loader";
import confirmationModal from "../Cruds/Modals/ConfirmationModal";


const required = (value) =>
    value || typeof value === "string" ? undefined : "Please Enter Your Email";
const required1 = (value) =>
    value || typeof value === "string" ? undefined : "Please Enter Your Password";

class UserLoginFormPlayStore extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            confirmModal: null,
            isLoading:false,
            values:undefined
        };
    }
    handleUserPassword = (values) => {
        this.setState({
            isLoading: true,
            confirmModal: true,
            values:values
        });
    };

    deleteInventory = (values) => {
        let body = values && values
        return fetch("POST", `auth/userDelete`, body)
            .then((response) => {
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, "success");
                    this.props.reset();

                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, "error");
                }
                this.setState({
                    isLoading: false,
                    confirmModal: false,
                });
            })
            .catch((err) => {
                return err;
            });
    }


    submit = (values) => {
        if (values) {
            // this.handleUserPassword(values);
            this.deleteInventory(values)
        }
    };

    clearInputFields = () => {
        this.setState({
            confirmModal: false,
            isLoading: false,
        })
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <div style={{ height: '100vh', width: '100vw', backgroundColor: '#f2f1ed' }} class="d-flex align-items-center">

                {/* TopBar */}
                <div className="topbar">
                    <div className="topbar__wrapper">
                    <div className="topbar__center" >
                       <div className='topbar__logo' ></div>                        
                    </div>      
                    </div>
                </div>
                {/* sidebar */}
                <div className="userLog_Sidebar"></div>


                <div className="col-md-6 " style={{ margin: "auto" }}>
                    <Card style={{ margin: "auto", width: "60%" }}>
                        <CardHeader style={{ fontSize: 20, textAlign: "center" }}>
                            Delete User Request
                        </CardHeader>
                        <CardBody>
                            <form className="form" onSubmit={handleSubmit(this.submit)}>
                                <div className="form__form-group">
                                    {/* <Loader loader={this.state.isLoading} /> */}
                                    <label className="form__form-group-label">Email</label>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="email"
                                            component={DefaultInput}
                                            type="email"
                                            placeholder="Email"
                                            validate={required}
                                        />
                                    </div>
                                </div>
                                 {/* Password Confirmation Field */}
                                {/* <div className="form__form-group">
                                    <label className="form__form-group-label">Password</label>
                                    <div className="form__form-group-field">
                                        <Field
                                            name="password"
                                            component={DefaultInput}
                                            type={"password"}
                                            placeholder="Password"
                                            validate={required1}
                                        />
                                    </div>
                                </div> */}

                                <div style={{ margin: "auto" }}>
                                    <Button color="primary" type="submit">
                                        Send
                                    </Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>


                    {/* Delete confirmation Pop Up modal */}

                    {/* {this.state.confirmModal ?
                        <div>
                            <Modal isOpen={this.state.confirmModal} className='deleteModalHeight confirm_modal'>
                                <ModalBody className='deleteModalBody'>
                                    <div style={{ marginBottom: 10 }}>{`Are you sure, you want to delete the User`}?</div>
                                    <Button color="primary" outline onClick={this.clearInputFields} className='deleteModalBtn marginRight'>No</Button>
                                    <Button color="primary" outline onClick={this.deleteInventory}
                                        className='deleteModalBtn'>{'Yes'}</Button>
                                </ModalBody>
                            </Modal>
                        </div>
                        : null} */}



                </div>
            </div>
        );
    }
}

// Connect the form component to Redux using reduxForm and connect
const ConnectedForm = reduxForm({
    form: "deleteInventory", // a unique identifier for this form
})(UserLoginFormPlayStore);

export default connect(
    (state) => ({
        // Add your mapStateToProps logic here if needed
    }),
    {
        // Add your mapDispatchToProps logic here if needed
    }
)(ConnectedForm);
