
// import React, { PureComponent } from 'react';
// import RolePermissions from '../../../Permissions/Permissions';

// import PropTypes from 'prop-types';
// import fetchMethodRequest from '../../../../../config/service';
// import TableRender from '../../../CommonDataTable/TableRender';
// import apiCalls from '../../../../../config/apiCalls';
// import configImages from '../../../../../config/configImages'

// export default class Reports extends PureComponent {
//     static propTypes = {
//         t: PropTypes.func.isRequired,
//     };

//     constructor() {
//         super();
//         this.state = {
//             tabType: 'Categories',
//             apiUrl: apiCalls.Categories,
//             settingInfo: {},
//             useTypes: [],
//             categoryTypes: []
//         };
//     }

//     componentDidMount = async () => {
//         let categoryTypes = await this.getCategoryDrodownValues();
//         return fetchMethodRequest('GET', apiCalls.Settings)
//             .then(async (response) => {
//                 if (response && response.settings && response.settings[0]) {
//                     if (response.settings[0]) {
//                         let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
//                         this.setState({
//                             categoryTypes: categoryTypes,
//                             useTypes: useTypes,
//                             inventoryTypes: response.settings[0].inventoryTypes
//                         });
//                     }
//                 }
//             });
//     }
//     getTableFields = () => {
//         let statusTypes = [
//             { label: 'All', value: null },
//             { label: 'Active', value: 'Active' },
//             { label: 'InActive', value: 'InActive' },
//             { label: 'Pending', value: 'Pending' }
//         ];

//         let data = [
//             { textAlign: 'left', width: 105, field: 'title', header: 'Product Name', filter: true, sortable: false },
//             { textAlign: 'left', width: 105, field: 'description', header: 'Description', filter: true, sortable: false },

//             { textAlign: 'left', width: 135, field: 'categoryName', header: 'Category', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 85, field: 'dosage', header: 'Volume', filter: true, sortable: true, placeholder: 'Search' },

//             { textAlign: 'left', width: 85, field: 'inventoryType', header: 'Type', placeholder: 'Search' },
//             // { textAlign: 'center', width: 85, field: 'existingQuantity', header: 'Quantity', filter: true, sortable: false },
//             //   { textAlign: 'center', width: 105, field: 'expirationDate', header: 'Expiration', filter: true, sortable: false },
//             //   { textAlign: 'center', width: 105, field: 'recalls', header: 'Recalls', filter: true, sortable: false },
//             //    { textAlign: 'center', width: 105, field: 'awaitingRefill', header: 'Awaiting Refill', filter: true, sortable: false },
//             // { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },
//             // { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },

//         ];
//         let screenPermissions = RolePermissions.screenPermissions('Product Directory');
//         if (screenPermissions && screenPermissions != 'edit') {
//             data.pop();
//         }
//         return data;
//     };


//     getFormFields = async () => {
//         return ([
//             {
//                 required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
//                 id: 'productName', placeholder: 'Product Name'
//             },
//             {
//                 required: true, value: '', type: 'text', name: 'description', label: 'Description',
//                 id: 'description', placeholder: 'Description'
//             },
//             // {
//             //     required: false, value: '', type: 'text', name: 'required',
//             //     label: 'Required', placeholder: 'Enter Required'
//             // },
//             {
//                 required: true, value: '', type: 'dropDown', name: 'categoryId',
//                 label: 'Category', id: 'categoryId', placeholder: 'Select Category'
//             },
//             // {
//             //     required: false, value: '', type: 'buttonGroup', name: 'reorder',
//             //     label: 'Re-Ordering', id: 'reordering', placeholder: 'Select',
//             //     options: [{ value: 'auto', label: 'Auto' }, { value: 'manual', label: 'Manual' }],
//             // },



//         ]);
//     }

//     getCategoryNames = async () => {
//         return fetchMethodRequest('GET', apiCalls.Categories)
//             .then(async (response) => {
//                 if (response && response.categories && response.categories[0]) {
//                     if (response.categories[0].categoryName) {
//                         let categoryNameOptions = [];
//                         for (let option of response.categories) {
//                             categoryNameOptions.push({
//                                 label: option.categoryName,
//                                 value: option._id,
//                                 categoryType: option.categoryType

//                             });
//                             await this.setState({
//                                 categoryNameOptions: categoryNameOptions
//                             });
//                         }
//                     }
//                 }
//             });
//     }

//     getDistributorDrodownValues = () => {
//         return fetchMethodRequest('GET', apiCalls.Distributors)
//             .then(async (response) => {

//                 if (response && response.distributors && response.distributors[0]) {
//                     if (response.distributors[0].displayName) {
//                         let distributorNames = response.distributors;
//                         let modifiedDistributorNames = [];
//                         for (let distributor of distributorNames) {
//                             modifiedDistributorNames.push({
//                                 label: distributor.displayName,
//                                 value: distributor._id
//                             })
//                         }
//                         return modifiedDistributorNames
//                     }
//                 }
//             });
//     }
//     getClinicDropDownValues = () => {
//         return fetchMethodRequest('GET', apiCalls.Clinics)
//             .then(async (response) => {
//                 if (response && response.clinics && response.clinics[0]) {
//                     if (response.clinics[0].name) {
//                         let clinicNames = response.clinics;
//                         let modifiedClinicNames = [];
//                         for (let clinic of clinicNames) {
//                             modifiedClinicNames.push({
//                                 label: clinic.name,
//                                 value: clinic._id
//                             })
//                         }
//                         return modifiedClinicNames
//                     }
//                 }
//             });
//     }

//     getCategoryDrodownValues = () => {
//         return fetchMethodRequest('GET', apiCalls.Categories)
//             .then(async (response) => {
//                 if (response && response.categories && response.categories[0]) {
//                     if (response.categories[0].categoryName) {
//                         let categoryNames = response.categories;
//                         let modifiedCategoryName = [];
//                         for (let category of categoryNames) {
//                             modifiedCategoryName.push({
//                                 label: category.categoryName,
//                                 value: category._id,
//                                 categoryType: category.categoryType
//                             })
//                         }
//                         return modifiedCategoryName
//                     }
//                 }
//             });
//     }

//     getUseTypeDrodownValues = (settingInfo) => {
//         if (settingInfo && settingInfo.useType) {
//             let useTypes = settingInfo.useType;
//             let modifiedUseTypes = [];
//             for (let category of useTypes) {
//                 modifiedUseTypes.push({
//                     label: category,
//                     value: category
//                 })
//             }
//             return modifiedUseTypes;
//         }
//     }

//     render() {
//         return (
//             <div>
//                 <TableRender type={'Product Directory'}
//                     globalSearch={'Product Name/ Category'}
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields} {...this.props}
//                     sample={configImages.productsSampleXL}
//                     bulkApi='product'
//                     inventoryTypes={this.state.inventoryTypes}

//                 />
//             </div>
//         );
//     }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetchMethodRequest from "../../../../../config/service";
import RolePermissions from '../../../Permissions/Permissions'
import AddCategoryModal from "../../../Modals/AddCategoryModal";
import showToasterMessage from "../../../../UI/ToasterMessage/toasterMessage";

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount = async () => {
    let categoryTypes = await this.getCategoryDrodownValues();
    this.setState({
      categoryTypes: categoryTypes
    })
    this.getSettings()
    let screenPermissions = RolePermissions.screenPermissions('Product Directory');
    this.setState({
      screenPermissions: screenPermissions
    })
    console.log(screenPermissions)

    //  this.getCategoryNames()
  }
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "title",
        "mobile": true,
        "header": "Product Name",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },

      {
        "show": true,
        "textAlign": "center",
        "width": 110,
        "field": "description",
        "mobile": true,
        "header": "Description",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 90,
        "field": "dosage",
        "mobile": true,
        "header": "Volume",
        "filter": false,
        "sortable": true
      },


      {
        "show": true,
        "textAlign": "left",
        "width": 120,
        "field": "companyName",
        "mobile": true,
        "header": "Company",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },

      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "isBatteryExist",
        "header": 'Actions',
        "filter": false,
        "sortable": false,
      },


    ]
    return data;
  };
  //     getCategoryNames = async () => {
  //         return fetchMethodRequest('GET', apiCalls.Categories)
  //             .then(async (response) => {
  //                 if (response && response.categories && response.categories[0]) {
  //                     if (response.categories[0].categoryName) {
  //                         let categoryNameOptions = [];
  //                         for (let option of response.categories) {
  //                             categoryNameOptions.push({
  //                                 label: option.categoryName,
  //                                 value: option._id,
  //                                 categoryType: option.categoryType

  //                             });
  //                             await this.setState({
  //                                 categoryNameOptions: categoryNameOptions
  //                             });
  //                         }
  //                     }
  //                 }
  //             });
  //     }

  //     getDistributorDrodownValues = () => {
  //         return fetchMethodRequest('GET', apiCalls.Distributors)
  //             .then(async (response) => {

  //                 if (response && response.distributors && response.distributors[0]) {
  //                     if (response.distributors[0].displayName) {
  //                         let distributorNames = response.distributors;
  //                         let modifiedDistributorNames = [];
  //                         for (let distributor of distributorNames) {
  //                             modifiedDistributorNames.push({
  //                                 label: distributor.displayName,
  //                                 value: distributor._id
  //                             })
  //                         }
  //                         return modifiedDistributorNames
  //                     }
  //                 }
  //             });
  //     }
  //     getClinicDropDownValues = () => {
  //         return fetchMethodRequest('GET', apiCalls.Clinics)
  //             .then(async (response) => {
  //                 if (response && response.clinics && response.clinics[0]) {
  //                     if (response.clinics[0].name) {
  //                         let clinicNames = response.clinics;
  //                         let modifiedClinicNames = [];
  //                         for (let clinic of clinicNames) {
  //                             modifiedClinicNames.push({
  //                                 label: clinic.name,
  //                                 value: clinic._id
  //                             })
  //                         }
  //                         return modifiedClinicNames
  //                     }
  //                 }
  //             });
  //     }

  //     getCategoryDrodownValues = () => {
  //         return fetchMethodRequest('GET', apiCalls.Categories)
  //             .then(async (response) => {
  //                 if (response && response.categories && response.categories[0]) {
  //                     if (response.categories[0].categoryName) {
  //                         let categoryNames = response.categories;
  //                         let modifiedCategoryName = [];
  //                         for (let category of categoryNames) {
  //                             modifiedCategoryName.push({
  //                                 label: category.categoryName,
  //                                 value: category._id,
  //                                 categoryType: category.categoryType
  //                             })
  //                         }
  //                         return modifiedCategoryName
  //                     }
  //                 }
  //             });
  //     }

  //     getUseTypeDrodownValues = (settingInfo) => {
  //         if (settingInfo && settingInfo.useType) {
  //             let useTypes = settingInfo.useType;
  //             let modifiedUseTypes = [];
  //             for (let category of useTypes) {
  //                 modifiedUseTypes.push({
  //                     label: category,
  //                     value: category
  //                 })
  //             }
  //             return modifiedUseTypes;
  //         }
  //     }
  // getFormFields = async () => {
  //     return ([
  //         {
  //             required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
  //             id: 'productName', placeholder: 'Product Name'
  //         },
  //         {
  //             required: true, value: '', type: 'text', name: 'description', label: 'Description',
  //             id: 'description', placeholder: 'Description'
  //         },
  //         // {
  //         //     required: false, value: '', type: 'text', name: 'required',
  //         //     label: 'Required', placeholder: 'Enter Required'
  //         // },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'categoryId',
  //             label: 'Category', id: 'categoryId', placeholder: 'Select Category'
  //         },
  //         // {
  //         //     required: false, value: '', type: 'buttonGroup', name: 'reorder',
  //         //     label: 'Re-Ordering', id: 'reordering', placeholder: 'Select',
  //         //     options: [{ value: 'auto', label: 'Auto' }, { value: 'manual', label: 'Manual' }],
  //         // },



  //     ]);
  // }
  getCategoryPage = () => {
    console.log('category')
    this.setState({
      isOpenCategoryModal: true
    })
  }
  closeCategoryModal = () => {
    this.setState({
      isOpenCategoryModal: false
    })
  }
  getFormFields = () => {
    let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
    return ([
      {
        "value": "",
        "name": "productName",
        "label": "Product Name",
        "id": "productName",
        "placeholder": "Product Name",
        "type": "text",
        "required": true,
        mandatory: true

      },
      {
        "value": "",
        "name": "description",
        "label": "Description",
        "id": "description",
        "placeholder": "Description",
        "type": "text",
        "required": true,
        mandatory: true

      },
      // {
      //     "value": "",
      //     "name": "categoryId",
      //     "label": "Category",
      //     "id": "Category",
      //     "placeholder": "Category",
      //     "type": "dropDown",
      //     "options": this.state.categoryTypes,
      //     "required": true
      // },
      {
        value: '',
        type: 'autoComplete',
        searchApi: api.Categories,
        searchField: 'categoryName',
        name: 'categoryId',
        filterValue: 'categoryName',
        filterField: "categoryName",
        label: 'Category',
        'onchange': this.getCategoryName,
        addFunctionality: this.getCategoryPage,
        id: 'category',
        options: [{
          'categoryId': "_id"
        }],
        placeholder: 'Category Name',
        mandatory: true

      },
      {
        "value": "",
        "name": "inventoryType",
        "label": "Inventory Type",
        "id": "inventoryType",
        "placeholder": "inventoryType",
        "type": "dropDown",
        "required": true,
        "options": this.state.inventoryTypes,
        "show": this.state.medicine ? true : false,
        mandatory: true

        // isAddFormHidden:this.state.medicine?false:true,
        // isEditFormHidden:true,
      },
      {
        "value": "",
        "name": "dosage",
        "label": "Volume",
        "id": "dosage",
        "placeholder": "Volume",
        "type": "text",
        "required": true,
        "show": this.state.medicine ? true : false,
        mandatory: true

      },
      {
        value: '', type: 'radio', name: 'batteryExists', label: 'Battery Exists',
        options: options, "show": this.state.nonMedicine ? true : false,
        mandatory: true

      }
    ]);
  }
  getCategoryName = (item) => {
    if (item.categoryType == 'Medicine') {
      this.setState({
        medicine: true,
        nonMedicine: false
      })
    } else if (item.categoryType == 'Non-Medicine') {
      this.setState({
        medicine: false,
        nonMedicine: true
      })
    }
    this.datatableref.getFormFields()
  }
  getCategoryDrodownValues = () => {
    return fetchMethodRequest('GET', api.Categories)
      .then(async (response) => {
        if (response && response.categories && response.categories[0]) {
          if (response.categories[0].categoryName) {
            let categoryNames = response.categories;
            let modifiedCategoryName = [];
            for (let category of categoryNames) {
              modifiedCategoryName.push({
                label: category.categoryName,
                value: category._id,
                categoryType: category.categoryType
              })
            }
            return modifiedCategoryName
          }
        }
      });
  }
  getSettings = () => {
    return fetchMethodRequest('GET', api.Settings)
      .then(async (response) => {
        if (response && response.settings && response.settings[0]) {
          if (response.settings[0]) {
            this.setState({
              inventoryTypes: response.settings[0].inventoryTypes
            });
          }
        }
      });
  }
  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };
  saveDataToServer = (formValues, type) => {
    let method = "POST";
    let apiUrl = api.Categories;
    return fetchMethodRequest(method, apiUrl, { ...formValues })
      .then(async (response) => {
        if (response && response.respCode == 204) {
          showToasterMessage(response.respMessage, "success")
          this.closeCategoryModal()
        } else {
          showToasterMessage(response.errorMessage, "error")
        };
      })
  };
  render() {
    const { t } = this.props;
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          editRequired={false}
          addRequried={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={true}
          globalSearch={'Product Name / Description'}
          type="Product Directory"
          apiResponseKey='products'
          apiUrl={api.Products}
          // addRequried={this.state.screenPermissions==='edit'? true:false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          }]}
          displayViewOfForm='modal'
          routeTo
        />
        {this.state.isOpenCategoryModal ? (
          <AddCategoryModal
            onRef={(ref) => (this.addCategoryModalRef = ref)}
            isOpenCategoryModal={this.state.isOpenCategoryModal}
            closeCategoryModal={this.closeCategoryModal}
            saveDataToServer={this.saveDataToServer}
            type={'Product Directory'}
          />
        ) : null}</span>

      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)
