// import React from 'react';
// import TableRender from '../../../CommonDataTable/TableRender';
// import RolePermissions from '../../../Permissions/Permissions';

// export default class TicketsTable extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//         };
//     }

//     getTableFields = () => {
//         let data = [
//             { textAlign: 'center', width: 80, field: 'context', header: 'Context', filter: true, sortable: true, placeholder: 'Search' },
//             {
//                 textAlign: 'center', width: 80, field: 'contextType', header: 'Context Type', filter: true,
//                 sortable: true, placeholder: 'Search'
//             },
//             {
//                 textAlign: 'center', width: 100, field: 'email', header: 'Email', filter: true,
//                 sortable: true, placeholder: 'search'
//             },
//             // { textAlign: 'center', width: 50, field: 'key', header: 'Key', filter: true, sortable: true },
//             { textAlign: 'center', width: 100, field: 'created', header: 'Created', filter: true, sortable: true },

//         ];
//         let screenPermissions = RolePermissions.screenPermissions('Activities');
//         if (screenPermissions && screenPermissions != 'edit') {
//             data.pop();
//         }
//         return data;
//     };
//     render() {
//         return (
//             <div>
//                 <TableRender type='Activities'
//                     globalSearch='Context'
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields} {...this.props}
//                 />
//             </div>
//         );
//     }
// }
// import React from 'react';
// import TableRender from '../../../CommonDataTable/TableRender';
// import RolePermissions from "../../../Permissions/Permissions";

// export default class GroupsTable extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         };
//     }
//     getTableFields = () => {
//         let statusTypes = [
//             { label: 'All', value: null },
//             { label: 'Pending', value: 'Pending' },
//             { label: 'Active', value: 'Active' },
//             { label: 'InActive', value: 'InActive' },
//             { label: 'Dismissed', value: 'Dismissed' },
//         ];
//         let data = [

//             { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 105, field: 'displayName', header: 'Point Of Contact Name', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 140, field: 'email', header: 'Point Of Contact Email', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'right', width: 105, field: 'phone', header: 'Point Of Contact Phone', filter: true, sortable: true, placeholder: 'Search' },
//             {
//                 textAlign: 'center', width: 75, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false

//             },
//             { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
//         ];
//         let screenPermissions = RolePermissions.screenPermissions('Distributors');
//         if (screenPermissions && screenPermissions != 'edit') {
//             data.pop();
//         }
//         return data;
//     };

//     getFormFields = () => {
//         let statusTypes = [
//             { label: 'Pending', value: 'Pending' },
//             { label: 'Active', value: 'Active' },
//             { label: 'InActive', value: 'InActive' },
//             { label: 'Dismissed', value: 'Dismissed' },
//         ];
//         return ([

//             { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
//             { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
//             { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
//             { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
//             {
//                 required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
//                 options: statusTypes, placeholder: 'Select Status'
//             },
//         ]);
//     }

//     render() {

//         return (
//             <div>
//                 <TableRender type='Distributors'
//                     globalSearch='Point Of Contact Name/Email'
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields} {...this.props}
//                 />
//             </div>
//         );
//     }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";

class Activities extends React.Component {

    getTableFields = () => {
        const { t } = this.props;

        let data = [
            {
                "show": true,
                "textAlign": 'center',
                "width": "40px",
                "selectionMode": 'multiple',
                "sortable": false,
                "filter": false,
                "mobile": false,
            },
            {
                "show": true,
                "textAlign": "center",
                "width": "40px",
                "field": "Sno",
                "header_lang": "Sno",
                "header": "Sno",
                "sortable": false,
                "filter": false,
                "mobile": false,
                "fieldType": "Text",
            },
            {
                "show": true,
                "textAlign": "left",
                "width": "120px",
                "field": "context",
                "header_lang": "Context",
                "header": "Context",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "textCapitalize": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": "120px",
                "field": "contextType",
                "header_lang": "Context Type",
                "header": "Context Type",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "textCapitalize": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": "150px",
                "field": "email",
                "header_lang": "Email",
                "header": "Email",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Email",
            },
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": "160px",
            //     "field": "created",
            //     "header_lang": "Created",
            //     "header": "Created",
            //     "sortable": true,
            //     "filter": false,
            //     "mobile": true,
            //     "fieldType": "Text",
            // },
            // {
            //     "show": true,
            //     "textAlign": "center",
            //     "width": "100px",
            //     "field": "status",
            //     "header_lang": "status",
            //     "header": "Status",
            //     "sortable": true,
            //     "filter": false,
            //     "mobile": true,
            //     "fieldType": "Pill",
            //     "options": config.tablefieldStatusTypes,
            // },
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": "100px",
            //     "field": "Actions",
            //     "header_lang": "Actions",
            //     "header": "Actions",
            //     "sortable": false,
            //     "filter": false,
            //     "mobile": false,
            //     "fieldType": "Actions",
            // },
        ];
        return data;
    };
    // getFormFields = () => {
    //     let statusTypes = [
    //         { label: 'Pending', value: 'Pending' },
    //         { label: 'Active', value: 'Active' },
    //         { label: 'InActive', value: 'InActive' },
    //         { label: 'Dismissed', value: 'Dismissed' },
    //     ];
    //     return ([

    //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
    //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
    //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
    //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
    //         {
    //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
    //             options: statusTypes, placeholder: 'Select Status'
    //         },
    //     ]);
    // }

    getFormFields = () => {
        return ([
            {
                "value": "",
                "name": "firstName",
                "label": "POC First Name",
                "id": "firstName",
                "placeholder": "FirstName",
                "type": "text",
                "required": true
            },
            {
                "value": "",
                "name": "lastName",
                "label": "LastName",
                "id": "lastName",
                "placeholder": "POC Last Name",
                "type": "text",
                "required": true
            },

            {
                "value": "",
                "name": "email",
                "label": "POC Email",
                "id": "email",
                "placeholder": "Email",
                "type": "email",
                "required": true
            },
            {
                "value": "",
                "name": "phone",
                "label": "Phone Number",
                "id": "phoneNumber",
                "placeholder": "Phone Number",
                "type": "text",
                "normalize": false,
                "required": true
            },
            {
                "value": "",
                "name": "status",
                "label": "Status",
                "id": "status",
                "placeholder": "Status",
                "type": "dropDown",
                "options": config.statusTypes,
                "required": true
            },

        ]);
    }

    viewFields = () => {
        let data = {
            "displayName": "",
            "email": "",
            "phoneNumber": "",
            "address": "",
            "city": "",
            "state": "",
            "createdByName": "",
            "created": "",
            "role": "",
            "status": "",
        }
        return data;
    };

    render() {
        const { t } = this.props;
        return (
            <DataTables
                getTableFields={this.getTableFields}
                viewFields={this.viewFields}
                formFields={this.getFormFields}
                type="Activities"
                apiUrl={api.Activity}
                apiResponseKey='activities'
                tableTitle={"Activities"}
                formTitle={"Activities"}
                detailsTitle={"Activities"}
                globalSearch={`Context Type / Email`}
                globalSearchRequired={true}
                addRequried={false}
                editRequired={false}
                deleteRequired={true}
                viewRequired={true}
                settingsRequired={true}
                exportToCsvRequired={false}
                bulkUploadRequired={false}
                multipleDeleteRequired={false}
                actionsTypes={[{
                    'name': 'Delete',
                    "options": [
                        { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
                    ]
                }]}
                displayViewOfForm='modal'
                routeTo
            // multipleDeleteApi='/multipleUpdate?type=Delete'
            // multipleDeleteMethod='POST'
            // multipleDeleteBodyKey='clients'
            />
        );
    }
}

export default withTranslation('common')(Activities)
