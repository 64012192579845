// import React from 'react';
// import TableRender from '../../../CommonDataTable/TableRender';

// export default class GroupsTable extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         };
//     }
//     componentDidMount() {
//         if (this.props.match.path == '/expirationdates/:locationId') {
//             window.history.pushState(null, null, window.location.pathname);
//             window.addEventListener('popstate', this.onBackButtonEvent);
//         }
//     }
//     onBackButtonEvent = () => {
//         if (this.props.match.path == '/expirationdates/:locationId') {
//             if (window.location.pathname != 'inmedtory/home') {
//                 window.location.pathname = 'inmedtory/home'
//             }
//         } else {
//             return window
//         }
//     }
//     getTableFields = () => {
//         let data = [
//             { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 105, field: 'expirationDate', header: 'Expiration Date', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 140, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 105, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 105, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 105, field: 'isOrdered', header: 'Order Exists', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'center', width: 190, field: 'order', header: 'Order' },
//         ];
//         return data;
//     };

//     render() {

//         return (
//             <div>
//                 <TableRender type='Expiration Dates'
//                     globalSearch='Storage Unit/Product Name'
//                     tableFields={this.getTableFields}
//                     {...this.props}
//                 />
//             </div>
//         );
//     }
// }
// import React from "react";
// // Table
// import TableRender from "../../../CommonDataTable/TableRender";

// // config file
// import apiCalls from "../../../../../config/apiCalls";

// export default class MissingItems extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       clinics: [],
//       products: [],
//       locations: [],
//     };
//   }

//   componentDidMount = () => {
//     if (this.props.match.path == "/track/:locationId") {
//       window.history.pushState(null, null, window.location.pathname);
//       window.addEventListener("popstate", this.onBackButtonEvent);
//     }
//   };
//   onBackButtonEvent = () => {
//     if (this.props.match.path == "/track/:locationId") {
//       if (window.location.pathname != "inmedtory/home") {
//         window.location.pathname = "inmedtory/home";
//       }
//     } else {
//       return window;
//     }
//   };

//   getTableFields = () => {
//     let data = [
//       {
//         textAlign: "left",
//         width: 105,
//         field: "productName",
//         header: "Product Name",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },

//       {
//         textAlign: "left",
//         width: 105,
//         field: "lotId",
//         header: "Lot Id",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 105,
//         field: "context",
//         header: "Context",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 105,
//         field: "contextType",
//         header: "Context Type",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 140,
//         field: "locationId",
//         header: "Storage Unit",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 140,
//         field: "createdBy",
//         header: "Created By",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },

//       {
//         textAlign: "left",
//         width: 105,
//         field: "created",
//         header: "Date",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//     ];
//     return data;
//   };

//   getFormFields = () => {
//     return [
//       {
//         required: true,
//         value: "",
//         type: "text",
//         name: "lotId",
//         label: "Lot Id",
//         id: "Lot Id",
//         placeholder: "Lot Id",
//       },
//       {
//         required: true,
//         value: "",
//         type: "autoComplete",
//         name: "productName",
//         label: "Product",
//         id: "products",
//         placeholder: "Search Product",
//         searchApi: apiCalls.Products,
//         searchField: "title",
//       },

//       {
//         required: true,
//         value: "",
//         type: "textarea",
//         name: "reason",
//         label: "Reason",
//         id: "reason",
//         placeholder: "Type the Reason",
//       },
//     ];
//   };

//   render() {
//     return (
//       <div>
//         <TableRender
//           type="Track Items"
//           globalSearch="Lot Id"
//           tableFields={this.getTableFields}
//           formFields={this.getFormFields}
//           {...this.props}
//         />
//       </div>
//     );
//   }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import OrderModal from '../../../Modals/OrderModal'
import OrderConfirmation from '../../../Modals/OrderConfirmationModal'
import RolePermissions from '../../../Permissions/Permissions'
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
console.log("params", params)
class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
      locationId: null

    }
  }
  componentDidMount = async () => {
    var urlSearchParams1 = new URLSearchParams(window.location.search);
    var params2 = Object.fromEntries(urlSearchParams1.entries(window.location.search));
    console.log(params2)
    console.log("props 76", this.props)
    if (this.props.location && this.props.location.state) {

      const { locationIdFromExpiredItems } = this.props.location.state;
      const { count } = this.props.location.state;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      console.log(params)
      this.setState({
        locationId: locationIdFromExpiredItems,
        count: count,
      })
    } else if (params2 && params2.locationId) {
      this.setState({
        locationId: params2.locationId,
        params: params2
      })
    }

    this.orderDisable()
  }
  // componentWillUnmount=async()=>{
  //   this.props.onRef(null)
  // }
  //   getTableFields = () => {
  //         let data = [
  //             { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 105, field: 'expirationDate', header: 'Expiration Date', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 140, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 105, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 105, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 105, field: 'isOrdered', header: 'Order Exists', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'center', width: 190, field: 'order', header: 'Order' },
  //         ];
  //         return data;
  //     };
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": 'center',
      //   width: 45,
      //   selectionMode: 'multiple',
      //   field: 'New',
      //   filter: false,
      //   sortable: false,
      //   placeholder: 'Search'
      // },

      {
        "show": true,
        "textAlign": "center",
        "width": "50px",
        "field": "Sno",
        "header_lang": "Sno",
        "header": "Sno",
        "sortable": false,
        "filter": false,
        "mobile": false,
        "fieldType": "Text",
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "productName",
        "mobile": true,
        "header": "Product Name",
        "fieldType": "Complex",
        "filter": false,
        // "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "description",
        "mobile": true,
        "header": "Description",
        // "fieldType": "Complex",
        "filter": false,
        // "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "volume",
        "mobile": true,
        "header": "Volume",
        "fieldType": "Complex",
        "filter": false,
        // "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 90,
        "field": "clinicName",
        "mobile": true,
        "header": "Facility",
        "filter": false,
        "fieldStyle": "capitalize",
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "cartName",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "expirationDate",
        "mobile": true,
        "header": "Expiration Date",
        "fieldType": "Date",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "lotId",
        //"fieldType":"Complex",
        "mobile": true,
        "header": "Lot Id",
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 80,
      //   "field": "needed",
      //   "mobile": true,
      //   "header": "Need to Order",
      //   "filter": false,
      //   "sortable": true
      // },
      // {
      //   "show": true,
      //   "textAlign": "center",
      //   "width": 80,
      //   "field": "isOrdered",
      //   "mobile": true,
      //   "header": "Ordered",
      //   "fieldType": "BoolText",
      //   "filter": false,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "center",
        "width": 160,
        "field": "Actions",
        "mobile": true,
        "header": "Actions",
        "fieldType": "addToOrderList",
        "filter": false,
        "sortable": true,
      },

      //   {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 180,
      //     "field": "created",
      //     "fieldType":"Date",
      //     "mobile": true,
      //     "header": "Date",
      //     "filter": true,
      //     "sortable": true,
      //   },
      //       {
      //         "show": true,
      //         "textAlign": "left",
      //         "width": 180,
      //         "field": "email",
      //         "mobile": true,
      //         "header": "Description",
      //         "filter": false,
      //         "sortable": true,
      //       },
      //         {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active',"color":"success" }, { "label": "Pending", "value": 'Pending' ,"color":"warning"}, { "label": "Inactive", "value": 'Inactive',"color":"danger", },{ "label": "Dismissed", "value": 'Dismissed',"color":"primary", }],
      //   },
      //   {
      //     "show": true,
      //     "textAlign": "center",
      //     "width": 140,
      //     "field": "role",
      //     "mobile": true,
      //     "header": "Role",
      //     "filter": true,
      //     "sortable": true,
      //     "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //     "fieldType": 'Role',
      //   },


      //   {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //   },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "firstName",
        "label": "POC First Name",
        "id": "firstName",
        "placeholder": "FirstName",
        "type": "text",
        "required": true
      },
      {
        "value": "",
        "name": "lastName",
        "label": "LastName",
        "id": "lastName",
        "placeholder": "POC Last Name",
        "type": "text",
        "required": true
      },

      {
        "value": "",
        "name": "email",
        "label": "POC Email",
        "id": "email",
        "placeholder": "Email",
        "type": "email",
        "required": true
      },
      {
        "value": "",
        "name": "phone",
        "label": "Phone Number",
        "id": "phoneNumber",
        "placeholder": "Phone Number",
        "type": "text",
        "normalize": false,
        "required": true
      },
      {
        "value": "",
        "name": "status",
        "label": "Status",
        "id": "status",
        "placeholder": "Status",
        "type": "dropDown",
        "options": config.statusTypes,
        "required": true
      },

    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };
  closeOrderModal = () => {
    this.setState({
      openOrderModal: false
    })
    let data = {}
    data.value = []
    this.datatableref.onSelectRowsUpdate(data)
  }
  orderDisable = () => {
    let screenPermissions = RolePermissions.screenPermissions("Orders");
    if (screenPermissions && screenPermissions != "edit") {
      this.setState({
        disableOrder: true,
      });
    }
  };
  addToOrderList = async (item) => {
    // console.log("asdfjsjgcb mxzdcnjudsytfuysdjbv sjdgtfuyshd jhudsgfuyesrdbc ", item)
    if (!this.state.disableOrder) {
      let selectedRecord = item;
      await this.setState({
        orderProductName: selectedRecord.productName,
        title: selectedRecord.title1,
        orderFrom: "Missing Items",
        orderCart: selectedRecord.cartName,
        orderClinic: selectedRecord.clinicId._id,
        orderClinicName: selectedRecord.clinicId.name,
        orderDescription: selectedRecord.description,
        orderConcentration: selectedRecord.volume,
        orderNeeded: selectedRecord.needed,
        orderProductId: selectedRecord.productId._id,
        orderlocationId: selectedRecord.locationId._id,
        companyName: selectedRecord.companyName,
        updateProductRequired: "true"
      });
      // console.log(selectedRecord)
      await this.getNeeded(selectedRecord._id);

      if (selectedRecord.isOrdered) {
        // console.log('hii')
        await this.setState({
          openOrderConfirmation: true,
        });
      } else {
        this.setState({
          openOrderModal: true,
        });
      }
    } else {
      showToasterMessage("You are not authorized", "error");
    }
  };
  getNeeded = (id) => {
    let needed;
    let body = { quantityId: id };
    return fetch("POST", "quantities/expiredNeedToOrder", body)
      .then(
        async (response) => {
          needed = response.limit != null && response.count != null
            ?
            response.limit - response.count >= 0
              ?
              (response.limit - response.count) : 0 :
            null
          await this.setState({
            orderNeeded: response.neededOrder,
            orderNeeded: needed
          });
        }
      );
  };
  openOrderConfirmation = async () => {
    this.setState({
      openOrderModal: true,
      openOrderConfirmation: false,
    });
  };

  closeOrderConfirmation = async () => {
    this.setState({
      openOrderConfirmation: false,
    });
  };

  passDataToTable = () => {
    this.datatableref.getlocationData();
  }
  render() {
    const { t } = this.props;
    const { locationId } = this.state;
    // console.log("count", this.state)
    return (
      <div>
        {locationId ?
          <>
            <DataTables
              getTableFields={this.getTableFields}
              formFields={this.getFormFields}
              addRequried={false}
              onRef={(ref) => (this.datatableref = ref)}
              editRequired={true}
              deleteRequired={true}
              viewRequired={true}
              settingsRequired={true}
              globalSearch={'Product Name / Facility / Storage Unit'}
              type="Expired Items"
              apiResponseKey='quantities'
              params={this.state.params}
              hasQueryInCall={true}
              apiUrl={api.ExpiredItems}
              locationId={this.state.locationId}
              // filterExtension={`locationId=${this.state.locationId}`}
              count={this.state.count}
              addToOrderList={this.addToOrderList}
              actionsTypes={[
                //   {
                //   'name': 'AddToOrderList',
                //   'action': this.addToOrderList,
                //   "options": [
                //     { 'label': 'Add to Order List', 'value': 'Add to Order List', 'show': true, "multiple": false },
                //   ]
                // }
              ]}
              displayViewOfForm='modal'
              routeTo='hi'
            />
            {this.state.openOrderModal ? (
              <OrderModal
                onRef={(ref) => (this.confirmRef = ref)}
                openOrderModal={this.state.openOrderModal}
                closeOrderModal={this.closeOrderModal}
                updateOrderWishlist={this.passDataToTable}
                product={this.state.orderProductName}
                title={this.state.title}
                orderFrom={this.state.orderFrom}
                orderClinic={this.state.orderClinic}
                orderCart={this.state.orderCart}
                orderClinicName={this.state.orderClinicName}
                orderDescription={this.state.orderDescription}
                orderConcentration={this.state.orderConcentration}
                orderNeeded={this.state.orderNeeded}
                orderProductId={this.state.orderProductId}
                orderlocationId={this.state.orderlocationId}
                productId={this.state.productId}
                companyName={this.state.companyName}
                updateProductRequired={this.state.updateProductRequired}
              />
            ) : null}
            {this.state.openOrderConfirmation ? (
              <OrderConfirmation
                Order={this.state.openOrderConfirmation}
                openOrderConfirmation={this.openOrderConfirmation}
                closeOrderConfirmation={this.closeOrderConfirmation}
              />
            ) : null}
          </>
          : null
        }
      </div>

    );
  }
}
export default withTranslation('common')(Employees)
