import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
import { loginUserInfo } from '../../../redux/actions/authActions';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return { userData: state.loginUser }
};

const noProfileImage = require('../../../assets/img/profile/userProfile.png');
class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  componentDidMount = async () => {
    let userData = await localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    await this.props.updateLoginUserInfo(userData);
  }

  render() {
    const { collapse } = this.state;
    const { userData } = this.props;
    const profileImage = userData && userData.photo ? userData.photo : null;
    let titleElements = document.getElementsByClassName("topbar__avatar");
    console.log("titleElement", titleElements)
    if (titleElements.length > 0) {
      let width = titleElements[0].offsetWidth;
      console.log("width", width)

      let menuElement = document.querySelector(".topbar__menu");
      console.log("menuElement", menuElement)

      if (menuElement) {
        menuElement.style.width = width + "px";
      }
    }

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          <img className="topbar__avatar-img"
            src={profileImage ? `${config.imgUrl}user/${profileImage}` : noProfileImage} />
          <p className="topbar__avatar-name" style={{ textTransform: "capitalize" }}>{userData && userData.displayName ?
            userData.displayName : 'No Name'}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu topbarMenu">
            <div className='topBarImageAlignment'>
              <img src={profileImage ? `${config.imgUrl}user/${profileImage}` : noProfileImage}
                className='topBarImage' />
              <div style={{ textTransform: "capitalize" }}>
                {userData && userData.displayName ?
                  userData.displayName : 'No Name'}
                <div className='themeColorText'>{userData &&
                  userData.role ?
                  userData.role : ''}</div>
              </div>
            </div>
            <div className="topbar__menu-divider" />
            <div className="topbar__menu">
              <TopbarMenuLink title="My Profile" icon="user" path="/profile" toggleClose={this.toggle} />
              <TopbarMenuLink title="Change Password" icon="bubble" path="/changePassword" toggleClose={this.toggle} />
              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Log Out" icon="exit" path="/log_in" toggleClose={this.toggle} />
            </div>
          </div>
        </Collapse>
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateLoginUserInfo: (userInfo) => dispatch(loginUserInfo(userInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);
