import React from 'react';
import FormModal from '../../Form/FormModal';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';

// config file
export default class AddCalendarEventModal1 extends React.Component {
    constructor(props) {
        console.log("propsLLLLLLLLLl", props)
        super(props);
        this.state = {
            useTypes: [],
            categoryTypes: [],
            inventoryTypes: []
        };
    }

    componentDidMount = () => {
        console.log("props", this.props.locationType)
        console.log("state", this.state)
        this.getClinicDropDownValues();
        this.getInventoryDropDownValues();
        // if (this.props.rowData) {
        //     this.props.load(this.props.rowData)
        //     this.setState({
        //         productName: this.props.rowData.productName,
        //         productId: this.props.rowData.productId
        //     })
        //     this.getClinicsData()
        //     this.handleAutoCompleteData()
        // }
    }

    getFormFields = () => {
        return ([]);
    }

    getClinicDropDownValues = async () => {
        let ls = JSON.parse(localStorage.loginCredentials)
        if (ls.clinics && ls.clinics.length > 0) {
            let modifiedClinics = []
            ls.clinics.map((item, i) => {
                modifiedClinics.push({
                    label: item.name,
                    value: item.clinicId
                })
            })
            await this.setState({
                clinics: modifiedClinics
            })
        } else {
            return fetchMethodRequest('GET', apiCalls.Clinics)
                .then(async (response) => {
                    if (response && response.clinics && response.clinics[0]) {
                        if (response.clinics[0].name) {
                            let clinics = response.clinics;
                            let modifiedClinicNames = [];
                            for (let clinic of clinics) {

                                modifiedClinicNames.push({
                                    label: clinic.name,
                                    value: clinic._id
                                })
                            }

                            await this.setState({
                                clinics: modifiedClinicNames
                            })

                        }
                    }
                });
        }
    }

    getInventoryDropDownValues = () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    this.setState({
                        inventoryTypes: response.settings[0].inventoryTypes
                    })
                }
            });
    }

    render() {
        return (
            <FormModal
                openFormModal={this.props.openInventoryModal}
                closeFormModal={this.props.closeInventoryModal}
                type={'DashInventory'}
                modalType={'addInventory'}
                rowData={this.props.rowData}
                formType={'DashInventory'}
                formFields={this.getFormFields}
                inventoryCartName={this.props.cart}
                inventoryCartId={this.props.cartId}
                clinicvalues={this.state.clinics}
                locationType={this.props.locationType}
                apiUrl={apiCalls.Quantities}
                inventoryTypes={this.state.inventoryTypes}
                {...this.props}
            />
        );
    }
}