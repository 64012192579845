import React, { Component } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';
import fetchMethodRequest from '../../../config/service';
import SessionModal from '../../Cruds/Modals/SessionExpirationModal';
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: {},
      openSessionModal: false
    };
  }

  componentDidMount = async () => {
    await this.getMenuListFromServer();
    let rolePermissions = localStorage.getItem("rolePermissions");
    rolePermissions = JSON.parse(rolePermissions);
    if (rolePermissions) {
      let modifiedMenuList = [];
      let permissions = Object.keys(rolePermissions);
      if (this.state.menuList && this.state.menuList.length > 0) {
        for (let item of this.state.menuList) {
          let permissionKey = permissions[permissions.indexOf(item.title)];
          let modifiedSubmenus = [], isSubmenuExist = false;
          if (permissionKey) {
            if (item.submenus.length > 0) {
              isSubmenuExist = true;
              for (let value of item.submenus) {
                let submenuPermissionKey = permissions[permissions.indexOf(value.title)];
                if (value.title == submenuPermissionKey && submenuPermissionKey) {
                  if (rolePermissions[submenuPermissionKey] != "noView") {
                    modifiedSubmenus.push(value);
                  }
                }
              }
              item.submenus = [];
              item.submenus = [...modifiedSubmenus];
            }
            if (item.title == permissionKey) {
              if (rolePermissions[permissionKey] != "noView" && (
                !isSubmenuExist && modifiedSubmenus.length == 0 || isSubmenuExist && modifiedSubmenus.length > 0)) {
                modifiedMenuList.push(item);
              }
            }
          }
        }
      }
      for(let i in modifiedMenuList){
        if(modifiedMenuList[i].displayTitle == "Administration"){
          for(let j in modifiedMenuList[i].submenus){
            if(modifiedMenuList[i].submenus[j].displayTitle =="Orders"){
              let submenus=[]
              let i1 ={
                'active':true,
                'displayTitle':'Orders List',
                icon: "store",
                'route':'/orderslist',
                'title':'Orders List'
              }
              let i2 ={
                'active':true,
                'displayTitle':'Placed Orders',
                icon: "store",
                'route':'/placedorders',
                'title':'Orders List'
              }
              let i3 ={
                'active':true,
                'displayTitle':'Fulfilled Orders',
                icon: "store",
                'route':'/fulfilledOrders',
                'title':'Orders List'
              }
              // modifiedMenuList[i].submenus.push(i1)
              // modifiedMenuList[i].submenus.push(i2)
              // modifiedMenuList[i].submenus.push(i3)
            }
          }
        }
        if(modifiedMenuList[i].displayTitle == "Inventory"){
          for(let j in modifiedMenuList[i].submenus){
            if(modifiedMenuList[i].submenus[j].displayTitle =="Recall Items"){
              let submenus=[]
              let i1 ={
                'active':true,
                'displayTitle':'Recall a Item',
                icon: "store",
                'route':'/recallitems',
                'title':'Recall a Item'
              }
              let i2 ={
                'active':true,
                'displayTitle':'Items Recalled',
                icon: "store",
                'route':'/itemsrecalled',
                'title':'Items Recalled'
              }
              // modifiedMenuList[i].submenus.push(i1)
              // modifiedMenuList[i].submenus.push(i2)
            }
          }
        }
        if(modifiedMenuList[i].displayTitle == "Facility"){
          for(let j in modifiedMenuList[i].submenus){
            if(modifiedMenuList[i].submenus[j].displayTitle =="Storage Unit"){
              let submenus=[]
              let i1 ={
                'active':true,
                'displayTitle':'Cart Locations',
                icon: "store",
                'route':'/cartlocations',
                'title':'Cart Locations'
              }
              let i2 ={
                'active':true,
                'displayTitle':'Supply Cabinets',
                icon: "store",
                'route':'/supplycabinets',
                'title':'Supply Cabinets'
              }
              let i3 ={
                'active':true,
                'displayTitle':'OR Suites',
                icon: "store",
                'route':'/orsuites',
                'title':'OR Suites'
              }
              // modifiedMenuList[i].submenus.push(i1)
              // modifiedMenuList[i].submenus.push(i2)
              // modifiedMenuList[i].submenus.push(i3)
            }
          }
        }
      }
      this.setState({
        menuList: modifiedMenuList
      });
    }
  };

  getMenuListFromServer = async () => {
    let filterCriteria = {}, url;
    filterCriteria.sortfield = 'sequenceNo';
    filterCriteria.direction = 'asc';
    url = `menuLists?filter=${JSON.stringify(filterCriteria)}`;
    return fetchMethodRequest('GET', url)
      .then(async (res) => {
        if (res) {
          let sessionexpired = localStorage.getItem("sessionexpired");
          if (sessionexpired == 'true') {
            this.setState({
              openSessionModal: true
            })
            localStorage.setItem('sessionexpired', false);
            return;
          }
          await this.setState({ menuList: res.menus })
        }
      })
  }

  render() {
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar, } = this.props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });
    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={this.state.menuList}
            />
          </div>
        </Scrollbar>
        <SessionModal openSessionModal={this.state.openSessionModal} />
      </div>
    );
  }
};

Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;
