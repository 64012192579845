import React from "react";

// table styles
import "../../../scss/table.css";

// Data Table
import DataTables from "./DataTable";

export default class TableRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeData: [],
      isOpenDeleteModal: false,
      isOpenEditModal: false,
      singleRowData: {},
    };
  }

  render() {
    return (
      <div key={this.props.type}>
        <DataTables
          {...this.props}
          AllData={this.state.employeeData}
          copyCartFields={this.props.copyCartFields}
          globalSearch={this.props.globalSearch}
          editSelectedRow={this.editSelectedRow}
          deleteSelectedRow={this.deleteConfirmAction}
          onRef={(ref) => (this.DataTablesRef = ref)}
          hasQueryInCall={true}
          type={this.props.type}
          getTableFields={this.props.tableFields}
          formFields={this.props.formFields}
          sample={this.props.sample}
          bulkApi={this.props.bulkApi}
          clinics={this.props.clinics}
          allClinicsName={this.props.allClinicsName}
          params={this.props.params}
        />
      </div>
    );
  }
}
