import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MissingItems from './components/MissingItems'

const MissingItemsTableList = (props) => (
    console.log("MissingItemsTableList index", props),

    <Container>
        <MissingItems {...props} />
    </Container>
);

MissingItemsTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(MissingItemsTableList);

