import React from 'react';
import {
    Nav, NavItem, NavLink
} from 'reactstrap';

import classnames from 'classnames';

export default class PaymentTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            handleRecallTypes: [
                { name: 'Recall a Item', value: 'Recall a Item' },
                { name: 'Items Recalled', value: 'Items Recalled' },
            ],
            currentShift: null
        };
    }

    // Handle Selected Group
    handleRecallTypes = (tab, RecallTabType) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
                currentShift: RecallTabType
            });
        }
        this.props.getSelectedRecallTypeInfo(RecallTabType);
    };

    render() {
        const { activeTab, handleRecallTypes } = this.state;
        return (
            <div>
                <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            {handleRecallTypes && handleRecallTypes.length > 0 ?
                                handleRecallTypes.map((RecallTabType, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({ active: activeTab === index })}
                                                onClick={() => {
                                                    this.handleRecallTypes(index, RecallTabType.name);
                                                }}><span style={{ fontSize: 15 }}>{RecallTabType.value}</span></NavLink>
                                        </NavItem>
                                    )
                                })
                                : null}
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}