import React from "react";
import { AutoComplete } from "primereact/autocomplete";
import fetch from "../../../config/service";
import PropTypes from "prop-types";
import { touch } from "redux-form";

export default class autoComplete extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };
  constructor(props) {
    super(props);
    this.state = {
      isValidate: false,
      filteredSuggestions: [],
      errorMessage: "",
      preSearch: undefined
    };
    this.getAllSuggestions("", "cont");
  }
  // componentDidMount = () =>{
  //   if(this.props.input.name == "distributorEmail"){
  //     this.getAllSuggestions()
  //   }
  // }

  // to get all suggestions in autocomplete
  getAllSuggestions = (event) => {
    this.setState({
      isValidate: false,
      noData: false,
      errorMessage: "",
    });
    let filterCriteria = {
      limit: 20,
      page: 1,
      sortfield: this.props.searchApi == "products/productList" ? "title1" : "created",
      direction: this.props.searchApi == "products/productList" ? "asc" : "desc",
      criteria: [],
    };
    if (this.props.searchApi == "users") {
      filterCriteria["criteria"].push({
        key: "status",
        value: "Active",
        type: "eq",
      });
    }
    if (event && event.query) {
      filterCriteria["criteria"].push({
        key: this.props.searchField,
        value: event.query,
        type: "regexOr",
      });
      this.setState({
        preSearch: event.query
      })
    } else if(event && event.query == ""){

    } else if (event && !event.query && this.state.preSearch && this.state.preSearch != undefined) {
      filterCriteria['criteria'].push({
        "key": this.props.searchField,
        "value": this.state.preSearch,
        "type": "regexOr"
      });
    }
    if (this.props.groupType && this.props.filterFieldType == "leaves") {
      filterCriteria["criteria"].push({
        key: "group",
        value: this.props.groupType,
        type: "regexOr",
      });
    }
    let apiUrl = this.props.searchApi;
    if (
      this.props.screen == "Storage Unit Items" ||
      this.props.screen == "DashInventory" ||
      this.props.id == "products" || this.props.id == "alternateproducts"
    ) {
      apiUrl = this.props.apiUrl;
    }
    let body;
    let url = `${this.props.searchApi}?filter=${JSON.stringify(
      filterCriteria
    )}`;

    if (this.props.screen == "Storage Unit Items" || this.props.screen == "DashInventory") {
      body = {
        productName: this.props.input.name === "description" ? this.props.productName : this.props.input.name === "alternateDescription" ? this.props.alternateProductName : null
      };
      console.log("productName", this.props.input.name, "productName", this.props.productName)

    } else {
      body = {
        productName: this.props.productName
      };
    }

    console.log("body", body)
    return fetch(
      this.props.screen == "Storage Unit Items" ||
        this.props.screen == "DashInventory"
        ? this.props.call
        : "GET",
      url,
      body
    )
      .then((response) => {
        if (response) {
          let dropdownData = [];
          if (
            (response[apiUrl] &&
              response[apiUrl].length &&
              response[apiUrl].length > 0) ||
            response["products"] ||
            response["clinics"] ||
            response["locations"]
          ) {
            // if(this.props.input.name == "distributorEmail"){
            //   this.props.showDistributorData(false)
            // }

            if (this.props.selectedCategory && this.props.screen == "Help") {
              let categoryList = [];
              for (let category of response[apiUrl]) {
                if (
                  category &&
                  category.category == this.props.selectedCategory
                ) {
                  categoryList.push(category);
                }
              }
              dropdownData = categoryList;
            } else if (this.props.id == "Storage Unit") {
              let locationsList = [];
              for (let locations of response['locations']) {

                if (locations.clinicName == this.props.clinicName) {
                  locationsList.push(locations);
                }
              }
              dropdownData = locationsList;
            } else if (this.props.id == "Cart Sub Locations") {
              let locationsList = [];
              let list = [];
              for (let locations of response[apiUrl]) {
                if (locations.cart == this.props.cartName) {
                  locationsList.push(locations.cartParts);
                }
              }
              locationsList.map((value) => {
                value.map((item) => {
                  list.push({ cartParts: item });
                });
              });
              dropdownData = list;
            } else if (this.props.id == "subLocations" && this.props.cartName) {
              let locationsList = [],
                list = [];
              for (let locations of response["locations"]) {
                if (locations.cart == this.props.cartName) {
                  locationsList.push(locations.cartParts);
                }
              }
              locationsList.map((value) => {
                value.map((item) => {
                  list.push({ cartParts: item });
                });
              });
              dropdownData = list;
            } else if (this.props.id == "products") {
              let list = [];
              let data = response["products"];
              data.sort((a, b) =>
                a.title1.toUpperCase() > b.title1.toUpperCase()
                  ? 1
                  : b.title1.toUpperCase() > a.title1.toUpperCase()
                    ? -1
                    : 0
              );
              list = data;
              dropdownData = list;
            } else if (this.props.id == "description" || this.props.id == "alternateDescription") {
              for (let object of response["products"]) {
                if (
                  (this.props.screen == "Storage Unit Items" ||
                    this.props.screen == "DashInventory") &&
                  !object["description"]
                ) {
                  this.props.handleAutoCompleteData(
                    response["products"],
                    "NoDescription"
                  );
                } else {
                  dropdownData = response["products"];
                }
              }
            } else if (this.props.id == "location" && this.props.facilityName) {
              let values = [];
              for (let value of response["locations"]) {
                if (value.clinicName == this.props.facilityName) {
                  values.push(value);
                }
              }
              dropdownData = values;
            } else if (this.props.id == "clinic") {
              let data = response["clinics"];
              dropdownData = data;
            } else {
              dropdownData = response[apiUrl];
            }
            if (this.props.screen == "Help") {
              dropdownData.push({
                subject: "Other",
              });
            }
          }
          if (dropdownData && dropdownData.length == 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true,
            });
          } else {
            this.setSuggestions(dropdownData);
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };

  // set filtered sugeestions, removing duplicates
  setSuggestions = async (dropdownData) => {
    if (
      this.props.input &&
      this.props.input.value && dropdownData &&
      this.props.input.value.length > 0
    ) {
      let values = this.props.input.value;
      if (Array.isArray(values)) {
        values.forEach((element) => {
          let field = "_id";
          dropdownData = dropdownData.filter(
            (item) =>
              item[field] !== element[field] && item[this.props.searchField]
          );
        });
      } else {
        dropdownData = dropdownData.filter(
          (item) => item && item[this.props.searchField]
        );
      }
    } else {
      if (dropdownData) {
        dropdownData = dropdownData.filter(
          (item) => item && item[this.props.searchField]
        );
      }
    }
    await this.setState({
      filteredSuggestions: dropdownData,
    });
  };

  // on selected value from suggestions
  async onSelectRecord(e) {
    console.log("e", e)
    this.setState({
      noData: false,
      isValidate: false,
      errorMessage: "",
    });
    if (this.props.input) {
      const { name, onChange } = this.props.input;
      if (this.props.handleAutoCompleteData) {
        this.props.handleAutoCompleteData(e.value, name, this.props.searchField, this.props.onchange);
        await this.setState({
          [name]: e.value[this.props.searchField]
        })
      }
      if (this.props.dependent) {
        this.props.handleFnEnableControlsBasedOnValue(e, this.props.dependent)
      }
      onChange(e.value);
      if (e && e.value) {
        this.removeDuplicates(e.value, name);
      }
    }
  }

  // to remove duplicates
  removeDuplicates() {
    if (this.state.filteredSuggestions) {
      this.setSuggestions(this.state.filteredSuggestions);
    }
  }

  render() {
    const { placeholder, input, multiple } = this.props;
    const { touched, error } = this.props.meta;
    return (
      <div className="date-picker">
        <span className="p-fluid">
          <AutoComplete
            style={{ background: "white" }}
            multiple={multiple}
            value={input && input.value ? input.value : null}
            suggestions={this.state.filteredSuggestions}
            completeMethod={(e) => this.getAllSuggestions(e)}
            size={20}
            minLength={3}
            placeholder={placeholder}
            dropdown={true}
            onChange={(e) => this.onSelectRecord(e)}
            field={this.props.searchField}
            disabled={this.props.disabled}
          />
        </span>
        {touched && error && !this.state.noData && (
          <span className="form__form-group-error">{error}</span>
        )}
        {this.state.noData && (
          <span className="form__form-group-error">
            {this.props.id == "description"
              ? "No Description Found"
              : "No records found"}
          </span>
        )}
        {this.state.isValidate ? (
          <span className="form__form-group-error">
            {this.state.errorMessage}
          </span>
        ) : null}
      </div>
    );
  }
}
