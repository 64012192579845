import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import * as moment from "moment";

import config from "../../../config/config";

class DatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      firstDay: null,
      lastDay: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {
    if (this.props.currentDate) {
      let date = this.props.currentDate ? this.props.currentDate : null;
      this.handleChange(date);
    }
    let date = new Date()
    this.getMonthChange(date)
  };

  handleChange(date) {
    const { onChange } = this.props.input;
    if (date) {
      this.setState({
        startDate: moment(date).toDate(),
      });

      onChange(moment(date).format(config.dateDBFormat));
    }
  }

  updateDatePickerValues = () => {
    this.setState({
      startDate: null,
    });
    const { onChange } = this.props.input;
    if (onChange) {
      onChange(null);
    }
  };
  getMonthChange = (e) => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    if (e) {
      y = e.getFullYear(), m = e.getMonth();
    }
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.setState({
      firstDay: firstDay,
      lastDay: lastDay,
    })

  }
  render() {
    const { startDate } = this.state;
    const { meta, clear, screen, disabled } = this.props;
    const { name, onChange, value } = this.props.input;
    if (
      (name == "startDate" || name == "endDate") &&
      this.props.minDate &&
      !(startDate && value)
    ) {
      onChange(moment(this.props.minDate).format(config.dateDBFormat));
    }
    const date = startDate
      ? startDate
      : value
        ? moment(value).toDate()
        : this.props.minDate
          ? this.props.minDate
          : null;
    let selectedate;
    if (clear) {
      selectedate = this.updateDatePickerValues();
      //adding default Trial Period as Two months one day
    } else if (screen == "register") {
      selectedate = moment().add(2, 'months').add(1, 'day').toDate();
    } else {
      selectedate = date;
    }
    return (
      <div className="date-picker">
        <DatePicker
          className="form__form-group-datepicker "
          selected={selectedate}
          // selected={clear ? this.updateDatePickerValues() : date}
          onChange={this.handleChange}
          dateFormat={
            this.props.name == "expirationDate" ? "MM-dd-yyyy" : "dd-MM-yyyy"
          }
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={20}
          dropDownMode="select"
          onMonthChange={this.getMonthChange}
          maxDate={this.props.maxDate}
          minDate={new Date()}
          placeholderText="Select Date"
          disabled={screen == "register" ? true : disabled}
        />
        {meta.touched && meta.error && (
          <span className="form__form-group-error">{meta.error}</span>
        )}
      </div>
    );
  }
}

const renderDatePickerField = (props) => {
  const { input } = props;
  return <DatePickerField {...input} {...props} clear={props.clear} />;
};

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

export default renderDatePickerField;
