import React, { PureComponent } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";


let count = 1;
class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  };

  static defaultProps = {
    placeholder: "",
    options: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      defaultValue: this.props.defaultValue ? this.props.options[0] : null,
    };
  }

  handlePopupModal(option) {
    console.log("option", option._id)
    this.props.handlePopupModal()
  }

  getOptionTemplate = (option) => {
    const { onChange, name } = this.props.input;
    if (name === "recommendedInventoryName" && this.props.formatOptionLabel && this.handlePopupModal) {
      return (
        <div className="d-flex">
          <span className="mr-auto">{option.label}</span>
          <span><FontAwesomeIcon className="template_icon" icon={faExclamationCircle} onClick={(option) => this.handlePopupModal(option)} style={{ color: "#3475aa", marginLeft: "auto" }} /></span>
        </div>
      );
    }

    return option.label;
  };



  static getDerivedStateFromProps = (props, state) => {
    if (
      props.input &&
      props.input.value &&
      props.input.name == "clinic" &&
      typeof props.input.value == "string" &&
      props.placeholder != "Select Facility"
    ) {
      let value = props.input.value ? JSON.parse(props.input.value) : null;
      return {
        value: {
          label: value.name,
          value: props.input.value,
        },
      };
    }
    return null;
  };

  handleChange = (selectedOption) => {
    const { onChange, name } = this.props.input;
    console.log('selectedOption', selectedOption);
    this.setState({
      value: selectedOption,
    });
    if (name == "lotId") {
      this.props.getProductName(selectedOption);
    }
    if (name == "clinicId") {
      this.props.clearLocationValues();
    }
    if (name == "description") {
      this.props.getCustomData(selectedOption);
    }
    if (name == "checkType") {
      this.props.onCheckType(selectedOption);
    }
    if (
      name == "productName" &&
      (this.props.type == "Inventory" || this.props.type == "DashInventory")
    ) {
      this.props.getUniProductName(selectedOption);
    }
    if (name == "cartSubLocation" && this.props.type == "DashInventory") {
      this.props.getRequiredQuantityBasedOnCartSubLoc(selectedOption);
    }
    // if (name == "categoryId" || name == "category") {
    //   this.props.getCategoryTypes(selectedOption, name);
    // }
    if (name == "productId") {
      this.props.getCategoryProductType(selectedOption);
    }
    if (name === "locationType" && this.props && this.props.getlocationNamesforInventory) {
      this.props.getlocationNamesforInventory(selectedOption);
    }

    if (name == "role" && this.props.handlePermissionedRole) {
      this.props.handlePermissionedRole(selectedOption);
    }
    if (this.props.type == "Storage Unit") {
      if (name == "inventoryType") {
        this.props.updateInventoryType(selectedOption);
      }
    }
    if (this.props.type == "Inventory") {
      if (name == "clinicId") {
        this.props.getLocationDropdownData(selectedOption);
      }
      if (name == "locationId") {
        this.props.getLocationSubParts(selectedOption);
      }
    }
    if (
      (name == "useType" || name == "transferLocation") &&
      this.props.type == "Storage Unit Items"
    ) {
      this.props.getTransferredLocation(selectedOption);
    }
    if (name == "clinic" && this.props.type == "Storage Unit Items") {
      this.props.getTransferredLocation(selectedOption);
    }
    if (this.props.type == "Storage Unit Items") {
      this.props.setFields(name, selectedOption.value);
    }
    onChange(selectedOption.value);
  };

  render() {
    const { placeholder, options, isDisable } = this.props;
    let { name, value, onChange } = this.props.input;
    let isValue = { label: value, value: value };
    const selectValue = this.state.value
      ? this.state.value
      : isValue && isValue.value
        ? isValue
        : this.state.defaultValue
          ? this.state.defaultValue
          : null;
    if (!value && !this.state.value) {
      if (this.state.defaultValue) {
        this.handleChange(this.state.defaultValue);
      }
    }
    return (
      <Select
        name={name}
        value={selectValue}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        formatOptionLabel={this.getOptionTemplate}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        style={
          isDisable
            ? { background: "lightgray", border: "none" }
            : { background: "white" }
        }
        {...placeholder}
        isDisabled={isDisable}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: config.templateColor,
          },
        })}
      />
    );
  }
}



const renderSelectField = (props) => {
  const { meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField {...props} />
      {meta.touched && meta.error && (
        <span className="form__form-group-error pt-2">{meta.error}</span>
      )}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: "",
};

export default renderSelectField;
