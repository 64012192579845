import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount = async () => {
    this.getDistributorEmails()
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    this.setState({
      screenPermissions: screenPermissions
    })
    console.log(screenPermissions)
  }
  // componentWillUnmount=async()=>{
  //   this.props.onRef(null)
  // }
  getOrdersFields = () => {
    let data = [
      { textAlign: 'center', width: 45, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false, placeholder: 'Search' },
      { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'required', header: 'Quantity', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 100, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 90, field: 'orderedBy', header: 'Company Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 50, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return data;
  };
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": 'center',
        width: 45,
        selectionMode: 'multiple',
        field: 'New',
        filter: false,
        sortable: false,
        placeholder: 'Search'
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "productName",
        "mobile": true,
        "header": "Product Name",
        "filter": false,
        "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "description",
        "mobile": true,
        "header": "Description",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "concentration",
        "mobile": true,
        "header": "Volume",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "required",
        "mobile": true,
        "header": "Quantity",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "distributorEmail",
        "mobile": true,
        "header": "Distributor Email",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "cartName",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "companyName",
        "mobile": true,
        "header": "Company Name",
        "filter": false,
        "sortable": true,
      },

      //       {
      //         "show": true,
      //         "textAlign": "left",
      //         "width": 180,
      //         "field": "email",
      //         "mobile": true,
      //         "header": "Description",
      //         "filter": false,
      //         "sortable": true,
      //       },
      //         {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active',"color":"success" }, { "label": "Pending", "value": 'Pending' ,"color":"warning"}, { "label": "Inactive", "value": 'Inactive',"color":"danger", },{ "label": "Dismissed", "value": 'Dismissed',"color":"primary", }],
      //   },
      //   {
      //     "show": true,
      //     "textAlign": "center",
      //     "width": 140,
      //     "field": "role",
      //     "mobile": true,
      //     "header": "Role",
      //     "filter": true,
      //     "sortable": true,
      //     "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //     "fieldType": 'Role',
      //   },


      //   {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //   },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }
  getDistributorEmails = () => {
    return fetch('GET', api.ClinicStaff)
      .then(async (response) => {
        if (response && response.users && response.users.length > 0) {

          let distributorEmails = response.users;
          let modifiedDistributors = [];
          for (let distirbutor of distributorEmails) {
            modifiedDistributors.push({
              label: distirbutor.email,
              value: distirbutor.email
            })
          }
          this.setState({
            distributorEmails: modifiedDistributors
          });

        }
      });
  }
  getOrdersFormFields = async () => {
    return ([

      {
        required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
        id: 'required', placeholder: 'Product Name', disable: true
      },
      {
        required: false, value: '', type: 'dropDown', name: 'distributorEmail', label: 'Staff Email',
        id: 'required', placeholder: 'Staff Email', options: this.state.distributorEmails
      },
      {
        required: true, value: '', type: 'number', name: 'required', label: 'Ordered Inventory',
        id: 'required', placeholder: 'Ordered'
      },
      {
        value: '', type: 'text', name: 'orderedBy', label: 'Ordered By',
        id: 'required', placeholder: 'Ordered By', disable: true
      },

    ]);
  }
  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "productName",
        "label": "Product Name",
        "id": "productName",
        "placeholder": "Product Name",
        "type": "text",
        "required": true,
        disable: 'true'
      },
      {
        "value": "",
        "name": "distributorEmail",
        "label": "Staff Email",
        "id": "email",
        "placeholder": "Staff Email",
        "type": "dropDown",
        "options": this.state.distributorEmails,
        "required": false,
      },

      {
        "value": "",
        "name": "orderedBy",
        "label": "Ordered By",
        "id": "required",
        "placeholder": "Ordered By",
        "type": "text",
        disable: true
      },
      {
        "value": "",
        "name": "required",
        "label": "Ordered Inventory",
        "id": "required",
        "placeholder": "Ordered Inventory",
        "type": "number",
        "required": true
      },
    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };
  submit = (item) => {
    console.log(item)
    let userBody = item;
    return fetch("POST", "orders/sendEmail", userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          this.datatableref.getDataFromServer(this.state.filterCriteria, "refresh");
          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        return err;
      });
  }
  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        onRef={(ref) => (this.datatableref = ref)}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={false}
        type="Orders List"
        filterExtension={"type=unchecked"}
        flags={{ 'label': 'checked', 'value': null }}
        apiResponseKey='orders'
        apiUrl={api.Orders}
        hasQueryInCall={true}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }, {
          'name': 'Submit',
          'action': this.submit,
          "options": [
            { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)