import React from "react";
import {
  Button,
  Modal,
  ButtonToolbar,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import apiCalls from "../../../../../config/apiCalls";
import fetchMethodRequest from "../../../../../config/service";
import { load as loadAccount } from "./../../../../../redux/reducers/commonReducer";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import classNames from "classnames";
// validate
import validate from "../../../../Validations/validate";
import Select from "../../../../../shared/components/form/Select";
import configMessages from "../../../../../config/configMessages";
import showToasterMessage from "../../../../UI/ToasterMessage/toasterMessage";
import Loader from "../../../../App/Loader";

const required = (value) =>
  value || typeof value === "string" ? null : configMessages.fillField;

export default class AddReasonForItemModal extends React.Component {
  constructor(props) {
    super(props);
    clinicNames: [],
      (this.reasonOptions = [
        { label: "Transferred", value: "transfered" },
        { label: "Consumed", value: "consumed" },
        { label: "Recalled", value: "recalled" },
        { label: "Expired", value: "expired" },
      ]);
    this.state = {
      formFields: [
        {
          label: "Reason",
          fieldName: "useType",
          required: true,
          placeholder: "Select Reason",
          options: this.reasonOptions,
        },
      ],
      transferredLocationOptions: [],
      transferredSubLocationOptions: [],
      facility: [],
      storageType: null,
      selectedTransferLocation: "",
      selectedTransferSubLocation: "",
    };
  }

  updateReason = (values) => {
    if (values && Object.keys(values).length > 0) {
      this.setState({
        isLoading: true,
      });
      let body = {
        itemId: this.props.selectedReasonId,
        ...values,
      };
      if (this.state.storageType) {
        body["locationType"] = this.state.storageType;
      }
      return fetchMethodRequest("POST", apiCalls.ReasonCartItem, body)
        .then(async (response) => {
          if (response && response.respCode === 200) {
            showToasterMessage(response.respMessage, "success");
            await this.closeReasonModal();
            await this.props.getDataFromServer();
          } else if (response && response.errorCode) {
            showToasterMessage(response.errorMessage, "error");
          }
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  closeReasonModal = () => {
    this.props.reset();
    this.props.closeReasonModal(false, "openReasonModal");
    this.updateFormFields();
  };

  getTransferredLocation = (option) => {
    if (option && option.value == "transfered") {
      this.setState({
        isLoading: true,
      });
      return fetchMethodRequest("GET", `${apiCalls.Clinics}`).then(
        async (response) => {
          if (response && response.clinics && response.clinics.length > 0) {
            let clinicNames = response.clinics;
            let modifiedClinicNames = [];
            for (let clinic of clinicNames) {
              modifiedClinicNames.push({
                label: clinic.name,
                value: clinic._id,
              });
            }
            await this.setState({
              clinicNames: modifiedClinicNames,
            });
          }

          await this.getFacilityFormFields(this.state.clinicNames);
          // await this.getLocationFormFields(this.state.clinicNames, this.state.transferredLocationOptions, this.state.transferredSubLocationOptions);
        }
      );
    } else {
      this.updateFormFields();
      this.props.change("transferLocation", "");
      this.props.change("transferSubLocation", "");
    }
  };

  getTransferSublocation = (option) => {
    if (option && option.value) {
      if (this.state.locations && this.state.locations.length > 0) {
        let index = this.state.locations.findIndex(
          (item) => item._id === option.value
        );
        if (index != -1) {
          let subLocations = this.state.locations[index]["cartParts"];
          let transferredSubLocationOptions = [];
          for (let location of subLocations) {
            transferredSubLocationOptions.push({
              label: location,
              value: location,
            });
          }
          this.setState({
            transferredSubLocationOptions: transferredSubLocationOptions,
            storageType: option.storageType,
          });
        }
      }
    }
  };

  updateFormFields = () => {
    let formFields = this.state.formFields;
    if (formFields && formFields.length > 1) {
      formFields.splice(1, 2);
      this.setState({
        formFields: formFields,
      });
    }
  };

  getLocations = async (clinicId) => {
    // this.props.change("transferLocation", "");
    // this.props.change("transferSubLocation", "");
    await this.props.load({
      transferLocation: "",
      transferSubLocation: "",
    });
    // let transferredLocationOptions = this.state.transferredLocationOptions,
    //   transferredSubLocationOptions = this.state.transferredSubLocationOptions,
    //   clinicNames = this.state.clinicNames;
    let transferredLocationOptions = [],
      transferredSubLocationOptions = [],
      clinicNames = [];
    if (
      clinicId
      // &&
      // transferredLocationOptions &&
      // transferredLocationOptions.length == 0
    ) {
      fetchMethodRequest("GET", `${apiCalls.CartLocations}`).then(
        (response) => {
          if (response && response.locations && response.locations.length > 0) {
            for (let location of response.locations) {
              if (
                location &&
                location.clinics &&
                location.clinics._id &&
                location.clinics._id === clinicId
              ) {
                transferredLocationOptions.push({
                  label: location.cart,
                  value: location._id,
                  storageType: location.storageType,
                });
              }
            }

            if (
              this.props.type == "Storage Unit Items" &&
              this.state.formFields.length == 2
            ) {
              this.getLocationFormFields(
                clinicNames,
                transferredLocationOptions,
                transferredSubLocationOptions
              );
            }
            // else {
            //   this.getLocationFormFields(
            //     clinicNames,
            //     transferredLocationOptions,
            //     transferredSubLocationOptions
            //   );
            // }

            this.setState({
              transferredLocationOptions: transferredLocationOptions,
              locations: response.locations,
            });
          }
        }
      );
    } else {
      this.getLocationFormFields(
        clincNames,
        transferredLocationOptions,
        transferredSubLocationOptions
      );
    }
  };

  getLocationFormFields = (
    clinicNames,
    transferredLocationOptions,
    transferredSubLocationOptions
  ) => {
    let formFields = this.state.formFields;
    formFields.push(
      {
        label: "Transferred Location",
        fieldName: "transferLocation",
        required: true,
        placeholder: "Select Transferred Location",
        options: transferredLocationOptions,
      },
      {
        label: "Transferred Sublocation",
        fieldName: "transferSubLocation",
        required: true,
        placeholder: "Select Transferred Sublocation",
        options: transferredSubLocationOptions,
      }
    );
    this.setState({
      formFields: formFields,
      isLoading: false,
    });
  };

  getFacilityFormFields = async (clinicNames) => {
    let formFields = this.state.formFields;
    formFields.push({
      label: "Facility",
      fieldName: "clinic",
      required: true,
      placeholder: "Select Facility",
      options: clinicNames,
    });
    await this.setState({
      formFields: formFields,
      isLoading: false,
    });
  };
  changeFields = (name, value) => {
    if (this.props.type == "Storage Unit Items") {
      if (name == "useType" && value != "clinic") {
        this.setState({
          formFields: [
            {
              label: "Reason",
              fieldName: "useType",
              required: true,
              placeholder: "Select Reason",
              options: this.reasonOptions,
            },
          ],
        });
      }
      if (name == "useType" && value == "transfer") {
        this.props.load({
          clinic: {},
        });
      }
      if (name == "clinic") {
        // this.props.load({
        //   // transferLocation: this.state.selectedTransferLocation ? {} : null,
        //   // transferSubLocation: {},
        // });
      }
      if (name == "transferLocation") {
        this.setState({ selectedTransferLocation: value });
      }
      if (name == "transferSubLocation") {
        this.setState({ selectedTransferSubLocation: value });
      }
    }
  };
  render() {
    const { handleSubmit } = this.props;
    const modalClass = classNames({
      "modal-dialog--colored": false,
      "modal-dialog--header": true,
    });

    return (
      <div>
        <Modal
          isOpen={this.props.openReasonModal}
          className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
        >
          <Loader loader={this.state.isLoading} />
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.closeReasonModal}
            />
            <p className="bold-text  modal__title">Add Reason</p>
          </ModalHeader>
          <ModalBody className="deleteModalBody">
            <form
              key={this.props.type}
              onSubmit={handleSubmit(this.updateReason)}
              autoComplete={"off"}
            >
              <div className="col-sm-12">
                <div className="col-sm-12">
                  <ButtonToolbar className=" modal__footer footerData justify-content-end">
                    <Button
                      color="primary"
                      outline
                      onClick={this.closeReasonModal}
                      className="deleteModalBtn marginRight"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      className="deleteModalBtn"
                    >
                      Submit
                    </Button>
                  </ButtonToolbar>
                </div>
                <div className="row form">
                  {this.state.formFields.map((item, index) => {
                    return (
                      <div className="col-md-6 text-left" key={index}>
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            {item.label}
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              key={item.fieldName}
                              name={item.fieldName}
                              component={Select}
                              options={
                                item.fieldName == "clinic"
                                  ? this.state.clinicNames
                                  : item.fieldName == "transferLocation"
                                  ? this.state.transferredLocationOptions
                                  : item.fieldName == "transferSubLocation"
                                  ? this.state.transferredSubLocationOptions
                                  : item.options
                              }
                              placeholder={item.placeholder}
                              type={this.props.type}
                              validate={[required]}
                              getTransferredLocation={(option) => {
                                item.fieldName === "useType"
                                  ? this.getTransferredLocation(option)
                                  : item.fieldName == "clinic"
                                  ? this.getLocations(option.value)
                                  : item.fieldName == "transferLocation"
                                  ? this.getTransferSublocation(option)
                                  : null;
                              }}
                              setFields={this.changeFields}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AddReasonForItemModal = reduxForm({
  form: "Reason Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(AddReasonForItemModal);

// You have to connect() to any reducers that you wish to connect to yourself
AddReasonForItemModal = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),

  { load: loadAccount } // bind account loading action creator
)(AddReasonForItemModal);
