import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StorageUnits from './components/StorageUnits';

const StorageUnitsList = ({ t }) => (
  <Container>
    <StorageUnits />
  </Container>
);

StorageUnitsList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(StorageUnitsList);
