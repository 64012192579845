import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { load as loadAccount } from './../../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OrderModal from './OrderModal'
import fetchMethodRequest from '../../../config/service';
import OrderConfirmation from '../Modals/OrderConfirmationModal'
// Loader
import Loader from '../../App/Loader';

// Slect
import Select from '../../../shared/components/form/Select'
import config from '../../../config/config';

export default class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      label: '',
      status: '',
      ShiftType: null,
      isOpenSuggestModal: false,
      suggestGroupInfo: [],
      selectedDate: null,
      type: null,
      openOrderModal: false,
    };
  }

  componentDidMount = () => {
    console.log("viewmodal")
    this.props.onRef(this);
    this.setState({
      type: 'TOP OF CART'
    })
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  getRowData = async (rowData) => {
    if (this.props.type == 'Storage Unit') {
      this.props.change('inventoryType', 'TOP OF CART')
      this.setState({
        type: 'TOP OF CART'
      })
      await this.getDrawerDropdownValues()
    }
    this.setState({
      rowData: rowData,
    });
  };
  openOrderModal = async (product, description, volume, needed, ordered) => {
    await this.setState({
      orderProductName: product,
      orderDescription: description,
      orderNeeded: needed,
      orderConcentration: volume,
    })
    if (ordered) {
      await this.setState({
        openOrderConfirmation: true
      })
    }
    else {
      this.setState({
        openOrderModal: true,
      })
    }
  }
  closeOrderModal = () => {
    this.setState({
      openOrderModal: false
    })
  }
  getDrawerDropdownValues = () => {
    let body = {
      locationId: this.props.locationId
    }
    console.log("viewmodal", this.props.locationId)
    return fetchMethodRequest('GET', `locations/cartView?locationId=${this.props.locationId}`, body)
      .then(async (response) => {
        if (response) {
          this.setState({
            info: response
          })
          for (let item of response) {
            for (let key of item) {
              if (key._id == this.state.type) {
                this.setState({
                  product: key.productName,
                  expiringInFiveWeeks: key.expiringInFiveWeeks,
                  limit: key.limit,
                  count: key.count,
                  expired: key.expiredCount
                })
              }
            }
          }
        }
      });
  }

  openOrderConfirmation = async () => {
    this.setState({
      openOrderModal: true,
      openOrderConfirmation: false,
    })
  }
  closeOrderConfirmation = async () => {
    this.setState({
      openOrderConfirmation: false,
    })
  }

  updateInventoryType = async (option) => {
    await this.setState({
      type: option.value,
      product: null,
      expiringInFiveWeeks: null,
      limit: null,
      count: null,
      expired: null
    })

    if (this.props.type == 'Storage Unit') {
      await this.getDrawerDropdownValues()
    }
  }

  //display table for RecommendedInventory screen
  renderRecommendedInventoryTable() {
    return (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />

        {/* Expirations Table */}
        <Row style={{ background: '#ffffff' }}>
          <div style={{ width: "100%" }}>
            <div className="col-12 modalTbody pl-0 pr-0" style={{ overflow: 'hidden', maxHeight: '500px', overflowY: 'auto' }}>
              <Table>
                <thead style={{ position: 'sticky', top: 0, background: '#3475aa' }}>                  <tr>
                  <th className="text-white">S.No</th>
                  <th className="text-white">Product Name</th>
                  <th className="text-white">Description</th>
                  <th className="text-white">Dosage</th>
                  <th className="text-white">Required</th>
                  <th className="text-white">Inventory Type</th>
                  <th className="text-white">Location Type</th>
                  <th className="text-white">Category Name</th>
                  <th className="text-white">Category Type</th>
                </tr>
                </thead>
                <tbody style={{ maxHeight: 80 }}>
                  {this.props && this.props.displayData && this.props.displayData.recommendedInventory && this.props.displayData.recommendedInventory.length > 0 ?
                    this.props.displayData.recommendedInventory.map((inventoryItem, j) => (
                      <tr key={j}>
                        <td>{inventoryItem.productName ? j + 1 : ""}</td>
                        <td className="word_align text-left">{inventoryItem.productName}</td>
                        <td className="word_align text-left">{inventoryItem.description ? inventoryItem.description : ""}</td>
                        <td>{inventoryItem.dosage}</td>
                        <td>{inventoryItem.required}</td>
                        <td>{inventoryItem.inventoryType !== undefined && inventoryItem.inventoryType !== "" ? inventoryItem.inventoryType : "-"}</td>
                        <td>{inventoryItem.locationType}</td>
                        <td>{inventoryItem.categoryName}</td>
                        <td>{inventoryItem.categoryType ? inventoryItem.categoryType : ""}</td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    );
  }





  render() {
    return (
      <div>
        <Modal isOpen={this.props.openViewModal} centered className={this.props.type === 'Recommended Inventories' ? '' : 'ViewModalHeight'} style={this.props.type === 'Recommended Inventories' ? { maxWidth: '90%', width: 'auto', maxHeight: '80vh' } : {}}>
          <ModalHeader style={{ justifyContent: "center" }}>
            <b>{this.props.type} Details</b>
            {this.props.type == 'Storage Unit' ?
              <span>
                <br />
                <span style={{ marginTop: 10, color: config.templateColor, fontWeight: 700 }}>
                  Cart Name - {this.props.locationName}
                </span>
              </span>
              : null}
            <button className="lnr lnr-cross modal__close-btn" type="button"
              onClick={this.props.closeViewModal} />

          </ModalHeader>
          <ModalBody>
            {this.state.rowData && this.state.rowData.length > 0 ?
              <div className="row form">
                {this.state.rowData.map((item, i) => {
                  return (
                    item.value != "" || null ?
                      <div className="col-sm-6" key={i}>
                        <div className="row">
                          <div
                            className="col-sm-5"
                            style={{ textAlign: "left", padding: "5px 5px" }}>
                            <span style={{ fontWeight: "bold" }}>
                              {item.label}
                            </span>
                          </div>

                          <div className="col-sm-7"
                            style={{ textAlign: "left", padding: "5px 0px" }}>
                            <span>{item.value}</span>
                          </div>
                        </div>
                      </div> : null
                  );
                })}
              </div>
              : null}

            {this.props.type == 'Storage Unit' ?
              <Container className="dashboard" style={{ width: 700 }}>

                <hr></hr>

                <Loader loader={this.state.isLoading} />

                {/* Expirations Table */}
                <Row style={{ background: '#ffffff' }}>
                  <div className='col-6 m-0 p-4 form form--horizontal'>{
                    this.props.type == 'Storage Unit' ?
                      <div className="form__form-group">
                        <span className="form__form-group-label mt-3" ><b>Sub Cart Location</b></span>
                        <div className="form__form-group-field mt-2">
                          < Field name={'inventoryType'}
                            component={Select}
                            placeholder={'Select'}
                            options={this.props.drawers}
                            updateInventoryType={this.updateInventoryType}
                            type={this.props.type}
                          />
                        </div>
                      </div>

                      : null
                  }</div>
                  <Col lg={12} xl={12} className='mt-0'>
                    <Card className="dashBoardCardWithScroll" style={{ padding: '0px', height: '270px' }} >
                      <CardBody style={{ padding: '0px', height: '200px' }}>
                        {/* <div className="card__title">
                          <h5 className="bold-text">Cart Sub Location Products</h5>
                        </div> */}
                        <Table className="table--bordered table--head-accent table-fixed" style={{ borderBottom: '1px #F9F9F9 solid' }} responsive hover>
                          <thead>
                            <tr style={{ background: config.templateColor }}>
                              {/* <th style={{ width: 8 + '%' }}>#</th> */}
                              <th style={{ width: 12 + '%', color: 'whitesmoke', borderRight: '1px solid white' }}>Product</th>
                              <th style={{ width: 14 + '%', color: 'whitesmoke', borderRight: '1px solid white' }}>Description</th>

                              <th style={{ width: 12 + '%', color: 'whitesmoke', borderRight: '1px solid white' }}>Required</th>
                              <th style={{ width: 12 + '%', color: 'whitesmoke', borderRight: '1px solid white' }}>Current</th>
                              <th style={{ width: 11 + '%', color: 'whitesmoke', borderRight: '1px solid white' }}>Need To Order</th>
                              <th style={{ width: 22 + '%', color: 'whitesmoke', borderRight: '1px solid white' }}>Expiring In 4weeks</th>
                              <th style={{ width: 16 + '%', color: 'whitesmoke' }}>Order</th>

                            </tr>
                          </thead>
                          <tbody>
                            {this.state.info ? this.state.info.map((cart, i) => cart.map((item) => (item._id == this.state.type ? <tr>
                              <td style={{ width: 12 + '%', textTransform: 'capitalize', textAlign: 'center' }}>{item.productName ? item.productName : null}</td>
                              <td style={{ width: 14 + '%', textTransform: "capitalize", textAlign: 'center' }}>{item.description ? item.description : null}</td>
                              <td style={{ width: 12 + '%', textTransform: "capitalize", textAlign: 'center' }}>{item.limit ? item.limit : null}</td>

                              <td style={{ width: 12 + '%', textTransform: "capitalize", textAlign: 'center' }}> {item.count ? item.count : item.expiredCount == 0 ? '0' : null}</td>

                              <td style={{ width: 11 + '%', textAlign: 'center' }}>
                                <span >
                                  {item.needed ? item.needed : (item.limit && item.count) ? item.limit - item.count >= 0 ? item.limit - item.count : '-' : null}</span>
                              </td>
                              <td style={{ width: 22 + '%', textTransform: "capitalize", textAlign: 'center' }}> {item.expiringInFiveWeeks ? 'yes' : 'no'}</td>

                              <td style={{ width: 16 + '%', textAlign: 'left', maxWidth: 100 + '%' }}><span><Button className="ml-auto" style={{ width: 101, padding: 0 }} color="primary" onClick={() => this.openOrderModal(item.productName, item.description, item.volume, (item.needed ? item.needed : (item.limit && item.count) ? item.limit - item.count : null), item.isOrdered)} outline><span style={{ fontSize: 12 }}>Add&nbsp;To</span><br /><span style={{ fontSize: 12 }}>Orders List</span></Button></span></td>

                            </tr> : null))) : null}

                          </tbody>
                          <hr></hr>
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container > : null}

            {this.props.type === 'Recommended Inventories' ? this.renderRecommendedInventoryTable() : null}

          </ModalBody>
          <ModalFooter style={{ width: "100%", height: "auto", padding: 10 }}>
            {this.props.type !== 'Recommended Inventories' ?
              <Button color="primary" onClick={this.props.closeViewModal}>
                Close
              </Button>
              : null}
          </ModalFooter>
        </Modal>
        {
          this.state.openOrderModal ?
            <OrderModal
              onRef={(ref) => (this.confirmRef = ref)}
              openOrderModal={this.state.openOrderModal}
              locationId={this.props.locationId}
              productId={this.props.productId}
              closeOrderModal={this.closeOrderModal}
              product={this.state.orderProductName}
              orderDescription={this.state.orderDescription}
              orderNeeded={this.state.orderNeeded}
              orderConcentration={this.state.orderConcentration}
              orderProductId={this.state.orderProductId}
              orderlocationId={this.state.orderlocationId}
            />
            : null
        }
        {
          this.state.openOrderConfirmation ?
            <OrderConfirmation
              Order={this.state.openOrderConfirmation}
              openOrderConfirmation={this.openOrderConfirmation}
              closeOrderConfirmation={this.closeOrderConfirmation}
            />
            : null
        }
      </div >

    );
  }
}
ViewModal = reduxForm({
  form: "View Form", // a unique identifier for this form
})(ViewModal);

// You have to connect() to any reducers that you wish to connect to yourself
ViewModal = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),

  { load: loadAccount } // bind account loading action creator
)(ViewModal);

