import React, { Component } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import { Button, Modal, ModalBody, ButtonToolbar } from 'reactstrap';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

export default class StorageUnitModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colored: false,
            header: true,
            selectedOption: null,
            rowData: this.props.rowData
        };
        this.options = [
            { label: "Consumed", value: "consumed" },
            { label: "Recalled", value: "recalled" },
            { label: "Expired", value: "expired" }
        ];
    }
    /**
     * 
     * this function should calls when clicking on the submit button 
     */
    onSubmit = () => {
        let apiUrl = `${apiCalls.storageUnitSubmit}?lotId=${this.props.rowData.lotId}`;
        var requestBody = {
            useType: this.state.selectedOption,
            itemId: this.props.rowData._id
        };

        return fetchMethodRequest('POST', apiUrl, requestBody)
            .then(async (response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');
                    this.props.getDataFromServer()
                    this.props.closeStorageUnitModal(false)

                } else {
                    showToasterMessage(response.errorMessage, 'error');

                }
            });
    }




    /**
   * 
   * this function should calls whenever we change the value in dropdown
   */
    onFilterChange = (e, name) => {
        this.setState({ selectedOption: e.value });
    };

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });

        return (
            <div>
                <Modal isOpen={this.props.openStorageModal} className={'modal-dialog-centered modal-dialog--primary'} style={{ width: '30%' }}>
                    <ModalBody className='deleteModalBody'>
                        <div className="form__form-group col-sm-8">
                            <div className="form__form-group-field">
                                <Dropdown
                                    style={{ width: '100%', marginTop: '40px', marginLeft: '7rem' }}
                                    name="categoryType"
                                    value={this.state.selectedOption}
                                    options={this.options}
                                    placeholder='Select'
                                    filterMatchMode="contains"
                                    onChange={(e) => this.onFilterChange(e, "dropDown")}
                                />
                            </div>
                        </div>
                        <div >
                            <ButtonToolbar style={{ marginLeft: '120px', marginTop: '50px', marginBottom: '30px' }} >
                                <Button
                                    color="primary"
                                    onClick={this.onSubmit}
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={this.props.closeStorageUnitModal}
                                >
                                    Cancel
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
