// import React from "react";
// import RolePermissions from "../../../Permissions/Permissions";

// // Table
// import TableRender from "../../../CommonDataTable/TableRender";

// //config file
// import apiCalls from "../../../../../config/apiCalls";
// import fetchMethodRequest from "../../../../../config/service";
// import config from "../../../../../config/config";

// let phoneval = (value) =>
//   value
//     ? config.phoneNumberRegex.test(value)
//       ? undefined
//       : "Enter a valid Phone Number"
//     : configMessage.fillField;
// export default class DoctorsTable extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       clinics: [],
//       allClinicsName: [],
//     };
//   }

//   componentDidMount = async () => {
//     this.getRolesDropDown();
//     return fetchMethodRequest("GET", apiCalls.Clinics).then(
//       async (response) => {
//         if (response && response.clinics && response.clinics.length > 0) {
//           let multiSelectclinics = [],
//             singleSelectclinics = [],
//             allClinics = [];
//           for (let clinics of response.clinics) {
//             allClinics.push(clinics.name);
//             multiSelectclinics.push({
//               label: clinics.name,
//               value: {
//                 clinicId: clinics._id,
//                 name: clinics.name,
//               },
//             });
//             singleSelectclinics.push({
//               label: clinics.name,
//               value: JSON.stringify({
//                 clinicId: clinics._id,
//                 name: clinics.name,
//               }),
//             });
//           }
//           await this.setState({
//             clinics: {
//               multiSelectclinics: multiSelectclinics,
//               singleSelectclinics: singleSelectclinics,
//             },
//             allClinicsName: allClinics,
//           });
//         }
//       }
//     );
//   };
//   getRolesDropDown = async () => {
//     let users = [
//       { label: "Admin", value: "Admin" },
//       { label: "Staff", value: "Staff" },
//     ];

//     await this.setState({
//       staffRoles: users,
//     });
//     // return fetchMethodRequest('GET', apiCalls.Roles)
//     //     .then(async (response) => {
//     //         if (response && response.staffRoles) {
//     //             let users = [{ label: 'Admin', value: 'Admin' },
//     //             { label: 'Staff', value: 'Staff' },]
//     //             for (let option of response.staffRoles) {
//     //                 users.push({
//     //                     label: option.staffRole,
//     //                     value: option.staffRole
//     //                 })
//     //             }
//     //             await this.setState({
//     //                 staffRoles: users
//     //             })
//     //         }

//     //     })
//   };

//   getTableFields = () => {
//     let data = [
//       {
//         textAlign: "left",
//         width: 100,
//         field: "displayName",
//         header: "Name",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 125,
//         field: "email",
//         header: "Email",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "center",
//         width: 105,
//         field: "clinics",
//         header: "Facility",
//         filter: true,
//         sortable: true,
//       },
//       {
//         textAlign: "left",
//         width: 125,
//         field: "phone",
//         header: "Phone",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },

//       {
//         textAlign: "center",
//         width: 110,
//         field: "role",
//         header: "Permissioned Role",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "center",
//         width: 90,
//         field: "Actions",
//         header: "Actions",
//         filter: false,
//         sortable: false,
//       },
//     ];
//     let screenPermissions = RolePermissions.screenPermissions("Facility Staff");
//     if (screenPermissions && screenPermissions != "edit") {
//       data.pop();
//     }
//     return data;
//   };

//   getFormFields = () => {
//     return [
//       {
//         required: true,
//         value: "",
//         type: "input",
//         name: "firstName",
//         label: "First Name",
//         id: "firstName",
//         placeholder: "First Name",
//       },
//       {
//         required: true,
//         value: "",
//         type: "input",
//         name: "lastName",
//         label: "Last Name",
//         id: "lastName",
//         placeholder: "Last Name",
//       },
//       {
//         required: true,
//         value: "",
//         type: "input",
//         name: "email",
//         label: "Email",
//         id: "email",
//         placeholder: "email@gmail.com",
//       },
//       {
//         required: true,
//         value: "",
//         type: "input",
//         name: "phone",
//         label: "PhoneNumber",
//         id: "phone",
//         placeholder: "phone number",
//         validate: { phoneval },
//       },
//       {
//         required: true,
//         value: "",
//         type: "dropDown",
//         name: "role",
//         label: "Permissioned Role",
//         id: "role",
//         options: this.state.staffRoles,
//         placeholder: "Role",
//       },
//     ];
//   };

//   render() {
//     return (
//       <div>
//         <TableRender
//           type="Facility Staff"
//           globalSearch="Name/Email/Facility/Permissioned Role"
//           tableFields={this.getTableFields}
//           formFields={this.getFormFields}
//           {...this.props}
//           clinics={this.state.clinics}
//           allClinicsName={this.state.allClinicsName}
//         />
//       </div>
//     );
//   }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetchMethodRequest from "../../../../../config/service";
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount = () => {
    let screenPermissions = RolePermissions.screenPermissions('Facility Staff');
    this.setState({
      screenPermissions: screenPermissions
    })
    //console.log(screenPermissions)
    this.getRolesDropDown()
    this.getClinicsDropDown()
  }
  getRolesDropDown = async () => {
    let users = [
      { label: "Admin", value: "Admin" },
      { label: "Staff", value: "Staff" },
    ];
    // await this.setState({
    //   staffRoles: users,
    // });
    return fetchMethodRequest('GET', api.Roles)
      .then(async (response) => {
        if (response && response.staffRoles) {
          let users = [{ label: 'Admin', value: 'Admin' },
          { label: 'Staff', value: 'Staff' },]
          for (let option of response.staffRoles) {
            users.push({
              label: option.staffRole,
              value: option.staffRole
            })
          }
          await this.setState({
            staffRoles: users
          })
        }

      })
  };
  getClinicsDropDown = async () => {
    return fetchMethodRequest("GET", api.Clinics).then(
      async (response) => {
        if (response && response.clinics && response.clinics.length > 0) {
          let multiSelectclinics = [],
            singleSelectclinics = [],
            allClinics = [];
          for (let clinics of response.clinics) {
            allClinics.push(clinics.name);
            multiSelectclinics.push({
              label: clinics.name,
              value: {
                clinicId: clinics._id,
                name: clinics.name,
              },
            });
            singleSelectclinics.push({
              label: clinics.name,
              value: JSON.stringify({
                clinicId: clinics._id,
                name: clinics.name,
              }),
            });
          }
          await this.setState({
            clinics: multiSelectclinics,
            allClinicsName: allClinics,
          });
        }
      }
    );
  };
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 140,
        "field": "displayName",
        "mobile": true,
        "header": "Name",
        "filter": false,
        "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "email",
        "mobile": true,
        "header": "Email",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 140,
        "field": "clinics",
        "mobile": true,
        "header": "Facility",
        "filter": false,
        // "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "phone",
        "mobile": true,
        "header": "Phone",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "role",
        "mobile": true,
        "header": "Permissioned Role",
        "filter": false,
        "sortable": true
      },




    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }
  //   getFormFields = () => {
  //     return [
  //       {
  //         required: true,
  //         value: "",
  //         type: "input",
  //         name: "firstName",
  //         label: "First Name",
  //         id: "firstName",
  //         placeholder: "First Name",
  //       },
  //       {
  //         required: true,
  //         value: "",
  //         type: "input",
  //         name: "lastName",
  //         label: "Last Name",
  //         id: "lastName",
  //         placeholder: "Last Name",
  //       },
  //       {
  //         required: true,
  //         value: "",
  //         type: "input",
  //         name: "email",
  //         label: "Email",
  //         id: "email",
  //         placeholder: "email@gmail.com",
  //       },
  //       {
  //         required: true,
  //         value: "",
  //         type: "input",
  //         name: "phone",
  //         label: "PhoneNumber",
  //         id: "phone",
  //         placeholder: "phone number",
  //         validate: { phoneval },
  //       },
  //       {
  //         required: true,
  //         value: "",
  //         type: "dropDown",
  //         name: "role",
  //         label: "Permissioned Role",
  //         id: "role",
  //         options: this.state.staffRoles,
  //         placeholder: "Role",
  //       },
  //     ];
  //   };

  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "firstName",
        "label": "First Name",
        "id": "firstName",
        "placeholder": "FirstName",
        "type": "text",
        "required": true,
        mandatory: true

      },
      {
        "value": "",
        "name": "lastName",
        "label": "LastName",
        "id": "lastName",
        "placeholder": "Last Name",
        "type": "text",
        "required": true,
        mandatory: true

      },

      {
        "value": "",
        "name": "email",
        "label": "Email",
        "id": "email",
        "placeholder": "Email",
        "type": "email",
        "required": true,
        mandatory: true

      },
      {
        "value": "",
        "name": "phone",
        "label": "Phone Number",
        "id": "phoneNumber",
        "placeholder": "Phone Number",
        "type": "text",
        "normalize": false,
        "required": true,
        mandatory: true

      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "role",
        label: "Permissioned Role",
        id: "role",
        options: this.state.staffRoles,
        placeholder: "Role",
        mandatory: true

      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "clinic",
        isMultiSelect: true,
        label: "Facilities",
        id: "clinic",
        options: this.state.clinics,
        placeholder: "Facilities",
        mandatory: true

      },
      // {
      //     "value": "",
      //     "name": "status",
      //     "label": "Status",
      //     "id": "status",
      //     "placeholder": "Status",
      //     "type": "dropDown",
      //     "options": config.statusTypes,
      //     "required": true
      // },

    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={true}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={'Name / Email / Role'}
        type="Facility Staff"
        apiResponseKey='users'
        apiUrl={api.SuperUsers}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)
