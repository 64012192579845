import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom'
import apiCalls from "../../config/apiCalls";
import fetchMethodRequest from "../../config/service";
import Loader from '../App/Loader';

export default class LoginCheck extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isPasswordChanged: true,
            isResponseChecked: false
        };
    }

    componentDidMount = async () => {
        localStorage.removeItem('loginCredentials');
        localStorage.removeItem('rolePermissions');
        localStorage.removeItem('loginBody');

        let body = {
            loginToken: this.props.match.params && this.props.match.params.accessToken ?
                this.props.match.params.accessToken : null,
            entityType: 'user'
        }
        return fetchMethodRequest('POST', `${apiCalls.loginCheck}`, body)
            .then(async (response) => {
                if (response) {
                    let tokenInfo = {
                        accessToken: response.accessToken,
                        refreshToken: response.refreshToken
                    }
                    let userData = { ...tokenInfo };
                    if (response.details) {
                        userData = { ...userData, ...response.details };
                    }
                    localStorage.setItem("loginCredentials", JSON.stringify(userData));
                    if (userData && userData.rolePermissions) {
                        // save user permissions in storage
                        localStorage.setItem('rolePermissions', JSON.stringify(userData.rolePermissions));
                    }
                    this.setState({
                        isPasswordChanged: userData.isPasswordChanged,
                        isLoading: false,
                        isResponseChecked: true
                    })
                }
            });
    }

    render() {
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                {this.state.isResponseChecked ?
                    !this.state.isPasswordChanged ?
                        <Redirect to='/loginChangePassword' /> :
                        <Redirect to='/home' />
                    : null
                }
            </div>
        );
    }
}