// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe,
    StripeProvider,
    CardCvcElement
} from "@stripe/react-stripe-js";
// import axios from "axios"
import React, { useState } from 'react'
// import React, { PureComponent } from 'react';
import { Modal, ModalBody, ModalHeader, Card, CardBody, Button, ButtonToolbar, Table } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Redirect } from "react-router";

import FormModal from '../Form/FormModal';
import apiCalls from '../../config/apiCalls';
import fetchMethodRequest from '../../config/service';
import configMessages from '../../config/configMessages'
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

import validate from '../Validations/validate';
import CompanySuccessModal from "../Cruds/List/FacilityStaff/components/MailSentConfirmationModal";

import Loader from '../App/Loader';
import DefaultInput from '../../shared/components/form/DefaultInput';
import Select from '../../shared/components/form/Select';
import configImages from "../../config/configImages";
// import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

const CARD_OPTIONS_Cardnumber = {
    iconStyle: "solid",
    placeholder: "Enter card number",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#5d6066",
            fontWeight: 450,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            borderColor: 'black',
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "#9c9c9c" }
        },
        invalid: {
            iconColor: "red",
            color: "red"
        }
    }
}
const CARD_OPTIONS_Month_Cvv = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#5d6066",
            fontWeight: 450,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "14px",
            borderColor: 'black',
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "#9c9c9c" }
        },
        invalid: {
            iconColor: "#red",
            color: "#red"
        }
    }
}

export default function PaymentForm(props) {
    const [createSuccess, setCreateSuccess] = useState(false)
    const [description, setDescription] = useState('false')
    const [amount, setAmount] = useState(props.info.match.params.amount)
    const [loginRedirect, setloginRedirect] = useState(JSON.parse(localStorage.getItem("paymentDetails")))

    const [isEmailConfirmationModal, setIsEmailConfirmationModal] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    function getDescription(values) {
        const value = values.target.value
        setDescription(value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            card: elements.getElement(CardExpiryElement),
            card: elements.getElement(CardCvcElement),
        })


        if (!error) {
            try {
                const { id } = paymentMethod
                let userBody = { 'amount': parseInt(amount), id, 'description': description }
                let loginDetails = JSON.parse(localStorage.getItem("loginBody"))
                userBody.email = loginDetails.email
                userBody.role = "admin"
                if (loginRedirect) {
                    let data = []
                    for (let i = 0; i < loginRedirect.length; i++) {
                        data.push(loginRedirect[i].billID)
                    }
                    userBody.billIds = data
                }
                return fetchMethodRequest('POST', `${apiCalls.Payment}?type=web`, userBody)
                    .then(async (response) => {

                        if (response && response.respCode == 204) {
                            showToasterMessage(response.respMessage, 'success');
                            setIsEmailConfirmationModal(true)
                        } else if (response && response.errorMessage) {
                            showToasterMessage(response.errorMessage, 'error');
                        }

                    })

            } catch (error) {
                console.log("Error", error)
            }
        } else {
            console.log(error.message)
        }
    }

    return (
        <>
            {!createSuccess ?
                <div>
                    <div className='row'>
                        <span className='col-lg-6 col-sm-12' style={{ fontSize: 20, marginTop: 10, height: 40 }}>
                            Credit/Debit Card
                        </span>
                        {/* <span className='col-2'></span> */}
                        <span className='col-lg-6 col-sm-12'>
                            <img src={configImages.CardsIcon} style={{ width: 200, borderRadius: 0, height: 40, marginTop: 5 }}></img>
                        </span>
                    </div>
                    <hr />
                    <form onSubmit={handleSubmit} style={{ paddingBottom: 40 }}>

                        <fieldset className="FormGroup">
                            <div className="FormRow payment_fields">
                                <CardNumberElement options={CARD_OPTIONS_Cardnumber} />

                            </div>
                        </fieldset>

                        {/* <fieldset className="FormGroup">
                <input
                 name="name"
                 placeholder="Enter card holder's name" 
                 className="payment_input"
                //  style={{border:'none'}}
                />
            </fieldset> */}
                        <fieldset className="FormGroup row" style={{ margin: 'auto' }}>
                            <div className="FormRow col-5 payment_fields">
                                <CardExpiryElement options={CARD_OPTIONS_Month_Cvv} />
                            </div>
                            <div className='col-1'></div>
                            <div className="FormRow col-5 payment_fields">
                                <CardCvcElement options={CARD_OPTIONS_Month_Cvv} />
                            </div>
                        </fieldset>
                        <fieldset className="FormGroup">
                            <textarea
                                rows='4'
                                name="description"
                                placeholder="Description"
                                className="payment_input"
                                onChange={getDescription}
                            //  style={{border:'none'}}
                            />
                        </fieldset>
                        <div className="account__btns" style={{ marginTop: 10, marginBottom: 10 }}>
                            <Button className="ml-auto mt-2" color='primary'
                                style={{ margin: 'auto', paddingX: 10, backgroundColor: '#3f4545', width: '90%', color: '#f5f5f5' }}
                            >Pay ${amount}</Button>
                        </div>
                        <CompanySuccessModal
                            isEmailConfirmationModal={isEmailConfirmationModal}
                            closeModal={(e) => setCreateSuccess(true)}
                            mailSentMessage={"Company created successfully."}
                        />
                        {loginRedirect ?
                            <div className="mt-3">
                                <Table>
                                    <thead style={{ backgroundColor: "#3475aa", color: "white" }}>
                                        <tr>
                                            <th style={{ color: "white" }}>S.No</th>
                                            <th style={{ color: "white" }}>BillID</th>
                                            <th style={{ color: "white" }}>Each Cart</th>
                                            <th style={{ color: "white" }}>No Of Carts</th>
                                            <th style={{ color: "white" }}>Pay Amount($)</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ maxHeight: 80 }}>
                                        {loginRedirect && loginRedirect.length > 0 ?
                                            loginRedirect.map((item, i) => {
                                                return <tr>
                                                    <td > {item.billID ? i + 1 : ""}</td>
                                                    <td>{item.billID}</td>
                                                    <td>{item.eachCart}</td>
                                                    <td>{item.noOfCarts}</td>
                                                    <td>${item.payAmount}</td>
                                                </tr>
                                            }) : ""}
                                        <tr style={{ borderTop: "1px solid black" }}>
                                            <td > </td>
                                            <td></td>
                                            <td></td>
                                            <td ><b>Total</b></td>
                                            <td><b>${amount}</b></td>
                                        </tr>
                                    </tbody>

                                </Table>
                            </div> : null}
                    </form>
                </div >
                :
                <Redirect to="/log_in" />
            }

        </>
    )
}