import React, { PureComponent } from 'react';
export default class QrModal extends PureComponent {
    constructor() {
        super();
        this.state = {
            modal: false,
            text: ''
        };
    }

    render() {
        return (
            <div>
                <div className="modal__body p-1" id='printable_area'>
                    <img id="qr-img" style={{ width: '10%', height: '10%', marginLeft: 200, borderRadius: 0 }}
                        src={this.props.location.pathname.slice(4, this.props.location.pathname.length)}
                        alt="QR Code" />
                </div>
            </div>
        );
    }
}
