import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import OrderModal from '../../../Modals/OrderModal'
import OrderConfirmation from '../../../Modals/OrderConfirmationModal'
import RolePermissions from '../../../Permissions/Permissions'
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
console.log(params)
class SupplyCabinets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },

    }
  }
  componentDidMount = async () => {
    this.orderDisable()
    let screenPermissions = RolePermissions.screenPermissions('Recall Items');
    this.setState({
      screenPermissions: screenPermissions
    })
    console.log(screenPermissions)
  }
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": 'center',
        width: 45,
        selectionMode: 'multiple',
        field: 'New',
        filter: false,
        sortable: false,
        placeholder: 'Search'
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "productName",
        "mobile": true,
        "header": "Product Name",
        "filter": false,
        "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "clinicName",
        "mobile": true,
        "header": "Facility",
        "filter": false,
        "sortable": true,
        "fieldStyle" :"capitalize"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "productsInCarts",
        "mobile": true,
        "header": "Storage Units",
        "filter": false,
        "sortable": true,
        "fieldStyle" :"capitalize"
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 180,
      //   "field": "isOrdered",
      //   "mobile": true,
      //   "header": "Order Exists",
      //   "filter": false,
      //   "sortable": true,
      // }
      //   {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 180,
      //     "field": "status",
      //     "mobile": true,
      //     "header": "Status",
      //     "filter": false,
      //     "sortable": true,
      //   },

      //   {
      //     "show": true,
      //     "textAlign": "center",
      //     "width": 140,
      //     "field": "role",
      //     "mobile": true,
      //     "header": "Role",
      //     "filter": true,
      //     "sortable": true,
      //     "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //     "fieldType": 'Role',
      //   },


      //   {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active','color':'success' }, { "label": "Pending", "value": 'Pending','color':'danger' }, { "label": "Inactive", "value": 'Inactive','color':'warning' }],
      //   },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }
  getFormFields = () => {
    return ([
      {
        required: true, value: '', type: 'autoComplete', name: 'lotId',
        searchApi: 'quantities', searchField: 'lotId',
        label: 'Lot Id', id: 'Lot Id', placeholder: 'Search Lot Id',
      },
      {
        value: '', type: 'text', name: 'productName',
        label: 'Product', id: 'products', placeholder: 'Product', disable: true
      },
      {
        required: true, value: '', type: 'textarea', name: 'reason',
        label: 'Reason', id: 'reason', placeholder: 'Type the Reason'
      }
    ]);
  }
  getFormFields = () => {
    return ([
      {
        value: '',
        type: 'autoComplete',
        searchApi: api.Quantities,
        searchField: 'lotId',
        name: 'lotId',
        // filterValue: 'cart',
        // filterField: "cart",
        label: 'Lot Id',
        show: true,
        id: 'Lot Id',
        placeholder: 'Search Lot Id',
        options: [{
          'productName': 'title1'
        }, {
          'title1': 'title1'
        }, {
          'lotId': 'lotId'
        }],
      },
      {
        "value": "",
        "name": "productName",
        "label": "Product",
        "id": "Product",
        "placeholder": "Product",
        "type": "text",
        "disable": true
      },
      {
        "value": "",
        "name": "reason",
        "label": "Reason",
        "id": "Reason",
        "placeholder": "Reason",
        "type": "textArea",
        "required": true
      }

    ]);
  }
  closeOrderModal = () => {
    this.setState({
      openOrderModal: false
    })
  }
  orderDisable = () => {
    let screenPermissions = RolePermissions.screenPermissions("Orders");
    if (screenPermissions && screenPermissions != "edit") {
      this.setState({
        disableOrder: true,
      });
    }
  };
  addToOrderList = async (item) => {
    if (!this.state.disableOrder) {
      await this.setState({
        orderProductName: item[0].productName,
        orderFrom: "Missing Items",
        orderCart: item[0].cartName,
        orderDescription: item[0].description,
        orderConcentration: item[0].volume,
        orderNeeded: item[0].needed,
      });
      console.log(item[0])
      if (item[0].isOrdered) {
        console.log('hii')
        await this.setState({
          openOrderConfirmation: true,
        });
      } else {
        this.setState({
          openOrderModal: true,
        });
      }
    } else {
      showToasterMessage("You are not authorized", "error");
    }
  };
  openOrderConfirmation = async () => {
    this.setState({
      openOrderModal: true,
      openOrderConfirmation: false,
    });
  };

  closeOrderConfirmation = async () => {
    this.setState({
      openOrderConfirmation: false,
    });
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <DataTables
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          addRequried={false}
          editRequired={this.state.screenPermissions === 'edit' ? true : false}
          deleteRequired={this.state.screenPermissions === 'edit' ? true : false}
          viewRequired={true}
          settingsRequired={true}
          globalSearch={'Product Name / Facility'}
          type="Items Recalled"
          apiResponseKey='recalls'
          params={params}
          apiUrl={api.Recalls}
          actionsTypes={[{
            'name': 'AddToOrderList',
            'action': this.addToOrderList,
            "options": [
              { 'label': 'Add to Order List', 'value': 'Add to Order List', 'show': true, "multiple": false },
            ]
          }]}
          displayViewOfForm='modal'
          routeTo
        />
        {this.state.openOrderModal ? (
          <OrderModal
            onRef={(ref) => (this.confirmRef = ref)}
            openOrderModal={this.state.openOrderModal}
            closeOrderModal={this.closeOrderModal}
            product={this.state.orderProductName}
            orderFrom={this.state.orderFrom}
            orderClinic={this.state.orderClinic}
            orderCart={this.state.orderCart}
            orderClinicName={this.state.orderClinicName}
            orderDescription={this.state.orderDescription}
            orderConcentration={this.state.orderConcentration}
            orderNeeded={this.state.orderNeeded}
          />
        ) : null}
        {this.state.openOrderConfirmation ? (
          <OrderConfirmation
            Order={this.state.openOrderConfirmation}
            openOrderConfirmation={this.openOrderConfirmation}
            closeOrderConfirmation={this.closeOrderConfirmation}
          />
        ) : null}
      </div>
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(SupplyCabinets)