
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import RolePermissions from '../../../Permissions/Permissions'

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
console.log(params)
class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },

    }
  }
  componentDidMount = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let screenPermissions = RolePermissions.screenPermissions('Storage Unit Checks');
    this.setState({
      screenPermissions: screenPermissions,
      params: params
    })
    console.log(screenPermissions)
  }

  // componentWillUnmount=async()=>{
  //   this.props.onRef(null)
  // }

  getOrdersFields = () => {
    let data = [
      { textAlign: 'left', width: 135, field: 'checkType', header: 'Frequency', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 135, field: 'name', header: 'Check Type', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 135, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },

      { textAlign: 'left', width: 135, field: 'cart', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 135, field: 'address', header: 'Cart Address', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 135, field: 'created', header: 'Date', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },

      { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return data;
  };
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": "50px",
        "field": "Sno",
        "header_lang": "Sno",
        "header": "Sno",
        "sortable": false,
        "filter": false,
        "mobile": false,
        "fieldType": "Text",
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 120,
        "field": "checkType",
        "mobile": true,
        "header": "Frequency",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize",
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "name",
        "mobile": true,
        "header": "Check Type",
        "filter": false,
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "clinicName",
        "mobile": true,
        "header": "Facility",
        "filter": false,
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "cart",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize",
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "address",
        "mobile": true,
        "header": "Address",
        "filter": false,
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 80,
        "field": "created",
        "mobile": true,
        "header": "Date",
        "filter": false,
        "fieldType": "Date",
        type: 'date',
        'dateFormat': 'mm-DD-YYYY',
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "isBatteryExist",
        "header": 'Actions',
        "filter": false,
        "sortable": false,
      },


    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }
  getFormFields = async () => {
    let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
    return ([
      {
        required: true, value: '', type: 'radio', name: 'isChecked', label: 'Storage Unit Checked',
        id: 'checked', options: options
      },
    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        onRef={(ref) => (this.datatableref = ref)}
        editRequired={true}
        deleteRequired={true}
        match={this.props.match}
        params={this.state.params}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={'Check Type / Facility / Storage Unit'}
        type="Storage Unit Checks"
        apiResponseKey='checks'
        apiUrl={api.WeeklyChecks}
        formUrl='checks'
        hasQueryInCall={true}
        filterRequired={false}
        homeLocation={this.props.location.search}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)
