import React from 'react';
import configImages from '../../../config/configImages';
import LogInForm from './components/LogInForm';
const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__title row mb-3" style={{ alignItems: 'center', justifyContent: 'center' }}>
          <b className='pt-2' style={{ fontSize: 25 }}>Welcome to</b>
          <span className="account__logo">
            <span className="account__logo-accent">
              <img src={configImages.logoImage} style={{ width: 163 }} />
            </span>
          </span>
        </div>
        <LogInForm onSubmit />
      </div>
    </div>
  </div>
);

export default LogIn;
