import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classNames from 'classnames';

// config file
export default class DeleteRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colored: false,
            header: true,
        };
    }

    deleteSelectedRow = (type) => {
        this.props.deleteSelectedRow(type, this.props.rowData && this.props.rowData._id ? this.props.rowData._id : "");
    }

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div>
                <Modal isOpen={this.props.openDeleteModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass} deleteModalWidth`}
                >
                    <ModalBody className='deleteModalBody'>
                        <div className='deleteModalText'>
                            {/* {this.props.confirmModalText} */}
                            <p>Are you sure want to Delete ? </p>
                        </div>
                        {this.props.type == 'Storage Unit Items'&& this.props.type =="Storage Unit Checks" && this.props.type == "Battery Checks" ?
                            <Button color="primary" outline
                                onClick={() => this.deleteSelectedRow('keepRequired')}
                                className='deleteModalBtn'>
                                Keep Required
                            </Button>
                            : null}
                        <Button color="primary"
                            onClick={() => this.deleteSelectedRow('delete')}
                            className='deleteModalBtn'>
                            {this.props.type == 'Storage Unit Items' && this.props.type =="Storage Unit Checks" && this.props.type == "Battery Checks" ? 'Delete Permanently' : 'Yes'}
                        </Button>
                        <Button color="primary" outline onClick={this.props.closeDeleteModal} className='deleteModalBtn marginRight'>No</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}