import React from 'react';
import ForgotPasswordPage from './components/ForgotPassword';
const ForgotPasswordScreen = () => (
    <div className="account">
        <div className="account__wrapper">
            <div className="account__card">
                <div style={{ textAlign: 'center', marginBottom: 15 }}>
                    <div className='topbar__logo' style={{ marginLeft: 110 }}></div>
                    <h3>Forgot Password</h3>
                </div>
                <ForgotPasswordPage onSubmit />
            </div>
        </div>
    </div>
);

export default ForgotPasswordScreen;
