import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersList from './components/OrdersList'

const OrdersListList = (props) => (

    <Container>
        <OrdersList {...props} />
    </Container>
);

OrdersListList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(OrdersListList);

