/* eslint indent: "off" */
import React from "react";
import { Route, Switch } from "react-router-dom";
import MainWrapper from "../App/MainWrapper";
import Layout from "../Layout/index";
import { Container } from "reactstrap";

// Login
import Landing from "../Landing";
import LoginCheck from "../Landing/loginCheck";

//Register
import Register from "./../Register/index";

// OtpVerification
import OtpVerification from './../OtpVerification/index';

// Dashboard
import Home from "../Dashboard";

// Profile
import OrdersList from "../Cruds/List/OrdersList";
import PlacedOrders from "../Cruds/List/PlacedOrders";
import FulfilledOrders from "../Cruds/List/FulfilledOrders";

import ChangePassword from "../Account/ChangePassword";
import ChangeRecoverPassword from "../Account/ChangeRecoverPassword";
import ForgotPassword from "../Account/ForgotPassword";
import PaymentForm from "../Account/PaymentForm";
import Profile from "../Account/Profile";
import LoginChangePassword from "../Account/LoginChangePassword";

// Tables
import QrModal from "../Cruds/Modals/QrModal";

// Facilities
import Facilities from "../Cruds/List/Facilties";
import CartLocations from "../Cruds/List/CartLocations";
import StorageUnits from "../Cruds/List/StorageUnits";
import SupplyCabinets from "../Cruds/List/SupplyCabinets";
import OrSuites from "../Cruds/List/OrSuites";

// Required Inventory
import RequiredInventory from "../Cruds/List/RequiredInventory";

// Inventory
import CartItems from "../Cruds/List/CartItems";
import BatteryChecks from "../Cruds/List/BatteryChecks";
import MissingItems from "../Cruds/List/MissingItems";
import Track from "../Cruds/List/Track";
import Recalls from "../Cruds/List/Recalls";
import RecallItems from "../Cruds/List/RecallItem";
import ItemsRecalled from "../Cruds/List/ItemsRecalled";
import StorageUnitModal from "../Cruds/List/StorageUnitItem/StorageUnitModal";
import CartChecks from "../Cruds/List/CartChecks";
import InventoryChecksList from "../Cruds/List/InventoryChecks/InventoryChecksModal";
import ExpirationChecksModal from "../Cruds/List/ExpirationChecks/ExpirationChecksModal";
import Expirations from "../Cruds/List/Expirations";
import RequiredInventoryTable from "../Cruds/List/RequiredInventory/components/RequiredInventoryTable"

// Administration
import Orders from "../Cruds/List/Orders";
import Distributers from "../Cruds/List/Distributers";
import Categories from "../Cruds/List/Categories";
import Products from "../Cruds/List/ProductDirectory";
import Payment from "../Cruds/List/Payment";
import Staff from "../Cruds/List/FacilityStaff";
import Activities from "../Cruds/List/Activities";
import Roles from "../Cruds/List/FacilityStaffRoles";
import Payments from "../Payments/StripeContainer"
// Reports
import Reports from "../Cruds/List/Reports";
import Recommended from "../Cruds/List/RecommendedInventories";
import RequredInventoryNew from "../Cruds/List/RequiredInventoryNew/RequredInventoryNew";
import StorageUnitNew from "../Cruds/List/StorageUnitNew/StorageUnitNew";
// Tickets
import Tickets from "../Cruds/List/Tickets";

// 404 page not found
import ErrorNotFound from "../Account/404";
import LoginNotFound from "../Account/404/LoginError";
import MissingChecksModal from "../Cruds/List/MissingChecks/MissingChecksModal";
import OrdersFrontScreen from "../Cruds/List/OrderChecks/OrdersFrontScreen";

import userLogScreen from "../PlayStoreLogin/userLoginFormPlayStore"

const Tables = () => {
  return (
    <Switch>
      <Route path="/home" component={Home} />

      <Route
        path="/missingItems/:locationId"
        render={(props) => <MissingItems {...props} />}
      />
      <Route
        path="/missingItems/:cart"
        render={(props) => <InventoryChecksList {...props} />}
      />
      <Route
        path="/track/:locationId"
        render={(props) => <Track {...props} />}
      />
      <Route
        path="/batterychecks/:cart"
        render={(props) => <BatteryChecks {...props} />}
      />
      <Route
        path="/cartchecks/:locationId"
        render={(props) => <CartChecks {...props} />}
      />
      <Route path="/recall/:cart" render={(props) => <Recalls {...props} />} />
      <Route
        path="/checks/:cart"
        render={(props) => <CartChecks {...props} />}
      />
      <Route
        path="/expirationdates/:locationId"
        render={(props) => <Expirations {...props} />}
      />
      <Route
        path="/missingItems/:locationId"
        render={(props) => <MissingItems {...props} />}
      />
      <Route
        path="/missingItemsTable/:locationId/:cart/:facilityName"
        render={(props) => <MissingItems {...props} />}
      />
      <Route
        path="/missingItemsTable"
        render={(props) => <MissingItems {...props} />}
      />
      <Route
        path="/expirationdatesTable"
        render={(props) => <Expirations {...props} />}
      />
      <Route
        path="/ordersTable"
        render={(props) => <Orders {...props} />}
      />
      <Route
        path="/requiredInventoryTable"
        render={(props) => <RequiredInventoryTable {...props} />}
      />
      <Route
        path='/storageUnitTable'
        render={(props) => <StorageUnits {...props} />}
      />

      <Route
        path="/inventorychecklist/:locationId/:cart/:clinic/:area/:room/:floor/:building/:company/:email/:companyId/:excel"
        render={(props) => <InventoryChecksList {...props} />}
      />
      <Route
        path='/storageunititems/:locationId'
        render={(props) => <CartItems {...props} />}
      />
      <Route
        path='/storageunititemsTable'
        render={(props) => <CartItems {...props} />}
      />




      {/* Facilities */}
      <Route path="/facilities" component={Facilities} />
      {/* <Route path="/locations" component={StorageUnits} /> */}
      <Route path="/cartlocations" component={CartLocations} />
      <Route path="/supplycabinets" component={SupplyCabinets} />
      <Route path="/orsuites" component={OrSuites} />

      {/* Required Inventory */}
      {/* <Route path="/requiredInventory" component={RequiredInventory} /> */}


      {/* Inventory */}
      {/* <Route path="/storageunititems" component={CartItems} /> */}
      <Route path="/inventorychecklist" component={InventoryChecksList} />
      <Route path="/expirationdates" component={ExpirationChecksModal} />
      <Route path="/storageunititems" component={StorageUnitModal} />
      <Route path="/missingItems" component={MissingChecksModal} />
      <Route path="/requiredInventory" component={RequredInventoryNew} />
      <Route path='/locations' component={StorageUnitNew} />



      <Route path="/recommended" component={Recommended} />
      <Route path="/recall" component={Recalls} />
      <Route path="/recallitems" component={RecallItems} />
      <Route path="/itemsrecalled" component={ItemsRecalled} />
      <Route path="/checks" component={CartChecks} />
      <Route path="/batterychecks" component={BatteryChecks} />
      <Route path="/track" component={Track} />

      {/* Administration */}
      <Route path="/facilityStaff" component={Staff} />
      <Route path="/categories" component={Categories} />
      <Route path="/productdirectory" component={Products} />
      <Route path="/reports" component={Reports} />
      <Route path="/distributors" component={Distributers} />
      <Route path="/facilitystaffroles" component={Roles} />
      <Route path="/payment" component={Payment} />
      <Route path="/help" component={Tickets} />
      <Route path="/activities" component={Activities} />
      <Route path="/orderslist" component={OrdersList} />
      <Route path="/placedorders" component={PlacedOrders} />
      <Route path="/fulfilledorders" component={FulfilledOrders} />

      {/* <Route path="/orders" component={Orders} /> */}
      <Route path="/orders" component={OrdersFrontScreen} />

      <Route path="/" component={Account} />
    </Switch>
  );
};

const ErrorCheck = () => (
  <MainWrapper>
    <main className="error">
      <Switch>
        <ErrorNotFound />
      </Switch>
    </main>
  </MainWrapper>
);

const Account = () => (
  <Switch>
    <Route path="/changepassword" component={ChangePassword} />
    <Route path="/profile" component={Profile} />
    <Route path="/PaymentForm" component={PaymentForm} />
    <Route path="/" component={ErrorCheck} />
  </Switch>
);

const wrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials
    ? JSON.parse(localStorage.loginCredentials)
    : false;
  if (loginCredentials) {
    return (
      <div>
        <Layout />
        <div className="container__wrap">
          <Route path="/" component={Tables} />
        </div>
      </div>
    );
  } else {
    return <LoginNotFound />;
  }
};

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route
          path="/changeRecoverPassword/:enEmail"
          render={(props) => <ChangeRecoverPassword {...props} />}
        />
        <Route
          path="/loginCheck/:accessToken"
          render={(props) => <LoginCheck {...props} />}
        />
        <Route path="/loginChangePassword" component={LoginChangePassword} />

        <Route exact path="/" component={Landing} />
        <Route path="/log_in" component={Landing} />

        <Route path="/UserLog" component={userLogScreen} />

        <Route path="/register" component={Register} />
        <Route path="/payments/:amount" component={Payments} />
        <Route path="/otpVerification" component={OtpVerification} />
        <Route path="/logout" component={Landing} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/qr/:qrcode" render={(props) => <QrModal {...props} />} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
