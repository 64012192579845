const validate = (values) => {
  const errors = {};

  let fields = ['firstName', 'lastName', 'newPassword', 'confirmPassword', 'dateOfBirth', 'joinDate', 'phoneNumber', 'skypeId', 'bitbuketId', 'laptopSNO', 'pancardNumber', 'aadharcardNumber'];

  fields.forEach((field) => {
    if (!values[field]) {
      let fieldName = capitalize(field);
      errors[field] = `${fieldName} field shouldn’t be empty`;
    }
  });

  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.companyEmail) {
    errors.companyEmail = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.companyEmail = 'Invalid email address';
  }
  //password Validation

  var passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/;
  if (!values.password) {
    errors.password = 'Password field shouldn’t be empty';
  }
  else if (!passwordRegexp.test(values.newPassword)) {
    errors.password = 'Please Enter Valid Password.';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Password field shouldn’t be empty';
  }
  else if (!passwordRegexp.test(values.newPassword)) {
    errors.newPassword = 'Please Enter Valid Password.';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Password field shouldn’t be empty';
  }
  else if (!passwordRegexp.test(values.confirmPassword)) {
    errors.confirmPassword = 'Confirm Password and Create Password are not same';
  } else if (values.confirmPassword !== values.newPassword) {
    errors.confirmPassword = 'Confirm Password and Create Password are not same';
  }


  if (!values.select) {
    errors.select = 'Please select the option';
  }

  return errors;
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default validate;