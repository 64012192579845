import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from './../../../../redux/reducers/commonReducer';
import { loginUserInfo } from './../../../../redux/actions/authActions';

import { Link } from 'react-router-dom';
// file upload
import RenderFileInputField from '../../../Form/components/FileUpload';

// validate
import validate from './validate';

// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import SessionModal from '../../../Cruds/Modals/SessionExpirationModal';

const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
    <div className="form__form-group-input-wrap">
      <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );



renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class Profile extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      openSessionModal: false
    };
  }

  componentDidMount = async () => {
    this.props.onRef(this);
    await this.getUserData();
  }

  componentWillUnmount = () => {
    this.props.onRef(null);
  }


  // get data from server based on Id
  getUserData = async () => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      this.setState({ userId: user._id });
      let Url = `${apiCalls.SuperUsers}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          let sessionexpired = localStorage.getItem("sessionexpired");
          if (sessionexpired == 'true') {
            this.setState({
              openSessionModal: true
            })
            localStorage.setItem('sessionexpired', false);
            return;
          }
          if (response && response.details) {
            if (response.details.photo) {
              this.setState({ pic: response.details.photo });
            }
            this.props.load(response.details);
            let userInfo = { ...user, ...response.details };

            // Store login info in redux store
            await this.props.updateLoginUserInfo(userInfo);
            localStorage.setItem('loginCredentials', JSON.stringify(userInfo));
          } else if (response && response.errorMessage) {
          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
  }

  getFileName = (file) => {
    this.setState({ pic: file });
  }

  render() {
    const { handleSubmit, reset, t } = this.props;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Profile</h5>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">First Name</span>
                  <div className="form__form-group-field">
                    <Field
                      name="firstName"
                      component={renderField}
                      type="text"
                      placeholder='FirstName'
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <Field
                      name="email"
                      component={renderField}
                      type="email"
                      placeholder="Email"
                      isDisable={true}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Company</span>
                  <div className="form__form-group-field">
                    <Field
                      name="companyName"
                      component={renderField}
                      type="text"
                      placeholder="Company"

                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Status</span>
                  <div className="form__form-group-field">
                    <Field
                      name="status"
                      component={renderField}
                      type="text"
                      placeholder="Status"
                    />
                  </div>
                </div>


              </div>


              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Last Name</span>
                  <div className="form__form-group-field">
                    <Field
                      name="lastName"
                      component={renderField}
                      type="text"
                      placeholder='Last Name'

                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Permissioned Role</span>
                  <div className="form__form-group-field">
                    <Field
                      name="role"
                      component={renderField}
                      type="text"
                      placeholder="Permissioned Role"
                    />
                  </div>
                </div>


                <div className="form__form-group">
                  <span className="form__form-group-label">Profile Pic</span>
                  <div className="form__form-group-field">

                    <Field
                      name="photo"
                      component={RenderFileInputField}
                      onRef={(ref) => (this.profileUploadRef = ref)}
                      url={'uploads?uploadPath=user&entityType=user/' + this.state.userId}
                      label='Upload photo'
                      type='profile'
                      acceptType="image/*"
                      getFileName={this.getFileName}
                    />
                  </div>
                  <div className='col-md-2' style={{ padding: '20px' }}>
                    <div style={{ justifyContent: 'center' }}>
                      {(this.state.pic) ?
                        <img src={`${config.imgUrl}user/${this.state.pic}`} className='detailsImgStyle' />
                        : null
                      }
                    </div>
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Link to={'/Home'}>
                    <Button style={{ marginLeft: '50px' }} color='primary'
                    >Cancel</Button>
                  </Link>

                  <Button color="primary" type="submit">Submit</Button>

                </ButtonToolbar>

              </div>
            </form>
          </CardBody>
        </Card>

        {/* Session Expiration Modal */}
        <SessionModal openSessionModal={this.state.openSessionModal} />

      </Col >
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Profile = reduxForm({
  form: "ProfileForm", // a unique identifier for this form
  enableReinitialize: true,
  validate
})(Profile);

// You have to connect() to any reducers that you wish to connect to yourself
Profile = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount }// bind account loading action creator
)(Profile);

const mapDispatchToProps = (dispatch) => {
  return {
    updateLoginUserInfo: (userInfo) => dispatch(loginUserInfo(userInfo))
  }
}

export default connect(null, mapDispatchToProps)(Profile);
