
import React from 'react';
import RolePermissions from '../../../Permissions/Permissions';

// Table 
import TableRender from '../../../CommonDataTable/TableRender';

// config file
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service'

export default class Recalls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clinics: [],
            products: [],
            locations: []
        };
    }

    componentDidMount = () => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.setState({ params: params })

        if (this.props.match.path == '/recall/:cart') {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
    }

    onBackButtonEvent = () => {
        if (this.props.match.path == '/recall/:cart') {
            if (window.location.pathname != 'inmedtory/home') {
                window.location.pathname = 'inmedtory/home'
            }
        } else {
            return window
        }
    }

    getTableFields = (tabType) => {
        let apiUrl = this.state.apiUrl;
        if (tabType == 'Items Recalled' || tabType == 'Recall a Item') {
            apiUrl = apiCalls.Recalls;
        }

        this.setState({
            tabType: tabType,
            apiUrl: apiUrl
        })
        let data = [];
        if (tabType == 'Recall a Item') {
            data = this.getRecallFields();
        } else if (tabType == 'Items Recalled') {
            data = this.getRecalledFields();
        } else {
            data = this.getRecallFields();
        }
        return data;
    };

    getRecallFields = () => {
        let data = [
            { textAlign: 'center', width: 135, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'createdByName', header: 'Recalled By', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'created', header: 'Recalled Date', filter: true, sortable: true },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Recall Items');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };
    getRecalledFields = () => {
        let data = [
            { textAlign: 'left', width: 135, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'volume', header: 'Volume', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'productsInCarts', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 135, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'isOrdered', header: 'Order Exists', filter: true, sortable: true, placeholder: 'Search' },

            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Recall Items');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'lotId',
                searchApi: 'quantities', searchField: 'lotId',
                label: 'Lot Id', id: 'Lot Id', placeholder: 'Search Lot Id', mandatory: true

            },
            {
                value: '', type: 'text', name: 'productName',
                label: 'Product', id: 'products', placeholder: 'Product', disable: true, mandatory: true

            },
            {
                required: true, value: '', type: 'textarea', name: 'reason',
                label: 'Reason', id: 'reason', placeholder: 'Type the Reason', mandatory: true

            }
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Recall Items'
                    globalSearch='Lot Id'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                    params={this.state.params}
                />
            </div>
        );
    }
}