// import React from "react";
// // Table
// import TableRender from "../../../CommonDataTable/TableRender";

// // config file
// import apiCalls from "../../../../../config/apiCalls";

// export default class MissingItems extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       clinics: [],
//       products: [],
//       locations: [],
//     };
//   }

//   componentDidMount = () => {
//     if (this.props.match.path == "/track/:locationId") {
//       window.history.pushState(null, null, window.location.pathname);
//       window.addEventListener("popstate", this.onBackButtonEvent);
//     }
//   };
//   onBackButtonEvent = () => {
//     if (this.props.match.path == "/track/:locationId") {
//       if (window.location.pathname != "inmedtory/home") {
//         window.location.pathname = "inmedtory/home";
//       }
//     } else {
//       return window;
//     }
//   };

//   getTableFields = () => {
//     let data = [
//       {
//         textAlign: "left",
//         width: 105,
//         field: "productName",
//         header: "Product Name",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },

//       {
//         textAlign: "left",
//         width: 105,
//         field: "lotId",
//         header: "Lot Id",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 105,
//         field: "context",
//         header: "Context",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 105,
//         field: "contextType",
//         header: "Context Type",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 140,
//         field: "locationId",
//         header: "Storage Unit",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//       {
//         textAlign: "left",
//         width: 140,
//         field: "createdBy",
//         header: "Created By",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },

//       {
//         textAlign: "left",
//         width: 105,
//         field: "created",
//         header: "Date",
//         filter: true,
//         sortable: true,
//         placeholder: "Search",
//       },
//     ];
//     return data;
//   };

//   getFormFields = () => {
//     return [
//       {
//         required: true,
//         value: "",
//         type: "text",
//         name: "lotId",
//         label: "Lot Id",
//         id: "Lot Id",
//         placeholder: "Lot Id",
//       },
//       {
//         required: true,
//         value: "",
//         type: "autoComplete",
//         name: "productName",
//         label: "Product",
//         id: "products",
//         placeholder: "Search Product",
//         searchApi: apiCalls.Products,
//         searchField: "title",
//       },

//       {
//         required: true,
//         value: "",
//         type: "textarea",
//         name: "reason",
//         label: "Reason",
//         id: "reason",
//         placeholder: "Type the Reason",
//       },
//     ];
//   };

//   render() {
//     return (
//       <div>
//         <TableRender
//           type="Track Items"
//           globalSearch="Lot Id"
//           tableFields={this.getTableFields}
//           formFields={this.getFormFields}
//           {...this.props}
//         />
//       </div>
//     );
//   }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
      params: {}
    }
  }
  componentDidMount = async () => {
    // this.props.onRef(this);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({
      params: params
    })
  }
  // componentWillUnmount=async()=>{
  //   this.props.onRef(null)
  // }
  getOrdersFields = () => {
    let data = [
      { textAlign: 'center', width: 45, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false, placeholder: 'Search' },
      { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'required', header: 'Quantity', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 100, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 90, field: 'orderedBy', header: 'Company Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 50, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return data;
  };
  getTableFields = () => {
    let data = [
      // {
      //   "show": true,
      //   "textAlign": 'center',
      //   width: 45,
      //   selectionMode: 'multiple',
      //   field: 'New',
      //   filter: false,
      //   sortable: false,
      //   placeholder: 'Search'
      // },
      {
        "show": true,
        "textAlign": "center",
        "width": "50px",
        "field": "Sno",
        "header_lang": "Sno",
        "header": "Sno",
        "sortable": false,
        "filter": false,
        "mobile": false,
        "fieldType": "Text",
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "requestJson.json.body.title1",
        "mobile": true,
        "header": "Product Name",
        "fieldType": "Complex",
        "filter": false,
        "field1": "ProductNames",
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 140,
        "field": "requestJson.json.body.lotId",
        "mobile": true,
        "header": "Lot Id",
        "fieldType": "Complex",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "context",
        "mobile": true,
        "header": "Context",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "contextType",
        "mobile": true,
        "header": "Context Type",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "requestJson.json.body.cartName",
        "fieldType": "Complex",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "field1": "cartName"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "requestJson.json.body.createdByName",
        "mobile": true,
        "header": "Created By",
        "fieldType": "Complex",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "created",
        "fieldType": "Date",
        "mobile": true,
        "header": "Date",
        "filter": false,
        "sortable": true,
      },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "firstName",
        "label": "POC First Name",
        "id": "firstName",
        "placeholder": "FirstName",
        "type": "text",
        "required": true
      },
      {
        "value": "",
        "name": "lastName",
        "label": "LastName",
        "id": "lastName",
        "placeholder": "POC Last Name",
        "type": "text",
        "required": true
      },

      {
        "value": "",
        "name": "email",
        "label": "POC Email",
        "id": "email",
        "placeholder": "Email",
        "type": "email",
        "required": true
      },
      {
        "value": "",
        "name": "phone",
        "label": "Phone Number",
        "id": "phoneNumber",
        "placeholder": "Phone Number",
        "type": "text",
        "normalize": false,
        "required": true
      },
      {
        "value": "",
        "name": "status",
        "label": "Status",
        "id": "status",
        "placeholder": "Status",
        "type": "dropDown",
        "options": config.statusTypes,
        "required": true
      },

    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        onRef={(ref) => (this.datatableref = ref)}
        editRequired={true}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={false}
        params={this.state.params}
        globalSearch={'Lot Id'}
        type="Track Items"
        flags={{ 'label': 'checked', 'value': null }}
        apiResponseKey='activities'
        apiUrl={api.Track}
        homeLocation={this.props.location.search}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)
