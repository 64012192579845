import React from 'react';

// Table 
import TableRender from '../../../CommonDataTable/TableRender';
import apiCalls from '../../../../../config/apiCalls';
import fetchMethodRequest from '../../../../../config/service'
import RolePermissions from '../../../Permissions/Permissions';

export default class MissingItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiUrl: `${apiCalls.Orders}?type=unchecked`,
            tabType: 'Orders List',
            settingInfo: {},
        };
    }

    componentDidMount = () => {
        this.getDistributorEmails();
    }

    getTableFields = (tabType) => {
        let apiUrl = this.state.apiUrl;
        if (tabType == 'Orders List') {
            apiUrl = `${apiCalls.Orders}?type=unchecked`;
        } else if (tabType == 'Placed Orders') {
            apiUrl = `${apiCalls.SentOrderss}?type=checked`;
        } else if (tabType == 'Fulfilled Orders') {

            apiUrl = `${apiCalls.SentOrderss}?type=fulfilled`;
        }
        this.setState({
            tabType: tabType,
            apiUrl: apiUrl
        })
        let data = [];
        if (tabType == 'Orders List') {
            data = this.getOrdersFields();
        } else if (tabType == 'Placed Orders') {
            data = this.getPlacedOrdersTableFields();
        } else if (tabType == 'Fulfilled Orders') {
            data = this.getFulfilledOrdersTableFields();
        } else {
            data = this.getOrdersFields();
        }
        return data;
    };

    getFormFields = (tabType) => {
        this.setState({
            tabType: tabType,
        })
        let data = [];
        if (tabType == 'Orders List') {
            data = this.getOrdersFormFields();
        } else if (tabType == 'Placed Orders') {
            data = this.getPlacedOrdersFormFields();
        }
        return data;
    };

    getDistributorEmails = () => {
        return fetchMethodRequest('GET', apiCalls.ClinicStaff)
            .then(async (response) => {
                if (response && response.users && response.users.length > 0) {

                    let distributorEmails = response.users;
                    let modifiedDistributors = [];
                    for (let distirbutor of distributorEmails) {
                        modifiedDistributors.push({
                            label: distirbutor.email,
                            value: distirbutor.email
                        })
                    }
                    this.setState({
                        distributorEmails: modifiedDistributors
                    });

                }
            });
    }

    getOrdersFields = () => {
        let data = [
            { textAlign: 'left', width: 25, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false, placeholder: 'Search' },
            { textAlign: 'center', width: 90, field: 'companyName', header: 'Company Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 180, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'facilityName', header: 'Facility Name', sortable: true, placeholder: 'Search', fieldStyle: "capitalize" },
            { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'required', header: 'Quantity', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Orders');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getPlacedOrdersTableFields = () => {
        let data = [
            { textAlign: 'center', width: 90, field: 'companyName', header: 'Company Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 150, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'facilityName', header: 'Facility Name', sortable: true, placeholder: 'Search', fieldStyle: "capitalize" },
            { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'required', header: 'Quantity', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 100, field: 'created', header: 'Ordered On', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 120, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
            // { textAlign: 'left', width: 70, field: 'invoiceNumber', header: 'Invoice Number', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 150, field: 'reSendEmail', header: 'Re-Send Email' },
            { textAlign: 'left', width: 50, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Orders');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFulfilledOrdersTableFields = () => {
        let data = [
            //facilityName
            { textAlign: 'center', width: 90, field: 'companyName', header: 'Company Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 180, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 70, field: 'facilityName', header: 'Facility Name', sortable: true, placeholder: 'Search', fieldStyle: "capitalize" },
            { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'required', header: 'Quantity', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 70, field: 'created', header: 'Fulfilled On', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 100, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 75, field: 'invoiceNumber', header: 'Invoice Number', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Orders');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getPlacedOrdersFormFields = async () => {
        return ([

            {
                required: true, value: '', type: 'number', name: 'required1', label: 'Number of Items Recieved',
                id: 'required', placeholder: 'Number of items Recieved', mandatory: true

            },

            {
                value: '', type: 'text', name: 'invoiceNumber', label: 'Invoice Number',
                id: 'required', placeholder: 'Invoice Number', mandatory: true

            },

        ]);
    }
    getOrdersFormFields = async () => {
        return ([

            {
                required: true, value: '', type: 'text', name: 'productName', label: 'Product Name',
                id: 'required', placeholder: 'Product Name', disable: true, mandatory: true

            },
            {
                required: false, value: '', type: 'dropDown', name: 'distributorEmail', label: 'Staff Email',
                id: 'required', placeholder: 'Staff Email', options: this.state.distributorEmails, mandatory: true

            },
            {
                required: true, value: '', type: 'number', name: 'required', label: 'Ordered Inventory',
                id: 'required', placeholder: 'Ordered', mandatory: true

            },
            {
                value: '', type: 'text', name: 'orderedBy', label: 'Ordered By',
                id: 'required', placeholder: 'Ordered By', disable: true, mandatory: true

            },

        ]);
    }

    render() {
        return (
            <div className='container'>
                <TableRender type='Orders'
                    tableFields={this.getTableFields}
                    apiUrl={this.state.apiUrl}
                    tabType={this.state.tabType}
                    formFields={this.getFormFields}
                    globalSearch='Product Name/Quantity/Email/Storage Unit'
                />
            </div>
        );
    }
}