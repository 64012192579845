import React from "react";
import { Button, Card, CardBody, Col, Container, Row, Table, Badge } from "reactstrap";
import { Redirect } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import Icon from "mdi-react/ArrowLeftIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cart from 'mdi-react/CartIcon';

// Config
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";

// Table
import TableRender from "../../CommonDataTable/TableRender";

// Toaster Message
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import DeleteRowModal from "../../Modals/DeleteRowModal";
// Permissions
import RolePermissions from "../../Permissions/Permissions";

import PaginatorComponent from "../../CommonDataTable/PaginatorComponent";
import FormModal from "../../../Form/FormModal";
// Loader
import Loader from "../../../App/Loader";
import configMessages from "../../../../config/configMessages";
// imporuseHistory from 'react-router-dom';

export default class StorageUnitNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: {},
            label: "",
            status: "",
            ShiftType: null,
            isOpenSuggestModal: false,
            suggestGroupInfo: [],
            selectedDate: null,
            type: null,
            openOrderModal: false,
            showInfo: true,
            openInventoryModal: false,
            delete: false,
            openDeleteModal: false,
            type: "Expiration Checks",
            isLoading: true,
            storageUnit: {},
            locationType: null,
            cartViewItems: [],
            duplicatecartViewItems: [],
            previousData: {},
            selectedOption: 'ENTIRE CART',
            headerInfo: [],
            locationId: null,
            totalRecordsLength: 0,
            productId: null,
            count: 0,
            openInventoryChecks: false,
            first: 0,
            rows: 10,
            isWeb: true,
            filterCriteria: { limit: 20, page: 1, criteria: [] },
            cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }],
            categoryType: null,
            categoryTypeOptions: [{ label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }],
            days: [
                { label: 'Sunday', value: 'Sunday' },
                { label: 'Monday', value: 'Monday' },
                { label: 'Tuesday', value: 'Tuesday' },
                { label: 'Wednesday', value: 'Wednesday' },
                { label: 'Thursday', value: 'Thursday' },
                { label: 'Friday', value: 'Friday' },
                { label: 'Saturday', value: 'Saturday' }
            ],
            statusTypes: [
                { label: 'All', value: null },
                { label: 'Pending', value: 'Pending' },
                { label: 'Active', value: 'Active' },
                { label: 'InActive', value: 'InActive' },
            ],
        };

    }

    componentDidMount = async () => {

        if (localStorage.getItem('storageUnit')) {
            localStorage.removeItem('storageUnit');
        }
        let screenPermissions = RolePermissions.screenPermissions(
            "Expiration Check List"
        );
        if (screenPermissions && screenPermissions != "edit") {
            await this.setState({
                noAdd: true,
            });
        }
        if (this.props.match.params.locationId) {
            if (this.props.match.params) {
                if (
                    this.props.match.params.locationId &&
                    this.props.match.params.cart &&
                    this.props.match.params.clinic
                ) {
                    this.getDrawerDropdownValues(
                        this.props.match.params.locationId,
                        this.props.match.params.cart,
                        this.props.match.params.clinic,
                        this.props.match.params.excel,
                        this.props.match.params.area,
                        this.props.match.params.building,
                        this.props.match.params.room,
                        this.props.match.params.floor,
                        this.props.match.params.email,
                        this.props.match.params.company,
                        this.props.match.params.companyId,
                        this.props.match.params.productId,
                    );
                }
            }
            this.setState({
                isLoading: false,
            });
        }
        else if (this.props.location) {
            if (this.props.location.pathname !== "/locations") {
                this.setState({
                    isLoading: true,
                });
                let cartName = this.props.location.pathname.slice(
                    20,
                    this.props.location.pathname.length
                );
                return fetchMethodRequest("GET", `${apiCalls.CartLocations}`).then(
                    async (response) => {
                        if (response && response.locations) {
                            for (let value of response.locations) {
                                if (value.cart == cartName) {
                                    this.getDrawerDropdownValues(
                                        value._id,
                                        value.cart,
                                        value.clinicName
                                    );
                                }
                            }
                            this.setState({
                                isLoading: false,
                            });
                        }
                    }
                );
            } else if (this.props.location.pathname == "/locations") {
                await this.getData();
                this.setState({
                    isLoading: false,
                });
            }
        }
    };


    getPaginator() {
        return (
            <PaginatorComponent
                totalRecords={this.state.totalRecordsLength}
                first={this.state.first}
                rows={this.state.rows}
                isWeb={true}
            />
        )
    }

    handleSelectCategoryChange = async (event) => {
        const selectedCategoryType = event.value;
        const filteredItems = this.state.allViewItems.filter((item) => {
            if (selectedCategoryType === "Medicine") {
                return item.categoryType === "Medicine";
            } else if (selectedCategoryType === "Non-Medicine") {
                return item.categoryType === "Non-Medicine";
            }
            return true;
        });

        await this.setState({ categoryType: selectedCategoryType, cartViewItems: filteredItems });
    };

    getRowData = async (rowData) => {
        this.props.change("inventoryType", "EXTERNAL");
        await this.getDrawerDropdownValues();
        this.setState({
            rowData: rowData,
        });
    };
    getData = () => {
        return fetchMethodRequest("GET", apiCalls.storageUnitsCount)
            .then(async (response) => {
                if (response && response.locations) {
                    this.setState({
                        locationsLength: response.locations
                    })
                    const formattedData = {
                        crashcart: [],
                        storage: [],
                        operatingRoom: [],
                    };

                    const allDocs = response.locations.flatMap(obj => obj.docs);

                    allDocs.forEach(field => {
                        if (field.storageType === "crashcart") {
                            formattedData.crashcart.push(field);
                        } else if (field.storageType === "storage") {
                            formattedData.storage.push(field);
                        } else if (field.storageType === "operatingRoom") {
                            formattedData.operatingRoom.push(field);
                        }
                    });
                    this.setState({
                        storageUnit: formattedData,
                    }, () => {
                        localStorage.setItem('storageUnitData', JSON.stringify(this.state.storageUnit));
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });
    };


    closeDeleteModal = () => {
        this.setState({
            openDeleteModal: false,
        });
    };




    deleteConfirmAction = (item, cart) => {
        this.setState({
            openDeleteModal: true,
            rowId: item._id,
            type: cart
        });
    };




    makeFalse = () => {
        this.setState({
            delete: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    closeFormModal = () => {
        this.setState({
            isOpenFormModal: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    openInventoryModal = async (cart, rowData) => {
        let data = {}
        if (rowData !== undefined) {
            data.productName = rowData.productName
            data.cartSubLocation = ""
            data.description = rowData.description
            data.volume = rowData.volume
            data.locationType = rowData.locationType
            data.expirationDate = rowData.expirationDate
            data.expirationDate1 = rowData.expirationDate1
            data.productId = rowData._id.productId
            data.title1 = rowData.title1
            data.volume = rowData.volume
            data.categoryType = rowData.categoryType

        }
        if (!this.state.noAdd) {
            await this.setState({
                openInventoryModal: true,
                inventoryCartName: cart,
                rowData: data
            });
        } else if (this.state.noAdd) {
            showToasterMessage("You are not authorized", "error");
        }
    };


    openOrderConfirmation = async () => {
        this.setState({
            openOrderModal: true,
            openOrderConfirmation: false,
        });
    };

    closeOrderConfirmation = async () => {
        this.setState({
            openOrderConfirmation: false,
        });
    };

    openOrderModal = async (
        product,
        subcart,
        description,
        needed,
        concentration,
        ordered,
        facilityName
    ) => {
        await this.setState({
            orderProductName: product,
            orderFrom: "Expiration Check List",
            orderCart: this.state.cartNumber,
            orderSubCart: subcart,
            ordercompanyName: this.state.cartViewItems[0].companyName,
            orderDescription: description,
            orderConcentration: concentration,
            orderNeeded: needed,
            orderFacilityName: facilityName,
            productId: productId,
        });
        if (ordered) {
            await this.setState({
                openOrderConfirmation: true,
            });
        } else {
            this.setState({
                openOrderModal: true,
            });
        }
    };

    closeOrderModal = () => {
        this.setState({
            openOrderModal: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    getDrawerDropdownValues = async (
        id,
        cartName,
        clinicName,
        locationId,
        facilityName,
        clinics,
        productId,
        excel,
        area,

    ) => {

        if (id) {
            if (!localStorage.getItem('storageUnit')) {
                localStorage.setItem('storageUnit', JSON.stringify({
                    cartName: cartName,
                    facilityName: clinicName
                }))
            }
            await this.setState({
                id: id,
                clinicName: clinicName,
                cartName: cartName,
                clinics: clinics,
                cartNumber: cartName,
                locationId: locationId,
                facilityName,
                excel: excel,
                isLoading: true,
                openInventoryChecks: true,
                isREdirectExpirationData: true,
                productId: productId,
            });


            if (area == "crashcart" || area == "storage" || area == "operatingRoom") {
                this.setState({
                    locationType:
                        area == "crashcart"
                            ? "Cart Location"
                            : area == "storage"
                                ? "Supply Cabinet"
                                : "OR Suite",
                });
            } else if (
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.locationType
            ) {
                if (
                    this.props.location.state.locationType == "crashcart" ||
                    this.props.location.state.locationType == "storage" ||
                    this.props.location.state.locationType == "operatingRoom"
                ) {
                    let type = this.props.location.state.locationType;
                    this.setState({
                        locationType:
                            type == "crashcart"
                                ? "Cart Location"
                                : type == "storage"
                                    ? "Supply Cabinet"
                                    : "OR Suite",
                    });
                } else {
                    this.setState({
                        locationType: this.props.location.state.locationType.replace(
                            /s$/,
                            ""
                        ),
                    });
                }
            }

            let apiUrl = `${apiCalls.LocationsCartView}?locationId=${locationId}`;
            return fetchMethodRequest(

                "GET",
                apiUrl,
            ).then(async (res) => {
                let response = res.quantity;
                let count = res.wishlistCount;
                let storageUnit = this.state.storageUnit;
                for (let object in storageUnit) {
                    var cartOptions = this.state.cartOptions;
                    if (object == area || storageUnit[object]) {
                        storageUnit[object].forEach((data, index) => {
                            if (data || data.cart === cart) {
                                for (var recordItem of data.cartParts) {
                                    const index = cartOptions.findIndex(object => object.label === recordItem);
                                    if (index === -1) {
                                        cartOptions.push({
                                            label: recordItem, value: recordItem
                                        })
                                    }
                                }
                                this.setState({
                                    cartOptions: cartOptions,
                                    count
                                })
                            }
                        });
                    }
                }
                this.setState({
                    cartOptions: cartOptions,
                    count
                })

                if (response && response.length > 0) {
                    let cartViewItems = [],
                        arr = [];
                    response.map((project) => {
                        if (project && project.data && project.data.length) {
                            project.data.map((item) => cartViewItems.push(item));
                        }
                    });

                    cartViewItems.sort((a, b) =>
                        a.productName.toUpperCase() > b.productName.toUpperCase()
                            ? 1
                            : b.productName.toUpperCase() > a.productName.toUpperCase()
                                ? -1
                                : 0
                    );
                    if (cartViewItems) {
                        for (let item of cartViewItems) {
                            if (item && item._id && item._id.cartSubLocation) {
                                if (arr.indexOf(item._id.cartSubLocation) === -1) {
                                    arr.push(item._id.cartSubLocation);

                                }
                            }
                        }
                    }
                    let headerInfo = arr.sort();
                    for (let item of headerInfo) {
                        let old_index = headerInfo.indexOf(item);
                        if (item == "TOP OF CART") {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") == -1 &&
                            item == "SIDE OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") != -1 &&
                            item == "SIDE OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") == -1 &&
                            headerInfo.indexOf("SIDE OF CART") == -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("SIDE OF CART") == -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") == -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") != -1 &&
                            headerInfo.indexOf("SIDE OF CART") != -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        }
                    }


                    await this.setState({
                        headerInfo: this.state.headerInfo && this.state.headerInfo.length && this.state.headerInfo.length > 0 ? this.state.headerInfo : ["ENTIRE CART"],
                        isLoading: false,
                        cartViewItems,
                        totalRecordsLength: cartViewItems.length,
                        duplicatecartViewItems: cartViewItems,
                        storageUnit: cartViewItems,
                        showInfo: true,
                    });

                    let event = { first: 0, rows: this.state.rows, page: 1 }
                } else {
                    this.setState({
                        cartViewItems: [],
                        headerInfo: [],
                        isLoading: false,
                        showInfo: true,
                    });
                }

            });

        }

    };

    openFormModal = async (type, rowData, keyIndex = 0) => {
        if (this.state.storageUnit && this.state.storageUnit[rowData]) {
            let objBody = this.state.storageUnit[rowData][keyIndex];
            const formValue = objBody[keyIndex];
            this.setState({ rowData: objBody })

        }
        await this.setState({
            isOpenFormModal: true,
            formType: type,
            selectedRows: "",
        });
        if (type == 'edit' || (type === "add" && rowData && rowData !== undefined)) {
            if (this.state.isOpenFormModal && this.formModalRef) {
                this.formModalRef.getRowData(this.state.rowData);
            }
        }
    };



    closeDeleteModal = async () => {
        await this.setState({
            openDeleteModal: false,
        });
    };


    /**
     * 
     *Delete row Data
     */
    deleteSelectedRow = (item) => {
        let method,
            url,
            body = {};
        method = "DELETE";
        url = this.state.rowId;
        if (this.state.type == "crashcart") {
            url = `locations/${url}?storageType=crashcart`;
        } else if (this.state.type == "storage") {
            url = `locations/${url}?storageType=storage`;
        } else if (this.state.type == "operatingRoom") {
            url = `locations/${url}?storageType=operatingRoom`;
        }
        return fetchMethodRequest(method, url)
            .then(async (response) => {
                this.setState({
                    openDeleteModal: false,
                });
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, "success");
                    this.getData();
                    this.onPageChange({ page: 0, rows: 20 });
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, "error");
                    this.getData();
                }
                this.props.makeFalse();
            })
            .catch((err) => {
                return err;
            });
    };

    deleteAction = async (rowData, keyIndex = 0) => {
        if (this.state.storageUnit && this.state.storageUnit[rowData]) {
            let objBody = this.state.storageUnit[rowData][keyIndex];
            const formValue = objBody[keyIndex];
            await this.setState({ rowData: objBody })
        }
        this.deleteConfirmAction(this.state.rowData, rowData);
    };

    getCartLocationFormFields = () => {
        let options = [
            { label: 'Required', value: 'required' },
            { label: 'Recommended', value: 'recommended' },

        ]
        return ([
            {
                required: true, value: '', type: 'autoComplete', searchApi: apiCalls.Clinics, searchField: 'name',
                name: 'clinics', label: 'Facility', id: 'clinic', placeholder: 'Search Facility Name', mandatory: true

            },
            { required: true, value: '', type: 'text', name: 'cart', label: 'Cart Name', id: 'Cart', placeholder: 'Cart Name', mandatory: true },

            // { value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building' },
            // { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
            // { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },

            // { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
            {
                required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On', id: 'Day',
                placeholder: 'Select Day', options: this.state.days, mandatory: true

            },
            {
                required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many drawers you want to choose?',
                id: 'noofDrawers', placeholder: 'Enter No.of Drawers', mandatory: true

            },
            {
                required: true,
                value: "",
                type: "dropDown",
                name: "storageType",
                label: "Select Location Type",
                id: "storageType",
                formatOptionLabel: true,
                placeholder: "Location Type",
                mandatory: true,
                options: [
                    { label: "Cart Location", value: "crashcart" },
                    { label: "Supply Cabinet", value: "storage" },
                    { label: "OR Suite", value: "operatingRoom" },
                ]
            },
        ]);
    }



    getFormFields = (type) => {
        let fields = [];
        if (type == 'Supply Cabinets') {
            fields = this.getCabinetFormFields();
        } else if (type == 'OR Suites') {
            fields = this.getOperatingRoomFormFields();
        } else {
            fields = this.getCartLocationFormFields();
        }
        return fields;
    }
    handleGoBack = () => {
        this.setState({
            redirect: true
        })
    }

    render() {
        return (
            <Container className="dashboard">
                <Loader loader={this.state.isLoading} />
                <Row>
                    <Col lg={12} xl={12}>

                        <Card className="inventoryCard">
                            <CardBody>

                                <div className="card__title">
                                    <h5 className="bold-text" style={{ display: 'inline-block' }}>Storage Unit</h5> &nbsp;&nbsp;
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className="p-1"
                                        onClick={() => this.openFormModal("add")}
                                        title="Add"
                                    >
                                        <FontAwesomeIcon
                                            icon="plus"
                                            className="pl-1"
                                            size="lg"
                                            data-toggle="tool-tip"
                                            onClick={() => this.openFormModal("add")}
                                        />
                                    </Button>
                                </div>


                                {this.state.storageUnit &&
                                    Object.keys(this.state.storageUnit).length > 0
                                    ? Object.keys(this.state.storageUnit).map((item, index) => {
                                        return (

                                            <Table
                                                className="table--bordered table--head-accent mb-4"
                                                hover
                                                key={index}
                                            >
                                                {this.state.storageUnit[item] &&
                                                    this.state.storageUnit[item].length > 0 ? (
                                                    <thead className="inventory-check-thead">
                                                        <tr style={{ background: config.templateColor }}>
                                                            <th
                                                                style={{
                                                                    width: 50 + "%",
                                                                    textAlign: "center",
                                                                    color: "whitesmoke",
                                                                    borderRight: "1px solid white",
                                                                }}
                                                            >
                                                                {item == "crashcart"
                                                                    ? "Cart Location"
                                                                    : item == "storage"
                                                                        ? "Supply Cabinet"
                                                                        : "OR Suite"}
                                                            </th>
                                                            <th className="pr-4"

                                                                style={{
                                                                    width: 50 + "%",
                                                                    textAlign: "center",
                                                                    color: "whitesmoke",
                                                                    paddingRight: '2rem'

                                                                }}
                                                            >
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                ) : null}
                                                <tbody className="inventory-check-tbody">

                                                    {this.state.storageUnit[item] &&
                                                        this.state.storageUnit[item].length > 0 ? (
                                                        this.state.storageUnit[item].map(
                                                            (location, index) => {
                                                                return (
                                                                    <tr key={index}>

                                                                        <td
                                                                            className="inventoryCheckRowTextColor storageTypeCount_hover"
                                                                            style={{ width: 50 + "%" }}
                                                                            onClick={() =>
                                                                                this.getDrawerDropdownValues(
                                                                                    location.clinicName,
                                                                                    location.cart,
                                                                                    location.clinics,
                                                                                    location._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <span color="info">
                                                                                <div className=" row alignItemsCenter">
                                                                                    <span> Facility &nbsp;:&nbsp;</span>  <span style={{ textTransform: 'capitalize' }} className="inventoryCheckListCountsFacility">{location.clinicName}</span>
                                                                                </div>
                                                                                <div className=" row alignItemsCenter">
                                                                                    <span> Cart &nbsp;:&nbsp;</span>  <span style={{ textTransform: 'capitalize' }} className="inventoryCheckListCountsFacility">                                                                                {location.cart}
                                                                                    </span>
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                        <td
                                                                            className="inventoryCheckRowTextColor"
                                                                            style={{ width: 50 + "%" }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                className="genderIconAlignment mr-4"
                                                                                color="white"
                                                                                icon="edit"
                                                                                data-toggle="tool-tip"
                                                                                title="Edit"
                                                                                style={{ color: "#024a88", width: "18", cursor: "pointer", marginRight: '10px' }}
                                                                                onClick={() => this.openFormModal("edit", item, index)}
                                                                            />
                                                                            <FontAwesomeIcon
                                                                                className="genderIconAlignment"
                                                                                color="white"
                                                                                icon="trash-alt"
                                                                                data-toggle="tool-tip"
                                                                                title="Delete"
                                                                                style={{
                                                                                    color: "#bf1725",
                                                                                    width: "18",
                                                                                    marginLeft: 30,
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => this.deleteAction(item, index)}

                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )
                                                    ) : null
                                                    }
                                                </tbody>
                                            </Table>
                                        );
                                    })
                                    : null
                                }

                            </CardBody>
                        </Card>

                    </Col>
                </Row>


                {this.state.isOpenFormModal || this.state.setFormRef ? (
                    <FormModal
                        onRef={(ref) => (this.formModalRef = ref)}
                        openFormModal={this.state.isOpenFormModal}
                        closeFormModal={this.closeFormModal}
                        closeRejectModal={this.closeRejectModal}
                        type="Cart Locations"
                        copyCartFields={this.props.copyCartFields}
                        formType={this.state.formType}
                        formFields={this.getFormFields}
                        apiUrl="locations"
                        getData={this.getData}
                        getDataFromServer={this.getDataFromServer}
                        role={this.state.loginRole}
                        tabType={this.state.tabType}
                        locationId={this.state.id}
                        locationsLength={this.state.locationsLength}
                        productId={this.state.productId}
                        categoryNames={this.props.categoryNames}
                        getClinicSublocationsList={this.props.getClinicSublocationsList}
                        clinicvalues={this.props.clinicvalues}
                        clinics={this.props.clinics}
                        addType={this.props.addType}
                        inventoryTypes={this.props.inventoryTypes}
                    />
                ) : null}

                <DeleteRowModal
                    openDeleteModal={this.state.openDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteSelectedRow={this.deleteSelectedRow}
                    type={this.props.type}
                />
                {this.state.openInventoryChecks ? (
                    <Redirect
                        to={{
                            pathname: `/inventorychecklist/${this.state.locationId}/${this.state.id}/${this.state.cartName}/ENTIRE CART/storageUnitNew`,
                            state: {
                                locationType: "StorageUnit",
                                direct: true,
                            },
                        }}
                    />
                ) : null}

            </Container>


        )
    }

}


