import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Redirect } from 'react-router';
import classNames from 'classnames';

export default class TopbarMenuLinks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLogout: false,
      isLogoutSuccess: false
    };
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  logout = async () => {
    await this.props.toggleClose();
    this.setState({
      isOpenDeleteModal: true
    })
  }

  navigateToLogin = () => {
    this.setState({
      isLogoutSuccess: true
    })
    localStorage.removeItem('loginCredentials');
    localStorage.removeItem('rolePermissions');
    localStorage.removeItem('loginBody');
  }

  // close delete modal
  handleModalBox = () => {
    this.setState({
      isOpenDeleteModal: false
    })
  }

  render() {
    const { title, icon, path, toggleClose } = this.props;
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      <div>
        {title == 'Log Out' ?
          <div className="topbar__link" style={{
            paddingBottom: '10px',
            paddingLeft: '23px', paddingTop: '10px'
          }}
            onClick={() => this.logout()}>
            <span className={`topbar__link-icon lnr lnr-${icon}`} />
            <span className="topbar__link-title">{title}</span>
          </div>
          :
          <Link className="topbar__link" to={path} onClick={toggleClose}>
            <span className={`topbar__link-icon lnr lnr-${icon}`} />
            <p className="topbar__link-title">{title}</p>
          </Link>}

        {this.state.isLogoutSuccess ?
          <div>
            <Redirect to='/log_in' />
          </div> : null}

        {/* display modal for logout */}
        <Modal isOpen={this.state.isOpenDeleteModal}
          className={`modal-dialog-centered modal-dialog--primary ${modalClass} deleteModalWidth`}>
          <ModalBody className='deleteModalBody'>

            <div style={{ marginBottom: 10, fontSize: 15 }}>Are you sure want to Logout?</div>
            <Button color="primary"
              onClick={this.navigateToLogin}
              className='deleteModalBtn'>Yes</Button>
            <Button color="primary" outline onClick={this.handleModalBox}
              className='deleteModalBtn marginRight'>No</Button>

          </ModalBody>
        </Modal>
      </div>

    );
  }
}
