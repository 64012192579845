import React from 'react';
import { Col, Card, CardBody, Container, Row, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ProductsTable from './components/ProductsTable'

const BasicTables = (props) => (

    <Container>
        <ProductsTable {...props} />
    </Container>
);

BasicTables.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicTables);

