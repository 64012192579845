import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// File Upload
import FileUpload from '../../Form/components/FileUpload';
import configImages from '../../../config/configImages';

// config file
export default class BulkUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.clickCount = 0;
    }

    componentDidMount = () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount = () => {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    uploadToServer = () => {
        if (this.clickCount == 0) {
            this.clickCount++;
            this.fileUploadRef.UploadFileToServer();
        }
    }

    handleClickCount = () => {
        this.clickCount = 0;
    }

    render() {
        return (
            <Modal isOpen={this.props.openBulkUploadModal} className='bulkUploadModalHeight'>
                <ModalHeader style={{ fontWeight: '900', fontSize: 20 }}>{this.props.type == 'Storage Unit' ? 'Template' : 'Bulk Upload'}</ModalHeader>
                <ModalBody>
                    {this.props.type != 'Storage Unit' ?
                        <div className='row'>
                            <div className='col-5'>
                                Sample Document
                            </div>
                            <div className='col-7'>
                                <a className='btn btn-primary' href={this.props.sample}>Download</a>
                            </div>
                        </div>
                        : null}
                    {this.props.type == 'Storage Unit' ?
                        <div className='row'>
                            <div className='col-5'>
                                Sample Document
                            </div>
                            <div className='col-7'>
                                <a className='btn btn-primary' href={configImages.cartLocationsXL}>Download</a>
                            </div>
                        </div>
                        : null}
                    <div className='row'>
                        <div className='col-5'>
                            Please Attach File
                            </div>
                        <div className='col-7'>
                            <FileUpload type="bulkUpload"
                                onRef={(ref) => (this.fileUploadRef = ref)}
                                url={this.props.type == 'Storage Unit' ? `uploads?uploadPath=xlsx&&entityType=location&&locationId=${this.props.cartLocId}` : 'quantities/bulkupload?type=' + this.props.bulkApi}
                                close={this.props.closeBulkModal}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ width: '370px' }}>
                    <Button color='primary' outline
                        onClick={this.props.closeBulkModal}>Close</Button>
                    <Button color='primary'
                        onClick={this.uploadToServer}>Upload - {this.props.type}</Button>
                </ModalFooter>
            </Modal >
        );
    }
}