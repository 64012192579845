// import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import "../../../scss/dashboardStyles.css";
import Cart from 'mdi-react/CartIcon';
import Icon from "mdi-react/ArrowLeftIcon";
import FormModalold from '../../Form/FormModal';


import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { CSVLink } from "react-csv";
import { Link, Redirect } from "react-router-dom";

//Modals
import FormModal from "../../Form/FormModal";
import DeleteRowModal from "../Modals/DeleteRowModal";
import ViewModal from "../Modals/viewModal";
import BulkUploadModal from "../Modals/BulkUploadModal";
import RolesModal from "../Modals/RolesModal";
import OrderModal from "../Modals/OrderModal";
import OrderConfirmation from "../Modals/OrderConfirmationModal";
import AddReasonForItemModal from "../List/CartItems/components/AddReasonForItemModal";

// fetch method for server call
import fetch from "../../../config/service";
import config from "../../../config/config";
import configImages from "../../../config/configImages";
import configMessages from "../../../config/configMessages";
import apiCall from "../../../config/apiCalls";

// Permissions
import RolePermissions from "../Permissions/Permissions";

// Toaster message
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";

// Date Formate
import dateFormats from "../../UI/FormatDate/formatDate";

import PaymentTypes from "../List/Payment/components/PaymentTypes";
import OrderTypes from "../List/Orders/components/OrdersTypes";
import RecallTypes from "../List/Recalls/components/RecallTypes";

import QrModal from "../Modals/QrModal";
import SessionModal from "../Modals/SessionExpirationModal";

import StorageUnitTypes from "../List/StorageUnits/components/StorageUnitTypes";
// import StorageUnitNew from "../List/StorageUnitNew/StorageUnitNew";

export default class DataTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteRowDataId: {},
      tableFields: [{
        "show": true,
        "textAlign": "center",
        "width": "100px",
        "field": "status",
        "header_lang": "status",
        "header_def": "Status",
        "sortable": true,
        "filter": false,
        "mobile": true,
        "fieldType": "Pill",
        "options": config.tablefieldStatusTypes,
      }],
      first: 0,
      rows: 20,
      direction: "asc",
      filterCriteria: {
        limit: 20,
        page: 1,
        criteria: [],
        sortfield: "created",
        direction: "desc",
      },
      selectedRows: "",
      selectedRowsId: "",
      totalRecords: [],
      exportData: [],
      isOpenFormModal: false,
      openBulkUploadModal: false,
      openViewModal: false,
      isLoading: false,
      isOpenRolesModal: false,
      selectedScreenPermission: "",
      loginRole: "Admin",
      startDate: "",
      endDate: "",
      apiUrl: "",
      totalRecordsLength: 0,
      tabType: this.props.tabType,
      doctorStatus: "",
      openQrModal: false,
      openOrderModal: false,
      openReasonModal: false,
      selectedReasonId: null,
      redirect: false,
      openSessionModal: false,
      ChecksExcel: "",
      requiredinventoryUpdate: false,
      isopenWishListModal: false,
      categoryType: "Medicine",
      fieldsToCapitalize: ["facilityName", "companyName", "productName", "createdByName", "clinicName", "productsInCarts"]
    };
  }

  componentDidMount = async () => {
    this.props.onRef(this);

    this.orderDisable();
    if (this.props.id == "checks") {
      this.setState({
        apiUrl: "quantities",
      });
      this.getChecksRowData(this.props.title, this.props.lotId);
    }
    this.getRolePermissions();
    if (this.props.getTableFields) {
      let tableFields = await this.props.getTableFields();
      this.setState({
        tableFields: tableFields,
      });
    }
    if (this.props.tabType == "Cart Locations") {
      this.getDataFromServer(this.state.filterCriteria, this.props.tabType);
    } else {

      this.getDataFromServer(this.state.filterCriteria);

    }
    if (this.props.match) {
      if (this.props.match.params) {
        if (this.props.match.params.locationId) {
          this.getLocationName(this.props.match.params.locationId);
        }
      }
    }
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  //get url based on type
  getApiUrl = () => {
    let apiUrl = "";
    if (this.props.type === "Distributors") {
      apiUrl = apiCall.Distributors;
    }
    else if (this.props.type === "Required Inventory") {
      apiUrl = apiCall.RequiredInventory;
    }
    else if (this.props.type === "Companies") {
      apiUrl = apiCall.Companies;
    } else if (this.props.type === "Storage Unit") {
      apiUrl = apiCall.CartLocations;
    } else if (this.props.type === "Facility Staff Roles") {
      apiUrl = apiCall.Roles;
    } else if (this.props.type === "Product Directory") {
      apiUrl = apiCall.Products;
    } else if (this.props.type === "Recall Items") {
      apiUrl = apiCall.Recalls;
    } else if (this.props.type === "Categories") {
      apiUrl = apiCall.Categories;
    } else if (this.props.type === "Missing Items") {
      apiUrl = apiCall.MissingItems;
    } else if (this.props.type === "Facilities") {
      apiUrl = apiCall.Clinics;
    } else if (this.props.type === "Storage Unit Checks") {
      apiUrl = apiCall.WeeklyChecks;
    } else if (this.props.type === "Battery Checks") {
      apiUrl = apiCall.BatteryChecks;
    }
    //Api call for Payments
    else if (this.props.type === "Payment") {
      apiUrl = apiCall.Payment;
    }
    else if (this.props.type == "Payment") {
      apiUrl =
        this.state.tabType == "Payment" ? apiCall.Payments : apiCall.History;
    }
    else if (this.props.type === "Storage Unit Items") {
      apiUrl = apiCall.Quantities;
    } else if (this.props.type === "Track Items") {
      apiUrl = apiCall.Track;
    } else if (this.props.type === "Facility Staff") {
      apiUrl = apiCall.ClinicStaff;
    } else if (this.props.type == "Help") {
      apiUrl = apiCall.Tickets;
    }
    else if (this.props.type == "Recommended Inventories") {
      apiUrl = apiCall.RecommendedInventories;
    }
    else if (this.props.type == "Orders") {
      apiUrl =
        this.state.tabType == "Orders List"
          ? apiCall.Orders
          : this.state.tabType == "Placed Orders"
            ? apiCall.SentOrders

            : this.state.tabType == "Fulfilled Orders"
              ? apiCall.FulfilledOrders
              : null;
    }
    else if (this.props.type == "Expiration Dates") {
      apiUrl = apiCall.ExpiredItems;
    } else if (this.props.type == "Activities") {
      apiUrl = apiCall.Activity;
    } else if (this.props.id == "checks") {
      apiUrl = apiCall.Quantities;
    }
    this.setState({
      apiUrl: apiUrl,
    });
    return apiUrl;
  };

  //get table list data from server with filters if any
  getDataFromServer = async (filterCriteria, type) => {
    // debugger
    let url = this.getApiUrl();
    let loggedUser = JSON.parse(localStorage.getItem('loginCredentials'));
    let ordersCount = 0,
      productsCount = 0,
      apiUrl;
    if (url) {
      let totalRecords = [...this.state.totalRecords];
      if (type != "sort" && type != "pagination") {
        totalRecords = [];
      }
      this.setState({
        isLoading: true,
        selectedRows: "",
        totalRecords,
      });

      if (!filterCriteria) {
        filterCriteria = {
          limit: 20,
          page: 1,
          criteria: [],
          direction: "desc",
          sortfield: "created",
        };
      } else {
        if (type === "refresh" && filterCriteria) {
          delete filterCriteria.globalSearch;
          filterCriteria["criteria"] = [];
          filterCriteria["direction"] = "desc";
          filterCriteria["sortfield"] = "created";
        }

        if (
          filterCriteria &&
          filterCriteria["criteria"] &&
          filterCriteria["criteria"].length > 0
        ) {
          delete filterCriteria["sortfield"];
          delete filterCriteria["direction"];
        }
      }

      if (this.props.type == "Missing Items") {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.locationId
        ) {
          url = apiCall.MissingItemsDashboard;
          apiUrl = `${url}?locationId=${this.props.match.params.locationId
            }&&storageType=${this.props.location.state.storageType
            }&&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          url = apiCall.MissingItemsSidebar;
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
          if (type == "search") {
            apiUrl = `${apiUrl}&&search=search`;
          }
        }
      } else if (this.props.type == "Storage Unit Checks") {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.cart
        ) {
          apiUrl = `${apiCall.WeeklyCheckDashboard}?cart=${this.props.match.params.cart
            }&&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          url = apiCall.WeeklyChecks;
          apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
        }
      }
      else if (this.props.type == "Categories") {
        let filterCriteria1 =
        {
          limit: 20,
          page: 1,
          criteria: [],
          direction: "asc",
          sortfield: "categoryName",
        };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria1)}`;
      }
      else if (this.props.type == "Track Items") {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.locationId
        ) {
          apiUrl = `${apiCall.TrackDashboard}?locationId=${this.props.match.params.locationId
            }&&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          url = apiCall.Track;
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
      } else if (this.props.type == "Recall Items") {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.cart
        ) {
          apiUrl = `${apiCall.RecallsDashboard}?cart=${this.props.match.params.cart
            }&&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          url = apiCall.Recalls;
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
      } else if (this.props.type == "Battery Checks") {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.cart
        ) {
          apiUrl = `${apiCall.BatteryCheckDashboard}?cart=${this.props.match.params.cart
            }&&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          url = apiCall.BatteryChecks;
          apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
        }
      }
      else if (this.props.type == "Expiration Dates") {
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.locationId
        ) {
          apiUrl = `${apiCall.ExpirationDatesDashboard}?locationId=${this.props.match.params.locationId
            }&&filter=${JSON.stringify(filterCriteria)}`;
        } else {
          url = apiCall.ExpiredItems;
          apiUrl = `${url}filter=${JSON.stringify(filterCriteria)}`;
        }
      }
      else if (this.props.type == "Orders") {
        const storageUnitString = localStorage.getItem("StorageUnitItems");
        const storageUnitObject = JSON.parse(storageUnitString);
        const locationId = storageUnitObject.locationId;
        apiUrl = `${url}&locationId=${locationId}&filter=${JSON.stringify(filterCriteria)}`;
      } else {
        if (filterCriteria) {

          if (this.props.type == "Required Inventory") {
            let filterCriteria1 = {
              limit: 20,
              page: 1,
              criteria: [],
              direction: "asc",
              sortfield: "productName",
            };
            if (filterCriteria.criteria.length > 0 || filterCriteria.globalSearch) {
              filterCriteria = filterCriteria;
            } if (this.props.hasQueryInCall) {
              let path = window.location.pathname;
              if (localStorage.getItem('requiredInventory')) {
                // debugger
                let filterData = JSON.parse(localStorage.getItem('requiredInventory'));
                if (!filterCriteria["criteria"]) {
                  filterCriteria["criteria"] = [];
                }

                // if (filterData.facilityName) {
                //   filterCriteria["criteria"].push({
                //     "key": "facilityName",
                //     "value": filterData.facilityName,
                //     "type": "regexOr"
                //   });
                // }

                if (filterData.locationName) {
                  filterCriteria["criteria"].push({
                    "key": "locationId",
                    "value": filterData.locationName,
                    "type": "eq"
                  });
                }

              }
              // if (localStorage.getItem('storageUnit')) {
              //   // debugger
              //   let filterData = JSON.parse(localStorage.getItem('storageUnit'));
              //   if (!filterCriteria["criteria"]) {
              //     filterCriteria["criteria"] = [];
              //   }

              //   // if (filterData.facilityName) {
              //   //   filterCriteria["criteria"].push({
              //   //     "key": "facilityName",
              //   //     "value": filterData.facilityName,
              //   //     "type": "regexOr"
              //   //   });
              //   // }

              //   if (filterData.locationName) {
              //     filterCriteria["criteria"].push({
              //       "key": "locationName",
              //       "value": filterData.locationName,
              //       "type": "regexOr"
              //     });
              //   }

              // }
            }
            else {
              filterCriteria = filterCriteria1;
            }
            apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;

          } else {
            apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
          }
        }
        if (
          type == "refresh" ||
          type == "sort" ||
          type == "search" ||
          type == "pagination"
        ) {
          type = this.state.tabType;
        }
        if (type == "Cart Locations") {
          apiUrl = `${apiUrl}&&storageType=crashcart&categoryType=${this.state.categoryType}`;
        } else if (type == "Supply Cabinets") {
          apiUrl = `${apiUrl}&&storageType=storage&categoryType=${this.state.categoryType}`;
        } else if (this.props.tabType == "OR Suites") {
          apiUrl = `${apiUrl}&&storageType=operatingRoom&categoryType=${this.state.categoryType}`;
        } else if (this.props.type == "Recommended Inventories") {
          // apiUrl = `${apiUrl}&companyId=${loggedUser.company_id}`;
          apiUrl = `${apiUrl}`;
        }
      }
      if (this.props.type === "Required Inventory" || this.props.type === "Product Directory" || this.props.type === "Storage Unit Items" || this.props.type === "Storage Unit" || this.props.type === "Expired Items" || this.props.type === "Missing Items") {
        apiUrl = `${apiUrl}&categoryType=${this.state.categoryType}`
      }

      return fetch("GET", apiUrl)
        .then(async (response) => {
          let AllEmployeesData = [],
            totalRecordsLength = this.state.totalRecordsLength;
          let sessionexpired = localStorage.getItem("sessionexpired");
          if (sessionexpired == "true") {
            this.setState({
              isLoading: false,
              openSessionModal: true,
            });
            localStorage.setItem("sessionexpired", false);
            return;
          }
          if (response && response.details && response.details.length > 0) {
            AllEmployeesData = response.details;
            totalRecordsLength = response.details.length;
          } else {
            if (url == apiCall.MissingItemsSidebar || url == apiCall.MissingItemsDashboard) {
              url = "missingItems";
            } else if (
              url == apiCall.WeeklyChecks ||
              url == apiCall.BatteryChecks
            ) {
              url = "checks";
            } else if (url == apiCall.Track) {
              url = "activities";
            } else if (url == apiCall.ExpiredItems) {
              url = "quantities";
            } else if (url == apiCall.Orders) {
              url = "orders";
            } else if (url == apiCall.SentOrders) {
              url = "orders";
            } else if (url == apiCall.FulfilledOrders) {
              url = "orders";
            }
            else if (url == apiCall.RecommendedInventories) {
              url = "recommendedInventorys"
            }
            if (
              response &&
              response[url] &&
              response[url].length &&
              response[url].length > 0
            ) {
              if (
                response.pagination &&
                (this.state.tabType != "Orders List" ||
                  this.state.tabType != "Fulfilled Orders")
              ) {
                totalRecordsLength = response.pagination.totalCount
                  ? response.pagination.totalCount
                  : this.state.totalRecordsLength;
              }
              if (response.requiredinventoryUpdate) {
                this.setState({
                  requiredinventoryUpdate: true,
                  requiredinventoryapprovedState: response.requiredinventoryUpdate.state
                })

              }
              if (
                this.props.type == "Storage Unit Items" ||
                this.props.type == "Expiration Dates" ||
                this.props.type == "Missing Items" ||
                this.props.type == "Recall Items"
              ) {
                let data = response[url];
                data.sort((a, b) =>
                  a.productName.toUpperCase() > b.productName.toUpperCase()
                    ? 1
                    : b.productName.toUpperCase() > a.productName.toUpperCase()
                      ? -1
                      : 0
                );
              }
              // if (this.props.type == "Product Directory") {
              //   let data = response[url];
              //   data.sort((a, b) =>
              //     a.title.toUpperCase() > b.title.toUpperCase()
              //       ? 1
              //       : b.title.toUpperCase() > a.title.toUpperCase()
              //         ? -1
              //         : 0
              //   );
              // }


              response[url].forEach((employee) => {
                if (this.state.tabType == "Placed Orders") {
                  totalRecordsLength = productsCount;
                  if (!employee.fulilled) {
                    productsCount = productsCount + 1;
                    AllEmployeesData.push(employee);
                    totalRecordsLength = productsCount;
                  }
                } else if (this.state.tabType == "Orders List") {
                  if (!employee.checked) {
                    ordersCount = ordersCount + 1;
                    AllEmployeesData.push(employee);
                    totalRecordsLength = ordersCount;
                  }
                  totalRecordsLength = ordersCount;
                } else if (this.props.type == "Battery Checks") {
                  if (!employee.cartCheckDay) {
                    AllEmployeesData.push(employee);
                  }
                } else {
                  if (this.props.type == "Missing Items") {
                    totalRecordsLength = response.pagination;
                  }
                  AllEmployeesData.push(employee);
                }
              });
            } else {
              if (response.pagination && this.state.tabType != "Orders List") {
                totalRecordsLength = response.pagination.totalCount
                  ? response.pagination.totalCount
                  : response.pagination.totalCount == 0
                    ? 0
                    : this.state.totalRecordsLength;
              }
            }
          }

          await this.setState({
            totalRecords: AllEmployeesData,
            isLoading: false,
            totalRecordsLength: totalRecordsLength,
          });
          return AllEmployeesData;
        })
        .catch((err) => {
          return err;
        });
    }
  };

  //Get all data of current screen with filters applied from server to export to CSV
  getDataToExport = async () => {
    this.setState({ isLoading: true });
    let filterCriteria = this.state.filterCriteria;
    delete filterCriteria["page"];
    filterCriteria["limit"] = "all";
    let url = this.getApiUrl();
    let apiUrl;
    apiUrl = `${this.state.apiUrl}?filter = ${JSON.stringify(
      filterCriteria
    )}&type=exportToCsv`;
    return fetch("GET", apiUrl)
      .then(async (response) => {
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            if (item.created) {
              item.created = dateFormats.formatDate(
                item.created,
                config.dateDayMonthFormat
              );
            }
            if (item.updated) {
              item.updated = dateFormats.formatDate(
                item.updated,
                config.dateDayMonthFormat
              );
            }

            if (item.fromDate) {
              item.fromDate = dateFormats.formatDate(
                item.fromDate,
                config.dateDayMonthFormat
              );
            }
            if (item.toDate) {
              item.toDate = dateFormats.formatDate(
                item.toDate,
                config.dateDayMonthFormat
              );
            }
            if (item.purchaseDate) {
              item.purchaseDate = dateFormats.formatDate(
                item.purchaseDate,
                config.dateDayMonthFormat
              );
            }
            if (item.date) {
              item.date = dateFormats.formatDate(
                item.date,
                config.dateDayMonthFormat
              );
            }
            if (item.startDate) {
              item.startDate = dateFormats.formatDate(
                item.startDate,
                config.dateDayMonthFormat
              );
            }
            if (item.endDate) {
              item.endDate = dateFormats.formatDate(
                item.endDate,
                config.dateDayMonthFormat
              );
            }
            if (item.joinDate) {
              item.joinDate = dateFormats.formatDate(
                item.joinDate,
                config.dateDayMonthFormat
              );
            }
            for (let key in item) {
              if (item[key] && item[key].name) {
                item[key] = item[key].name;
              }
            }
          });
          this.setState(
            {
              exportData: exportData,
              isLoading: false,
            },
            () => {
              // click the CSVLink component to trigger the CSV download
              this.csvLinkRef.link.click();
            }
          );
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        return err;
      });
  };
  openForm = async (type, rowData) => {
    await this.setState({
      selectedRows: "",
    });
    this.openFormModal(type, rowData);
  };
  //open Form modal
  openFormModal = async (type, rowData) => {
    // debugger
    if (this.props.type == "Facility Staff Roles") {
      this.setState({
        isOpenRolesModal: true,
        formType: type,
        selectedRows: "",
      });
      if (type == "edit") {
        this.roleModalRef.getRowData(rowData, type);
      }
      if (type == "add") {
        let rowData,
          allRoles = this.state.totalRecords;
        let permissions = localStorage.getItem("rolePermissions");
        if (allRoles && allRoles.length > 0 && !permissions) {
          for (let roleInfo of allRoles) {
            if (roleInfo.staffRole || roleInfo.role == "Super Admin") {
              rowData = roleInfo;
            }
          }
          this.roleModalRef.getRowData(rowData);
        } else {
          if (permissions) {
            this.roleModalRef.getRowData({
              permissions: JSON.parse(permissions),
            });
          }
        }
      }
    } else {
      await this.setState({
        isOpenFormModal: true,
        formType: type,
        selectedRows: "",
      });
      if (type == 'edit' || (type === "add" && rowData && rowData !== undefined)) {
        if (this.state.isOpenFormModal && this.formModalRef) {
          this.formModalRef.getRowData(rowData);
        }
      }

      if (
        type == "edit" ||
        (this.props.type == "Storage Unit" && type == "add")
      ) {
        if (this.state.isOpenFormModal && this.formModalRef) {
          if (this.props.type == "Storage Unit" && type == "add") {
            rowData = {};
            rowData.noofDrawers = 5;
            this.formModalRef.getRowData(rowData);
          } else {
            this.formModalRef.getRowData(rowData);
          }
        }
      }
    }
  };

  //closeRolesModal
  closeRolesModal = () => {
    this.setState({
      isOpenRolesModal: false,
    });
  };

  //close form modal
  closeFormModal = async (type, quantityId) => {
    await this.setState({
      isOpenFormModal: false,
      isopenWishListModal: false,
      isclosewishListModal: false
    });
    if (
      this.props.type == "Storage Unit Items" &&
      this.state.formType == "edit"
    ) {
      this.getDataFromServer(this.state.filterCriteria, "refresh");
    }
    if (
      this.props.type == "Storage Unit Items" &&
      this.state.formType == "add" &&
      type == "save"
    ) {
      let quantityList = this.state.totalRecords;

      let existingIndex = quantityList.findIndex(
        (item) => item._id == quantityId
      );
      if (existingIndex != -1) {
        if (
          quantityList[existingIndex] &&
          quantityId == quantityList[existingIndex]._id
        ) {
          this.setState({
            qrcode: quantityList[existingIndex].qrcode,
          });
        }
      }
    }
  };

  // delete selected row
  deleteSelectedRow = (type) => {


    let apiUrl = this.getApiUrl();
    let method,
      url,
      body = {};

    if (
      this.props.type == "Doctors" &&
      this.state.selectedRowsId &&
      this.state.selectedRowsId.length &&
      this.state.selectedRowsId.length > 0
    ) {
      method = "POST";

      url = `${apiUrl}/${apiCall.updateMultipleUsers}`;
      body["status"] = this.state.doctorStatus;
      body[apiUrl] = [...this.state.selectedRowsId];
    } else {
      url = `${apiUrl}/${this.state.deleteRowDataId}`;
      if (this.state.tabType == "Fulfilled Orders") {
        url = `orders/${this.state.deleteRowDataId}`;
      }
      if (
        this.props.type == "Storage Unit Checks" ||
        this.props.type == "Battery Checks"
      ) {
        url = `checks/${this.state.deleteRowDataId}`;
      }
      method = "DELETE";
    }
    if (this.state.tabType == "Orders List") {
      method = "POST";
      body = {
        orderId: this.state.ItemStatus._id,
        productName: this.state.ItemStatus.productName,
        description: this.state.ItemStatus.description,
        distributorEmail: this.state.ItemStatus.distributorEmail
          ? this.state.ItemStatus.distributorEmail
          : null,
        cartName: this.state.ItemStatus.cartName
          ? this.state.ItemStatus.cartName
          : null,
      };
      url = "orders/deleteOrderList";
    }
    if (this.props.tabType == "Cart Locations") {
      url = `${url}?storageType=crashcart`;
    } else if (this.props.tabType == "Supply Cabinets") {
      url = `${url}?storageType=storage`;
    } else if (this.props.tabType == "OR Suites") {
      url = `${url}?storageType=operatingRoom`;
    }

    if (this.props.type == "Storage Unit Items" && type) {
      url = `${url}?type=${type}`;
    }

    return fetch(method, url, this.state.tabType == "Orders List" ? body : null)
      .then(async (response) => {
        this.setState({
          openDeleteModal: false,
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, "success");
          this.onPageChange({ page: 0, rows: 20 });
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
        this.props.makeFalse();
      })
      .catch((err) => {
        return err;
      });
  };


  approveRequiredinventory = async () => {
    let loggedUser = JSON.parse(localStorage.getItem('loginCredentials'));
    console.log(loggedUser)
    let userBody = {
      companyId: loggedUser.company_id,
      state: this.state.requiredinventoryapprovedState
    }

    return fetch('POST', `states/approveRequiredinventory `, userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          // display message
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          // display message
          showToasterMessage(response.errorMessage, 'error');
        }
        this.setState({ isLoading: false, requiredinventoryUpdate: false });
        this.getDataFromServer(this.state.filterCriteria, "refresh");
        // this.getDataFromServer()
      }).catch((err) => {
        return err;
      });
  }

  //close delete modal
  closeDeleteModal = async () => {
    await this.setState({
      openDeleteModal: false,
    });
  };
  orderDisable = () => {
    let screenPermissions = RolePermissions.screenPermissions("Orders");
    if (screenPermissions && screenPermissions != "edit") {
      this.setState({
        disableOrder: true,
      });
    }
  };
  deleteAction = async (rowData, value) => {
    console.log("rowData", rowData, "value", value)

    await this.setState({
      selectedRows: "",
    });
    this.deleteConfirmAction(rowData, value);
  };
  // conformation for delete employee
  deleteConfirmAction = async (rowData, value) => {
    await this.setState({
      selectedRows: "",
    });
    let selectedRowsId = [];
    let selectedRows = [...this.state.selectedRows];
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id);
      });
      await this.setState({ selectedRowsId: selectedRowsId });
      if (this.props.type == "Storage Unit Items") {
        await this.setState({
          ItemStatus: value,
        });
        this.deleteSelectedRow();
      }
    }
    if (
      (this.props.type != "Doctors" &&
        selectedRows &&
        selectedRows.length &&
        selectedRows.length > 0) ||
      rowData
    ) {
      this.setState({
        deleteRowDataId: rowData,
        openDeleteModal: true,
        ItemStatus: value,
      });
    }
  };

  //change dropdown elememt
  changeDropDownElement = (event) => {
    this.dt.filter(event.target.value, event.target.name, "equals");
    this.setState({
      [event.target.name]: event.value,
    });
  };

  openQrModal = async (code) => {
    this.setState({
      qrcode: code,
      openQrModal: true,
    });
  };

  getCountData = async (locationId, productId) => {
    if (locationId && productId) {
      let body = { locationId: locationId, productId: productId };
      return fetch("POST", "quantities/currentInventoryCount", body).then(
        async (response) => {
          if (response && (response.count || response.count == 0)) {
            await this.setState({
              ItemCount: response.count,
            });
          }
        }
      );
    }
  };

  openViewModal = async (rowData) => {
    await this.setState({
      displayData: rowData
    })
    let keys = Object.keys(rowData);
    // console.log("formfields", rowData)
    let formFields = this.props.formFields ? await this.props.formFields() : [];
    // console.log("this.props.formFields", this.props.formFields)
    if (formFields) {
      if (this.props.type == "Categories") {

        if (rowData["products"]) {
          let products = [];
          if (rowData["products"]) {
            for (let product of rowData["products"]) {
              // if (rowData.categoryName == product.categoryName) {
              if (product && product.productId && product.productId.title) {
                products.push(product.productId.title);
              }
              // }
            }
          }
          formFields = [
            {
              label: "Products",
              value:
                products && products.length > 0
                  ? products.join(", ")
                  : "No products",
            },
          ];
        }
      }
      if (this.props.type == "Storage Unit Items") {
        await this.getCountData(rowData.locationId._id, rowData.productId._id);
        let index = rowData.productId.title.indexOf("-") + 1;
        let expirationDate = rowData.expirationDate
          ? dateFormats.formatDate(rowData.expirationDate, config.dateDBFormat)
          : "-";
        let created = rowData.created
          ? dateFormats.formatDate(rowData.created, config.dateDBFormat)
          : "-";
        let concentration = rowData.volume ? rowData.volume : "-";
        let description = rowData.description ? rowData.description : "-";
        let clinicName = rowData.clinicName ? rowData.clinicName : "-";
        let lotId = rowData.lotId ? rowData.lotId : "-";
        let cartName = rowData.cartName ? rowData.cartName : "-";
        let createdByName = rowData.createdByName ? rowData.createdByName : "-";
        let type = rowData.type ? rowData.type : "-";
        let cartSubLocation = rowData.cartSubLocation
          ? rowData.cartSubLocation
          : "-";
        let limit = rowData.limit ? rowData.limit : "-";
        let productName = rowData.title1 ? rowData.title1 : "-";
        let count = this.state.ItemCount;
        formFields.push(
          {
            label: "Product Name",
            value: productName,
          },
          { label: "Facility", value: clinicName },
          { label: "Description", value: description },
          { label: "Cart", value: cartName },
          { label: "Volume", value: concentration },
          { label: "Sub Location", value: cartSubLocation },
          { label: "Expiry Date", value: expirationDate },
          { label: "Created By", value: createdByName },
          { label: "Current Inventory", value: count },
          { label: "Created On", value: created },
          { label: "Required", value: limit },
          { label: "Lot Id", value: lotId },
          { label: "Type", value: type }
        );

        for (let item of formFields) {
          if (keys.indexOf(item.name) !== -1) {
            if (item.name == "productName" || item.name == "volume") {
              item.value = rowData[item.name];
            } else if (item.name == "concentration") {
              item.value = item.value;
            }
          }
        }
      }
      if (this.props.type == "Facilities") {
        for (let item of formFields) {
          if (keys.indexOf(item.name) !== -1) {
            if (item.name) {
              item.value = rowData[item.name];
            }
          }
        }
      }
      if (this.props.type == "Inventory Check List") {
        if (rowData) {
          for (let value1 of keys) {
            if (value1 == "_id") {
              this.setState({
                locationId: rowData[value1],
              });
            }
          }
        }
      }
      if (this.props.type == "Storage Unit") {
        if (rowData) {
          for (let value1 of keys) {
            if (value1 == "_id") {
              this.setState({
                locationId: rowData[value1],
              });
            }
            if (value1 == "cart") {
              this.setState({
                locationName: rowData[value1],
              });
            }
            if (value1 == "cartParts") {
              let drawers = [];
              for (let value of rowData[value1]) {
                drawers.push({
                  label: value,
                  value: value,
                });
              }
              this.setState({
                drawers: drawers,
              });
            }
          }
          let cart = rowData["cart"] ? rowData["cart"] : "-";
          let companyName = rowData["companyName"]
            ? rowData["companyName"]
            : "-";
          let clinicName = rowData["clinicName"] ? rowData["clinicName"] : "-";
          let companyemail = rowData["companyemail"]
            ? rowData["companyemail"]
            : "-";
          let companyId = rowData["companyId"] ? rowData["companyId"] : "-";
          let area = rowData["cartLocation"]
            ? rowData["cartLocation"]["area"]
              ? rowData["cartLocation"]["area"]
              : "-"
            : "-";
          let building = rowData["cartLocation"]
            ? rowData["cartLocation"]["building"]
              ? rowData["cartLocation"]["building"]
              : "-"
            : "-";
          let floor = rowData["cartLocation"]
            ? rowData["cartLocation"]["floor"]
              ? rowData["cartLocation"]["floor"]
              : "-"
            : "-";
          let room = rowData["cartLocation"]
            ? rowData["cartLocation"]["room"]
              ? rowData["cartLocation"]["room"]
              : "-"
            : "-";

          formFields.push(
            { label: "Company Name", value: companyName },
            { label: "Area", value: area },
            { label: "Facility", value: clinicName },
            { label: "Building", value: building },
            { label: "Company Email", value: companyemail },
            { label: "Floor", value: floor },
            { label: "Company Id", value: companyId },
            { label: "Room", value: room }
          );
        }
      } else if (this.props.type == "Facility Staff") {
        let displayName = rowData["displayName"] ? rowData["displayName"] : "-";
        let email = rowData["email"] ? rowData["email"] : "-";
        let role = rowData["role"] ? rowData["role"] : "-";
        formFields.push(
          { label: "Name", value: displayName },
          { label: "Email", value: email },
          { label: "Role", value: role }
        );
      }
      await this.setState({
        openViewModal: true,
      });
      this.viewModalRef.getRowData(formFields);
    }
  };

  closeViewModal = async () => {
    await this.setState({
      openViewModal: false,
    });
  };
  closeQrModal = () => {
    this.setState({
      openQrModal: false,
    });
  };
  sendEmail = (
    productName,
    distributorEmail,
    required,
    description,
    concentration
  ) => {
    let userBody = {
      productName: productName,
      distributorEmail: distributorEmail,
      required: required,
      description: description,
      concentration: concentration,
    };

    return fetch("POST", "orders/sendEmail", userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          this.getDataFromServer(this.state.filterCriteria, "refresh");

          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
        this.setState({
          isLoading: false,
        });
        this.clearInputFields();
      })
      .catch((err) => {
        return err;
      });
  };
  ResendEmail = (
    item
  ) => {
    let userBody = item;

    return fetch("POST", "orders/resendEmail", userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
        this.setState({
          isLoading: false,
          tabType: "Placed Orders",
        });
        this.clearInputFields();
      })
      .catch((err) => {
        return err;
      });
  };
  getLocationName = (id) => {
    return fetch("GET", apiCall.CartLocations).then(async (response) => {
      if (response && response.locations) {
        for (let location of response.locations) {
          if (location._id == id) {
            this.setState({
              locationValue: location.cart,
            });
          }
        }
      }
    });
  };

  //on changing pagination
  onPageChange = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria["limit"] = event.rows;
      filterCriteria["page"] = currentPage;

      await this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
      });
    }
    this.getDataFromServer(filterCriteria, "pagination");
  };
  getChecksRowData = (product, lotId) => {
    return fetch("GET", apiCall.Quantities).then(async (response) => {
      if (response && response.quantities) {
        for (let option of response.quantities) {
          if (option.title1 == product && option.lotId == lotId) {
            this.deleteConfirmAction(option._id, option);
          }
        }
      }
    });
  };

  //sorting fields
  sortChange = (event) => {
    this.setState({ selectedRows: "" });
    let sortCount = this.state.sortCount;
    if (event && event["sortField"]) {
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      let sortField = event["sortField"];
      let filterCriteria = {
        direction: sortCount == 0 ? "desc" : "asc",
        sortfield: sortField,
        page: 1,
        limit: 20,
        criteria: [],
      };
      this.setState({
        sortCount: sortCount,
        isLoading: true,
      });
      this.getDataFromServer(filterCriteria, "sort");
    }
  };

  // Handle when click on refresh icon
  refresh = () => {
    this.getDataFromServer(this.state.filterCriteria, "refresh");
  };

  // on search get data from server
  onFilterChange = async (event, type) => {
    this.setState({
      isLoading: true,
      selectedRows: "",
    });
    if (type == "dropdownFilter") {
      await this.setState({
        [event.target.name]: event.target.value,
      });
    }
    let fieldName = "",
      filterCriteria = this.state.filterCriteria,
      selectedFilterValue,
      selectedFilter,
      selecterFilterType,
      formattedDate,
      isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = await Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (
          fieldName == "dateOfBirth" ||
          fieldName == "fromDate" ||
          fieldName == "toDate" ||
          fieldName == "date" ||
          fieldName == "created" ||
          fieldName == "updated" ||
          fieldName == "expirationDate" ||
          fieldName == "startDate" ||
          fieldName == "joinDate" ||
          fieldName == "backDate"
        ) {
          isDateFilter = true;
          selectedFilterValue =
            selectedFilter &&
              selectedFilter.value &&
              selectedFilter.value.length == 10
              ? selectedFilter.value
              : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dateDBFormat);
          selecterFilterType = "lte";
        } else if (fieldName == "required" || fieldName == "invoiceNumber") {
          selecterFilterType = "gte";
          selectedFilterValue =
            selectedFilter && selectedFilter.value
              ? selectedFilter.value
              : null;
        } else {
          selecterFilterType = "regexOr";
          selectedFilterValue =
            selectedFilter && selectedFilter.value
              ? selectedFilter.value
              : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue =
          event && event.target && event.target.value
            ? event.target.value
            : null;
      }
      if (type == "dropdownFilter" || type == "dropdown") {
        selecterFilterType = "eq";
      }
      if (
        selectedFilterValue &&
        selectedFilterValue.length > 0 &&
        (selectedFilterValue.length >= 3 ||
          (fieldName == "required" && selectedFilterValue.length >= 1))
      ) {
        if (fieldName == "createdBy" && this.props.type != "Track Items") {
          fieldName = "createdBy.name";
        }
        if (fieldName == "isOrdered") {
          if ((selectedFilterValue = "yes" || "Yes")) {
            selectedFilterValue = "true";
          } else {
            selectedFilterValue = "false";
          }
        }

        if (fieldName == "cartLocation") {
          fieldName = "address";
        }

        if (this.props.type == "Track Items" && fieldName == "productName") {
          fieldName = "requestJson.json.body.productName";
        }
        if (this.props.type == "Facilities" && fieldName == "address") {
          fieldName = "address1";
        }
        if (
          this.props.type == "Facility Staff Roles" &&
          fieldName == "updatedBy"
        ) {
          fieldName = "updatedBy.name";
        }
        if (this.props.type == "Missing Items")
          if (fieldName == "cart") {
            fieldName = "cartName";
          } else if
            (fieldName == "facilityName") {
            fieldName = "clinicName";
          }
        if (fieldName == "requestJson") {
          fieldName = "requestJson.json.body.lotId";
        }

        if (this.props.type == "Track Items" && fieldName == "locationId") {
          fieldName = "requestJson.json.body.cartName";
        }
        if (this.props.type == "Track Items" && fieldName == "createdBy") {
          fieldName = "requestJson.json.body.createdByName";
        }
        if (this.props.type == "Track Items" && fieldName == "lotId") {
          fieldName = "requestJson.json.body.lotId";
        }
        if (fieldName == "Have") {
          fieldName = "count";
        }

        if (fieldName == "globalSearch") {
          filterCriteria.check = "global";
          filterCriteria.globalSearch = {
            value: selectedFilterValue,
            type: "user",
          };
        } else {
          if (selecterFilterType == "gte") {
            if (fieldName == "required" || fieldName == "invoiceNumber") {
              filterCriteria["criteria"].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType,
              });
            } else {
              let obj = filterCriteria.criteria.find((x) => x.key == fieldName);
              let index = filterCriteria.criteria.indexOf(obj);
              if (index != -1) {
                filterCriteria["criteria"].splice(index, 1, {
                  key: fieldName,
                  value: formattedDate,
                  type: "lte",
                });
              } else {
                filterCriteria["criteria"].push({
                  key: fieldName,
                  value: formattedDate,
                  type: "lte",
                });
              }
            }
          } else {
            if (fieldName == "clinics") {
              fieldName = "clinics.name";
            }
            if (fieldName == "pharmasistCompanies") {
              fieldName = "pharmasistCompanies.name";
            }
            if (
              fieldName == "updated" &&
              !(
                this.props.type == "Help" ||
                this.props.type == "Facility Staff Roles"
              )
            ) {
              fieldName = "updated.name";
            }
            if (fieldName == "needed") {
              fieldName = "needed";
            }
            if (
              filterCriteria["criteria"].length == 0 &&
              selecterFilterType != "lte"
            ) {
              filterCriteria["criteria"].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType,
              });
            } else {
              let obj = filterCriteria.criteria.find((x) => x.key == fieldName);
              let index = filterCriteria.criteria.indexOf(obj);
              if (selecterFilterType == "lte") {
                if (selectedFilterValue.length == 10) {
                  filterCriteria["criteria"].splice(0, 1, {
                    key: fieldName,
                    value:
                      selectedFilterValue.substring(6, 10) +
                      "-" +
                      selectedFilterValue.substring(0, 2) +
                      "-" +
                      selectedFilterValue.substring(3, 5) +
                      "T23:59:59Z",
                    type: selecterFilterType,
                  });
                }
              }
              if (index != -1 && selecterFilterType != "lte") {
                filterCriteria["criteria"].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType,
                });
              } else if (selecterFilterType != "lte") {
                filterCriteria["criteria"].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType,
                });
              }
            }
          }
          this.setState({
            filterCriteria: filterCriteria,
          });
        }
        await this.getDataFromServer(this.state.filterCriteria, "search");
        this.setState({
          isLoading: false,
          first: 1,
        });
      } else {
        if (selectedFilterValue == null && !isDateFilter) {
          let obj = filterCriteria.criteria.find((x) => x.key == fieldName);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          if (fieldName == "globalSearch") {
            filterCriteria.globalSearch = {};
          }
          this.setState({
            filterCriteria: filterCriteria,
          });
          await this.getDataFromServer(filterCriteria, "search");
        }
        this.setState({
          isLoading: false,
        });
      }
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  //open Bulk Modal
  bulkUploadMoadal = async (id) => {
    await this.setState({ openBulkUploadModal: true, cartLocId: id });
  };

  //close Bulk Modal
  closeBulkModal = async () => {
    await this.setState(
      {
        openBulkUploadModal: false,
      },
      () => {
        // this.bulkUploadModalRef.handleClickCount();
      }
    );
    if (this.props.type == "Storage Unit") {
      this.getDataFromServer(this.state.filterCriteria, "refresh");
    }
  };

  //select multiple rows to delete
  onSelectRowsUpdate = async (event) => {
    if (this.props.type == "Leaves") {
      let rowInfo = event.value && event.value[0] ? event.value[0] : {};
      this.openFormModal("edit", rowInfo);
    }
    await this.setState({
      selectedRows: event.value,
    });
  };

  openOrderConfirmation = async () => {
    console.log("LLLLLLLLLLLLLLLLLL", this.state)
    this.setState({
      openOrderModal: true,
      openOrderConfirmation: false,
    });
  };

  closeOrderConfirmation = async () => {
    this.setState({
      openOrderConfirmation: false,
    });
  };

  openOrderModal = async (
    product,
    type,
    Id,
    companyName,
    clinic,
    cart,
    orderDescription,
    needed,
    concentration,
    ordered,
    quantityId,
    // locationId,
    // _id,
    // updateProductRequired
  ) => {
    // console.log('data>>..', this.state.disableOrder)
    if (!this.state.disableOrder) {
      await this.setState({
        orderProductName: product,
        orderFrom: type,
        orderCart: cart,
        ordercompanyName: companyName,
        orderClinic: Id,
        orderClinicName: clinic,
        orderDescription: orderDescription,
        orderConcentration: concentration,
        orderNeeded: needed,
        // orderLocationId: locationId,
        // orderProductId: _id,
        // orderUpdateProductRequired: updateProductRequired
      });

      await this.getNeeded(quantityId);

      if (ordered) {
        await this.setState({
          openOrderConfirmation: true,
        });
      } else {
        this.setState({
          openOrderModal: true,
        });
      }
    } else {
      showToasterMessage("You are not authorized", "error");
    }
  };

  getNeeded = (id) => {
    if (this.props.type == "Expiration Dates") {
      let body = { quantityId: id };
      return fetch("POST", "quantities/expiredNeedToOrder", body).then(
        async (response) => {
          await this.setState({
            orderNeeded: response.neededOrder,
          });
        }
      );
    }
  };

  openInventoryChecks = async (
    cart,
    clinic,
    id,
    area,
    building,
    room,
    floor,
    name,
    companyId,
    companyEmail,
    excel
  ) => {
    this.setState({
      ChecksCart: cart,
      ChecksClinic: clinic,
      ChecksId: id,
      ChecksArea: area,
      ChecksBuilding: building,
      ChecksFloor: floor,
      ChecksRoom: room,
      ChecksCompany: name,
      ChecksCompanyEmail: companyEmail,
      ChecksCompanyId: companyId,
      openInventoryChecks: true,
      ChecksExcel: excel,
    });
  };

  closeOrderModal = () => {
    this.setState({
      openOrderModal: false,
    });
    this.getDataFromServer(this.state.filterCriteria, "refresh");
  };

  //change table body values
  changeFieldValues = (item, column) => {

    // if (column.field == "facilityName" || column.field == "companyName" || column.field == "productName" || column.field == "createdByName" || column.field == "clinicName") {
    //   return <p style={{ textTransform: "capitalize" }}>{item[column.field]}</p>
    // }
    const fieldsToCapitalize = this.state.fieldsToCapitalize

    if (fieldsToCapitalize.includes(column.field)) {
      return <p style={{ textTransform: "capitalize" }}>{item[column.field]}</p>;
    }


    if (column.field == "isOrdered") {
      if (item[column.field]) {
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>Yes</span>
          </span>
        );
      } else {
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>No</span>
          </span>
        );
      }
    }
    if (column.field == "isCopyExistingRec") {
      return (
        <span>
          <span > <FontAwesomeIcon
            className="genderIconAlignment"
            color="white"
            icon="plus"
            data-toggle="tool-tip"
            title="plus"
            style={{ color: "#024a88", width: "15", cursor: "pointer" }}
            onClick={() => this.openForm("add", item)}
          /></span>
        </span>
      );

    }

    //condition to show payment screen data  
    if (this.props.type === "Payment") {
      let val;
      if (column.field === "numberOfCarts") {
        val = item.numberOfCarts;
        console.log("val", val);
        return <span>{val}</span>;
      } else if (column.field === "amount") {
        val = item.amount;
        console.log("val", val);
        return <span>{val}</span>;
      } else if (column.field === "billId" && item.billId === "Trial Period") {
        return <span style={{ color: "green" }}>{item.billId}</span>;
      }
    }


    if (this.props.type == "Track Items") {
      if (column.field == "createdBy" && item.requestJson) {
        if (item.requestJson["json"]) {
          if (item.requestJson["json"].body) {
            if (item.requestJson["json"].body.createdByName) {
              return (
                <span>
                  <span id={`TooltipRight${item._id}`}>
                    {item.requestJson["json"].body.createdByName}
                  </span>
                </span>
              );
            }
          }
        }
      }
      if (column.field == "productName" && item.requestJson) {
        if (item.requestJson["json"]) {
          if (item.requestJson["json"].body) {
            if (item.requestJson["json"].body.productName) {
              if (
                item.requestJson["json"].body.productName.title &&
                typeof item.requestJson["json"].body.productName == "object"
              ) {
                return (
                  <span>
                    <span id={`TooltipRight${item._id}`}>
                      {item.requestJson["json"].body.productName.title}
                    </span>
                  </span>
                );
              } else if (
                item.requestJson["json"].body.productName &&
                typeof item.requestJson["json"].body.productName == "string"
              ) {
                return (
                  <span>
                    <span id={`TooltipRight${item._id}`}>
                      {item.requestJson["json"].body.productName}
                    </span>
                  </span>
                );
              } else {
                return (
                  <span>
                    <span id={`TooltipRight${item._id}`}>-</span>
                  </span>
                );
              }
            } else {
              return (
                <div id={`TooltipRight${item._id}`} className="text-center">
                  -
                </div>
              );
            }
          }
        }
      }
      if (column.field == "description" && item.requestJson) {
        if (item.requestJson["json"]) {
          if (item.requestJson["json"].body) {
            if (item.requestJson["json"].body.description) {
              return (
                <span>
                  <span id={`TooltipRight${item._id}`}>
                    {item.requestJson["json"].body.description}
                  </span>
                </span>
              );
            }
          }
        }
      }
      if (column.field == "lotId" && item.requestJson) {
        if (item.requestJson["json"]) {
          if (item.requestJson["json"].body) {
            if (item.requestJson["json"].body.lotId) {
              return (
                <span>
                  <span id={`TooltipRight${item._id}`}>
                    {item.requestJson["json"].body.lotId}
                  </span>
                </span>
              );
            }
          }
        }
      }
      if (column.field == "locationId" && item.locationId) {
        if (item.locationId.cart) {
          return (
            <span>
              <span id={`TooltipRight${item._id}`}>{item.locationId.cart}</span>
            </span>
          );
        }
      }
      // if (
      //   this.props.type == "Track Items" &&
      //   column.field == "requestJson.json.body.cartName"
      // ) {
      //   if (item["requestJson.json.body.cartName"]) {
      //     return <div>Hiii</div>;
      //   } else {
      //     return <div>Hello</div>;
      //   }
      // }
    }

    if (column.field === "distributorEmail") {
      return <span>
        <span id={`TooltipLeft${item._id}`}>{item[column.field]}</span>
        <UncontrolledTooltip
          placement="bottom"
          style={{ backgroundColor: config.templateColor }}
          target={`TooltipLeft${item._id}`}
        >
          {item && item.distributorEmail}
        </UncontrolledTooltip>
      </span>
    }



    if (this.props.type == "Storage Unit") {
      if (column.field == "cartParts") {
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>
              {item[column.field] && item[column.field].length > 0
                ? item[column.field].join(", ")
                : null}
            </span>
            <UncontrolledTooltip
              placement="bottom"
              style={{ backgroundColor: config.templateColor }}
              target={`TooltipRight${item._id}`}
            >
              {item[column.field] && item[column.field].length > 0
                ? item[column.field].join(", ")
                : null}
            </UncontrolledTooltip>
          </span>
        );
      }
      if (column.field == "cartLocation") {
        let cartLocation = "";
        if (item[column.field]) {
          cartLocation = item[column.field].building
            ? item[column.field].building
            : "";

          cartLocation = `${cartLocation}${(item[column.field].building &&
            item[column.field].area
            ? ","
            : "") + (item[column.field].area ? item[column.field].area : "")}`;
          cartLocation = `${cartLocation}${((item[column.field].area &&
            item[column.field].floor) ||
            (item[column.field].building && item[column.field].floor)
            ? ","
            : "") +
            (item[column.field].floor ? item[column.field].floor : "")}`;
          cartLocation = `${cartLocation}${((item[column.field].floor &&
            item[column.field].room) ||
            (item[column.field].area && item[column.field].room) ||
            (item[column.field].building && item[column.field].room)
            ? ","
            : "") + (item[column.field].room ? item[column.field].room : "")}`;
          return (
            <span>
              <span id={`Tool${item._id}`}>{cartLocation}</span>
              <UncontrolledTooltip
                placement="right"
                style={{ backgroundColor: config.templateColor }}
                target={`Tool${item._id}`}
              >
                {"Building: " +
                  (item[column.field].building
                    ? item[column.field].building
                    : "-") +
                  ", Area: " +
                  (item[column.field].area ? item[column.field].area : "-") +
                  ", Floor: " +
                  (item[column.field].floor ? item[column.field].floor : "-") +
                  ", Room: " +
                  (item[column.field].room ? item[column.field].room : "-")}
              </UncontrolledTooltip>
            </span>
          );
        }
      }
    }
    if (this.props.type == "Recall Items") {
      if (column.field == "qrcode") {
        return (
          <div className="text-center">
            <Link
              to={`/qr/${item[column.field]}`}
              target="_blank"
              className="textEclipses"
              style={{
                color: config.templateColor,
                textDecoration: "underline",
              }}
            >
              view
            </Link>
          </div>
        );
      }
    }

    if (column.field === 'name' && this.props.type === 'Recommended Inventories') {
      return (
        <div>
          <span
            className='textElipses'
            onClick={() => this.openViewModal(item)

            }>
            {item[column.field]}

          </span>
        </div>
      );
    }


    //address field in clinics
    if (
      this.props.type == "Battery Checks" ||
      this.props.type == "Storage Unit Checks"
    ) {
      if (column.field == "address") {
        let cartLocation = "";
        if (item["cartLocation"]) {
          cartLocation = item["cartLocation"].building
            ? item["cartLocation"].building
            : "";
          cartLocation = `${cartLocation}${(item["cartLocation"].building &&
            item["cartLocation"].area
            ? ","
            : "") +
            (item["cartLocation"].area ? item["cartLocation"].area : "")}`;
          cartLocation = `${cartLocation}${((item["cartLocation"].area &&
            item["cartLocation"].floor) ||
            (item["cartLocation"].building && item["cartLocation"].floor)
            ? ","
            : "") +
            (item["cartLocation"].floor ? item["cartLocation"].floor : "")}`;
          cartLocation = `${cartLocation}${((item["cartLocation"].floor &&
            item["cartLocation"].room) ||
            (item["cartLocation"].area && item["cartLocation"].room) ||
            (item["cartLocation"].building && item["cartLocation"].room)
            ? ","
            : "") +
            (item["cartLocation"].room ? item["cartLocation"].room : "")}`;

          return (
            <span>
              <span id={`TooltipLeft${item._id}`}>{cartLocation}</span>
              <UncontrolledTooltip
                placement="bottom"
                style={{ backgroundColor: config.templateColor }}
                target={`TooltipLeft${item._id}`}
              >
                {"Building: " +
                  (item["cartLocation"].building
                    ? item["cartLocation"].building
                    : "-") +
                  ", Area: " +
                  (item[column.field].area ? item[column.field].area : "-") +
                  ", Floor: " +
                  (item[column.field].floor ? item[column.field].floor : "-") +
                  ", Room: " +
                  (item[column.field].room ? item[column.field].room : "-")}
              </UncontrolledTooltip>
            </span>
          );
        }
      }
    }

    if (this.props.type == "Storage Unit") {
      if (column.field == "clinicName") {
        return (
          <span>
            <span>{item["clinics"] ? item["clinics"]["name"] : null}</span>
          </span>
        );
      }
    }

    if (this.props.type == "Facilities") {
      if (column.field == "address") {
        if (item[column.field]) {
          let address = "";
          return (
            <span>
              <span id={`TooltipLeft${item._id}`}>{item["address1"]}</span>
              <UncontrolledTooltip
                placement="bottom"
                style={{ backgroundColor: config.templateColor }}
                target={`TooltipLeft${item._id}`}
              >
                {"Street: " +
                  item[column.field].street +
                  ", City: " +
                  item[column.field].city +
                  ", State: " +
                  item[column.field].state +
                  ", Zip: " +
                  item[column.field].zip}
              </UncontrolledTooltip>
            </span>
          );
        }
      }
    }
    if (this.props.type == "Missing Items") {
      if (column.field == "productCount") {
        if (item[column.field] || item[column.field] == 0) {
          return (
            <span>
              <span id={`TooltipLeft${item._id}`}>
                {item[column.field] ? item[column.field] : 0}
              </span>
            </span>
          );
        }
      }
    }

    if (this.props.type == "Facility Staff") {
      if (column.field == "pharmasistCompanies") {
        let companiesList = [],
          companyName;
        if (item[column.field] && item[column.field].length > 0) {
          for (let company of item[column.field]) {
            if (company && company.name) {
              companiesList.push(company.name);
            }
          }
        } else {
          companyName = item["companyName"];
        }

        return (
          <span>
            <span id={`TooltipTop${item._id}`}>
              {companiesList && companiesList.length > 0
                ? companiesList.join(", ")
                : companyName
                  ? companyName
                  : null}
            </span>

            <UncontrolledTooltip
              placement="top"
              style={{ backgroundColor: config.templateColor }}
              target={`TooltipTop${item._id}`}
            >
              {companiesList && companiesList.length > 0
                ? companiesList.join(", ")
                : companyName
                  ? companyName
                  : null}
            </UncontrolledTooltip>
          </span>
        );
      }
      if (column.field == "clinics") {
        let clinicsList = [];
        if (
          item[column.field] &&
          item[column.field].length > 0 &&
          item["role"] != "Super Admin"
        ) {
          for (let clinic of item[column.field]) {
            if (clinic && clinic.name) {
              clinicsList.push(clinic.name);
            } else {
              clinicsList.push(clinic);
            }
          }
        } else if (
          this.props.allClinicsName &&
          this.props.allClinicsName.length
        ) {
          for (let clinic of this.props.allClinicsName) {
            if (clinic) {
              clinicsList.push(clinic);
            }
          }
        }
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>
              {clinicsList && clinicsList.length > 0
                ? clinicsList.join(", ")
                : null}
            </span>

            <UncontrolledTooltip
              placement="top"
              style={{ backgroundColor: config.templateColor }}
              target={`TooltipRight${item._id}`}
            >
              {clinicsList && clinicsList.length > 0
                ? clinicsList.join(", ")
                : null}
            </UncontrolledTooltip>
          </span>
        );
      }
    }
    if (this.props.type == "Storage Unit") {

      if (column.field == "cart") {
        return (
          <span className="textElipses">
            <span
              onClick={() =>
                this.openInventoryChecks(
                  item.cart,
                  item.clinicName,
                  item._id,
                  item["cartLocation"]
                    ? item["cartLocation"]["area"]
                      ? item["cartLocation"]["area"]
                      : "-"
                    : "-",
                  item["cartLocation"]
                    ? item["cartLocation"]["building"]
                      ? item["cartLocation"]["building"]
                      : "-"
                    : "-",
                  item["cartLocation"]
                    ? item["cartLocation"]["floor"]
                      ? item["cartLocation"]["floor"]
                      : "-"
                    : "-",
                  item["cartLocation"]
                    ? item["cartLocation"]["room"]
                      ? item["cartLocation"]["room"]
                      : "-"
                    : "-",
                  item["companyName"] ? item["companyName"] : "-",
                  item["companyId"] ? item["companyId"] : "-",
                  item["companyemail"] ? item["companyemail"] : "-",
                  item["excel"] ? item["excel"] : null,
                  // item["locationId"] && item["locationId"]._id ? item["locationId"]._id : null
                )
              }
            >
              {item.cart}
            </span>
          </span>
        );
      }
    }
    if (this.props.type == "Storage Unit Checks") {
      if (column.field == "clinicName") {
        return (
          <span>
            <span>
              {item["clinics"] && item["clinics"].name
                ? item["clinics"].name
                : null}
            </span>
          </span>
        );
      }
    }
    if (
      this.state.tabType == "Placed Orders" ||
      this.state.tabType == "Fulfilled Orders"
    ) {
      if (column.field == "invoiceNumber") {
        return (
          <span>
            <span id={`TooltipLeft${item._id}`}>
              {item["invoiceNumber"] ? item["invoiceNumber"] : `-`}
            </span>
          </span>
        );
      }
    }
    //Quantities fields
    if (this.props.type == "Storage Unit Items") {
      if (column.field == "clinicId") {
        let clinic;
        if (item[column.field] && item[column.field].name) {
          clinic = item[column.field].name;
        } else {
          clinic = item[column.field] ? item[column.field] : null;
        }
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>{clinic ? clinic : null}</span>
          </span>
        );
      }
      if (column.field == "productId") {
        let product;
        if (item[column.field] && item[column.field].title) {
          product = item[column.field].title;
        } else {
          product = item[column.field];
        }
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>
              {product ? product : null}
            </span>
          </span>
        );
      }
      if (column.field == "locationId") {
        let location;
        if (item[column.field] && item[column.field].cart) {
          location = item[column.field].cart;
        } else {
          location = item[column.field];
        }
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>
              {location ? location : null}
            </span>
          </span>
        );
      }
      if (column.field == "qrcode" && item["lotId"]) {
        return (
          <div className="text-center">
            <Link
              to={`/qr/${item[column.field]}`}
              target="_blank"
              className="textEclipses"
              style={{
                color: config.templateColor,
                textDecoration: "underline",
              }}
            >
              view
            </Link>
          </div>
        );
      }
    }

    if (
      this.props.type == "Storage Unit" ||
      this.props.type == "Product Directory" ||
      this.props.type == "Facility Staff"
    ) {
      if (column.field == "clinics" || column.field == "clinicId") {
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>
              {item[column.field] && item[column.field].name
                ? item[column.field].name
                : null}
            </span>
          </span>
        );
      }
      if (column.field == "categoryId") {
        let category;
        if (item[column.field] && item[column.field].categoryName) {
          category = item[column.field].categoryName;
        } else {
          category = item[column.field] ? item[column.field] : null;
        }
        return (
          <span>
            <span id={`TooltipRight${item._id}`}>
              {category ? category : null}
            </span>
          </span>
        );
      }
    }

    if (
      (column.field == "displayName" && this.props.type == "Facility Staff") ||
      (column.field == "categoryName" && this.props.type == "Categories") ||
      (column.field == "name" && this.props.type == "Facilities") ||
      (column.field == "cart" && this.props.type == "Inventory Check List") ||
      (this.props.type == "Storage Unit Items" && column.field == "productName")
    ) {
      return (
        <div>
          <span
            className="textElipses"
            onClick={() => this.openViewModal(item)}
          >
            {item[column.field]}
          </span>
        </div>
      );
    }

    if (column.field == "locationType") {
      if (item.locationType == 'crashcart') {
        return "Cart Location";
      } else if (item.locationType == 'storage') {
        return "Supply Cabinet";
      } else {
        return "OR Suite";
      }
    }

    if (column.field == "employeeName") {
      return (
        <div className="textElipses">
          <span
            style={{ color: "blue" }}
            onClick={() => this.openViewModal(item)}
          >
            {item.employeeName}
          </span>
        </div>
      );
    } else if (column.field == "file") {
      return (
        <div>
          {/* <a
            className="btn btn-primary outline mb-0"
            style={{ width: 62, fontSize: 11, padding: 5, paddingRight: 5 }}
            href={
              item.excel
                ? `${config.imgUrl}xlsx/${item.excel}`
                : configImages.cartLocationsXL
            }
          >
            Download
          </a> */}
          <Button
            className="ml-auto my-auto"
            style={{ width: 60, fontSize: 11, padding: 5, marginBottom: 10 }}
            color="primary"
            onClick={() => this.bulkUploadMoadal(item._id)}
            outline
          >
            Upload
          </Button>
        </div>
      );
    } else if (column.field == "order") {
      return (
        <Button
          className="ml-auto my-auto"
          color={item.isOrdered ? "danger" : "primary"}
          onClick={() =>
            this.openOrderModal(
              this.props.type == "Expiration Dates" ||
                this.props.type == "Recall Items"
                ? item.title1
                : item.productName,
              this.props.type,
              item.clinicId ? item.clinicId._id : null,
              item.clinicName ? item.clinicName : null,
              item.cartName
                ? item.cartName
                : item.cart
                  ? item.cart
                  : item.productsInCarts
                    ? item.productsInCarts
                    : null,
              item.description ? item.description : null,
              item.needed ? item.needed : null,
              item.companyName,
              item.volume,
              item.isOrdered,
              // item._id,
              // item.locationId,
              // item.updateProductRequired
            )
          }
          outline
        >
          Add to Orders List
        </Button>
      );
    } else if (column.field == "sendEmail") {
      return (
        <Button
          className="ml-auto my-auto"
          color="primary"
          onClick={() =>
            this.sendEmail(
              item.productName,
              item.distributorEmail,
              item.required,
              item.description,
              item.concentration
            )
          }
          outline
        >
          Submit
        </Button>
      );
    } else if (column.field == "reSendEmail") {
      return (
        <Button
          className="ml-auto my-auto"
          color="primary"
          onClick={() =>
            this.ResendEmail(
              item
            )
          }
          outline
        >
          Re-Send Email
        </Button>
      );
    } else if (column.field == "status") {
      if (
        item.status == "Active" ||
        item.status == "Fixed" ||
        item.status == "InStock" ||
        item.status == "Answered"
      ) {
        return (
          <Badge color="success" pill style={dropdownOptionsStyle}>
            {item.status}
          </Badge>
        );
      } else if (item.status == "Pending" || item.status == "Rejected") {
        return (
          <Badge color="danger" pill style={dropdownOptionsStyle}>
            {item.status}
          </Badge>
        );
      } else if (column.field == "order") {
        return (
          <Button
            className="ml-auto my-auto"
            color="primary"
            onClick={this.openOrderModal}
            outline
          >
            Order
          </Button>
        );
      } else if (
        item.status == "Inactive" ||
        item.status == "inProgress" ||
        item.status == "Cancelled" ||
        item.status == "Reopen" ||
        item.status == "Assigned"
      ) {
        return (
          <Badge color="warning" pill style={dropdownOptionsStyle}>
            {item.status}
          </Badge>
        );
      } else if (
        item.status == "Approved" ||
        item.status == "New" ||
        item.status == "Completed"
      ) {
        return (
          <Badge color="primary" pill style={dropdownOptionsStyle}>
            {item.status}
          </Badge>
        );
      } else if (
        item.status == "Reject" ||
        item.status == "Closed" ||
        item.status == "Repair"
      ) {
        return (
          <Badge color="danger" pill style={dropdownOptionsStyle}>
            {item.status}
          </Badge>
        );
      } else {
        return (
          <Badge color="info" pill style={dropdownOptionsStyle}>
            {item.status}
          </Badge>
        );
      }
    } else if (column.field == "role") {
      if (item.role == "Admin") {
        return (
          <Badge color="success" pill style={dropdownOptionsStyle}>
            {item.role}
          </Badge>
        );
      } else if (item.role == "Super Admin") {
        return (
          <Badge color="danger" pill style={dropdownOptionsStyle}>
            {item.role}
          </Badge>
        );
      } else if (item.role == "Staff") {
        return (
          <Badge color="warning" pill style={dropdownOptionsStyle}>
            {item.role}
          </Badge>
        );
      } else if (item.role == "Support") {
        return (
          <Badge color="secondary" pill style={dropdownOptionsStyle}>
            {item.role}
          </Badge>
        );
      } else {
        return (
          <Badge color="info" pill style={dropdownOptionsStyle}>
            {item.role}
          </Badge>
        );
      }
    } else if (column.field == "staffRole") {
      if (item.staffRole == "Admin") {
        return (
          <Badge color="success" pill style={dropdownOptionsStyle}>
            {item.staffRole}
          </Badge>
        );
      } else if (item.staffRole == "Super Admin") {
        return (
          <Badge color="danger" pill style={dropdownOptionsStyle}>
            {item.staffRole}
          </Badge>
        );
      } else if (item.staffRole == "Support") {
        return (
          <Badge color="secondary" pill style={dropdownOptionsStyle}>
            {item.staffRole}
          </Badge>
        );
      } else {
        return (
          <Badge color="info" pill style={dropdownOptionsStyle}>
            {item.staffRole}
          </Badge>
        );
      }
    } else if (column.field == "Actions") {
      return (
        <div className="row" style={{ justifyContent: "center" }}>
          {this.props.type == "Swap Request" ||
            this.props.type == "Leave History" ||
            ((item.status != "Pending" &&
              item.status != "PendingAtManager" &&
              item.status != "WaitingForAdminApproval" &&
              this.props.type == "Leaves") ||
              (this.props.type == "Clinic Staff" &&
                item["role"] == "Super Admin")) ? null : (
            <div>
              {(this.state.tabType != "Fulfilled Orders" && this.props.type != "Recommended Inventories") || (this.props.type == "Recommended Inventories" && item.companyName) ? (
                <FontAwesomeIcon
                  className="genderIconAlignment mr-2"
                  color="white"
                  icon="edit"
                  data-toggle="tool-tip"
                  title="Edit"
                  style={{ color: "#024a88", width: "15", cursor: "pointer" }}
                  onClick={() => this.openForm("edit", item)}
                />
              ) : null}

              {item.role != "Super Admin" ? (
                (this.state.tabType != "Placed Orders" && this.props.type != "Recommended Inventories") || (this.props.type == "Recommended Inventories" && item.companyName) ? (
                  <FontAwesomeIcon
                    className="genderIconAlignment"
                    color="white"
                    icon="trash-alt"
                    data-toggle="tool-tip"
                    title="Delete"
                    style={{
                      color: "#bf1725",
                      width: "13",
                      marginLeft: 40,
                      cursor: "pointer",
                    }}
                    onClick={() => this.deleteAction(item._id, item)}
                  />
                ) : null
              ) : null}

              {this.props.type == "Storage Unit Items" ? (
                <FontAwesomeIcon
                  className="genderIconAlignment"
                  color="white"
                  icon="exclamation-circle"
                  data-toggle="tool-tip"
                  title="Add Reason"
                  style={{
                    color: "#17bf6b",
                    width: 25,
                    marginLeft: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => this.addReasonModal(item._id, item)}
                />
              ) : null}
            </div>
          )}
        </div>
      );
    } else if (column.field == "photo") {
      return (
        <div style={{ textAlign: "center" }}>
          {item.photo ? (
            <img
              src={`${config.imgUrl}location/${item.photo}`}
              className="imgStyles"
            />
          ) : (
            <img src={configImages.defaultImg} className="imgStyles" />
          )}
        </div>
      );
    } else if (column.field == "gender") {
      if (item.gender == "Female") {
        return (
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon
              className="genderIconAlignment"
              icon="female"
              color="white"
              data-toggle="tool-tip"
              title="Female"
              style={{ backgroundColor: "red", width: "15" }}
            />
          </div>
        );
      } else if (item.gender == "Male") {
        return (
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon
              className="genderIconAlignment"
              icon="male"
              color="white"
              data-toggle="tool-tip"
              title="Male"
              style={{ backgroundColor: "green", width: "15" }}
            />
          </div>
        );
      }
    } else if (
      column.field == "createdBy" &&
      this.props.type == "Facility Staff Roles"
    ) {
      column.field == "createdBy.name";
      if (item[column.field] && item[column.field].name) {
        return item[column.field].name;
      }
    } else if (
      column.field == "updatedBy" &&
      this.props.type == "Facility Staff Roles"
    ) {
      if (item[column.field] && item[column.field].name) {
        return item[column.field].name;
      }
    } else if (
      column.field == "startDate" ||
      column.field == "endDate" ||
      column.field == "DOB" ||
      column.field == "joinDate" ||
      column.field == "invoiceDate" ||
      column.field == 'validateTo' ||
      column.field == 'validateFrom' ||
      column.field == "fromDate" ||
      column.field == "toDate" ||
      column.field == "date" ||
      column.field == "updated" ||
      column.field == "backDate" ||
      column.field == "joiningDate" ||
      column.field == "date"
    ) {
      let date = dateFormats.formatDate(
        item[column.field],
        config.dateDayMonthFormat
      );
      return date;
    } else if (
      column.field == "expirationDate" ||
      column.field == "created" ||
      column.field == "updated"
    ) {
      let date = dateFormats.formatDate(
        item[column.field],
        config.dateDBFormat
      );
      return date;
    } else if (column.field == "startTime" || column.field == "endTime") {
      let date = dateFormats.formatDate(item[column.field], config.timeFormat);
      return date;
    } else {
      if (
        (item[column.field] ||
          (item[column.field] == 0 && column.field == "existingQuantity")) &&
        typeof item[column.field] !== "object"
      ) {
        return item[column.field];
      }
    }
  };

  addReasonModal = (id, item) => {
    this.setState({
      openReasonModal: true,
      selectedReasonId: id,
    });
  };

  updateState = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  getRolePermissions = () => {
    let selectedScreenPermission = RolePermissions.screenPermissions(
      this.props.type
    );
    if (selectedScreenPermission) {
      this.setState({
        selectedScreenPermission: selectedScreenPermission,
      });
    }
  };

  rowClassName = (item) => {
    if (this.props.type == "TimeSheet") {
      if (item.isHoliday === true || item.isWeekEnd === true) {
        return {
          "p-highlightHoliday":
            item.isHoliday === true || item.isWeekEnd === true,
        };
      }
    }
  };

  getSelectedTabTypeInfo = async (tabType) => {
    let filterCriteria = undefined;
    let tableFields = await this.props.getTableFields(tabType);
    await this.setState(
      {
        tableFields: tableFields,
        tabType: tabType,
      },
      async () => {
        await this.getDataFromServer(filterCriteria, tabType);
      }
    );
  };

  customFilterVal = async (e) => {
    if (e) {
      await this.setState({
        categoryType: e.value
      })
    }
    let criteria = this.state.filterCriteria;
    this.getDataFromServer(criteria)

  }
  handleGoBack = () => {
    this.setState({
      redirect: true
    })
  }

  // openFormModal = () => {
  //   this.setState({
  //     isOpenFormModal: true
  //   })
  // }

  render() {
    let data = {}
    let header =
      this.props.type != "Payment" ? (
        <div>
          <div className="row">
            {this.state.requiredinventoryUpdate ?
              <div className='col-12'>
                <div className='serachAlignment float-right' >
                  <span className="mr-2 mt-1 updatebtn">Click here to update all the required inventory</span>
                  <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={this.approveRequiredinventory}>
                    Update
                  </Button>
                </div>
              </div>
              : null}
          </div >
          <div className="row ">
            <div>
              {/* global Search */}
              <div className="col-6" style={{ textAlign: "right" }}>
                {this.props.match ? (
                  this.props.match.params.cart ||
                    this.props.match.params.locationId ? (
                    <div>
                      <span style={{ color: config.templateColor }}>
                        {" "}
                        {this.props.match.params.cart
                          ? this.props.match.params.cart
                          : this.state.locationValue}
                      </span>
                    </div>
                  ) : null
                ) : null}

              </div>

              {this.props.type === "Required Inventory" || this.props.type === "Storage Unit Items" || this.props.type === "Storage Unit" || this.props.type === "Expired Items" ?
                <div className="col-6 d-flex float-left mr-3" style={{ textAlign: "left", marginLeft: this.props.type == "Required Inventory" ? "1rem" : null }}>

                  {/* {this.props.type === 'Required Inventory' ?
                    <span>
                      <span
                        style={{ fontSize: 17, paddingTop: 10 }}
                        title={"Back"}
                        onClick={this.handleGoBack}
                        className="mr-2"
                      >
                        <Icon className="mr-2" />&nbsp;&nbsp;
                        {this.state.redirect && this.props.type === "Required Inventory" ? <Redirect to="/requiredInventory" /> : null}

                      </span>
                    </span>
                    : null} */}
                  {/* <label className="mr-2">Category Type</label> */}
                  <Dropdown
                    style={{ minWidth: "140px", height: "100%", lineHeight: 1.25, textAlign: 'center' }}
                    appendTo={document.body}
                    name={"categoryType"}
                    value={this.state.categoryType}
                    options={[{ label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]}
                    onChange={(e) => this.customFilterVal(e)}
                  />&nbsp;&nbsp;&nbsp;
                </div>
                : null}
            </div>
            <div>
              {this.props.id != "checks" ? (
                <div>
                  {this.props.type != "Payment" ? (
                    <span>
                      <Row >
                        <Button
                          color="primary"
                          className="p-1 mr-4 ml-auto"
                          size={"sm"}
                          onClick={this.getDataToExport}
                          style={{ marginLeft: 0 }}
                        >
                          <FontAwesomeIcon
                            icon="file"
                            data-toggle="tool-tip"
                            title="Export To CSV"
                            className="pl-1"
                            size="lg"
                          />
                        </Button>

                        <CSVLink
                          data={this.state.exportData}
                          filename={`${this.props.type}.csv`}
                          className="hidden text-white"
                          ref={(r) => (this.csvLinkRef = r)}
                          target="_blank"
                        />

                        {/* Bulk Upload */}
                        {this.props.sample ? (
                          <Button
                            color="primary"
                            size="sm"
                            className="p-1 mr-4"
                            onClick={this.bulkUploadMoadal}
                            title="Bulk-Upload"
                          >
                            <FontAwesomeIcon
                              icon="upload"
                              className="pl-1"
                              size="lg"
                              data-toggle="tool-tip"
                              onClick={this.bulkUploadMoadal}
                            />
                          </Button>
                        ) : null}

                        {/* Add button */}
                        {this.state.selectedScreenPermission == "edit" &&
                          this.state.tabType != "Items Recalled" &&
                          this.props.type != "Battery Checks" &&
                          this.props.type != "Orders" &&
                          this.props.type != "Expiration Dates" &&
                          this.props.type != "Storage Unit Checks" ? (
                          <Button
                            color="primary"
                            size="sm"
                            className="p-1 mr-4"
                            onClick={() => this.openFormModal("add")}
                            title="Add"
                          >
                            <FontAwesomeIcon
                              icon="plus"
                              className="pl-1"
                              size="lg"
                              data-toggle="tool-tip"
                              onClick={() => this.openFormModal("add")}
                            />
                          </Button>
                        ) : null}

                        {/* Refresh Icon */}
                        <Button
                          type="button"
                          size="sm"
                          color="secondary"
                          className="p-1"
                          onClick={() =>
                            this.getDataFromServer(
                              this.state.filterCriteria,
                              "refresh"
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon="sync-alt"
                            size="lg"
                            data-toggle="tool-tip"
                            title="Refresh"
                            color={config.templateColor}
                            className="refreshIcon pl-1"
                          />
                        </Button>
                      </Row>
                    </span>
                  ) : (
                    <span className="col-9">
                      <Row style={{ justifyContent: "flex-end" }}>
                        {this.props.type == "Payment" &&
                          this.props.tabType != "Order and Invoice History" ? (
                          <Link to={"/PaymentForm"}>
                            <Button
                              className="ml-auto"
                              color="primary"
                              size="sm"
                              style={{
                                float: "right",
                                marginRight: 50,
                                paddingBottom: 3,
                                marginBottom: 0,
                              }}
                            >
                              Pay
                            </Button>
                          </Link>
                        ) : null}
                      </Row>
                    </span>
                  )}
                </div>
              ) : null}

            </div>


            <div className="col">
              <div className="serachAlignment float-right">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search" />
                  </span>
                  <InputText
                    type="search"
                    name="globalSearch"
                    onChange={(e) => this.onFilterChange(e)}
                    placeholder={
                      this.props.globalSearch ? this.props.globalSearch : "search"
                    }
                    size="30"
                  />
                </div>
              </div>
            </div>
          </div >
        </div >
      ) : null;
    return (
      <div>
        {this.props.id != "checks" ? (
          <Card>
            <CardHeader className="cardHeader">
              <div className="row m-0">
                {/* table name */}
                <div className="col-5 col-md-3 pl-0 pt-2">
                  <h4>
                    {this.props.type === 'Orders' || this.props.type === 'Required Inventory' || (this.props.params && this.props.params.cart && this.props.type == "Recall Items") ?
                      <span>
                        <span
                          style={{ fontSize: 17, paddingTop: 8, color: '#007ad9' }}
                          title={"Back"}
                          onClick={this.handleGoBack}
                          className="mr-2"
                        >
                          <Icon className="mr-0" />
                          {this.state.redirect && this.props.params ? <Redirect to="/home" /> :
                            this.state.redirect && this.props.type === "Required Inventory" ? <Redirect to="/requiredInventory" /> : this.state.redirect && this.props.type === "Orders" ? <Redirect to="/orders" /> : null}

                        </span>
                      </span>
                      : null}
                    <b style={{ color: '#007ad9' }}>
                      {this.props.type == "Quantities"
                        ? "Inventory" :
                        this.props.type == "Expiration Dates"
                          ? "Expired Items"
                          : this.props.type}
                    </b>
                  </h4>
                </div>

              </div>
            </CardHeader>
            <CardBody className="tableCardBody">
              {/* Payment types */}
              {this.props.type == "Payment" ? (
                <Row className="pb-1">
                  <Col sm={12}>
                    <PaymentTypes
                      getSelectedPaymentTypeInfo={this.getSelectedTabTypeInfo}
                    />
                  </Col>
                </Row>
              ) : null}

              {/* Orders Tab Types */}
              {this.props.type == "Orders" ? (
                <Row className="pb-1">
                  <Col sm={12}>
                    <OrderTypes
                      selectedRows={this.state.selectedRows}
                      refresh={this.refresh}
                      getSelectedOrderTypeInfo={this.getSelectedTabTypeInfo}
                    />
                  </Col>
                </Row>
              ) : null}

              {/* Recall Tab types */}
              {this.props.type == "Recall Items" ? (
                <Row className="pb-1">
                  <Col sm={12}>
                    <RecallTypes
                      getSelectedRecallTypeInfo={this.getSelectedTabTypeInfo}
                    />
                  </Col>
                </Row>
              ) : null}

              {/* Storage Unit Tab types */}
              {this.props.type == "Storage Unit" ? (
                <Row className="pb-1">
                  <Col sm={12}>
                    <StorageUnitTypes
                      getSelectedStorageUnit={this.getSelectedTabTypeInfo}
                    />
                  </Col>
                </Row>
              ) : null}

              {/* Data table */}
              <DataTable
                rowClassName={this.rowClassName}
                ref={(el) => (this.dt = el)}
                value={this.state.totalRecords}
                header={header}
                totalRecords={this.state.totalRecordsLength}
                paginator={false}
                lazy={true}
                resizableColumns={true}
                columnResizeMode="expand"
                onSort={this.sortChange}
                globalFilter={
                  this.props.type != "Payment" ? this.state.globalFilter : false
                }
                onFilter={this.onFilterChange}
                scrollable={true}
                selection={this.state.selectedRows}
                onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
                scrollHeight="calc(100vw - 200px)"
                style={{ marginTop: 0 }}
                emptyMessage={configMessages.noRecords}
                sortMode="single"
                selectionMode={
                  this.props.type == "Orders" &&
                    this.props.tabType == "Orders List"
                    ? "multiple"
                    : null
                }
                metaKeySelection={false}
                loading={this.state.isLoading}
              // metaKeySelection={false}
              // style={
              //   this.state.totalRecords && this.state.totalRecords.length == 0
              //     ? { textAlign: "center" }
              //     : null
              // }
              >
                {this.state.tableFields && this.state.tableFields.length
                  ? this.state.tableFields.map((item, i) => {
                    return (
                      <Column
                        key={item.field + i}
                        style={{
                          maxwidth: item.width,
                          padding: 4,
                        }}
                        bodyStyle={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textAlign: item.textAlign ? item.textAlign : "left",
                        }}
                        selectionMode={item.selectionMode}
                        field={item.field}
                        header={item.header}
                        body={this.changeFieldValues}
                        headerStyle={{
                          padding: 4,
                          fontWeight: 500,
                          width: item.width,
                          fontSize: 13,
                          color: "white",
                          backgroundColor: config.templateColor,
                        }}
                        filter={item.filter ? true : false}
                        sortable={item.sortable ? true : false}
                        filterPlaceholder={
                          item.placeholder ? item.placeholder : "search"
                        }
                        filterElement={
                          item.filterElement ? (
                            <div style={{ height: 26 }}>
                              <Dropdown
                                style={{ minWidth: "10%", lineHeight: 1.1 }}
                                appendTo={document.body}
                                name={item.field}
                                value={this.state[item.field]}
                                options={item.filterElement}
                                onChange={(e) =>
                                  this.onFilterChange(e, "dropdownFilter")
                                }
                              />
                            </div>
                          ) : null
                        }
                      />
                    );
                  })
                  : null}
              </DataTable>
              <Row className="paginationcss  mr-0">
                <Col className="pr-0 col-12 col-md-9">
                  <Paginator
                    totalRecords={this.state.totalRecordsLength}
                    first={this.state.first}
                    rows={this.state.rows}
                    rowsPerPageOptions={[10, 15, 20, 30, 50, 100]}
                    onPageChange={this.onPageChange}
                    style={{
                      padding: 10,
                      backgroundColor: "rgba(0,0,0,.03)",
                      border: "none",
                    }}
                  />
                </Col>
                <Col className="col-12 col-md-3 pt-3 text-center bold-text">
                  <span
                    style={{
                      fontSize: 13,
                      fontFamily: "Open Sans,Helvetica Neue,sans-serif",
                    }}
                  >
                    TOTAL RECORDS : {this.state.totalRecordsLength}
                  </span>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : null}




        {/* Add/Edit Modal */}
        {this.state.isOpenFormModal || this.state.setFormRef ? (
          <FormModal
            onRef={(ref) => (this.formModalRef = ref)}
            openFormModal={this.state.isOpenFormModal}
            closeFormModal={this.closeFormModal}
            closeRejectModal={this.closeRejectModal}
            type={this.props.type}
            locationId={this.props.locationId}
            copyCartFields={this.props.copyCartFields}
            formType={this.state.formType}
            formFields={() =>
              this.props.formFields
                ? this.props.type == "Storage Unit Items" ||
                  this.props.type == "Orders" ||
                  this.props.type == "Storage Unit"
                  ? this.props.formFields(this.state.tabType)
                  : this.props.formFields()
                : null
            }
            getDataFromServer={this.getDataFromServer}
            apiUrl={this.state.apiUrl}
            role={this.state.loginRole}
            tabType={this.state.tabType}
            categoryNames={this.props.categoryNames}
            getClinicSublocationsList={this.props.getClinicSublocationsList}
            clinicvalues={this.props.clinicvalues}
            clinics={this.props.clinics}
            addType={this.props.addType}
            inventoryTypes={this.props.inventoryTypes}
          />
        ) : null}


        {/* Roles modal */}
        < RolesModal
          onRef={(ref) => (this.roleModalRef = ref)}
          openRolesModal={this.state.isOpenRolesModal}
          closeRolesModal={this.closeRolesModal}
          type={this.props.type}
          formType={this.state.formType}
          getDataFromServer={this.getDataFromServer}
          apiUrl={this.state.apiUrl}
        />

        {/* Display Selected Row Info */}
        <ViewModal
          type={this.props.type}
          openViewModal={this.state.openViewModal}
          rowData={this.state.rowData}
          formFields={this.props.formFields}
          onRef={(ref) => (this.viewModalRef = ref)}
          closeViewModal={this.closeViewModal}
          locationId={this.state.locationId}
          locationName={this.state.locationName}
          drawers={this.state.drawers}
          displayData={this.state.displayData}

        />

        {/* Delete Confirmation */}
        <DeleteRowModal
          openDeleteModal={this.state.openDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          deleteSelectedRow={this.deleteSelectedRow}
          message={
            this.props.type === "Facilities"
              ? configMessages.FacilitiesDeleteMsg
              : configMessages.DeleteMsg
          }
          type={this.props.type}
        />


        {/* Bulk Upload modal */}
        {this.state.openBulkUploadModal ? (
          <BulkUploadModal
            onRef={(ref) => (this.bulkUploadMoadalRef = ref)}
            bulkApi={this.props.bulkApi}
            type={this.props.type}
            sample={this.props.sample}
            openBulkUploadModal={this.state.openBulkUploadModal}
            cartLocId={this.state.cartLocId}
            closeBulkModal={this.closeBulkModal}
          />
        ) : null}

        {this.state.openOrderModal ? (
          <OrderModal
            onRef={(ref) => (this.confirmRef = ref)}
            openOrderModal={this.state.openOrderModal}
            closeOrderModal={this.closeOrderModal}
            product={this.state.orderProductName}
            orderFrom={this.state.orderFrom}
            orderClinic={this.state.orderClinic}
            orderCart={this.state.orderCart}
            orderClinicName={this.state.orderClinicName}
            orderDescription={this.state.orderDescription}
            orderConcentration={this.state.orderConcentration}
            orderNeeded={this.state.orderNeeded}
          />
        ) : null}
        {this.state.openQrModal ? (
          <QrModal
            openQrModal={this.state.openQrModal}
            closeQrModal={this.closeQrModal}
            selectedQtyQrCode={this.state.selectedQtyQrCode}
            qrcode={this.state.qrcode}
          />
        ) : null}
        {this.state.openOrderConfirmation ? (
          <OrderConfirmation
            Order={this.state.openOrderConfirmation}
            openOrderConfirmation={this.openOrderConfirmation}
            closeOrderConfirmation={this.closeOrderConfirmation}
          />
        ) : null}
        {this.state.openInventoryChecks ? (
          <Redirect
            to={{
              pathname: `/inventorychecklist/${this.state.ChecksId}/${this.state.ChecksCart
                }/${this.state.ChecksClinic}/${this.state.ChecksArea}/${this.state.ChecksRoom
                }/${this.state.ChecksFloor}/${this.state.ChecksBuilding}/${this.state.ChecksCompany
                }/${this.state.ChecksCompanyEmail}/${this.state.ChecksCompanyId
                }/${this.state.ChecksExcel}`,
              state: {
                locationType: this.state.tabType,
              },
            }}
          />
        ) : null}


        {/* {
          this.state.isopenWishListModal ? (
            <FormModalold
              onRef={(ref) => (this.formModalRef = ref)}
              openFormModal={this.state.isopenWishListModal}
              closeFormModal={this.closeFormModal}
              // type="Recommended Inventories"
              productTablefields={this.state.productTablefields}
              facilityName={this.state.facilityName}
              type="Wishlist"
              orderList='addToCart'
              getlocationData={this.getlocationData}
              apiUrl={"orders"}
              // formType="add"
              locationId={this.state.locationId}
              productId={this.state.productId}
            />
          ) : null
        } */}

        <AddReasonForItemModal
          openReasonModal={this.state.openReasonModal}
          selectedReasonId={this.state.selectedReasonId}
          closeReasonModal={this.updateState}
          type={this.props.type}
          getDataFromServer={this.getDataFromServer}
        />

        {/* Session Expiration modal */}
        <SessionModal openSessionModal={this.state.openSessionModal} />
      </div>
    );


  }
}
//setting fixed width for badges
const dropdownOptionsStyle = {
  padding: 5,
  fontSize: 11,
  fontWeight: "500",
  color: "white",
  width: 70,
};
