import React from 'react';
import { Modal, ModalBody, ModalHeader, Card, CardBody, Button, ButtonToolbar } from 'reactstrap';
import classNames from 'classnames';

import FormModal from '../../Form/FormModal';
import apiCalls from '../../../config/apiCalls';
import fetchMethodRequest from '../../../config/service';
import configMessages from '../../../config/configMessages'
import { load as loadAccount } from './../../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import validate from '../../Validations/validate';

import Loader from '../../App/Loader';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import Select from '../../../shared/components/form/Select';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

export default class AddCalendarEventModal1 extends React.Component {
    constructor(props) {
        super(props);
        this.categoryTypes = [];
        this.useTypes = [];
        this.state = {
            isLoading: false,
            formFields: [],
            colored: false,
            header: true
        };
        this.props.load({})
    }

    componentDidMount = async () => {
        await this.getCategoryOptions();
        let formFields = [
            {
                required: true, value: '', type: 'text', name: 'categoryName', label: 'Category Name',
                id: 'categoryName', placeholder: 'Category Name'
            },
            {
                required: true, value: '', type: 'dropDown', name: 'categoryType', label: 'Category Type',
                id: 'categoryType', placeholder: 'Category Type', options: this.categoryTypes
            },
            {
                required: true, value: '', type: 'dropDown', name: 'useType', label: 'Use Type',
                id: 'useType', placeholder: 'Use Type', options: this.useTypes
            }
        ];
        this.setState({
            formFields: formFields
        })
        this.props.onRef(this);
    }

    componentWillUnmount = () => {
        this.props.onRef(null)
    }

    getCategoryOptions = () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0].categoryType) {
                        let categoryTypes = response.settings[0].categoryType;
                        let useTypes = response.settings[0].useType;

                        let modifiedCategoryTypes = [];
                        for (let category of categoryTypes) {
                            modifiedCategoryTypes.push({
                                label: category,
                                value: category
                            })
                        }
                        let modifiedUseTypes = [];
                        for (let use of useTypes) {
                            modifiedUseTypes.push({
                                label: use,
                                value: use
                            })
                        }
                        this.categoryTypes = modifiedCategoryTypes;
                        this.useTypes = modifiedUseTypes
                    }
                }
            });
    }

    submit = async (values) => {
        this.setState({
            isLoading: true
        })
        if (values && Object.keys(values).length > 0) {
            await this.props.saveDataToServer(values, 'AddCategory');
            this.props.closeCategoryModal();
            this.clearFormFields();
            this.setState({
                isLoading: false
            });
        }
    }

    clearFormFields = () => {
        this.props.reset();
    }

    render() {
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });

        return (
            // <FormModal
            //     openFormModal={this.props.isOpenCategoryModal}
            //     closeFormModal={this.props.closeCategoryModal}
            //     type={'Category'}
            //     modalType={'addCategoryFromProducts'}
            //     formType={this.props.formType}
            //     formFields={this.getFormFields}
            //     apiUrl={apiCalls.Categories}
            // />
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.isOpenCategoryModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
                >
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.props.closeCategoryModal} />
                        <p className="bold-text  modal__title">{'Add Category'}</p>
                    </ModalHeader>
                    <ModalBody className="modal__body mb-0">
                        <Card className='m-0 p-0'>
                            <CardBody className='m-0 p-0'>
                                <Loader loader={this.state.isLoading} />
                                <form key={this.props.type} onSubmit={handleSubmit(this.submit)}
                                    autoComplete={'off'}
                                    style={this.props.formType == 'edit' && ((this.props.type == 'Leaves' && isLeaveDisabled) ||
                                        (this.props.type == 'Schedule' && this.state.isPreviousDate)) ? { pointerEvents: "none" } : null}>
                                    <div className='col-sm-12'>
                                        <ButtonToolbar className=" modal__footer footerData">
                                            {/* Cancel */}
                                            < Button color='primary' type="button"
                                                onClick={this.props.closeCategoryModal}>Cancel</Button>
                                            {/* Save */}
                                            <Button
                                                color='primary'
                                                outline
                                                type="submit"> {'Save'}</Button>

                                        </ButtonToolbar>
                                    </div>
                                    <div className="row form" >
                                        {this.state.formFields && this.state.formFields.length > 0 ?
                                            this.state.formFields.map((item, i) => {
                                                return <div key={this.props.type + i} className={(item.type == 'editor' ? 'col-sm-12' : 'col-sm-6')}  >
                                                    <div className="form__form-group" >
                                                        <label className="form__form-group-label">{item.label}</label>
                                                        {item.type == 'dropDown' ?
                                                            <div className="form__form-group-field">
                                                                <Field key={i}
                                                                    name={item.name}
                                                                    component={Select}
                                                                    options={item.options}
                                                                    defaultValue={item.defaultValue}
                                                                    placeholder={item.placeholder}
                                                                    getLocationSubParts={this.getLocationSubParts}
                                                                    getCategoryProductType={this.getCategoryProductType}
                                                                    handlePermissionedRole={this.handlePermissionedRole}
                                                                    getCategoryTypes={this.getCategoryTypes}
                                                                    getLocationDropdownData={this.getLocationDropdownData}
                                                                    type={this.props.type}
                                                                />

                                                            </div>
                                                            : <Field key={i}
                                                                name={item.name ? item.name : null}
                                                                component={DefaultInput}
                                                                isDisable={item.disable}
                                                                type={item.type ? item.type : "text"}
                                                                id={item.id ? item.id : null}
                                                                placeholder={item.placeholder ? item.placeholder : null}
                                                                validate={(this.props.type == 'Products' && item.required) ? [required] : null}
                                                            />
                                                        }
                                                    </div>
                                                </div>

                                            })
                                            : null}


                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody >
                </Modal >
                <FormModal
                    formType='category'
                />

            </div >
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AddCalendarEventModal1 = reduxForm({
    form: "Category Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(AddCalendarEventModal1);

// You have to connect() to any reducers that you wish to connect to yourself
AddCalendarEventModal1 = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),

    { load: loadAccount } // bind account loading action creator
)(AddCalendarEventModal1);




