import React from 'react';

// Table 
import TableRender from '../../../CommonDataTable/TableRender';

// config file
import apiCalls from '../../../../../config/apiCalls';

export default class CompaniesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        let statusTypes = [
            { label: 'All', value: null },
            { label: 'Pending', value: 'Pending' },
            { label: 'Approved', value: 'Approved' },
            { label: 'Rejected', value: 'Rejected' },
        ];

        let data = [
            { textAlign: 'center', width: 50, field: 'Sno', header: 'S.No', filter: false, sortable: false },
            { textAlign: 'left', width: 135, field: 'oldLocation', header: 'Old Location', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'newLocation', header: 'New Location', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'category', header: 'Category', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'productName', header: 'Product Name', filter: true, sortable: false },
            { textAlign: 'center', width: 105, field: 'quantity', header: 'Quantity', filter: true, sortable: false },
            { textAlign: 'left', width: 135, field: 'moveDate', header: 'Move Date', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'left', width: 135, field: 'moveTime', header: 'Move Time', filter: true, sortable: true, placeholder: 'Search' },
            { textAlign: 'center', width: 105, field: 'expiration', header: 'Expiration', filter: true, sortable: false },
            { textAlign: 'center', width: 105, field: 'transferredBy', header: 'Transferred By', filter: true, sortable: false },
            { textAlign: 'center', width: 135, field: 'reasonForTransfer', header: 'Reason For Transfer', filter: true, sortable: false },
            { textAlign: 'center', width: 105, field: 'lastAudit', header: 'Last Audit', filter: true, sortable: false },
            { textAlign: 'center', width: 105, field: 'auditedBy', header: 'Audited By', filter: true, sortable: false },
            { textAlign: 'center', width: 125, field: 'reasonForAudit', header: 'Reason For Audit', filter: true, sortable: false },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];
        return data;
    };

    getFormFields = () => {
        return ([
            {
                required: true, value: '', type: 'autoComplete', name: 'name', label: 'Company Name',
                id: 'companyName', searchApi: apiCalls.Companies, searchField: 'name', placeholder: 'Search Company Name',
            },
            {
                required: true, value: '', type: 'input', name: 'email',
                label: 'Company Email', id: 'email', placeholder: 'email@yopmail.com'
            },
            {
                required: true, value: '', name: 'companyId', label: 'Company Id',
                id: 'companyId', placeholder: 'Company Id',
            }
        ]);
    }

    render() {
        return (
            <div>
                <TableRender
                    globalSearch='Company Id'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}