import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const OptionsModal = (props) => {

    console.log("props", props)
    return (
        <div>
            <Modal isOpen={props.openDeleteModal}
                className='modal-dialog-centered modal-dialog--primary m-auto logout_modal_width'
            >
                <ModalBody className='deleteModalBody'>
                    <div style={{ marginBottom: 20, fontSize: 16 }}>To customize your options, navigate to recommended inventories and create your checklist.</div>
                    <Button color="primary" outline
                        onClick={props.closeDeleteModal}
                        className='deleteModalBtn marginRight'>Close</Button>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default OptionsModal;
