// import React from 'react';
// import TableRender from '../../../CommonDataTable/TableRender';
// import RolePermissions from "../../../Permissions/Permissions";

// export default class CategoyTable extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         };
//     }
//     componentDidMount = () => {
//         if (this.props.match.path == '/batterychecks/:cart') {
//             window.history.pushState(null, null, window.location.pathname);
//             window.addEventListener('popstate', this.onBackButtonEvent);
//         }
//     }
//     onBackButtonEvent = () => {
//         if (this.props.match.path == '/batterychecks/:cart') {

//             if (window.location.pathname != 'inmedtory/home') {
//                 window.location.pathname = 'inmedtory/home'
//             }
//         } else {
//             return window;
//         }
//     }

//     getTableFields = () => {
//         let statusTypes = [
//             { label: 'All', value: null },
//             { label: 'checked', value: 'checked' },
//             { label: 'Pending', value: 'Pending' }
//         ];

//         let data = [
//             { textAlign: 'left', width: 135, field: 'checkType', header: 'Frequency', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 135, field: 'name', header: 'Product Checked', filter: true, sortable: true, placeholder: 'Search' },

//             { textAlign: 'left', width: 135, field: 'cart', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 135, field: 'address', header: 'Cart Address', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'center', width: 135, field: 'created', header: 'Date', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },

//             { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
//         ];
//         let screenPermissions = RolePermissions.screenPermissions('Battery Checks');
//         if (screenPermissions && screenPermissions != 'edit') {
//             data.pop();
//         }
//         return data;
//     };


//     getFormFields = async () => {
//         let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
//         return ([
//             {
//                 required: true, value: '', type: 'radio', name: 'isChecked', label: 'Item Checked',
//                 id: 'checked', options: options
//             }
//         ]);
//     }

//     render() {
//         return (
//             <div>
//                 <TableRender type='Battery Checks'
//                     globalSearch='Status'
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields}
//                     {...this.props}
//                 />
//             </div>
//         );
//     }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    this.state = {
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },

    }
  }
  componentDidMount = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ params: params })
  }

  getOrdersFields = () => {
    let data = [
      { textAlign: 'left', width: 135, field: 'checkType', header: 'Frequency', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 135, field: 'name', header: 'Check Type', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 135, field: 'clinicName', header: 'Facility', filter: true, sortable: true, placeholder: 'Search' },

      { textAlign: 'left', width: 135, field: 'cart', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 135, field: 'address', header: 'Cart Address', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 135, field: 'created', header: 'Date', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },

      { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return data;
  };
  //     getTableFields = () => {
  //         let statusTypes = [
  //             { label: 'All', value: null },
  //             { label: 'checked', value: 'checked' },
  //             { label: 'Pending', value: 'Pending' }
  //         ];

  //         let data = [
  //             { textAlign: 'left', width: 135, field: 'checkType', header: 'Frequency', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 135, field: 'name', header: 'Product Checked', filter: true, sortable: true, placeholder: 'Search' },

  //             { textAlign: 'left', width: 135, field: 'cart', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 135, field: 'address', header: 'Cart Address', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'center', width: 135, field: 'created', header: 'Date', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },

  //             { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
  //         ];
  //         let screenPermissions = RolePermissions.screenPermissions('Battery Checks');
  //         if (screenPermissions && screenPermissions != 'edit') {
  //             data.pop();
  //         }
  //         return data;
  //     };
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": "50px",
        "field": "Sno",
        "header_lang": "Sno",
        "header": "Sno",
        "sortable": false,
        "filter": false,
        "mobile": false,
        "fieldType": "Text",
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 140,
        "field": "checkType",
        "mobile": true,
        "header": "Frequency",
        "filter": false,
        "fieldStyle": "capitalize",
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "name",
        "mobile": true,
        "header": "Product Checked",
        "filter": false,
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "clinicName",
        "mobile": true,
        "header": "Facility",
        "filter": false,
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "cart",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize",
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "address",
        "mobile": true,
        "header": "Cart Address",
        "filter": false,
        "sortable": true,
        displayInSettings: true

      },
      {
        "show": true,
        "textAlign": "center",
        "width": 80,
        "field": "created",
        "mobile": true,
        "header": "Date",
        "filter": false,
        "sortable": true,
        type: 'date',
        'dateFormat': 'mm-DD-YYYY',
        "fieldType": "Date",
        displayInSettings: true

      },

      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "isBatteryExist",
        "header": 'Actions',
        "filter": false,
        "sortable": false,
      },

      {
        "show": true,
        textAlign: 'center',
        width: 100,
        field: 'status',
        "mobile": true,
        fieldType: 'Badge',
        header: 'Status',
        filter: true,
        sortable: false,
        style: { textTransform: "capitalize" },
        options: [{ "label": "Active", "value": 'Active', "color": "success" }, { "label": "Pending", "value": 'Pending', "color": "warning" }, { "label": "Inactive", "value": 'Inactive', "color": "danger", }, { "label": "Dismissed", "value": 'Dismissed', "color": "primary", }],
      },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {

    let options = [{ label: 'Yes', value: true }, { label: 'No', value: false }]
    return ([
      {
        required: false, value: '', type: 'radio', name: 'isChecked', label: 'Battery Checked',
        id: 'checked', options: options
      },
    ]);




  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        onRef={(ref) => (this.datatableref = ref)}
        editRequired={true}
        deleteRequired={true}
        match={this.props.match}
        params={this.state.params}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={'Search'}
        type="Battery Checks"
        apiResponseKey='checks'
        apiUrl={api.WeeklyChecks}
        formUrl='checks'
        hasQueryInCall={true}
        filterRequired={false}
        homeLocation={this.props.location ? this.props.location.search : null}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />

    );
  }
}

export default withTranslation('common')(Employees)
