import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount = () => {
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    this.setState({
      screenPermissions: screenPermissions
    })
    console.log(screenPermissions)
  }
  getPlacedOrdersTableFields = () => {
    let data = [
      { textAlign: 'left', width: 100, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'description', header: 'Description', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'concentration', header: 'Volume', sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'required', header: 'Ordered Inventory', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 100, field: 'distributorEmail', header: 'Distributor Email', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 90, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 100, field: 'created', header: 'Ordered On', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 70, field: 'invoiceNumber', header: 'Invoice Number', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 150, field: 'reSendEmail', header: 'Re-Send Email' },
      { textAlign: 'left', width: 50, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Orders');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return data;
  };
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": 'center',
        width: 45,
        selectionMode: 'multiple',
        field: 'New',
        filter: false,
        sortable: false,
        placeholder: 'Search'
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "productName",
        "mobile": true,
        "header": "Product Name",
        "filter": false,
        "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "description",
        "mobile": true,
        "header": "Description",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 110,
        "field": "concentration",
        "mobile": true,
        "header": "Volume",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 110,
        "field": "required",
        "mobile": true,
        "header": "Ordered Inventory",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "distributorEmail",
        "mobile": true,
        "header": "Distributor Email",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 160,
        "field": "cartName",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 90,
        "field": "created",
        "mobile": true,
        "fieldType": "Date",
        "header": "Ordered On",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "invoiceNumber",
        "mobile": true,
        "header": "Invoice Number",
        "filter": false,
        "sortable": true,
      },

      //       {
      //         "show": true,
      //         "textAlign": "left",
      //         "width": 180,
      //         "field": "email",
      //         "mobile": true,
      //         "header": "Description",
      //         "filter": false,
      //         "sortable": true,
      //       },
      //         {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active',"color":"success" }, { "label": "Pending", "value": 'Pending' ,"color":"warning"}, { "label": "Inactive", "value": 'Inactive',"color":"danger", },{ "label": "Dismissed", "value": 'Dismissed',"color":"primary", }],
      //   },
      //   {
      //     "show": true,
      //     "textAlign": "center",
      //     "width": 140,
      //     "field": "role",
      //     "mobile": true,
      //     "header": "Role",
      //     "filter": false,
      //     "sortable": true,
      //     "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //     "fieldType": 'Role',
      //   },


      //   {
      //     "show": true,
      //     textAlign: 'center',
      //     width: 80,
      //     field: 'status',
      //     "mobile": true,
      //     fieldType: 'Badge',
      //     header: 'Status',
      //     filter: true,
      //     sortable: false,
      //     options: [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //   },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }
  getPlacedOrdersFormFields = async () => {
    return ([

      {
        required: true, value: '', type: 'number', name: 'required1', label: 'Number of Items Recieved',
        id: 'required', placeholder: 'Number of items Recieved'
      },

      {
        value: '', type: 'text', name: 'invoiceNumber', label: 'Invoice Number',
        id: 'required', placeholder: 'Invoice Number'
      },

    ]);
  }
  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "required1",
        "label": "Number of Items Recieved",
        "id": "required1",
        "placeholder": "Number of Items Recieved",
        "type": "text",
        "change": { 'label': 'required', 'value': 'required1' },
        "required": true
      },
      {
        "value": "",
        "name": "invoiceNumber",
        "label": "Invoice Number",
        "id": "invoiceNumber",
        "placeholder": "Invoice Number",
        "type": "text",
        "change": { 'label': 'orderId', 'value': '_id' },
        "remove": '_id',
        "required": true
      },
      //   {
      //     "value": "",
      //     "name": "invoiceNumber",
      //     "label": "Invoice Number",
      //     "id": "invoiceNumber",
      //     "placeholder": "Invoice Number",
      //     "type": "text",
      //     "required": true,
      // },

    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };
  getResendEmail = (item) => {
    console.log(item)
    let userBody = item;
    return fetch("POST", "orders/resendEmail", userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
      })
      .catch((err) => {
        return err;
      });
  }
  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={false}
        editRequired={this.state.screenPermissions === 'edit' ? true : false}
        deleteRequired={false}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={false}
        type="Placed Orders"
        apiResponseKey='orders'
        filterExtension={"type=checked"}
        apiUrl={api.SentOrders}
        flags={{ 'label': 'fulilled', 'value': null }}
        hasQueryInCall={true}
        formUrl={'orders/updateOrder'}
        idNotRequired={true}
        actionsTypes={[{
          'name': 'Re-Send Email',
          'action': this.getResendEmail,
          "options": [
            { 'label': 'Re-Send Email', 'value': 'Re-Send Email', 'show': true, "multiple": false },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)