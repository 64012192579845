// import React from 'react';
// import TableRender from '../../../CommonDataTable/TableRender';
// import RolePermissions from "../../../Permissions/Permissions";

// export default class GroupsTable extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         };
//     }
//     getTableFields = () => {
//         let statusTypes = [
//             { label: 'All', value: null },
//             { label: 'Pending', value: 'Pending' },
//             { label: 'Active', value: 'Active' },
//             { label: 'InActive', value: 'InActive' },
//             { label: 'Dismissed', value: 'Dismissed' },
//         ];
//         let data = [

//             { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 105, field: 'displayName', header: 'Point Of Contact Name', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 140, field: 'email', header: 'Point Of Contact Email', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'right', width: 105, field: 'phone', header: 'Point Of Contact Phone', filter: true, sortable: true, placeholder: 'Search' },
//             {
//                 textAlign: 'center', width: 75, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false

//             },
//             { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
//         ];
//         let screenPermissions = RolePermissions.screenPermissions('Distributors');
//         if (screenPermissions && screenPermissions != 'edit') {
//             data.pop();
//         }
//         return data;
//     };

//     getFormFields = () => {
//         let statusTypes = [
//             { label: 'Pending', value: 'Pending' },
//             { label: 'Active', value: 'Active' },
//             { label: 'InActive', value: 'InActive' },
//             { label: 'Dismissed', value: 'Dismissed' },
//         ];
//         return ([

//             { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
//             { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
//             { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
//             { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
//             {
//                 required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
//                 options: statusTypes, placeholder: 'Select Status'
//             },
//         ]);
//     }

//     render() {

//         return (
//             <div>
//                 <TableRender type='Distributors'
//                     globalSearch='Point Of Contact Name/Email'
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields} {...this.props}
//                 />
//             </div>
//         );
//     }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount = () => {
    let screenPermissions = RolePermissions.screenPermissions('Distributors');
    this.setState({
      screenPermissions: screenPermissions
    })
  }
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 140,
        "field": "companyName",
        "mobile": true,
        "header": "Company",
        // "fieldType": 'Link',
        "filter": false,
        // "fieldType": 'Link',
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 140,
        "field": "displayName",
        "mobile": true,
        "header": "Point Of Contact Name",
        "filter": false,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "phone",
        "mobile": true,
        "header": "Point Of Contact Phone",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "email",
        "mobile": true,
        "header": "Point Of Contact Email",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "isBatteryExist",
        "header": 'Actions',
        "filter": false,
        "sortable": false,
      },
      {
        "show": true,
        textAlign: 'center',
        width: 80,
        field: 'status',
        "mobile": true,
        fieldType: 'Badge',
        header: 'Status',
        filter: false,
        sortable: false,
        options: [{ "label": "Active", "value": 'Active', "color": "success" }, { "label": "Pending", "value": 'Pending', "color": "warning" }, { "label": "InActive", "value": 'InActive', "color": "danger", }, { "label": "Dismissed", "value": 'Dismissed', "color": "primary", }],

      },


    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "firstName",
        "label": "POC First Name",
        "id": "firstName",
        "placeholder": "FirstName",
        "type": "text",
        "required": true,
        mandatory: true

      },
      {
        "value": "",
        "name": "lastName",
        "label": "LastName",
        "id": "lastName",
        "placeholder": "POC Last Name",
        "type": "text",
        "required": true,
        mandatory: true

      },

      {
        "value": "",
        "name": "email",
        "label": "POC Email",
        "id": "email",
        "placeholder": "Email",
        "type": "email",
        "required": true,
        mandatory: true

      },
      {
        "value": "",
        "name": "phone",
        "label": "Phone Number",
        "id": "phoneNumber",
        "placeholder": "Phone Number",
        "type": "text",
        "normalize": false,
        "required": true,
        mandatory: true

      },
      {
        "value": "",
        "name": "status",
        "label": "Status",
        "id": "status",
        "placeholder": "Status",
        "type": "dropDown",
        "options": config.statusTypes,
        "required": true,
        mandatory: true

      },

    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={this.state.screenPermissions === 'edit' ? true : false}
        editRequired={this.state.screenPermissions === 'edit' ? true : false}
        deleteRequired={this.state.screenPermissions === 'edit' ? true : false}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={'Point of Contact Name / Email'}
        type="Distributors"
        apiResponseKey='distributors'
        apiUrl={api.Distributors}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)
