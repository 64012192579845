import React from "react";
import TableRender from "../../../CommonDataTable/TableRender";
import RolePermissions from "../../../Permissions/Permissions";
import fetchMethodRequest from "../../../../../config/service";
import apiCalls from "../../../../../config/apiCalls";

export default class TicketsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    let locationType = [
      { label: "Cart Location", value: "crashcart" },
      { label: "Supply Cabinet", value: "storage" },
      { label: "OR Suite", value: "operatingRoom" },
    ];
    this.setState({
      locationType: locationType,
    });

    return fetchMethodRequest("GET", apiCalls.Settings).then(
      async (response) => {
        if (response && response.settings && response.settings[0]) {
          if (response.settings[0]) {
            let categoryTypes = this.getCategoryDrodownValues(
              response.settings[0]
            );
            let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
            this.setState({
              categoryTypes: categoryTypes,
              useTypes: useTypes,
            });
          }
        }
      }
    );
  };

  getCategoryDrodownValues = (settingInfo) => {
    if (settingInfo && settingInfo.categoryType) {
      let categoryTypes = settingInfo.categoryType;
      let modifiedCategoryTypes = [];
      for (let category of categoryTypes) {
        modifiedCategoryTypes.push({
          label: category,
          value: category,
        });
      }
      return modifiedCategoryTypes;
    }
  };

  getUseTypeDrodownValues = (settingInfo) => {
    if (settingInfo && settingInfo.useType) {
      let useTypes = settingInfo.useType;
      let modifiedUseTypes = [];
      for (let category of useTypes) {
        modifiedUseTypes.push({
          label: category,
          value: category,
        });
      }
      return modifiedUseTypes;
    }
  };

  getTableFields = () => {
    let statusTypes = [
      { label: "All", value: null },
      { label: "Active", value: "Active" },
      { label: "InActive", value: "InActive" },
      { label: "Pending", value: "Pending" },
    ];
    let data = [
      {
        textAlign: "left",
        width: 165,
        field: "productName",
        header: "Product",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 115,
        field: "description",
        header: "Description",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 100,
        field: "dosage",
        header: "Volume",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 105,
        field: "required",
        header: "Required Inventory",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 115,
        field: "categoryType",
        header: "Category Type",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 110,
        field: "categoryName",
        header: "Category Name",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },

      {
        textAlign: "center",
        width: 125,
        field: "locationName",
        header: "Location Name",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 125,
        field: "locationType",
        header: "Location Type",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },

      // {
      //   textAlign: "left",
      //   width: 125,
      //   field: "categoryType",
      //   header: "Category Type",
      //   filter: true,
      //   sortable: true,
      //   placeholder: "Search",
      // },

      {
        textAlign: "center",
        width: 125,
        field: "inventoryType",
        header: "Inventory Type",
        filter: true,
        sortable: true,
        placeholder: "Search",
      },
      {
        textAlign: "center",
        width: 70,
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
      },
    ];

    let screenPermissions = RolePermissions.screenPermissions(
      "RequiredInventory"
    );
    if (screenPermissions && screenPermissions != "edit") {
      data.pop();
    }
    return data;
  };

  getFormFields = () => {
    return [
      {
        required: true,
        value: "",
        type: "autoComplete",
        searchApi: apiCalls.Products,
        searchField: "title1",
        name: "productName",
        label: "Product Name",
        id: "productName",
        placeholder: "Search Product Name",
        mandatory: true

      },
      //   {
      //     required: true,
      //     value: "",
      //     type: "input",
      //     name: "productName",
      //     label: "Product Name",
      //     id: "productName",
      //     placeholder: "Product Name",
      //   },

      {
        required: true,
        value: "",
        type: "input",
        name: "description",
        label: "Description",
        id: "description",
        placeholder: "Description",
        disable: true,
        mandatory: true

      },
      {
        required: true,
        value: "",
        type: "text",
        name: "categoryName",
        label: "Category Name",
        id: "categoryName",
        placeholder: "Category Name",
        disable: true,
        mandatory: true

      },
      {
        required: true,
        value: "",
        type: "text",
        name: "dosage",
        label: "Volume ",
        id: "dosage",
        placeholder: "Volume",
        disable: true,
        mandatory: true

      },

      {
        required: true,
        value: "",
        type: "text",
        name: "inventoryType",
        label: "Select Inventory Type ",
        id: "inventoryType",
        placeholder: "Inventory Type",
        disable: true,
        mandatory: true

      },
      //   {
      //     required: true,
      //     value: "",
      //     type: "dropDown",
      //     name: "useType",
      //     label: "Select Inventory Type",
      //     id: "useType",
      //     placeholder: "Inventory Type",
      //     options: this.state.useTypes,
      //     disable: true,
      //   },

      {
        required: true,
        value: "",
        type: "dropDown",
        name: "locationType",
        label: "Select Location Type",
        id: "locationType",
        placeholder: "Location Type",
        options: this.state.locationType,
        mandatory: true

      },
      {
        required: true,
        value: "",
        type: "dropDown",
        name: "locationName",
        label: "Location Name",
        id: "useType",
        placeholder: "Inventory Type",
        options: [],
        // disable: true,
        mandatory: true

      },
      {
        required: true,
        value: "",
        type: "input",
        name: "required",
        label: "Required",
        id: "required",
        placeholder: "Required",
        mandatory: true

      },
    ];
  };

  render() {
    return (
      <div>
        <TableRender
          type="Required Inventory"
          globalSearch="Product/Category"
          tableFields={this.getTableFields}
          formFields={this.getFormFields}
          {...this.props}
        />
      </div>
    );
  }
}
