import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LocationsTable from './components/SupplyCabinets';

const LocationTableList = ({ t }) => (
  <Container>
    <LocationsTable />
  </Container>
);

LocationTableList.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(LocationTableList);
