import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import config from '../../../../../config/config';
import classNames from 'classnames';

// config file
export default class MailSentConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div>
                <Modal isOpen={this.props.isEmailConfirmationModal}
                    className={`modal-dialog-centered modal-companySuccess modal-dialog--primary  ${modalClass}`}>
                    <ModalBody className='deleteModalBody'>
                        {/* <div style={{
                            background: config.templateColor, color: 'white', display: 'flex', justifyContent: 'center',
                            height: 45, alignItems: 'center', fontSize: 17, fontWeight: 'bold', borderRadius: 5
                        }}>
                            Success
                        </div> */}
                        {/* <div className="text-center">
                            <h4 className="pt-4 pb-3">{this.props.mailSentMessage}</h4>
                        </div> */}
                        <div className="text-center pt-4">
                            <h4 className="pb-5">Company created and verified successfully</h4>
                        </div>
                        <div className="confirm-modal">
                            <Button color='primary' onClick={this.props.closeModal}>
                                <span style={{ color: 'white' }}>Done</span>
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}