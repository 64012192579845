import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Orders from './components/orders'

const OrdersTable = (props) => (

    <Container>
        <Orders {...props} />
    </Container>
);

OrdersTable.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Orders);

