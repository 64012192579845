const imagePath = '../assets/';
const configImages = {

    logoImage: require(`../shared/img/logo/inmedtoryLogo.png`),


    defaultImg: require(`../assets/img/profile/userProfile.png`),
    employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
    productsSampleXL: require(`../assets/files/productsSample.xlsx`),
    cartLocationsXL: require(`../assets/files/cartttts.xlsx`),
    quantitySampleXL: require(`../assets/files/quantitySample.xlsx`),
    publicHolidaysSampleXL: require(`../assets/files/publicHolidaysSampleUpload.xlsx`),
    expiredIcon: require(`../assets/img/logo/calender.png`),
    cartIcon: require(`../assets/img/logo/cart1.png`),
    batteryIcon: require(`../assets/img/logo/battery.jpg`),
    recallIcon: require(`../assets/img/logo/recall.png`),
    cartChecksIcon: require(`../assets/img/logo/cartcheck.jpg`),
    MissingIcon: require(`../assets/img/logo/index.png`),
    CheckIcon: require(`../assets/img/logo/list.png`),
    CardsIcon: require(`../assets/img/logo/cards.png`),



};
export default configImages;
