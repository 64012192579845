import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import configImages from "../../../../config/configImages";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";

import { Redirect } from "react-router";
import PropTypes from "prop-types";

import renderCheckBoxField from "../../../../shared/components/form/CheckBox";
import { load as loadAccount } from "../../../../redux/reducers/commonReducer";
import { loginUserInfo } from "../../../../redux/actions/authActions";

// Validate input
import validate from "../../../Validations/validate";

// Input field
import DefaultInput from "../../../../shared/components/form/DefaultInput";

// Config
import configMessage from "../../../../config/configMessages";
import fetchRequest from "../../../../config/service";

// Toaster message
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";

// Loader
import Loader from "../../../App/Loader";
import apiCalls from "../../../../config/apiCalls";
import ConfirmationModal from '../../../Cruds/Modals/ConfirmationModal';


const required = (value) =>
  value || typeof value === "string" ? undefined : "Please Enter Your Email";
const required1 = (value) =>
  value || typeof value === "string" ? undefined : "Please Enter Your Password";

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isLoginDisabled: false,
      isLoading: false,
      loginRememberInfo: null,
      captchaValue: null,
      isRobot: false,
      isLoginSuccess: false,
      isLoading: false,
      redirectPayment: false,
    };

    this.getLoginRememberInfo();
  }

  getLoginRememberInfo = async () => {
    localStorage.removeItem("loginCredentials");
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("loginBody");

    let loginRememberInfo = localStorage.getItem("loginRememberInfo");
    loginRememberInfo = loginRememberInfo
      ? JSON.parse(loginRememberInfo)
      : null;
    await this.props.load(loginRememberInfo);
    this.setState({
      loginRememberInfo: loginRememberInfo,
      remember_me:
        loginRememberInfo && loginRememberInfo.remember_me
          ? loginRememberInfo.remember_me
          : false,
    });
  };

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  submit = (values) => {
    debugger
    if (values.email && values.password) {
      this.setState({
        remember_me: values.remember_me,
        isLoginDisabled: true,
        isLoading: true,
      });
      this.handleLoginUser(values);
    }
  };

  handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: "user",
      loginSite: "cch",
    };
    localStorage.setItem("loginBody", JSON.stringify(userBody));
    return fetchRequest("POST", apiCalls.loginUser, userBody)
      .then(async (response) => {
        if (response && response.key !== undefined && response.key === "PaymentPendingAndRedirecting") {
          let sum = 0;
          for (let i = 0; i < response.paymentDetails.length; i++) {
            sum += response.paymentDetails[i].payAmount;
          }
          localStorage.setItem("paymentDetails", JSON.stringify(response.paymentDetails))
          await this.setState({
            openConfirmationModal: true,
            redirectPayment: false,
            amount: sum
          })
        } else {
          if (response && response.respCode && response.respCode == 200) {
            this.setState({
              isLoading: true,
            });

            let userDetails = response.details;
            if (userDetails && userDetails._id) {

              if (response.accessToken) {
                let tokenInfo = {
                  accessToken: response.accessToken,
                  refreshToken: response.refreshToken,
                  tokenExpires: response.tokenExpires,
                };
                userDetails = { ...userDetails, ...tokenInfo };
                // save user credentials in storage
                localStorage.setItem(
                  "loginCredentials",
                  JSON.stringify(userDetails)
                );
              }

              if (userDetails && userDetails.rolePermissions) {
                // save user permissions in storage
                localStorage.setItem(
                  "rolePermissions",
                  JSON.stringify(userDetails.rolePermissions)
                );
              }
            }
            showToasterMessage(response.respMessage, "success");
            await this.setState(
              {
                isLoginSuccess: true,
              },
              () => {
                if (this.state.remember_me) {
                  var data = {
                    email: values.email,
                    password: values.password,
                    remember_me: this.state.remember_me,
                  };
                  if (
                    this.state.loginRememberInfo &&
                    this.state.loginRememberInfo.email &&
                    this.state.loginRememberInfo.email != values.email
                  ) {
                    localStorage.setItem(
                      "loginRememberInfo",
                      JSON.stringify(data)
                    );
                  } else if (!this.state.loginRememberInfo) {
                    localStorage.setItem(
                      "loginRememberInfo",
                      JSON.stringify(data)
                    );
                  }
                } else {
                  localStorage.removeItem("loginRememberInfo");
                }
              }
            );
          } if (response && response.errorMessage === configMessage.paymentErrorMessage) {
            setTimeout(() => {
              this.setState({
                isLoginDisabled: false,
                isLoading: false,
                openConfirmationModal: true,
                redirectPayment: false,
              });
            }, 250);
          } else if (response && response.errorMessage) {
            setTimeout(() => {
              this.setState({
                isLoginDisabled: false,
                isLoading: false,
              });
              showToasterMessage(response.errorMessage, "error");
            }, 250);
          } else {
            this.setState({
              isLoginDisabled: false,
              isLoading: false,
            });
          }

        }
      })
      .catch((err) => {
        this.setState({
          isLoginDisabled: false,
          isLoading: false,
        });
      });
  };

  onChange = (value) => {
    if (value == "remember" && this.state.remember_me) {
      this.setState({
        remember_me: false,
      });
    }
  };

  clickConfirm = () => {
    this.setState({ redirectPayment: true })
    this.closeConfirmationModal();
  }

  closeConfirmationModal = () => {
    this.setState({
      isLoginDisabled: false,
      isLoading: false,
      openConfirmationModal: false
    })
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, } = this.props;
    const { showPassword } = this.state;

    return (
      <div>
        <form className="form" onSubmit={handleSubmit(this.submit)}>
          <Loader loader={this.state.isLoading} />
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="email"
                component={DefaultInput}
                type="email"
                placeholder="Email"
                validate={required}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <Field
                name="password"
                component={DefaultInput}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                validate={required1}
              />
              <button
                type="button"
                className={`form__form-group-button${showPassword ? " active" : ""
                  }`}
                onClick={(e) => this.showPassword(e)}
              >
                <EyeIcon />
              </button>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="remember_me"
                component={renderCheckBoxField}
                label="Remember me"
                checked={this.state.remember_me}
                onChange={() => this.onChange("remember")}
              />
            </div>

            <div className="account__forgot-password">
              <Link to="/forgot_password" >Forgot a password?</Link>
            </div>
          </div>
          <div className="account__btns forgotPasswordLoginLink">
            <Button
              className="ml-auto"
              color="primary"
              disabled={this.state.isLoginDisabled}>Sign In</Button>
            {/* <Button
            type="button"
            onClick={reset}
            color="primary"

          // disabled={pristine || submitting}
          >Reset</Button> */}
          </div>

          <div className="text-center">
            New User ?<Link to="/register">&nbsp;Add a company</Link>
          </div>


        </form>
        {this.state.openConfirmationModal ?
          <ConfirmationModal
            // formType={this.state.formType}
            onRef={(ref) => (this.confirmRef = ref)}
            openConfirmationModal={this.state.openConfirmationModal}
            closeConfirmationModal={this.closeConfirmationModal}
            confirm={this.clickConfirm}
            text={'Payment Pending And Redirecting'}
            isNo={'Cancel'}
            isYes={'Confirm'}
          />
          : null}



        {/* Navigate to Home */}
        {this.state.isLoginSuccess ? <Redirect to="/home" /> : null}
        {this.state.redirectPayment ? <Redirect to={`/payments/${this.state.amount}`} /> : null}

      </div>
    );
  }
}

LogInForm = reduxForm({
  form: "log_in_form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(LogInForm);

export default LogInForm;
