import React from 'react';
import {
    Nav, NavItem, NavLink, Button
} from 'reactstrap';
import classnames from 'classnames';

export default class StorageUnitTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            storageUnitTypes: [
                { name: 'Cart Locations' },
                { name: 'Supply Cabinets' },
                { name: 'OR Suites' }
            ],
        };
    }
    // Handle Selected Group
    handleOrderTypes = (tab, OrderTabType) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        this.props.getSelectedStorageUnit(OrderTabType);
    };

    render() {
        const { activeTab, storageUnitTypes } = this.state;
        return (
            <div>
                <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            {storageUnitTypes && storageUnitTypes.length > 0 ?
                                storageUnitTypes.map((orderTabType, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({ active: activeTab === index })}
                                                onClick={() => {
                                                    this.handleOrderTypes(index, orderTabType.name);
                                                }}>
                                                <span style={{ fontSize: 15 }}>{orderTabType.name}</span>
                                            </NavLink>
                                        </NavItem>
                                    )
                                })
                                : null}
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}