import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PlacedOrders from './components/PlacedOrders'

const PlacedOrdersList = (props) => (

    <Container>
        <PlacedOrders {...props} />
    </Container>
);

PlacedOrdersList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PlacedOrdersList);

