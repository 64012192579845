
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  ButtonToolbar,
  ButtonGroup,
  Table,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import classNames from "classnames";
import AddRecommendedInventoryModal from "../Cruds/Modals/RequiredInventoryModal"
import { load as loadAccount } from "./../../redux/reducers/commonReducer";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddProductModal from "../Cruds/Modals/AddProductModal";
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

// Now you can use the faQuestion icon in your project

//components
import AutoComplete from "./components/AutoComplete";
import { InputText } from "primereact/inputtext";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import Select from "../../shared/components/form/Select";
import { Dropdown } from "primereact/dropdown";
import RadioButton from "../../shared/components/form/RadioButton";
import DefaultInput from "../../shared/components/form/DefaultInput";
import Checkbox from "../../shared/components/form/CheckBox"
import TimePicker from "../../shared/components/form/TimePicker";
import DefaultTextArea from "../../shared/components/form/DefaultTextArea";
import renderColorPicker from "../../shared/components/form/ChromeColorPicker";
import DatePicker from "../../shared/components/form/DatePicker";
import RenderFileInputField from "../Form/components/FileUpload";
import RecommendedDetails from "./GetSelectedInventory";
import DeleteRowModal from "../Cruds/Modals/DeleteRowModal";
// config
import config from "../../config/config";
import configMessages from "../../config/configMessages";
import apiCalls from "../../config/apiCalls";
import fetchMethodRequest from "../../config/service";

// Toaster message
import showToasterMessage from "../UI/ToasterMessage/toasterMessage";

// format date
import dateFormats from "../UI/FormatDate/formatDate";

// validate
import validate from "../Validations/validate";

// Multi select Dropdown
import MultiSelectDropDown from "./components/MultiSelect";

// Loader
import Loader from "../App/Loader";

// Category Modal
import AddCategoryModal from "../Cruds/Modals/AddCategoryModal";
import SessionModal from "../Cruds/Modals/SessionExpirationModal";
import MailSentConfirmationModal from "../Cruds/List/FacilityStaff/components/MailSentConfirmationModal";

import OptionsModal from "../Cruds/Modals/OptionsModal";
import FormModalNew from "./FormModalNew";



let validation;
var date = new Date();
const required = (value) =>
  value || typeof value === "string" ? undefined : configMessages.fillField;

const requiredField = (value) =>
  value
    ? value.length > 0
      ? undefined
      : configMessages.fillField
    : configMessages.fillField;

const lessThan = (otherField) => (value, previousValue, allValues) =>
  value < allValues[otherField] ? value : previousValue;
const greaterThan = (otherField) => (value, previousValue, allValues) =>
  value > allValues[otherField] ? value : previousValue;
const greaterThan0 = (value) => (value > 0 ? value : null);
const lessThanEqual = (otherField) => (value, previousValue, allValues) =>
  value <= allValues[otherField] && value > 0 ? value : previousValue;
const required1 = (value) => (value ? undefined : configMessages.fillField);

const normalizePhone = (value) => {
  if (!value) {
    return value;
  }
  let onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length == 1) {
    onlyNums = "1-" + onlyNums;
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(
      4
    )}`;
  }

  return `${onlyNums.slice(0, 1)}-${onlyNums.slice(1, 4)}-${onlyNums.slice(
    4,
    7
  )}-${onlyNums.slice(7, 11)}`;
};
const normalizestate = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^a-z A-z]/g, "");
  return `${onlyNums}`;
};
const normalizename = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^a-z A-z 0-9]/g, "");
  return `${onlyNums}`;
};
const normalizenumber = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  return `${onlyNums}`;
};

class FormModal extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isProductsModalOpen: false,
      addedProduct: true,
      formFields: [],
      formValues: {},
      DOB: "",
      colored: false,
      header: true,
      isLoading: false,
      checked: false,
      inventoryDetails: {},
      inventoryHistory: [],
      inventoryComments: [],
      inventoryAttachments: [],
      locationImage: null,
      customLocationOptions: this.props.cartSublocationsList,
      categoryNameOptions: [],
      categoryType: 'Medicine',
      // categoryType: '',
      isCustomCategory: false,
      isAutoReOrder: false,
      isManualReOrder: false,
      isOpenCategoryModal: false,
      isSubLoction: false,
      lotAndExpirationRequired: false,
      isTypeMedicine: true,
      showCategoryType: false,
      openInventoryModal: false,
      notMedicine: false,
      showSingle: false,
      subLocations: [],
      isCompanySelect: false,
      isDistributerSelect: false,
      clinicsLocations: [],
      selectedCategory: null,
      isBattery: false,
      isCustom: false,
      isCustomVolume: false,
      isCustomVolumeDes: false,
      isWeekly: false,
      selectedOption: false,
      selectedalternateOption: false,
      showRequired: false,
      noDescription: false,
      isNotBattery: false,
      openSessionModal: false,
      isEmailConfirmationModal: false,
      mailSentMessage: "",
      formModalType: "",
      isCheckboxSelected: false,

      locationType: null,
      showError: false,
      updateProductRequired: true,
      locationId: this.props.locationId ? this.props.locationId : null,
      productId: this.props.productId ? this.props.productId : null,
      lotId: this.props.lotId,
      data: [],
      allOriginalFields: [],
      openDeleteModal: false,
      openOptionsModal: false,
      deleteConfModal: false,
      locationIdForRecall: null,
      isopenFormModal: false,
      selectedOptions: false,

      // showDistributorData:true

    };
  }






  componentDidMount = async () => {
    if (this.props.type === 'Required Inventory') {
      // let data = JSON.parse(localStorage.getItem('requiredInventory'));
      // await this.setState({ locationName: data.cartName })





      let path = window.location.pathname;
      const parts = path.split('/');
      const id = parts[parts.length - 1];
      await this.setState({
        id: id
      });
    }

    this.handleCategoryNames();
    if (this.props.formType == "edit") {
      this.setState({ isCheckboxSelected: true })
    }

    if (this.props.type === "Wishlist") {
      await fetchMethodRequest('GET', `wishlist/getWishByLocationId?locationId=${this.state.locationId}`)
        .then(async (resp) => {
          await this.setState({
            productTablefields: resp && resp.wishlist && resp.wishlist.products ? resp.wishlist.products : []
          });
        })
    }

    if (this.props.type == "Order") {
      await this.setState({
        orderProduct: this.props.orderProductName,
        orderDescription: this.props.orderDescription,
        orderCart: this.props.orderCart,
        orderProductId: this.props.orderProductId,
        orderConcentration: this.props.orderConcentration,
        facilityName: this.props.facilityName,
        productId: this.props.productId,
        locationId: this.props.locationId

      });
    }
    if (this.props.rowData && Object.keys(this.props.rowData).length > 0) {
      this.props.load(this.props.rowData);
      let data = {
        title1: this.props.rowData.title1,
        _id: this.props.rowData.productId,
        description: this.props.rowData.description,
        dosage: this.props.rowData.volume,
        categoryType: this.props.rowData.categoryType
      }

      this.getUniProductName(this.props.rowData.title1);
      if (this.props.rowData.volume !== null) {
        this.setState({
          isMedicine: true,
          isNonMedicine: true,
          isBattery: true,
        })
      }
      this.handleAutoCompleteData(data, "description")
      this.props.change("cartSubLocation", "")
    }
    // else {

    else {

      this.props.load({});

    }

    // }
    // if (this.props.type == "Required Inventory" && this.props.formType == "add") {
    //   // this.handleAutoCompleteData()
    //   // this.props.change("locationName", this.state.locationName)

    //   // this.props.load({ locationName: 'aaaaaa' })
    // }
    if (this.props.type == "DashInventory") {
      await this.setState({
        inventoryCart: this.props.inventoryCartName,
        inventoryCartId: this.props.inventoryCartId,
      });
      this.setClinicName(this.props.inventoryCartName);
      this.getLocationSubParts(this.props.inventoryCartName);
    }
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    this.cartName();
    this.getFormModalType();
    this.getFormFields();


    if (this.props.type == "Leaves") {
      this.setState({
        minDate: new Date(),
      });
    }
    if (
      (this.props.type == "Storage Unit Items" ||
        this.props.type == "DashInventory") &&
      this.props.formType
    ) {
      this.getUniqueProducts();
      this.getProductsData();
    }
    if (this.props.type == "Order") {
      this.props.change("productName", this.props.orderProductName);
      this.props.change("description", this.state.orderDescription);
      this.props.change("volume", this.state.orderConcentration);
      this.props.change("concentration", this.state.orderConcentration);
      this.props.change("required", this.props.orderNeeded);
      this.props.change("facilityName", this.state.facilityName)
      this.props.change("updateProductRequired", this.state.updateProductRequired)
      this.props.change("productId", this.state.productId)
      this.props.change('locationId', this.state.locationId)

    }

    // this.props.change("locationInventoryId",this.state.locationInventoryId)
    this.props.change("productId", this.state.productId)
    this.props.change('locationId', this.state.locationId)



    if (this.props.onRef) {
      this.props.onRef(this);
    }

    if (this.props.type == "Product Directory") {
      this.getCategoryDrodownValues();
    }

    this.getLocationsForInventory();
    if (this.props.id == "Checks") {
      this.setState({
        isLoading: true,
      });
      this.getTitleData(this.props.title1, this.props.cart);
      this.getLocationSubParts(this.props.inventoryCartName);
    }
    // this.RequiredInventoryData()

  };


  // //api call to update the cart data 
  // getUpdatedData = () => {
  //   let apiUrl = `${apiCalls.LocationsCartView}`;
  //   return fetchMethodRequest('GET', apiUrl)
  //     .then(async (response) => {
  //       if (response && response.quantity) {
  //         if (response.respMessage) {
  //           //console.log(response.respMessage)
  //         } else {
  //           //console.log(response.errorMessage)
  //         }
  //       }
  //     });
  // }


  handleCheckboxChange = () => {
    if (this.props.type === 'Storage Unit' || this.props.type === 'Cart Locations') {
      this.setState((prevState) => ({
        isCheckboxSelected: !prevState.isCheckboxSelected,
      }));
    }
  };


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }

  getFormModalType = () => {
    let formModalType = this.props.type;
    if (formModalType == "Facilities") {
      formModalType = "Facility";
    } else if (formModalType == "Storage Unit") {
      if (this.props.tabType == "Cart Locations") {
        this.handleCategoryNames()
        formModalType = "Cart Location";
      } else if (this.props.tabType == "Supply Cabinets") {
        this.handleCategoryNames()

        formModalType = "Supply Cabinet";
      } else if (this.props.tabType == "OR Suites") {

        formModalType = "OR Suite";
      }
    } else if (formModalType == "Categories") {
      formModalType = "Category";
    } else if (formModalType == "Help") {
      formModalType = "Ticket";
    } else {
      if (
        typeof formModalType == "string" &&
        (formModalType != "DashInventory" &&
          formModalType != "Facility Staff" &&
          formModalType != "Product Directory")
      ) {
        formModalType = formModalType.replace(/.$/, "");
      }
    }
    this.setState({
      formModalType,
    });
  };


  recommendedInventory123 = (e) => {
    this.setState({
      openOptionsModal: true

    })
  }



  getFormFields = async () => {
    // debugger
    if (this.props.formFields) {
      let formFields = await this.props.formFields();
      if (this.props.type == "Storage Unit" && this.props.formType == "edit") {
        formFields = formFields.filter((field) => {
          return field.name != "cartSublocation";
        });
      }
      await this.setState({
        formFields: formFields,
      });
      // if (this.props.type === 'Cart Locations') {
      //   //console.log("formfields>...", this.state.formFields)
      //   const storageUnitFields = this.state.formFields.filter((field) => {
      //     return field.type === 'Storage Unit';
      //   });
      //   //console.log("storageUnitFields", storageUnitFields)
      // }
    }
  };
  getProductsData = async () => {
    return fetchMethodRequest(
      "GET",
      'products?filter={"limit":"all","page":1,"criteria":[],"sortfield":"created","direction":"desc"}'
    ).then(async (response) => {
      let products = [];
      if (response && response.products) {
        for (let product of response.products) {
          products.push({
            label: product.title1,
            value: product._id,
          });
        }
        await this.setState({
          InvProducts: products,
        });
      }
    });
  };

  //Get From Fields data on Edit//
  getRowData = async (rowData) => {
    if (this.props.type === 'Cart Locations') {
      await this.setState({
        storagetype: rowData.storageType
      })
    }
    if (rowData && !rowData.recommendedInventoryName) {
      this.setState({
        isCheckboxSelected: false
      })
    }
    if (this.props.type === 'Recall Items' && rowData && rowData.lotId) {
      let filter = {
        // criteria: [
        //   { key: "lotId", value: '776543', type: "eq" },
        //   { key: 'companyId', value: "6539198665b60e7842299312", type: 'eq' }
        // ],
      };
      let url = `${apiCalls.Quantities}?filter=${JSON.stringify(
        filter
      )}&load=true`;
      fetchMethodRequest("GET", url).then(async (response) => {
        if (response && response.quantities && response.quantities.length) {
          response.quantities.forEach((quantity) => {
            if (rowData.lotId == quantity.lotId) {
              rowData.productId = quantity.productId ? quantity.productId._id : null;
              rowData.locationId = quantity.locationId ? quantity.locationId._id : null;
            }
            this.setState({ locationIdForRecall: rowData.locationId });
          });
        }
      });

    }

    await this.getClinicsDataFromId(rowData.clinicName);

    validation = "";
    if (rowData) {
      await this.setState(
        {
          isLoading: true,
        },
        async () => {
          if (this.props.type == "Product Directory") {
            await this.getCategoryNames();
            this.setState({
              isCustomCategory: true,
              showCategoryType: true,
            });
          }

          if (this.props.type == "Recommended Inventories") {
            const sortedData = rowData.recommendedInventory.sort((a, b) => {
              let fa = a.productName.toLowerCase(),
                fb = b.productName.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            let fileterdData = sortedData
            if (this.state.categoryType && this.state.categoryType != '') {
              fileterdData = sortedData.filter((obj) => obj.categoryType == this.state.categoryType)
            }
            await this.setState({
              productTablefields: fileterdData,
              allOriginalFields: sortedData
            })
          }

          if (this.props.tabType == "Placed Orders") {
            if (rowData.required) {
              rowData.required == null;
            }
          }
          if (rowData && rowData.selectedInventoryType) {
            await this.setState({
              selectedInventoryType: rowData.selectedInventoryType
            })
          }

          if (this.props.type === "Cart Locations") {
            rowData.clinics = rowData.clinicName
            // rowData.storageType = rowData.storageType === "crashcart" ? "Cart Location" : rowData.storageType === "storage" ? "Supply Cabinet" : rowData.storageType === "operatingRoom" ? "OR Suite" : null

          }

          if (this.props.type == "Storage Unit Items") {
            this.getLocationDropdownData(rowData.clinicName);
            this.getLocationSubParts(rowData.cartName);
            this.getUniProductName(rowData.title1);
            this.getUniAlternateProductName(rowData.title1);
            this.getCountData(rowData.locationId._id, rowData.productId._id, rowData.cartSubLocation
            );

            if (rowData.batteryExists) {
              await this.setState({
                isBattery: true,
              });
            } else if (!rowData.batteryExists) {
              await this.setState({
                isMedicine: true,
                isNonMedicine: true,
              });
            }

            await this.setState({
              productName: rowData.productId ? rowData.productId.title : null,
              alternateProductName: rowData.alternateProductNames ? rowData.alternateProductNames[0] : null,
              products: rowData.productId,
              clinicName: rowData.clinicName,
              cartName: rowData.cartName,
              description: rowData.description,
              volume: rowData.volume,
              oldSubLocation: rowData.cartSubLocation,
              expirationDate: rowData.expirationDate,
            });
            if (rowData.locationId) {
              rowData.locationId = rowData.locationId.cart
                ? rowData.locationId.cart
                : rowData.locationId;
            }
            if (rowData.clinicId) {
              rowData.clinicId = rowData.clinicId.name
                ? rowData.clinicId.name
                : rowData.clinicId;
            }
            if (this.props.id == "Checks") {
              rowData.cartSubLocation = this.props.cartSubLocation;
              rowData.title1 = this.props.title1;
            }
          }
          if (this.props.type === "Required Inventory") {
            this.getlocationNamesforInventory(rowData.locationType)
          }
          if (rowData.categoryType) {
            let showCategoryType = false;
            if (rowData.categoryType == "Medicine") {
              showCategoryType = true;
            }
            this.setState({
              showCategoryType: showCategoryType,
            });
          }
          if (rowData.userName) {
            rowData.user = rowData.userName;
          }
          if (this.props.type == "Facility Staff") {
            if (rowData.role == "Super Admin") {
              this.setState({
                permissionedRole: rowData.role,
              });
            }
            if (rowData.clinics) {
              rowData.clinic = [];
              for (let clinic of rowData.clinics) {
                delete clinic._id;
                rowData.clinic.push(clinic);
              }
            }
          }

          if (rowData.locationId) {
            rowData.locationId = rowData.locationId.cart
              ? rowData.locationId.cart
              : rowData.locationId;
          }
          if (rowData.clinicId) {
            rowData.clinicId = rowData.clinicId.name
              ? rowData.clinicId.name
              : rowData.clinicId;
          }
          if (this.props.type == "Product Directory") {
            if (rowData.categoryId) {
              this.setState({
                catId: rowData.categoryId._id,
              });
            }
            rowData.categoryId = rowData.categoryName;
            if (rowData.clinicId) {
              this.setState({
                clinic_Id: rowData.clinicId._id,
              });
              rowData.clinicId = rowData.clinicId.name
                ? rowData.clinicId.name
                : rowData.clinicId;
            }
          }

          if (rowData.distributorId) {
            rowData.distributorId = rowData.distributorId.displayName;
          }

          if (rowData.clinicname) {
            rowData.clinicname =
              rowData.clinic_id && rowData.clinic_id.name
                ? rowData.clinic_id.name
                : null;
          }
          if (this.props.type == "Product Directory") {
            rowData.categoryField = "selectCategory";
            if (rowData.batteryExists) {
              this.setState({
                notMedicine: true,
              });
            }
            if (rowData.productId) {
              rowData.productId = rowData.productId.productName
                ? rowData.productId.productName
                : rowData.productId;
            }
            if (rowData.locationId) {
              rowData.locationId = rowData.locationId.cart
                ? rowData.locationId.cart
                : rowData.locationId;
            }
            if (rowData.distributorId) {
              if (rowData.distributorId) {
                this.setState({
                  distributor_Id: rowData.distributorId._id,
                });
              }
              rowData.distributorId = rowData.distributorId.displayName
                ? rowData.distributorId.displayName
                : rowData.distributorId;
            }
            if (rowData.reorder && rowData.reorder == "auto") {
              this.setState({
                isAutoReOrder: true,
                reorder: "auto",
              });
            }
            if (rowData.reorder && rowData.reorder == "manual") {
              this.setState({
                isManualReOrder: true,
                reorder: "manual",
              });
            }
          }

          if (rowData.cartLocation) {
            rowData.building = rowData.cartLocation.building
              ? rowData.cartLocation.building
              : null;
            rowData.area = rowData.cartLocation.area
              ? rowData.cartLocation.area
              : null;
            rowData.floor = rowData.cartLocation.floor
              ? rowData.cartLocation.floor
              : null;
            rowData.facility = rowData.cartLocation.facility
              ? rowData.cartLocation.facility
              : null;
            rowData.room = rowData.cartLocation.room
              ? rowData.cartLocation.room
              : null;
          }
          if (rowData.address) {
            rowData.street = rowData.address.street
              ? rowData.address.street
              : null;
            rowData.city = rowData.address.city ? rowData.address.city : null;
            rowData.state = rowData.address.state
              ? rowData.address.state
              : null;
            rowData.zip = rowData.address.zip ? rowData.address.zip : null;
          }
          if (rowData.reordering) {
            rowData.autoMin = rowData.reordering.autoMin
              ? rowData.reordering.autoMin
              : null;
            rowData.autoMax = rowData.reordering.autoMax
              ? rowData.reordering.autoMax
              : null;
            rowData.manualMax = rowData.reordering.manualMax
              ? rowData.reordering.manualMax
              : null;
          }

          if (rowData.reorder) {
            let isAutoReOrder = false,
              isManualReOrder = false;
            if (rowData.reorder == "auto") {
              isAutoReOrder = true;
            } else {
              isManualReOrder = true;
            }
            this.setState({
              isAutoReOrder: isAutoReOrder,
              isManualReOrder: isManualReOrder,
            });
          }

          if (this.props.type == "Help") {
            if (rowData.category) {
              this.setState({
                selectedCategory: rowData.category,
              });
            }
          }

          await this.getFormFields(rowData);
          let keys = Object.keys(rowData);
          let formData = [];
          if (this.state.formFields) {
            for (let item of this.state.formFields) {
              if (keys.indexOf(item.name) !== -1) {
                if (
                  item.name == "expirationDate" ||
                  item.name == "endDate" ||
                  item.name == "dateOfBirth" ||
                  item.name == "joinDate" ||
                  item.name == "fromDate" ||
                  item.name == "toDate"
                ) {
                  item.value = dateFormats.formatDate(
                    rowData[item.name],
                    config.dateDayMonthFormat
                  );
                } else {
                  item.value = rowData[item.name];
                }
              } else {
                item.value = " ";
              }
              formData.push(item);
            }
          }
          if (this.props.load) {
            this.props.load(rowData);
          }
          let newFormFields = [];
          let medicineType;
          if (this.props.type == "Required Inventory") {
            medicineType =
              rowData && rowData.categoryType ? rowData.categoryType : "";
            if (medicineType == "Non-Medicine") {
              for (let item of formData) {
                if (item.name != "dosage" && item.name != "inventoryType") {
                  newFormFields.push(item);
                }
              }
            }

          }
          await this.setState(
            {
              formFields:
                this.props.type == "Required Inventory" && medicineType == "Non-Medicine"
                  ? newFormFields
                  : formData,
              editRowDataID: rowData._id ? rowData._id : null,
              isLoading: false,
              rowData: rowData,
              locationImage: rowData.photo ? rowData.photo : null,
            }

          );
        }
      );
    }
  };

  getClinicsDataFromId = (id) => {
    let filter = {
      criteria: [
        { key: 'name', value: id, type: "eq" }
      ]
    }
    let apiUrl = `${apiCalls.Clinics}?filter=${JSON.stringify(filter)}`;
    return fetchMethodRequest('GET', apiUrl)
      .then((response) => {
        if (response && response.clinics) {
          this.setState({
            clinicsData: response.clinics[0]
          })
        } else {
        }
      });
  }

  // to empty form
  clearFormFields = async () => {
    if (this.state.formFields) {
      let formData = [...this.state.formFields];
      formData
        ? formData.forEach((item) => {
          item.value = "";
          item.invalid = false;
          item.message = "";
        })
        : null;
      await this.setState({
        formFields: formData,
      });
      this.getFormFields();
    }
  };

  closeFormModal = () => {
    if (this.props.id != "Checks") {
      this.clearFormFields();
    }
    this.props.reset();
    this.props.closeFormModal();
    if (this.props.getlocationData) {
      this.props.getlocationData()
    }

  };




  closeRecommendedModal = () => {
    this.setState({
      isSelectRecommendOption: false
    })
  }
  openInventoryModal = async (item, type, index) => {
    const { showError } = this.props;
    if (type == "edit" && this.inventoryModalRef) {
      await this.setState({
        // rowData: item,
        showError: false,
        openInventoryModal: true,
        inventorytype: type
      });
      this.inventoryModalRef.getRowData(this.state.productTablefields, index);
    } else {
      await this.setState({
        //isCustomCategory: isCustomCategory,
        inventorytype: type,
        openInventoryModal: true,

      });
    }

  }
  closeInventoryModal = () => {
    if (this.props.formType == "edit") {
      // this.props.load(this.state.rowData);
    }
    //this.props.change("categoryField", "selectCategory");
    this.setState({
      //isCustomCategory: true,
      openInventoryModal: false,
      //showCategoryType: true,
    });
  };

  closeCategoryModal = () => {
    if (this.props.type == "Product Directory") {
      this.getCategoryDrodownValues();
    }
    this.setState({
      isOpenCategoryModal: false,
    });
  };

  expDate = () => { };

  // form Submit
  submit = async (values) => {
    if (this.props.type === 'Storage Unit') {
      values.clinicName = values.clinics.clinicname
    }
    if (this.props.type === 'DashInventory') {
      values.locationType = values.locationType
    }
    // getlocationData


    if (this.props.type === 'Cart Locations') {
      values.clinicName = values.clinics.clinicname
      // values.storageType = values.storageType === 'crashcart' ? "Cart Location" : values.storageType === 'storage' ? "Supply Cabinet" : values.storageType === 'operatingRoom' ? "OR Suite" : null
      if (typeof values.clinics === 'string') {
        values.clinics = this.state.clinicsData;
      }
    }


    values.locationId = this.props.locationId ? this.props.locationId : this.state.inventoryCartId ? this.state.inventoryCartId : null
    if (this.state.productTablefields) {
      values.products = this.state.productTablefields
    }
    if (this.props.facilityName) {
      values.facilityName = this.props.facilityName
    }
    if (this.props.type == "Required Inventories" || this.props.type === "Recommended Inventories") {
      await this.onFilterChange()
    }
    if (this.props.type == "Order") {
      values.type = this.props.orderFrom;
      values.required = parseInt(values.required);
      values.cartName = this.state.orderCart;
      values.facilityName = this.state.facilityName;
    }
    if (values.distributorEmail && values.distributorEmail.email) {
      values.distributorEmail = values.distributorEmail.email;
    }
    if (this.props.tabType == "Orders List") {
      values.newdistributorEmail = values.distributorEmail;
      values.olddistributorEmail = this.state.olddistributorEmail;
    }
    if (this.props.type == "Companies") {
      values = this.formatCompaniesData(values);
    }
    if (this.props.type == "Storage Unit" || this.props.type === 'Cart Locations') {
      values = this.formatLocationsData(values);
      values.clinicId = values.clinics._id;
    }

    if (this.props.type == "Help") {
      if (values.subject && values.subject.subject == "Other") {
        values.subject = values.otherSubject;
        delete values.otherSubject;
      } else {
        values.subject =
          values.subject && values.subject.subject
            ? values.subject.subject
            : values.subject
              ? values.subject
              : null;
      }
    }
    if (this.props.type == "Clinics") {
      values = this.formatClinicAddressData(values);
    }
    if (this.props.type == "Facilities") {
      values = this.formatClinicAddressData(values);
    }
    if (this.props.type == "Product Directory") {
      values.reorder = this.state.reorder;
      if (values.reorder) {
        values = this.formatReorderData(values);
      }
    }
    if (this.props.type == "Recall Items") {
      values.productName =
        values.productName && values.productName.title
          ? values.productName.title
          : values.productName;
      values.title1 = this.state.recallTitle;
    }
    if (
      this.props.type == "Storage Unit Items" ||
      this.props.type == "DashInventory"
    ) {
      values.addedInventory = values.addedInventory
        ? parseInt(values.addedInventory)
        : 0;
      if (values.productName && !values.productId && !this.state.productId) {
        for (let product of this.state.InvProducts) {
          if (values.productName == product.label) {
            values.productId = product.value;
          }
        }
      } else if (this.state.productId) {
        values["productId"] = this.state.productId;
        // values[]
      }
      values["alternateProductId"] = this.state.alternateProductId
      values.limit = values.limit;
      if (this.props.formType == "add" || this.props.type == "DashInventory") {
        values.expirationDate1 = values["expirationDate"]
          ? values["expirationDate"].slice(6, 10) +
          "-" +
          values["expirationDate"].slice(0, 2) +
          "-" +
          values["expirationDate"].slice(3, 5)
          : null;
      }
    }
    if (this.props.type == "Storage Unit Items") {
      values.locationType = this.state.locationType
        ? this.state.locationType
        : values.locationType
          ? values.locationType
          : null;
      values.cartName = this.state.cartName
        ? this.state.cartName
        : values.cartName;
      values.description = this.state.isCustom
        ? values.customDescription
        : values.description;
      values.volume = this.state.isCustomVolumeDes
        ? values.customVolumeDes
        : values.volume;
      values.alternateVolume = values.isCustomalternateVolumeDes
        ? values.isCustomalternateVolumeDes
        : values.alternateVolume;
      values.locationId =
        values.locationId && values.locationId._id
          ? values.locationId._id
          : values.locationId;
      if (this.props.formType == "add") {
        values.clinicId = values.clinicId
          ? values.clinicId._id
          : values.clinicId;
        values.cartSubLocation =
          values.cartSubLocation && values.cartSubLocation.cartParts
            ? values.cartSubLocation.cartParts
            : values.cartSubLocation;
      }
    }
    if (this.props.type == "Order") {
      values.volume = this.state.isCustomVolume
        ? values.customVolume
        : values.volume ? values.volume : "-"
      values.description = this.state.isCustom
        ? values.customDescription
        : values.description;
    }
    if (this.props.type == "Recommended Inventories") {
      if (this.props.type === "Recommended Inventories") {
        let loggedUser = JSON.parse(localStorage.getItem('loginCredentials'));
        values.companyName = loggedUser.companyName
        values.companyId = loggedUser.company_id
        values.recommendedInventory = {}
        values.recommendedInventory = this.state.productTablefields;
      }
    }

    if (this.props.type == "DashInventory") {

      // if (this.props.locationType) {
      //   values.locationType = this.props.locationType
      //     ? this.props.locationType == "Cart Location"
      //       ? "crashcart"
      //       : this.props.locationType == "Supply Cabinet"
      //         ? "storage"
      //         : this.props.locationType == "OR Suite"
      //           ? "operatingRoom"
      //           : null
      //     : null;
      // }
      // else {
      //   values.locationType = values.locationType
      //     ? values.locationType
      //     : this.state.locationType
      //       ? this.state.locationType
      //       : null;
      // }

      values.volume = this.state.isCustomVolumeDes
        ? values.customVolumeDes
        : values.volume;
      values.description = values.description;
      if (this.props.clinicvalues) {
        for (let option of this.props.clinicvalues) {
          if (option.label == values.clinicId) {
            values.clinicId = option.value;
          }
        }
      }

      if (this.state.locations && this.props.type == "DashInventory") {
        for (let option of this.state.locations) {
          if (option.label == values.locationId) {
            values.locationId = option.value;
            this.setState({
              locationId: option.value
            })
          }
        }
      }
    }

    if (this.props.type == "Facility Staff") {
      if (values.noofDrawers) {
        values.noofDrawers = parseInt(values.noofDrawers);
      }
      if (values.clinic) {
        let multiSelectclinics =
          this.props.clinics && this.props.clinics.multiSelectclinics
            ? this.props.clinics.multiSelectclinics
            : [];
        if (typeof values.clinic == "string") {
          values.clinic = JSON.parse(values.clinic);
          if (typeof values.clinic == "object") {
            values.clinic = [{ ...values.clinic }];
          } else {
            values.clinic = values.clinics && values.clinics[0];
          }
        } else if (
          multiSelectclinics &&
          typeof multiSelectclinics == "object" &&
          multiSelectclinics.length > 0 &&
          typeof values.clinic == "object" &&
          values.clinic.length > 0 &&
          multiSelectclinics.length == values.clinic.length
        ) {
          values.clinicType = "all";
        }
      }
    }
    if (values && Object.keys(values).length > 0) {
      this.saveDataToServer({ ...values });
    } else {
      return;
    }
  };




  //send data to server
  saveDataToServer = (formValues, type) => {
    let validationExists;
    this.setState({
      isLoading: true,
    });
    if (this.props.formType == "edit") {
      if (this.props.type == "Companies") {
        if (formValues.companyId) {
          delete formValues.companyId;
        }
      }
      if (this.props.type == "Distributors") {
        if (formValues.companyId) {
          delete formValues.companyId;
        }
        if (formValues.companyName && this.props.type !== "Order") {
          delete formValues.companyName;
        }
      }
      if (
        this.props.tabType == "Placed Orders" ||
        this.props.tabType == "Orders List"
      ) {
        if (formValues._id) {
          formValues.orderId = formValues._id;

          delete formValues._id;
        }
        if (formValues.required) {
          formValues.required = parseInt(formValues.required);
        }
      }
      if (this.props.type == "Product Directory") {
        if (formValues.reorder) {
          formValues = this.formatReorderData(formValues);
        }
        formValues.categoryId = this.state.catId;
        if (formValues.categoryId) {
          for (let value of this.state.categoryNameOptions) {
            if (value.value == formValues.categoryId) {
              formValues.categoryName = value.label;
            }
          }
        }
        if (formValues.productId) {
          delete formValues.productId;
        }
      }

      if (this.props.tabType == "Placed Orders") {
        if (formValues.required1) {
          formValues.required = formValues.required1;
        } else if (!formValues.required1) {
          delete formValues.required;
        }
        if (this.state.placedProduct) {
          formValues.productName = this.state.placedProduct;
        }
      }
      if (this.props.type == "Storage Unit Items") {
        delete formValues.qrcode;
        formValues.productName = this.state.productName;
        if (this.props.addType == "Quantity") {
          formValues.title1 = this.state.productName;
        }
        formValues.productId = this.state.productId
          ? this.state.productId
          : this.state.products._id;
        formValues.expirationDate1 = formValues.expirationDate
          ? formValues.expirationDate.slice(0, 10)
          : null;
        formValues.locationId =
          formValues.locationId && formValues.locationId._id
            ? formValues.locationId._id
            : formValues.locationId;

        // this.setState({
        //   locationType: values.storageType
        // })
        formValues.cartSubLocation =
          formValues.cartSubLocation && formValues.cartSubLocation.cartParts
            ? formValues.cartSubLocation.cartParts
            : formValues.cartSubLocation;
        if (this.props.clinicvalues) {
          for (let option of this.props.clinicvalues) {
            if (option.label == formValues.clinicId) {
              formValues.clinicId = option.value;
            }
          }
        }
        if (this.state.locations) {
          for (let option of this.state.locations) {
            if (option.label == formValues.locationId) {
              formValues.locationId = option.value;
            }
          }
        }
        if (this.state.expirationDate != formValues.expirationDate) {
          formValues.expirationDateChanged = true;
        } else if (this.state.expirationDate == formValues.expirationDate) {
          formValues.expirationDateChanged = false;
        }
      }

      if (this.props.type == "Locations") {
        if (formValues.companyId) {
          delete formValues.companyId;
        }
      } else if (this.props.id == "Checks") {
        formValues.cart = formValues.cartName;
        formValues.oldSubLocation = this.props.cartSubLocation;
        formValues.newSubLocation = formValues.cartSubLocation;
      }

    }
    if (this.props.type === "Required Inventory") {
      formValues.locationId = formValues.locationName.locationId
      formValues.locationId = this.state.id
      formValues.locationName = this.state.locationName
      formValues.productId = formValues.productId
    }
    if (!validationExists) {
      if (formValues) {

        let method, apiUrl;
        if (this.props.formType == "edit") {
          delete formValues.password;
          method = "PUT";
          apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
          if (
            this.props.type == "Storage Unit Checks" ||
            this.props.type == "Battery Checks"
          ) {
            apiUrl = `checks/${this.state.editRowDataID}`;
          }
          if (this.props.tabType == "Placed Orders") {
            if (this.props.formType === 'edit') {
              const storageUnitString = localStorage.getItem("StorageUnitItems");
              const storageUnitObject = JSON.parse(storageUnitString);
              const locationId = storageUnitObject.locationId;
              formValues.fulilled = true;
              formValues.locationId = locationId;
              // formValues.locationId = 
            }
            apiUrl = `orders/updateOrder`;
          }
          if (this.props.tabType == "Orders List") {
            apiUrl = `orders/updateOrderRequired?companyId=${formValues.company_id}`;
          }
          if (this.props.id == "Checks") {
            apiUrl = this.props.apiUrl;
          }

          if (
            this.props.type == "Storage Unit Items" &&

            this.props.previousData
          ) {
            formValues = { ...this.props.previousData, ...formValues };

            if (
              formValues &&
              formValues["clinicId"] &&
              formValues["clinicId"]["_id"]
            ) {
              formValues["newClinicId"] = formValues["clinicId"]["_id"];
            } else if (formValues && formValues["clinicId"]) {
              formValues["newClinicId"] = formValues["clinicId"];
            }
            if (formValues && formValues["cartName"]) {
              formValues["newCartName"] = formValues["cartName"];
            }
            if (formValues && formValues["oldCartName"]) {
              formValues["oldCartName"] = formValues["oldCartName"];
            }
            formValues["newLocationId"] = formValues["locationId"];
            formValues["newLocationType"] = formValues["locationType"];
            formValues["productName"] = {
              title: formValues["title1"],
            };
            formValues["clinicId"] = formValues["newClinicId"];
            formValues["expirationDateChanged"] = false;

            delete formValues["locationId"];
            delete formValues["locationType"];
          }
        }
        else {
          method = "POST";
          apiUrl = this.props.apiUrl;

          if (type == "AddCategory") {
            apiUrl = apiCalls.Categories;
          } else if (type == "AddProduct") {
            apiUrl = apiCalls.Products;
          }

          // else if (type == "Missing Items") {
          //   apiUrl = apiCalls.MissingItems
          // }
        }
        if (this.props.tabType == "Cart Locations" || formValues.storageType == "crashcart") {
          apiUrl = `${apiUrl}?storageType=crashcart`;



        } else if (this.props.tabType == "Supply Cabinets" || formValues.storageType == "storage") {
          apiUrl = `${apiUrl}?storageType=storage`;


        } else if (this.props.tabType == "OR Suites" || formValues.storageType == "operatingRoom") {
          apiUrl = `${apiUrl}?storageType=operatingRoom`;

        }
        if (this.props.type == "Required Inventory") {
          formValues.productName = formValues.productName.title1
        }
        if (this.props.type == "Order") {
          formValues.companyName = this.props.ordercompanyName
        }
        if (this.props.type == "Cart Locations" && this.state.event !== 'operatingRoom') {
          formValues.recommenedInventoryId = this.state.recommenedInventoryId
          formValues.recommendedInventoryName = this.state.recommendedInventoryName
          formValues.selectedInventoryType = "recommended"
        }
        if (formValues && formValues.lotId && this.props.type == "Recall Items") {
          formValues.lotId = formValues.lotId.lotId ? formValues.lotId.lotId : formValues.lotId ? formValues.lotId : ""
          formValues.locationId = this.state.locationIdForRecall
        }
        if (formValues.products && formValues.products.length > 0) {
          formValues.products.map((obj) => {
            if (obj.title1) {
              obj.productName = obj.title1;
            }
            return obj;
          })
        }
        if (apiUrl == 'wishlist/createOrderList') {
          formValues.title1 = this.props.orderTitle
        }

        if (apiUrl === '/orders') {
          apiUrl = 'wishlist/createOrderList'
        }
        if (apiUrl === 'wishlist') {
          apiUrl = 'orders'
        }
        if (this.props.orderProductId && this.props.orderlocationId) {
          formValues.productId = this.props.orderProductId;
          formValues.locationId = this.props.orderlocationId
        }
        if (this.props.type == "DashInventory") {
          formValues.productName1 = { title: formValues.productName && formValues.productNameproductName }
          formValues.cartName = formValues.cart
        }

        return fetchMethodRequest(method, apiUrl, { ...formValues })
          .then(async (response) => {
            // this.props.closeFormModal()

            // debugger
            if (this.props.type === 'Cart Locations') {
              showToasterMessage(response.respMessage, "success");
              this.props.getData()
            }
            if (response && response.respCode) {
              this.props.closeFormModal()
              // showToasterMessage(response.respMessage, "success");
              if (this.props.type === 'Recall Items') {
                this.props.getDataFromServer()
              }
              // if (this.props.type === 'Missing Items' || this) {
              //   this.props.getDataFromServer()
              // }
              if (this.props.id == "Checks") {
                this.props.closeFormModal();
              } else if (this.props.modalType == "addProductFromInventory") {
                this.setState({
                  isProductsModalOpen: false,
                });
              } else if (
                type != "AddCategory" &&
                type != "AddProduct" &&
                this.props.type != "Order" &&
                this.props.type != "DashInventory" &&
                this.props.type != "Wishlist"
              ) {
                await this.props.getDataFromServer(
                  undefined,
                  this.props.tabType
                );
              } else if (response.quantityId) {
                this.props.closeFormModal("save", response.quantityId);
              }
              if (this.props.type === "Facility Staff" && response.isMailSent) {
                this.setState({
                  isEmailConfirmationModal: true,
                  mailSentMessage: response.respMessage,
                });
                return;
              } else {
                showToasterMessage(response.respMessage, "success");
                let path = window.location.pathname
                if ((path.includes('missingItemsTable') && apiUrl === "wishlist/createOrderList") || (path.includes('expirationdatesTable') && apiUrl === "wishlist/createOrderList")) {
                  this.props.updateCartCount();
                }

                if (this.props.getlocationData) {
                  this.props.getlocationData()
                }


              }
              if (this.props.type == "Wishlist" && this.props.screnType != "inventoryChecks") {
                await this.props.getDataFromServer(
                  undefined,
                  this.props.type
                );
              }

            } else if (response && response.errorMessage) {
              if (localStorage.getItem("sessionexpired") == "true") {
                this.setState({
                  openSessionModal: true,
                });
                localStorage.setItem("sessionexpired", false);
                return;
              }
              showToasterMessage(response.errorMessage, "error");
            }

            this.closeForm(type);
          })
          .catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  };















  closeForm = (type) => {
    if (type != "AddCategory" && type != "AddProduct") {
      this.props.closeFormModal();
      this.clearFormFields();
      this.props.reset();
    }

    validation = "";
    this.setState({
      isLoading: false,
    });
  };

  closeMailConfirmationModal = async () => {
    await this.setState({
      isEmailConfirmationModal: false,
    });
    await this.closeForm("");
  };

  // Modify Cart Location address
  formatLocationsData = (values) => {
    if (values) {
      values.cartLocation = {};
      if (values.building) {
        values.cartLocation.building = values.building;
      }
      if (values.area) {
        values.cartLocation.area = values.area;
      }
      if (values.floor) {
        values.cartLocation.floor = values.floor;
      }
      if (values.facility) {
        values.cartLocation.facility = values.facility;
      }
      if (values.room) {
        values.cartLocation.room = values.room;
      }
      return values;
    }
  };
  // Modify Cart Location address

  //format Address field in clinics
  formatClinicAddressData = (values) => {
    if (values) {
      values.address = {};
      if (values.street) {
        values.address.street = values.street;
      }
      if (values.city) {
        values.address.city = values.city;
      }
      if (values.state) {
        values.address.state = values.state;
      }
      if (values.zip) {
        values.address.zip = values.zip;
      }

      return values;
    }
  };
  onCheckType = (value) => {
    if (value.value == "weekly") {
      this.setState({
        isWeekly: true,
      });
    } else {
      this.setState({
        isWeekly: false,
      });
    }
  };
  formatReorderData = (values) => {
    if (values) {
      values.reordering = {};
      if (values.autoMin) {
        values.reordering.autoMin = values.autoMin;
      }
      if (values.autoMax) {
        values.reordering.autoMax = values.autoMax;
      }
      if (values.manualMax) {
        values.reordering.manualMax = values.manualMax;
      }
      return values;
    }
  };
  formatCompaniesData = (values) => {
    if (values) {
      if (values.name && values.name.name) {
        values.name = values.name.name;
      }
      return values;
    }
  };
  customDescription = async () => {
    if (this.state.selectedOption) {
      this.setState({
        isCustom: !this.state.isCustom,
      });
    } else if (this.state.noDescription) {
      await this.setState({
        isCustom: !this.state.isCustom,
        showRequired: true,
      });
    } else if (this.state.selectedalternateOption) {
      this.setState({
        isalternateCustom: !this.state.isalternateCustom,
      });
    } else if (this.state.noDescription) {
      this.setState({
        isalternateCustom: !this.state.isalternateCustom,
      });
    } else {
      await this.setState({
        isCustom: !this.state.isCustom,
        // showRequired: true
      });
    }
  };
  customVolume = () => {
    this.setState({
      isCustomVolume: !this.state.isCustomVolume,
    });
  };
  customVolumeInventory = () => {
    this.setState({
      isCustomVolumeDes: !this.state.isCustomVolumeDes,
    });
  };
  customalternateVolumeInventory = () => {
    this.setState({
      isCustomalternateVolumeDes: !this.state.isCustomalternateVolumeDes,
    });
  };

  openCategoryModal = async () => {
    this.setState({
      isOpenCategoryModal: true,
    });
  };

  getProductName = async (name) => {
    this.props.change("productName", name.productName);
    this.setState({
      recallTitle: name.title1,
    });
  };

  handleReOrdering = async (event) => {
    if (this.props.type == "Product Directory") {
      if (event) {
        let isManualReOrder = false,
          isAutoReOrder = false;
        if (event == "auto") {
          this.props.change("autoMin", 1);
          this.props.change("autoMax", 10);
          await this.getCategoryNames();
          isAutoReOrder = true;
          this.setState({
            reorder: "auto",
          });
        } else if (event == "manual") {
          isManualReOrder = true;
          this.setState({
            reorder: "manual",
          });
        }
        this.setState({
          isAutoReOrder: isAutoReOrder,
          isManualReOrder: isManualReOrder,
        });
      }
    }
  };
  // RequiredInventory: 'requiredInventory',

  setClinicName = async (value) => {
    if (value) {
      return fetchMethodRequest("GET", apiCalls.CartLocations).then(
        async (response) => {
          if (response && response.locations && response.locations.length > 0) {
            for (let option of response.locations) {
              if (option.cart == value) {
                this.props.change("clinicId", option.clinicName);
                this.props.change("cart", option.cart);
                this.setState({
                  locationType: option.storageType,
                });
              }
            }
          }
        }
      );
    }
  };
  // RequiredInventoryData = async (value) => {
  //   let apiUrl = `${apiCalls.RequiredInventory}?locationId=${this.state.id}`;
  //   return fetchMethodRequest("GET", apiUrl).then(async (res) => {
  //     if (res && res.requiredInventory && res.requiredInventory.length > 0) {
  //       for (let invoptions of res.requiredInventory) {
  //         if (invoptions) {
  //           this.setState({
  //             invoptions: invoptions
  //           })

  //         }
  //       }

  //     }
  //   })
  // };

  getCategoryNames = async () => {
    var apiUrl, url,
      filterCriteria = {
        limit: 20,
        page: 1,
        criteria: [],
        direction: "asc",
        sortfield: "categoryName",
      };
    url = apiCalls.Categories;
    apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;

    return fetchMethodRequest("GET", apiUrl).then(
      async (response) => {
        if (response && response.categories && response.categories.length > 0) {
          let categories = [];
          for (let option of response.categories) {
            categories.push({
              label: option.categoryName,
              value: option._id,
              categoryType: option.categoryType,
            });
          }
          this.setState({
            categoryNameOptions: categories,
          });
        }
      }
    );
  };

  getClinicsForInventory = async () => {
    return fetchMethodRequest("GET", apiCalls.Clinics).then(
      async (response) => {
        let clinicLocations = [];
        if (response && response.clinics) {
          for (let option of response.clinics) {
            clinicLocations.push({
              label: option.name,
              value: option._id,
            });
          }
        }
        await this.setState({
          clinics: clinicLocations,
        });
      }
    );
  };

  getTitleData = (name, cart) => {
    let originalName = name;
    if (name) {
      name = name.replace("%", "ptile");
      name = name.replace("/", "slash");
    }

    let filter = {
      criteria: [
        { key: "title1", value: name, type: "eq" },
        { key: "cartName", value: cart, type: "eq" },
      ],
    };
    let url = `${apiCalls.Quantities}?filter=${JSON.stringify(
      filter
    )}&load=true`;
    return fetchMethodRequest("GET", url).then(async (response) => {
      if (response && response.quantities) {
        for (let option of response.quantities) {
          if (option.title1 == originalName && option.cartName == cart) {
            await this.getRowData(option);
          }
        }
      }
    });
  };

  getLocationsForInventory = async () => {
    return fetchMethodRequest("GET", apiCalls.CartLocations).then(
      async (response) => {
        let clinicLocations = [];
        if (response && response.locations) {
          for (let option of response.locations) {
            clinicLocations.push({
              label: option.cart,
              value: option._id,
            });
          }
        }
        await this.setState({
          locations: clinicLocations,
        });
      }
    );
  };
  getLocationData = async (value) => {
    await this.setState({
      locationName: value,
    });
  };

  //changing value of roleType
  handleAutoCompleteData = async (value, name) => {


    if (this.props.type == "Recall Items") {
      this.getProductName({
        productName: value.productName,
        title1: value.title1,
      });
      this.props.change("lotId", value.lotId);
      this.props.change("productId", value.productId._id);
      this.props.change("locationId", value.locationId._id);
      this.setState({ locationIdForRecall: value.locationId._id })
    }
    if (this.props.type == "Storage Unit Items") {
      if (name == "clinicId") {
        this.props.change("locationId", "");
        this.setState({
          facilityName: value.name,
          clinicName: value.name,
        });
      }
      if (name == "locationId") {
        await this.setState({
          cartName: value.cart,
          inventoryCartId: value._id,
        });
        this.getCountData();
      }
    }
    if ((this.props.type == "Storage Unit Items" || this.props.type == "DashInventory") && name == "NoDescription" || name == "noalternateDescription"
    ) {
      if (value[0]["categoryType"]) {
        this.props.change("limit", value[0]["required"]);
        this.props.change("volume", value[0]["dosage"]);

        if (value[0]["categoryType"] == "Medicine" && !value.batteryExists) {
          this.setState({
            isMedicine: true,
            isNonMedicine: false,
            isBattery: false,
          });
        } else if (
          value[0]["categoryType"] == "Non-Medicine" &&
          !value.batteryExists
        ) {
          this.setState({
            isMedicine: false,
            isNonMedicine: true,
            isBattery: false,
          });
        } else if (value.batteryExists) {
          this.setState({
            isMedicine: false,
            isNonMedicine: false,
            isBattery: true,
          });
        }
      }
      this.setState({
        productId: value[0]["_id"],
      });

      this.getCountData();
    }
    if ((this.props.type == "Storage Unit Items" || this.props.type == "DashInventory") && name == "description" || name == "alternateDescription") {
      if (value) {
        if (name == "description") {
          this.setState({
            selectedOption: true,
          });

          if (value && value.categoryId) {
            if (value.categoryId.categoryType) {
              if (
                value.categoryId.categoryType == "Medicine" &&
                !value.batteryExists
              ) {
                this.setState({
                  isMedicine: true,
                  isNonMedicine: false,
                  isBattery: false,
                });
              } else if (
                value.categoryId.categoryType == "Non-Medicine" &&
                !value.batteryExists
              ) {
                this.setState({
                  isMedicine: false,
                  isNonMedicine: true,
                  isBattery: false,
                });
              } else if (value.batteryExists) {
                this.setState({
                  isMedicine: false,
                  isNonMedicine: false,
                  isBattery: true,
                });
              }
            }
          }
          if (value && value.categoryType) {
            if (value.categoryType) {
              if (value.categoryType == "Medicine" && !value.batteryExists) {
                this.setState({
                  isMedicine: true,
                  isNonMedicine: false,
                  isBattery: false,
                });
              } else if (
                value.categoryType == "Non-Medicine" &&
                !value.batteryExists
              ) {
                this.setState({
                  isMedicine: false,
                  isNonMedicine: true,
                  isBattery: false,
                });
              } else if (value.batteryExists) {
                this.setState({
                  isMedicine: false,
                  isNonMedicine: false,
                  isBattery: true,
                });
              }
            }
          }
        } else if (name == "alternateDescription") {
          this.setState({
            selectedalternateOption: true,
          });

          if (value && value.categoryId) {
            if (value.categoryId.categoryType) {
              if (
                value.categoryId.categoryType == "Medicine" &&
                !value.batteryExists
              ) {
                this.setState({
                  isalternateMedicine: true,
                  isalternateNonMedicine: false,
                  isalternateBattery: false,
                });
              } else if (
                value.categoryId.categoryType == "Non-Medicine" &&
                !value.batteryExists
              ) {
                this.setState({
                  isalternateMedicine: false,
                  isalternateNonMedicine: true,
                  isalternateBattery: false,
                });
              } else if (value.batteryExists) {
                this.setState({
                  isalternateMedicine: false,
                  isalternateNonMedicine: false,
                  isalternateBattery: true,
                });
              }
            }
          }
          if (value && value.categoryType) {
            if (value.categoryType) {
              if (value.categoryType == "Medicine" && !value.batteryExists) {
                this.setState({
                  isalternateMedicine: true,
                  isalternateNonMedicine: false,
                  isalternateBattery: false,
                });
              } else if (
                value.categoryType == "Non-Medicine" &&
                !value.batteryExists
              ) {
                this.setState({
                  isalternateMedicine: false,
                  isalternateNonMedicine: true,
                  isalternateBattery: false,
                });
              } else if (value.batteryExists) {
                this.setState({
                  isalternateMedicine: false,
                  isalternateNonMedicine: false,
                  isalternateBattery: true,
                });
              }
            }
          }
        }
        if (this.state.locationName) {
        }
      }
    }
    if (this.props.type == "Facility Staff" && name == "pharmasistCompanies") {
      let isCompanySelect = false;
      if (value && value.length > 0) {
        isCompanySelect = true;
      }
      this.setState({
        isCompanySelect: isCompanySelect,
      });
    }
    if (this.props.type == "Storage Unit Items" && name == "cartSubLocation") {
      this.getRequiredQuantityBasedOnCartSubLoc(value);
    }
    if (this.props.type === "Storage Unit Items" || this.props.type == "DashInventory") {
      if (name == "productName") {
        if (value["title"]) {
          if (value["title"].lastIndexOf("-") !== -1) {
            // let pos = value['title'].lastIndexOf('-')
            // let val = value['title'].slice(0, pos)
            // this.props.change('productName', val)
          }
        }
      }

      if (name == "locationId") {
        this.setState({
          locationType: value.storageType,
        });
        this.getLocationSubParts(value);
      }
      if (name == "description") {
        await this.setState({
          productId: value._id,
          productName: value.title1,
        });
        this.props.change(
          "description",
          value.description ? value.description : null
        );
        this.props.change("volume", value.dosage ? value.dosage : null);
      }
      if (name == "alternateDescription") {
        await this.setState({
          alternateProductId: value._id,
          alternateProductName: value.title1,
        });
        this.props.change(
          "alternateDescription",
          value.description ? value.description : null
        );
        this.props.change("alternateVolume", value.dosage ? value.dosage : null);
      }
      if (name == "cartSubLocation") {

        await this.setState({
          cartSubLocation: value.cartParts,
        });
        this.getCountData();
      }
    }

    if (
      this.props.type == "DashInventory" ||
      this.props.type == "Storage Unit Items"
    ) {
      if (name == "productName") {
        this.getUniProductName(value.title1);
      }
      if (name == "alternateProductName") {
        this.getUniAlternateProductName(value.title1);
      }
    }

    if (this.props.type == "Help") {
      let isTicketType = false;
      if (value && value.subject == "Other") {
        isTicketType = true;
      }
      this.setState({
        isTicketType: isTicketType,
      });
    }
    if (this.props.type == "Required Inventory") {
      let body = {
        description: value.description,
        categoryName: value.categoryName,
        dosage: value.dosage,
        inventoryType: value.inventoryType,
        productName: value.title1,
        inventoryType: value.inventoryType,
        productId: value && value._id ? value._id : "",
        categoryId: value && value.categoryId ? value.categoryId._id : "",
      };
      let newFormFields = [];
      let medicineType = value && value.categoryType ? value.categoryType : "";
      if (medicineType == "Non-Medicine") {
        for (let item of this.state.formFields) {
          if (item.name != "dosage" && item.name != "inventoryType") {
            newFormFields.push(item);
          }
        }
      }
      if (medicineType == "Medicine") {
        let isDosageFieldFound = false;
        for (let item of this.state.formFields) {
          if (item.name == "dosage") {
            newFormFields.push(item);
            isDosageFieldFound = true;
          } else {
            newFormFields.push(item);
          }
        }
        if (!isDosageFieldFound) {
          newFormFields.splice(
            3,
            0,
            {
              required: true,
              value: "",
              type: "text",
              name: "dosage",
              label: "Volume ",
              id: "dosage",
              placeholder: "Volume",
              disable: true,
            },
            {
              required: true,
              value: "",
              type: "text",
              name: "inventoryType",
              label: "Select Inventory Type ",
              id: "inventoryType",
              placeholder: "Inventory Type",
              disable: true,
            }
          );
        }
      }
      await this.setState({
        formFields: value && value.productName ? newFormFields : this.state.formFields,
      });

      this.props.load(body);
      // this.props.change("locationType", this.state.invoptions.locationType = this.state.invoptions.locationType === "crashcart" ? "Cart Location" : this.state.invoptions.locationType === "storage" ? "Supply Cabinet" : this.state.invoptions.locationType === 'operatingRoom' ? "OR Suite" : null);
      // this.props.change("locationName", this.state.locationName);
      // this.props.change("locationType", this.state.invoptions.locationType);

    }
  };
  handlePopupModal = () => {
    this.setState({
      isSelectRecommendOption: true,
    })

  }
  cartName = () => {
    if (this.props.type == "DashInventory") {
      this.props.change("locationId", this.state.inventoryCart);
    }
  };

  // set upload image in state
  getFileName = (file) => {
    this.setState({
      locationImage: file,
    });
  };

  getCustomData = (option) => {
    if (option.value == "others") {
      this.setState({
        isCustom: true,
      });
    } else {
      this.setState({
        isCustom: false,
      });
    }
  };

  getCountData = (locationId, productId, cartSubLocation) => {
    if (this.props.type == "DashInventory" || this.props.type == "Storage Unit Items") {
      if ((this.state.inventoryCartId != null ||
        this.state.locationId != null) &&
        this.state.productId != null
        && this.state.cartSubLocation != null
      ) {
        let body = {
          locationId: this.state.inventoryCartId != null ? this.state.inventoryCartId : this.state.locationId,
          productId: this.state.productId,
          cartSubLocation: this.state.cartSubLocation,
        };
        return fetchMethodRequest(
          "POST",
          "quantities/currentInventoryCount",
          body
        ).then(async (response) => {
          if (response && (response.count || response.count == 0)) {
            this.props.change("count2", response.count);
          }
        });
      }
    }
    if (locationId && productId && cartSubLocation) {
      this.setState({
        productId: productId
        , locationId: locationId,
      })
      let body = {
        locationId: locationId,
        productId: productId,
        cartSubLocation: cartSubLocation,
      };
      return fetchMethodRequest(
        "POST",
        "quantities/currentInventoryCount",
        body
      ).then(async (response) => {
        if (response && (response.count || response.count == 0)) {
          this.props.change("count2", response.count);
        }
      });
    }
  };

  noDescription = () => {
    this.setState({
      noDescription: true,
    });
  };
  noalternateDescription = () => {
    this.setState({
      noalternateDescription: true,
    });
  };
  isShowAlternateProductDetails = () => {
    this.setState({
      isShowAlternateProduct: !this.state.isShowAlternateProduct,
    });
  }
  getNumberBeingAdded = (value) => {
    if (value > 0) {
      this.setState({
        lotAndExpirationRequired: true,
      });
    }
  };
  getLocationSubParts = (selectedLocation) => {
    this.setState({
      cartName:
        selectedLocation && selectedLocation.cart
          ? selectedLocation.cart
          : selectedLocation,
    });
    if (this.props.type == "Storage Unit Items" && selectedLocation) {
      this.setState({
        inventoryCartId: selectedLocation.value
          ? selectedLocation.value
          : selectedLocation._id,
      });
    }
    return fetchMethodRequest("GET", apiCalls.CartLocations).then(
      async (response) => {
        let subLocations = [];
        let selectedLocationName =
          selectedLocation && selectedLocation.label
            ? selectedLocation.label
            : selectedLocation;
        if (response && response.locations) {
          for (let option of response.locations) {
            if (selectedLocationName == option.cart) {
              for (let value of option.cartParts) {
                subLocations.push({
                  label: value,
                  value: value,
                });
              }
            }
          }
        }
        this.setState({
          subLocations: subLocations,
        });
      }
    );
  };

  getLocationDropdownData = async (value) => {
    this.setState({
      clinicName: value.label,
    });
    return fetchMethodRequest("GET", apiCalls.CartLocations).then(
      async (response) => {
        let Locations = [];
        let i = value.label ? value.label : value;
        if (response && response.locations) {
          for (let option of response.locations) {
            if (
              option &&
              option.clinics &&
              option.clinics.name &&
              option.clinics.name == i
            ) {
              Locations.push({
                label: option.cart,
                value: option._id,
              });
            }
          }
        }
        this.setState({
          clinicsLocations: Locations,
        });
      }
    );
  };

  getClinicSublocationsList = async () => {
    return fetchMethodRequest("GET", apiCalls.Clinics).then(
      async (response) => {
        let clinicLocations = [];
        if (response && response.clinics) {
          for (let option of response.clinics) {
            clinicLocations.push({
              label: option,
              value: option,
            });
          }
        }
        this.setState({
          clinicLocationsList: clinicLocations,
        });
      }
    );
  };
  getUniProductName = async (value) => {
    await this.setState({
      uniProductName: value,
      isCustom: false,
      noDescription: false,
      selectedOption: false,
      showRequired: false,
      isMedicine: false,
      isNonMedicine: false,
    });
    this.props.change("description", "");
    this.props.change("volume", "");
  };
  getUniAlternateProductName = async (value) => {
    await this.setState({
      unialternateProductName: value,
      isCustomAlternate: false,
      noalternateDescription: false,
      selectedalternateOption: false,
      showalternateRequired: false,
      isalternateMedicine: false,
      isalternateNonMedicine: false,
    });
    this.props.change("alternateDescription", "");
    this.props.change("alternateVolume", "");
  };
  changepro = (value) => {
    this.props.change("productName", "hi");
  };
  getUniqueProducts = async () => {
    return fetchMethodRequest("GET", apiCalls.ProductsList).then(
      async (response) => {
        let products = [];
        if (response && response.products) {
          let arr = response.products.sort();
          for (let option of arr) {
            products.push({
              label: option.title,
              value: option.title,
            });
          }
        }
        await this.setState({
          uniProducts: products,
        });
      }
    );
  };

  getCategoryDrodownValues = async () => {
    var apiUrl, url,
      filterCriteria = {
        limit: 20,
        page: 1,
        criteria: [],
        direction: "asc",
        sortfield: "categoryName",
      };
    url = apiCalls.Categories;
    apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
    return fetchMethodRequest("GET", apiUrl).then(
      async (response) => {
        let modifiedCategoryName = [];
        if (response && response.categories && response.categories.length > 0) {
          for (let category of response.categories) {
            modifiedCategoryName.push({
              label: category.categoryName,
              value: category._id,
              categoryType: category.categoryType,
            });
          }
        }
        await this.setState({
          categoryDropDownValues: modifiedCategoryName,
        });
      }
    );
  };

  getCategoryProductType = (selectedProduct) => {
    let isTypeMedicine = false;
    if (selectedProduct && selectedProduct.productCategoryType) {
      if (selectedProduct.productCategoryType == "Medicine") {
        isTypeMedicine = true;
      } else if (selectedProduct.productCategoryType == "Non-Medicine") {
        isTypeMedicine = false;
      }
      this.setState({
        isTypeMedicine: isTypeMedicine,
      });
    }
  };

  closeDeleteModal = async () => {
    await this.setState({
      openDeleteModal: false,
      openOptionsModal: false
    });
  };

  seleteConfirmationFun = async (id, type, formType) => {
    await this.setState({
      openDeleteModal: true,
      deleteObj: { id: id, type: type, formType: formType }
    })
  }

  deleteSelectedRow = (str) => {
    const { id, type, formType } = this.state.deleteObj

    if (str === 'delete') {
      this.props.type === "Recommended Inventories" ? this.deleteproductAction(id, type, formType) : this.deleteOrderProduct(id)

    }
    this.closeDeleteModal()
  }

  deleteproductAction = (id, type, formType) => {
    let productTablefields = this.state.productTablefields;
    let data = productTablefields[id];
    productTablefields.splice(id, 1);
    let allOriginalFields = this.state.allOriginalFields.filter((obj) => JSON.stringify(obj) != JSON.stringify(data));
    this.setState({
      productTablefields: productTablefields,
      allOriginalFields: allOriginalFields
    });
  }
  // deleteOrderProduct = (item) => {
  //   // debugger
  //   // ////console.log("check")
  //   let pload = {};
  //   // //console.log("pload", pload)
  //   pload['locationId'] = this.props.locationId;
  //   pload['productId'] = item._id;
  //   let url = `wishlist/removeWishProduct`;
  //   return fetchMethodRequest("POST", url, pload)
  //     .then((res) => {
  //       if (res) {
  //         let productTablefields = this.state.productTablefields
  //         let pID = pload['productId']
  //         let newProductTablefields = productTablefields.filter(
  //           (product) => product._id !== pID
  //         );
  //         this.setState({
  //           productTablefields: newProductTablefields,
  //         });
  //         // this.refreshWishlist();
  //         showToasterMessage(res.respMessage, "success");
  //       }
  //     })
  // }


  deleteOrderProduct = (item) => {
    let pload = {};
    pload['locationId'] = this.props.locationId;
    pload['productId'] = item._id;
    let url = `wishlist/removeWishProduct`;
    return fetchMethodRequest("POST", url, pload)
      .then((res) => {
        if (res) {
          let productTablefields = this.state.productTablefields
          let pID = pload['productId']
          let newProductTablefields = productTablefields.filter(
            (product) => product._id !== pID
          );
          this.setState({
            productTablefields: newProductTablefields,
          });
          showToasterMessage(res.respMessage, "success");
        }
      })
  }



  handleinventoryTable = async (values, type, index) => { //filtering
    let arr = []
    let productTablefields = values

    if (type == 'add') {
      arr = this.state.allOriginalFields
      arr.push(values[values.length - 1])
      productTablefields = arr.filter((obj) => {
        if (this.state.categoryType != '' && this.state.categoryType == obj.categoryType) {
          return obj
        }
      })
    }

    let i = -1;
    if (type == 'edit') {
      productTablefields[index] = values[index];
      arr = this.state.allOriginalFields.map((obj, i2) => {
        if (this.state.categoryType != '' && this.state.categoryType == obj.categoryType) {
          i++;
          if (i == index) {
            return values[index];
          }
          return values[i];
        }
        else if (this.state.categoryType == '' && index == i2) {
          return values[index];
        }
        return obj;
      })
      productTablefields = arr.filter((obj) => {
        if (this.state.categoryType != '' && this.state.categoryType == obj.categoryType) {
          return obj
        }
      })
    }
    this.setState({
      productTablefields: productTablefields,
      allOriginalFields: arr
    })

  }
  onFilterChange = async (e, type) => {
    let productTablefields = this.state.allOriginalFields, newArray = []
    if (type === "dropDown" && e.value) {
      if (productTablefields !== undefined && productTablefields && productTablefields.length > 0 && e && e.value !== null) {
        newArray = productTablefields.filter(function (item) {

          if (item.categoryType) {
            let pN = item.categoryType
            return pN === e.value
          }
        });
        await this.setState({
          productTablefields: newArray,
          categoryType: e.value
        })
      } else {
        await this.setState({
          categoryType: e.value
        })
      }
    } else if (e && e.target && e.target.value && e.target.value.length > 0) {
      if (productTablefields !== undefined && productTablefields && productTablefields.length > 0 && e && e.target && e.target.value && e.target.value.length > 0) {
        newArray = productTablefields.filter(function (item) {
          if (item.productName) {
            let pN = item.productName.toLowerCase()
            return pN.includes(e.target.value)
          }
        });
        await this.setState({
          productTablefields: newArray
        })

      }
    } else {
      newArray = this.state.allOriginalFields
      await this.setState({
        productTablefields: newArray,
        // categoryType: ""
      })

    }
  }

  clearLocationValues = () => {
    if (
      this.props.formType == "edit" &&
      this.props.type == "Storage Unit Items"
    ) {
      this.props.change("locationId", "");
      // this.props.change("cartSubLocation", "");
      this.props.change("lotId", "");
    }
  };

  getCategoryTypes = (selectedCategory, name) => {
    if (selectedCategory.value) {
      this.setState({
        catId: selectedCategory.value,
      });
    }
    if (selectedCategory && selectedCategory.categoryType == "Medicine") {
      this.setState({
        showCategoryType: true,
        notMedicine: false,
      });
    } else if (selectedCategory.categoryType == "Non-Medicine") {
      this.setState({
        showCategoryType: false,
        notMedicine: true,
      });
    } else if (
      name == "category" &&
      selectedCategory &&
      selectedCategory.value
    ) {
      this.setState({
        selectedCategory: selectedCategory.value,
      });
    }
  };

  handlePermissionedRole = async (selectedRole) => {
    this.props.change("clinic", "");
    this.setState({
      permissionedRole: selectedRole && selectedRole.value,
    });
  };
  openAddProductsModal = async () => {
    await this.setState({
      isProductsModalOpen: true,
    });
  };
  closeAddProductsModal = () => {
    this.setState({
      isProductsModalOpen: false,
    });
  };

  getRequiredQuantityBasedOnCartSubLoc = async (selectedLocation) => {

    // let body = {
    //   productId: this.state.productId,
    //   locationId: this.props.inventoryCartId
    //     ? this.props.inventoryCartId
    //     : this.state.locationId
    //       ? this.state.inventoryCartId
    //       : null,

    // };
    await this.setState({
      cartSubLocation: selectedLocation.value
        ? selectedLocation.value
        : selectedLocation.cartParts
          ? selectedLocation.cartParts
          : null,
    });

    await this.getCountData();
    if (this.state.productId != null
      && (this.props.locationType != null)) {
      let filterCriteria = {
        direction: "desc",
        page: 1,
        limit: 20,
        criteria: []
      };

      if (this.state.locationType != null) {
        filterCriteria['criteria']
          =
          [
            { "key": "productId", "value": this.state.productId, "type": "eq" },
            {
              "key": "locationType", "value": this.state.locationType
              , "type": "eq"
            }, {
              "key": "locationId", "value": this.state.inventoryCartId != null ? this.state.inventoryCartId : this.state.locationId
              , "type": "eq"
            }
          ];
      } else {
        filterCriteria['criteria']
          = [
            { "key": "productId", "value": this.state.productId, "type": "eq" },
            {
              "key": "locationType", "value": this.props.locationType == "Cart Location" ?
                "crashcart" : this.props.locationType == "Supply Cabinet" ? "storage" : "operatingRoom"
              , "type": "eq"
            }, {
              "key": "locationId", "value": this.state.inventoryCartId != null ? this.state.inventoryCartId : this.state.locationId
              , "type": "eq"
            }
          ]
      }


      return fetchMethodRequest("GET", apiCalls.RequiredInventory
        + `?filter=${JSON.stringify(filterCriteria)}`
      ).then(
        (response) => {
          if (response) {
            let requiredCount;
            if (response) {
              if (response.requiredInventory && response.requiredInventory.length > 0) {
                requiredCount = response.requiredInventory[0].required;
                this.props.change("limit", requiredCount);
              } else {
                this.props.change("limit", 0);

              }
            }
          }
        }
      );
    }

  };

  handleCategoryNames = async (e) => {
    this.setState({
      selectedInventoryType: "recommended"
    })
    let filterCriteria = { sortfield: "name", direction: "desc" }
    let loggedUser = JSON.parse(localStorage.getItem('loginCredentials'));
    let companyId = loggedUser.company_id
    filterCriteria.criteria = [{ key: "companyId", value: [undefined, companyId], type: "in" }]
    // if (e === "recommended") {
    return fetchMethodRequest("GET", `recommendedInventory?filter=${JSON.stringify(filterCriteria)}`).then(
      async (response) => {
        let Locations = [];

        if (response && response.recommendedInventorys) {
          for (let option of response.recommendedInventorys) {
            Locations.push({
              label: option.name,
              value: option
            });

          }
        }
        await this.setState({
          recommendedInventory: Locations,
        });
      }
    );
    // } else {
    //   await this.setState({
    //     recommenedInventoryId: null,
    //     recommendedInventoryName: null
    //   })
    // }
  }

  getlocationNamesforInventory = (name) => {

    if (this.props.type === "Required Inventory") {
      return fetchMethodRequest(
        "GET",
        `locations?filter={"limit":"all","page":1,"criteria":[],"sortfield":"created","direction":"desc"}&storageType=${name.value ? name.value : name}`
      ).then(async (response) => {
        let locations = [];
        if (response && response.locations) {
          for (let location of response.locations) {
            locations.push({
              label: location.cart,
              value: {
                locationId: location._id,
                locationName: location.cart
              },
            });
          }
          await this.setState({
            Invlocations: locations,
          });
        }
      });
    } else {
      this.setState({
        Invlocations: [],
      });

    }
  }

  isopenFormModal = () => {
    this.props.setIsOpenFormModal(true);
  }


  handleFieldChange = async (event) => {
    await this.setState({
      event: event,
    })
    this.props.change("recommendedInventoryName", '')
  }

  render() {
    const { handleSubmit } = this.props;
    const modalClass = classNames({
      "modal-dialog--colored": this.state.colored,
      "modal-dialog--header": this.state.header,
    });

    const { rowData } = this.state;
    const checkTypes = [
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
    ];
    if (
      (this.props.type == "Storage Unit Items" ||
        this.props.type == "DashInventory") &&
      !this.state.addedProduct
    ) {
      this.getUniqueProducts();
    }
    let days = [
      { label: "Sunday", value: "sunday" },
      { label: "Monday", value: "monday" },
      { label: "Tuesday", value: "tuesday" },
      { label: "Wednesday", value: "wednesday" },
      { label: "Thursday", value: "thursday" },
      { label: "Friday", value: "friday" },
      { label: "Saturday", value: "saturday" },
    ];

    if (rowData && rowData.status) {
      if (
        rowData.status == "Cancelled" ||
        rowData.status == "Rejected" ||
        rowData.status == "waitingForConsultantApproval" ||
        rowData.status == "ApprovedByManager"
      ) {
        isLeaveDisabled = true;
      }
    }
    return (
      <div style={{ display: "flex" }}>
        {this.props.locationsLength ?
          <OptionsModal
            openDeleteModal={this.state.openOptionsModal}
            closeDeleteModal={this.closeDeleteModal}
          />
          : null}

        <Modal
          isOpen={this.props.openFormModal}
          className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
          style={this.props.type === "Recommended Inventories" || this.props.type == 'Wishlist' ? {
            maxWidth: 1400
          } : {}}
        >
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={this.closeFormModal}
            />
            <p className="bold-text  modal__title">
              {this.props.formType && this.props.formType == "edit"
                ? "Edit "
                : "Add "}
              {this.props.type == "Order"
                ? "To Orders List" :
                this.props.type == "Required Inventory" ?
                  'Required Inventory' : this.props.type == "Recommended Inventories" ?
                    'Recommended Inventory' :
                    this.props.type == "DashInventory" ?
                      'Quantity'
                      : this.props.type == 'Wishlist' ? 'To Orders List'
                        : this.state.formModalType
                          ? this.state.formModalType
                          : null}
            </p>
          </ModalHeader>

          {/* Modal Body */}
          <ModalBody className="modal__body mb-0">
            <Card className="m-0 p-0">
              <CardBody className="m-0 p-0">
                {/* Loader start */}
                <Loader loader={this.state.isLoading} />

                {/* Form fields */}
                <form
                  key={this.props.type}
                  onSubmit={handleSubmit(this.submit)}
                  autoComplete={"off"}
                >
                  <div className="col-sm-12">
                    <ButtonToolbar className=" modal__footer footerData">
                      {/* Cancel */}
                      <Button
                        color="primary"
                        type="button"
                        onClick={
                          this.props.type == "Product Directory" &&
                            this.props.modalType == "addCategoryFromProducts"
                            ? this.closeCategoryModal
                            : this.closeFormModal
                        }
                      >
                        Cancel
                      </Button>
                      {/* Save */}
                      <Button
                        color="primary"
                        outline
                        type="submit"

                        disabled={!this.state.isCheckboxSelected && (this.props.type == "Cart Locations" && (this.state.event !== 'operatingRoom' && this.state.storagetype !== 'operatingRoom'))}

                      >
                        {(this.props.modalType &&
                          this.props.modalType === "addCategoryFromProducts") ||
                          (this.props.formType &&
                            this.props.formType === "add" &&
                            (this.props.type === "DashInventory"))
                          ? "Add To Orders List"
                          : this.props.type === "Order" || this.props.type == "Wishlist"
                            ? "Add To Orders List"
                            : this.props.formType === "add"
                              ? "Save"
                              : "Update"}
                      </Button>

                      {/* {(this.props.type == "Storage Unit Items" ||
                        this.props.type == "DashInventory") &&
                        this.props.formType != "edit" ? (
                        <Button
                          color="primary"
                          type="button"
                          onClick={this.openAddProductsModal}
                        >
                          Add Product
                        </Button>
                      ) : null} */}
                    </ButtonToolbar>
                  </div>
                  <div className="row form">
                    {this.state.formFields
                      ? this.state.formFields.map((item, i) => {
                        return (
                          <div
                            key={this.props.type + i}
                            className={
                              item.type == "editor" ? "col-sm-12" : "col-sm-6"
                            }
                          >
                            <div className="form__form-group">
                              <label className="form__form-group-label">
                                {item.label}
                              </label>
                              <span className="form__form-group-label">
                                {item.mandatory ? <span className="mandatoryStyle">&nbsp;*</span> : null}
                              </span>
                              {item.type == "dropDown" &&
                                item.isMultiSelect ? (
                                <div className="form__form-group-field">
                                  <Field
                                    key={i}
                                    name={item.name}
                                    component={MultiSelectDropDown}
                                    id={item.id}
                                    validate={[required]}
                                    options={item.options}
                                  />
                                </div>
                              )
                                : item.type == "dropDown" ? (
                                  <div className="form__form-group-field">

                                    <Field
                                      key={i}
                                      name={item.name}
                                      component={Select}
                                      value={item.value}

                                      onChange={(e) => this.handleFieldChange(e)}

                                      isDisable={
                                        this.state.permissionedRole === "Super Admin" ||
                                          (
                                            this.props.type === 'Cart Locations' &&
                                            this.props.formType === 'edit' &&
                                            item.name === 'storageType'
                                          )
                                          // ||
                                          // (
                                          //   this.props.type === 'Required Inventory' &&
                                          //   (
                                          //     this.props.formType === 'add' ||
                                          //     this.props.formType === 'edit'
                                          //   ) &&
                                          //   (item.name === 'locationType' || item.name === 'locationName')
                                          // )
                                          ? true
                                          : false
                                      }

                                      options={
                                        item.name == "productName" &&
                                          this.props.type == "Storage Unit Items"
                                          ? this.state.uniProducts
                                          : item.name == "productName" &&
                                            this.props.type == "DashInventory"
                                            ? this.state.uniProducts
                                            : item.name == "cartSubLocation"
                                              ? this.state.subLocations
                                              : item.name == "locationId"
                                                ? this.state.clinicsLocations
                                                : item.name == "categoryId"
                                                  ? this.state.categoryDropDownValues
                                                  : item.name == "locationName"
                                                    ? this.state.Invlocations
                                                    : item.options
                                      }
                                      defaultValue={
                                        this.props.formType &&
                                          this.props.formType == "add"
                                          ? item.defaultValue
                                          : null
                                      }
                                      placeholder={item.placeholder}
                                      getCategoryProductType={
                                        this.getCategoryProductType
                                      }
                                      getlocationNamesforInventory={this.getlocationNamesforInventory}
                                      handlePermissionedRole={
                                        this.handlePermissionedRole
                                      }
                                      getCategoryTypes={this.getCategoryTypes}
                                      getCustomData={this.getCustomData}
                                      getProductName={this.getProductName}
                                      getUniProductName={this.getUniProductName}
                                      getLocationData={this.getLocationData}
                                      type={this.props.type}
                                      validate={
                                        (this.props.type == "DashInventory" &&
                                          item.name == "productName") ||
                                          item.required
                                          ? [required]
                                          : null
                                      }
                                    />
                                    {item.name == "categoryId" ||
                                      item.name == "description" ? (
                                      <div className="col-1 text-center ml-2 pl-2 pt-1">
                                        <FontAwesomeIcon
                                          icon="plus"
                                          size="lg"
                                          className="categoryAddIcon"
                                          data-toggle="tool-tip"
                                          title="Add"
                                          style={{
                                            backgroundColor:
                                              config.templateColor,
                                          }}
                                          onClick={this.openCategoryModal}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                ) : item.type == "profile" ? (
                                  <div>
                                    <Field
                                      key={i}
                                      onRef={(ref) =>
                                        (this.profileUploadRef = ref)
                                      }
                                      name={item.name ? item.name : null}
                                      component={RenderFileInputField}
                                      label="Upload photo"
                                      type="profile"
                                      id={"photo"}
                                      acceptType="image/*"
                                      url={apiCalls.LocationImagePath}
                                      getFileName={this.getFileName}
                                    />
                                    <div
                                      className="col-md-2"
                                      style={{
                                        padding: 20,
                                      }}
                                    >
                                      <div
                                        style={{
                                          justifyContent: "center",
                                        }}
                                      >
                                        {this.state.locationImage ? (
                                          <img
                                            src={`${config.imgUrl}location/${this.state.locationImage
                                              }`}
                                            className="detailsImgStyle"
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : item.type == "date" ? (
                                  <div key={i} className="form__form-group">
                                    <div className="form__form-group-field">
                                      <Field
                                        key={i}
                                        name={item.name ? item.name : null}
                                        placeholder={
                                          item.placeholder
                                            ? item.placeholder
                                            : null
                                        }
                                        id={item.id ? item.id : null}
                                        component={DatePicker}
                                        minDate={
                                          this.state.minDate
                                            ? this.state.minDate
                                            : null
                                        }
                                        maxDate={
                                          this.state.maxDate
                                            ? this.state.maxDate
                                            : null
                                        }
                                        screen={this.props.type}
                                      />
                                      <div className="iconstyle form__form-group-icon">
                                        <CalendarBlankIcon />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        color: "#dc3545",
                                      }}
                                    >
                                      {item.message ? item.message : null}
                                    </div>
                                  </div>
                                ) : item.type == "radio" ? (
                                  <div className="mb-0">
                                    <div className="row">
                                      {item.options.map((option, ind) => {
                                        return (
                                          <div
                                            key={ind}
                                            className="form__form-group col-sm-6 mb-0"
                                          >
                                            <div className="form__form-group-field">
                                              <Field
                                                key={this.props.type + i + ind}
                                                name={
                                                  item.name ? item.name : null
                                                }
                                                component={RadioButton}
                                                label={option.label}
                                                radioValue={option.value}
                                                disabled={false}
                                                getCustomLocationList={
                                                  this.props.type ==
                                                    "Storage Unit"
                                                    ? this
                                                      .handleCartSublocations
                                                    : null
                                                }
                                                onChange={(e) => this.handleCategoryNames(e)}
                                                // getCustomCategoryNames={
                                                //   (e) => this.handleCategoryNames(e)
                                                // }
                                                defaultChecked={
                                                  option.defaultChecked
                                                    ? option.defaultChecked
                                                    : null
                                                }
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div
                                      style={{
                                        color: "#dc3545",
                                      }}
                                    >
                                      {item.message ? item.message : null}
                                    </div>
                                  </div>
                                ) : item.type == "autoComplete" ? (
                                  <div className="row">
                                    <div
                                      className={
                                        item.name == "categoryId"
                                          ? "col-11"
                                          : "col-12"
                                      }
                                    >
                                      <Field
                                        key={i}
                                        name={item.name ? item.name : null}
                                        component={AutoComplete}
                                        type={item.type}
                                        filterField={item.filterField}
                                        filterValue={item.filterValue}
                                        filterType={item.isNotEq}
                                        multiple={item.isMultiple}
                                        placeholder={
                                          item.placeholder
                                            ? item.placeholder
                                            : ""
                                        }
                                        searchApi={item.searchApi}
                                        searchField={item.searchField}
                                        handleAutoCompleteData={
                                          this.handleAutoCompleteData
                                        }
                                        screen={this.props.type}
                                        allow={this.state.allowDuplicates}
                                        filterFieldType={
                                          item.filterFieldType
                                            ? item.filterFieldType
                                            : null
                                        }
                                        disabled={
                                          this.props.type ==
                                            "Required Inventory" &&
                                            this.props.formType == "edit"
                                            ? true
                                            : false
                                        }
                                        formType={this.props.formType}
                                        selectedCategory={
                                          this.state.selectedCategory
                                        }
                                      />
                                    </div>
                                  </div>

                                ) : item.type == "textarea" ? (
                                  <Field
                                    key={i}
                                    name={item.name ? item.name : null}
                                    component={DefaultTextArea}
                                    type={item.type ? item.type : null}
                                    placeholder={
                                      item.placeholder ? item.placeholder : null
                                    }
                                    validate={
                                      !this.state.isTaskSet &&
                                        item.name == "otherTask"
                                        ? [required]
                                        : null
                                    }
                                  />
                                ) : item.type == "time" ? (
                                  <div>
                                    <Field
                                      key={i}
                                      name={item.name ? item.name : null}
                                      component={TimePicker}
                                      id={item.id ? item.id : null}
                                      timeMode={false}
                                      placeholder={
                                        item.placeholder
                                          ? item.placeholder
                                          : null
                                      }
                                      validate={
                                        !this.state.isTaskSet &&
                                          item.name == "screenName"
                                          ? [required]
                                          : null
                                      }
                                    />
                                    {validation && item.name == "endTime" ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: 10,
                                        }}
                                      >
                                        {validation}
                                      </div>
                                    ) : null}
                                  </div>
                                ) : item.type == "color" ? (
                                  <Field
                                    key={i}
                                    name={item.name ? item.name : null}
                                    component={renderColorPicker}
                                    color={
                                      this.props.formType == "edit"
                                        ? this.state.eventColor
                                        : ""
                                    }
                                    id={"color"}
                                  />
                                ) : item.type == "buttonGroup" ? (
                                  item.options && item.options.length > 0 ? (
                                    <ButtonToolbar className="mt-0">
                                      <ButtonGroup size="sm">
                                        {item.options.map((button, index) => {
                                          return (
                                            <Button
                                              style={{
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                              }}
                                              key={index}
                                              color="primary"
                                              outline
                                              size="sm"
                                              active={
                                                this.state[
                                                `is${button.label}ReOrder`
                                                ]
                                              }
                                              onClick={() =>
                                                this.handleReOrdering(
                                                  button.value
                                                )
                                              }
                                            >
                                              {button.label}
                                            </Button>
                                          );
                                        })}
                                      </ButtonGroup>
                                    </ButtonToolbar>
                                  ) : null
                                ) : (
                                  <div className="form__form-group-field">
                                    <Field
                                      key={i}
                                      name={item.name ? item.name : null}

                                      component={DefaultInput}
                                      mandatory={item.mandatory}
                                      isDisable={
                                        this.state.permissionedRole ==
                                          "Super Admin" && item.name == "email"
                                          ? true
                                          : (this.props.formType &&
                                            this.props.formType == "edit") ||
                                            (this.props.type ==
                                              "Storage Unit Items" ||
                                              (this.props.type ==
                                                "Required Inventory" &&
                                                (item.name == "description" ||
                                                  item.name == "categoryName" ||
                                                  item.name == "dosage" ||
                                                  item.name ==
                                                  "inventoryType")) ||
                                              (this.props.type == "Order" &&
                                                item.name == "productName") ||
                                              (this.props.type == "Order" &&
                                                item.name == "description") ||
                                              (this.props.type == "Order" &&
                                                item.name == "volume") ||
                                              ((this.props.type ==
                                                "Storage Unit Items" ||
                                                this.props.type ==
                                                "DashInventory") &&
                                                item.name == "description") ||
                                              (this.props.type ==
                                                "Recall Items" &&
                                                item.name == "productName") ||
                                              (this.props.type ==
                                                "DashInventory" &&
                                                (item.name == "locationId" ||
                                                  item.name == "clinicId"))) ||
                                            (this.props.tabType ==
                                              "Orders List" &&
                                              (item.name == "productName" ||
                                                item.name ==
                                                "distributorEmail" ||
                                                item.name == "required" ||
                                                item.name == "orderedBy"))
                                            ? item.disable
                                            : null
                                      }
                                      type={item.type ? item.type : "text"}
                                      defaultValue={"hi"}
                                      id={item.id ? item.id : null}
                                      placeholder={
                                        item.placeholder
                                          ? item.placeholder
                                          : null
                                      }
                                      validate={
                                        (this.props.type ==
                                          "Product Directory" &&
                                          item.required) ||
                                          (this.props.type == "Categories" &&
                                            item.required)
                                          ? [required]
                                          : null
                                      }
                                      maxLength={
                                        item.name == "phone" ? 15 : null
                                      }
                                      normalize={
                                        item.name == "invoiceNumber"
                                          ? normalizenumber
                                          : item.name == "phone"
                                            ? normalizePhone
                                            : this.props.type == "Facilities" &&
                                              item.name == "name"
                                              ? normalizename
                                              : this.props.type ==
                                                "Product Directory" &&
                                                item.name == "productName"
                                                ? normalizename
                                                : this.props.type ==
                                                  "Facility Staff" &&
                                                  item.name == "firstName"
                                                  ? normalizename
                                                  : this.props.type ==
                                                    "Facility Staff" &&
                                                    item.name == "lastName"
                                                    ? normalizename
                                                    : item.name == "city" ||
                                                      item.name == "state" ||
                                                      (this.props.type ==
                                                        "Facility Staff" &&
                                                        item.name == "lastName")
                                                      ? normalizestate
                                                      : item.name == "required"
                                                        ? normalizenumber
                                                        : this.props.tabType ==
                                                          "Placed Orders" &&
                                                          item.name == "required1"
                                                          ? lessThanEqual("required")
                                                          : this.props.type == "Order" &&
                                                            item.name == "required"
                                                            ? normalizenumber
                                                            : null
                                      }
                                      screen={this.props.type}
                                    />
                                    {this.props.type !== "Order" &&
                                      this.props.type !== 'Required Inventory' &&
                                      (item.name === "description" || item.name === "alternateDescription") &&
                                      (this.props.type === "Storage Unit Items" ||
                                        this.props.type === "DashInventory") ? (
                                      <div className="col-1 text-center pl-2 pt-1">
                                        <FontAwesomeIcon
                                          icon="plus"
                                          size="lg"
                                          className="categoryAddIcon"
                                          data-toggle="tooltip" // Corrected typo in "tool-tip" to "tooltip"
                                          title="Add"
                                          style={{
                                            backgroundColor: config.templateColor,
                                          }}
                                          onClick={this.customDescription}
                                        />
                                      </div>
                                    ) : null}


                                    {item.name == "volume" && this.props.type !== "Order"
                                      ? (
                                        <div className="col-1 text-center pl-2 pt-1">
                                          <FontAwesomeIcon
                                            icon="plus"
                                            size="lg"
                                            className="categoryAddIcon"
                                            data-toggle="tool-tip"
                                            title="Add"
                                            style={{
                                              backgroundColor:
                                                config.templateColor,
                                            }}
                                            onClick={this.customVolume}
                                          />
                                        </div>
                                      ) : null}
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                      })
                      : null}
                    {/* {this.props.locationsLength === 0 ? */}
                    {
                      this.state.selectedInventoryType !== "recommended" || this.props.type === 'Cart Locations' && (this.state.event !== 'operatingRoom' && this.state.storagetype !== 'operatingRoom') ? (
                        <div className="col-6">
                          <div className="form__form-group">
                            <label className="form__form-group-label">Options</label>
                            <span className="form__form-group-label">
                              <span className="mandatoryStyle">&nbsp;*</span>
                            </span>&nbsp;
                            <span style={{ cursor: 'pointer' }}><FontAwesomeIcon className="template_icon" icon={faQuestionCircle} onClick={this.recommendedInventory123} style={{ color: "#3475aa", marginLeft: "auto" }} /></span>
                            <div className="form__form-group-field">
                              <Field
                                name={"recommendedInventoryName"}
                                component={Select}
                                options={this.state.recommendedInventory}
                                placeholder={"Select Option"}
                                formatOptionLabel={true}
                                // onFocus={this.recommendedInventory123}
                                onChange={(e) => this.setState({
                                  recommenedInventoryId: e._id,
                                  recommendedInventoryName: e.name,
                                  isSelectedData: e,
                                  selectedOptions: true,
                                  isCheckboxSelected: false,
                                })}
                                // onClick={this.recommendedInventory123}
                                handlePopupModal={this.handlePopupModal}
                              />

                            </div>
                          </div>
                        </div>
                      ) : null
                    }
                    {/* : null} */}

                    {(this.props.type === 'Cart Locations' && this.state.event !== 'operatingRoom' && this.state.storagetype !== 'operatingRoom') && (this.state.isSelectedData || (this.props.formType === "edit" && (this.state.rowData && this.state.rowData.recommendedInventoryName))) ?

                      <div className="form__form-group-field">
                        <input
                          type="checkbox"
                          onChange={this.handleCheckboxChange}
                          checked={(this.state.isCheckboxSelected)}
                          style={{
                            marginLeft: '14px',
                            marginTop: '2px',
                            marginRight: '8px',
                            height: '30px',
                            width: '30px'
                          }}
                        />
                        <div style={{ paddingTop: '4px' }}>
                          <label>
                            {configMessages.checkMessage}
                          </label>
                        </div>
                      </div>
                      : null}


                    {this.props.type == "Product Directory" &&
                      this.state.isAutoReOrder ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Auto Re-order at X minimum
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"autoMin"}
                              component={DefaultInput}
                              placeholder={"Auto Re-order at X minimum"}
                              type="number"
                              id="number"
                              normalize={lessThan("autoMax")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Product Directory" &&
                      this.state.isAutoReOrder ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Auto Re-order to X maximum
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"autoMax"}
                              component={DefaultInput}
                              placeholder={"Auto Re-order to X maximum"}
                              type="number"
                              id="number"
                              normalize={greaterThan("autoMin")}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Product Directory" &&
                      this.state.isManualReOrder ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Manually Reorder to Maximum
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"manualMax"}
                              component={DefaultInput}
                              placeholder={"Enter"}
                              type="number"
                              id="number"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Product Directory" &&
                      this.state.showCategoryType ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Inventory Type
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"inventoryType"}
                              component={Select}
                              options={this.props.inventoryTypes}
                              placeholder={"Select"}
                              getCategoryTypes={this.getCategoryTypes}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Product Directory" &&
                      this.state.showCategoryType ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"dosage"}
                              component={DefaultInput}
                              placeholder={"Volume"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Product Directory" &&
                      this.state.notMedicine ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Battery Exists
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"batteryExists"}
                              component={RadioButton}
                              label={"Yes"}
                              radioValue={true}
                            />
                            <Field
                              name={"batteryExists"}
                              component={RadioButton}
                              label={"No"}
                              radioValue={false}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isTicketType && this.props.type == "Help" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Subject
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"otherSubject"}
                              component={DefaultInput}
                              placeholder={"Enter Subject"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Product
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"productName"}
                              component={AutoComplete}
                              id={"products"}
                              apiUrl="products"
                              searchField="title1"
                              placeholder="Product Name"
                              required={true}
                              searchApi={"products/productList"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              validate={[required]}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" &&
                      this.state.uniProductName ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Description
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"description"}
                              component={AutoComplete}
                              id={"description"}
                              placeholder="Description"
                              searchApi={apiCalls.Descriptions}
                              productName={this.state.uniProductName}
                              screen={this.props.type}
                              searchField={"description"}
                              apiUrl={"products"}
                              call={"POST"}
                              noDescription={this.noDescription}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {(this.state.isMedicine || this.state.isNonMedicine) &&
                      !this.state.IsBattery &&
                      this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Volume
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"volume"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                              isDisable={true}
                            />
                            <FontAwesomeIcon
                              icon="plus"
                              size="lg"
                              className="categoryAddIcon"
                              data-toggle="tool-tip"
                              title="Add"
                              style={{
                                backgroundColor: config.templateColor,
                                marginTop: 5,
                              }}
                              onClick={this.customVolumeInventory}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" &&
                      this.state.isCustomVolumeDes ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Volume
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"customVolumeDes"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.isCustom &&
                      (this.props.type == "DashInventory" ||
                        this.props.type == "Order") ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Description
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"customDescription"}
                              component={DefaultInput}
                              placeholder={"Custom Description"}
                              validate={required}

                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            LotId
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"lotId"}
                              normalize={normalizenumber}
                              component={DefaultInput}
                              id={"lotId"}
                              placeholder="Lot Id"
                              validate={
                                this.state.lotAndExpirationRequired
                                  ? required
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Expiration Date
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"expirationDate"}
                              component={DatePicker}
                              placeholder={"Expiration Date"}
                              validate={
                                this.state.lotAndExpirationRequired
                                  ? required
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Facility
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"clinicId"}
                              component={DefaultInput}
                              validate={required1}
                              clearLocationValues={this.clearLocationValues}
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Storage Unit
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"cart"}
                              component={DefaultInput}
                              isDisable={true}
                              validate={required1}

                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Cart SubLocations
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"cartSubLocation"}
                              component={Select}
                              id={"sub"}
                              options={this.state.subLocations}
                              placeholder="Cart SubLocation"
                              validate={required1}
                              getRequiredQuantityBasedOnCartSubLoc={
                                this.getRequiredQuantityBasedOnCartSubLoc
                              }
                              type={this.props.type}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Current Inventory
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"count2"}
                              component={DefaultInput}
                              placeholder="Current Inventory"
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Inventory Being Added
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"addedInventory"}
                              component={DefaultInput}
                              placeholder="Inventory"
                              normalize={normalizenumber}
                              // type="number"
                              getNumberBeingAdded={this.getNumberBeingAdded}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(((this.state.isMedicine || this.state.isNonMedicine) &&
                      !this.state.battery) ||
                      this.state.showRequired) &&
                      this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Required Inventory
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"limit"}
                              component={DefaultInput}
                              placeholder={"Required Inventory"}
                              isDisable={true}
                              mandatory={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" &&
                      this.state.isBattery ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Frequency
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"checkType"}
                              component={Select}
                              validate={required}
                              id={"clinic"}
                              options={checkTypes}
                              onCheckType={this.onCheckType}
                              placeholder="Check Type"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" &&
                      this.state.isWeekly ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Battery Check Day
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"batteryCheckDay"}
                              component={Select}
                              id={"clinic"}
                              options={days}
                              validate={required}
                              placeholder="Check Type"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* {
                      this.props.type == "DashInventory" ? (
                        <div className="col-6">
                          <div className="form__form-group">
                            <label className="form__form-group-label">
                              Alternate  Product
                            </label>
                            <div className="form__form-group-field">
                              <Field
                                name={"isAlternateProduct"}
                                component={Checkbox}
                                // validate={required}
                                onChange={(e) => this.isShowAlternateProductDetails(e)}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                    {this.props.type == "DashInventory" && this.state.isShowAlternateProduct ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Alternate  Product Name
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"alternateProductName"}
                              component={AutoComplete}
                              id={"alternateproducts"}
                              apiUrl="products"
                              searchField="title1"
                              required={true}
                              placeholder="Alternate Product Name"
                              searchApi={"products/productList"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            // validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "DashInventory" &&
                      this.state.unialternateProductName && this.state.isShowAlternateProduct ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Alternate Description
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"alternateDescription"}
                              component={AutoComplete}
                              id={"alternateDescription"}
                              placeholder="Description"
                              searchApi={apiCalls.Descriptions}
                              alternateProductName={this.state.unialternateProductName}
                              screen={this.props.type}
                              searchField={"description"}
                              apiUrl={"products"}
                              call={"POST"}
                              noDescription={this.noalternateDescription}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            // validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(this.state.isalternateMedicine || this.state.isalternateNonMedicine) &&
                      !this.state.IsalternateBattery && this.state.isShowAlternateProduct &&
                      this.props.type == "DashInventory" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Volume
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"alternateVolume"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                              isDisable={true}
                            />
                            <FontAwesomeIcon
                              icon="plus"
                              size="lg"
                              className="categoryAddIcon"
                              data-toggle="tool-tip"
                              title="Add"
                              style={{
                                backgroundColor: config.templateColor,
                                marginTop: 5,
                              }}
                              onClick={this.customalternateVolumeInventory}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "DashInventory" &&
                      this.state.isCustomalternateVolumeDes ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Volume
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"CustomalternateVolumeDes"}
                              component={DefaultInput}
                              id={"isCustomalternateVolumeDes"}
                              placeholder="Volume"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Products
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"productName"}
                              component={AutoComplete}
                              id={"products"}
                              apiUrl="products"
                              searchField="title1"
                              placeholder="Search Product Name"
                              searchApi={"products/productList"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}


                    {this.props.type == "Storage Unit Items" &&
                      this.state.uniProductName &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Description
                          </label>
                          <span className="form__form-group-label">
                            <span className="mandatoryStyle">&nbsp;*</span>
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name={"description"}
                              component={AutoComplete}
                              id={"description"}
                              placeholder="Search Description"
                              searchApi={apiCalls.Descriptions}
                              productName={this.state.uniProductName}
                              noDescription={this.noDescription}
                              screen={this.props.type}
                              searchField={"description"}
                              apiUrl={"products"}
                              call={"POST"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.props.id == "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Product Name
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"title1"}
                              component={DefaultInput}
                              id={"products"}
                              isDisable={true}
                              placeholder="Product Name"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.props.id == "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Desription
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"description"}
                              component={DefaultInput}
                              id={"products"}
                              isDisable={true}
                              placeholder="Description"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      ((this.state.isMedicine || this.state.isNonMedicine) &&
                        !this.state.isBattery) ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"volume"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                              isDisable={true}
                            />
                            {this.props.id != "Checks" ? (
                              <FontAwesomeIcon
                                icon="plus"
                                size="lg"
                                className="categoryAddIcon"
                                data-toggle="tool-tip"
                                title="Add"
                                style={{
                                  backgroundColor: config.templateColor,
                                  marginTop: 5,
                                }}
                                onClick={this.customVolumeInventory}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.isCustom &&
                      this.props.type == "Storage Unit Items" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Description
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"customDescription"}
                              component={DefaultInput}
                              placeholder={"Custom Description"}

                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.isCustomVolume && this.props.type == "Order" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"customVolume"}
                              component={DefaultInput}
                              placeholder={"Custom Volume"}
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.state.isCustomVolumeDes ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Custom Volume
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"customVolumeDes"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            LotId
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"lotId"}
                              validate={
                                this.state.lotAndExpirationRequired
                                  ? required
                                  : null
                              }
                              component={DefaultInput}
                              id={"lotId"}
                              placeholder="Lot Id"
                              isDisable={
                                this.props.formType == "edit" &&
                                  this.state.rowData &&
                                  this.state.rowData.lotId
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Expiration Date
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"expirationDate"}
                              component={DatePicker}
                              placeholder={"mm-dd-yyyy"}
                              onBlur={this.expDate}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" ? (
                      // &&
                      // this.props.id != "Checks"
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Facility
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"clinicId"}
                              component={AutoComplete}
                              id={"clinic"}
                              searchApi={`clinics`}
                              screen={this.props.type}
                              searchField={"name"}
                              call={"GET"}
                              placeholder="Search Facility"
                              validate={required}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* {this.props.type == "Storage Unit Items" &&
                    this.props.id == "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Facility
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"clinicId"}
                              component={DefaultInput}
                              isDisable={true}
                              id={"clinic"}
                              type="Inventory"
                              placeholder="Facility"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null} */}

                    {/* {this.props.type == "Storage Unit Items" &&
                    this.props.formType == "add" &&
                    this.state.facilityName ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Storage Unit
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"locationId"}
                              component={AutoComplete}
                              id={"location"}
                              searchApi={`locations`}
                              screen={this.props.type}
                              searchField={"cart"}
                              call={"GET"}
                              facilityName={this.state.facilityName}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              placeholder="Search Storage Unit"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null} */}

                    {this.props.type == "Storage Unit Items" || this.props.type == "storage unit new" &&
                      // this.props.formType == "edit" &&
                      this.state.clinicName ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Storage Unit
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"locationId"}
                              component={
                                // this.props.id == "Checks"
                                //   ? DefaultInput
                                //   :
                                AutoComplete
                              }
                              id={"Storage Unit"}
                              type="Inventory"
                              placeholder="Search Storage Unit"
                              validate={required}
                              searchApi={apiCalls.CartLocations}
                              clinicName={this.state.clinicName}
                              screen={this.props.type}
                              searchField={"cart"}
                              apiUrl={"locations"}
                              call={"GET"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              isDisable={
                                this.props.id == "Checks" ? true : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.props.formType == "add" &&
                      this.state.cartName ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Cart SubLocations
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"cartSubLocation"}
                              component={AutoComplete}
                              id={"subLocations"}
                              searchApi={`locations`}
                              apiUrl={"locations"}
                              screen={this.props.type}
                              searchField={"cartParts"}
                              call={"GET"}
                              cartName={this.state.cartName}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              placeholder="Search Cart SubLocation"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.props.formType == "edit" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Cart SubLocations
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"cartSubLocation"}
                              component={AutoComplete}
                              id={"Cart Sub Locations"}
                              placeholder="Search Cart Sub Location"
                              validate={required}
                              searchApi={apiCalls.CartLocations}
                              cartName={this.state.cartName}
                              screen={this.props.type}
                              searchField={"cartParts"}
                              apiUrl={"locations"}
                              call={"GET"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Current Inventory
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"count2"}
                              component={DefaultInput}
                              placeholder="Current Inventory"
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(((this.state.isMedicine || this.state.isNonMedicine) &&
                      !this.state.isBattery) ||
                      this.state.showRequired) &&
                      this.props.type == "Storage Unit Items" &&
                      this.props.id == "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Required Inventory
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"limit"}
                              component={DefaultInput}
                              placeholder={"Required Inventory"}
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.props.formType != "edit" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Inventory Being Added
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"addedInventory"}
                              component={DefaultInput}
                              placeholder="Inventory"
                              normalize={greaterThan0}
                              getNumberBeingAdded={this.getNumberBeingAdded}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {(((this.state.isMedicine || this.state.isNonMedicine) &&
                      !this.state.isBattery) ||
                      this.state.showRequired) &&
                      this.props.type == "Storage Unit Items" &&
                      this.props.id != "Checks" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Required Inventory
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"limit"}
                              component={DefaultInput}
                              placeholder={"Required Inventory"}
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Storage Unit Items" &&
                      this.state.isBattery ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Frequency
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"checkType"}
                              component={Select}
                              id={"clinic"}
                              options={checkTypes}
                              onCheckType={this.onCheckType}
                              placeholder="Check Type"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" &&
                      this.state.isWeekly ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Battery Check Day
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"batteryCheckDay"}
                              component={Select}
                              id={"clinic"}
                              options={days}
                              placeholder="Check Type"
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.type == "Facility Staff" &&
                      this.state.permissionedRole !== "Super Admin" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Clinics
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"clinic"}
                              component={MultiSelectDropDown}
                              id={"clinic"}
                              validate={[requiredField]}
                              options={
                                this.props.clinics &&
                                  this.props.clinics.multiSelectclinics
                                  ? this.props.clinics.multiSelectclinics
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" && this.props.formType == "edit" && this.state.rowData && this.state.rowData.isAlternateProduct ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Alternate  Product
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"isAlternateProduct"}
                              component={Checkbox}
                              validate={required}
                              disabled={true}
                              onChange={(e) => this.isShowAlternateProductDetails(e)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" && this.props.formType == "edit" && this.state.rowData && this.state.rowData.isAlternateProduct ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Alternate  Product
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"alternateProductName"}
                              component={AutoComplete}
                              id={"alternateproducts"}
                              disabled={true}
                              apiUrl="products"
                              searchField="title1"
                              placeholder="Alternate Product Name"
                              searchApi={"products/productList"}
                              handleAutoCompleteData={
                                this.handleAutoCompleteData
                              }
                              validate={required}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Storage Unit Items" && this.props.formType == "edit" && this.state.rowData && this.state.rowData.isAlternateProduct ? (

                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Alternate  Description
                          </label>
                          <div className="form__form-group-field">
                            <Field
                              name={"alternateDescription"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.props.formType === "edit" && this.state.rowData && this.state.rowData.isAlternateProduct &&
                      this.props.type == "Storage Unit Items" ? (
                      <div className="col-6">
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Alternate Volume
                          </label>Wishlist
                          <div className="form__form-group-field">
                            <Field
                              name={"alternateVolume"}
                              component={DefaultInput}
                              id={"volume"}
                              placeholder="Volume"
                              isDisable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.type == "Wishlist" &&
                      <div style={{ width: "100%" }}>
                        <div className="d-flex">
                          <div className="col-6">
                            <div className="form__form-group">
                              <label className="form__form-group-label">
                                Enter Email
                              </label>
                              <div className="form__form-group-field">
                                <Field
                                  name={"distributorEmail"}
                                  component={AutoComplete}
                                  searchApi={`distributors`}
                                  apiUrl={"distributors"}
                                  // screen={this.props.type}
                                  searchField={"email"}
                                  // call={"GET"}
                                  placeholder="Distributor email"
                                  validate={required}
                                  showDistributorData={(e) => this.setState({ showDistributorData: e })}

                                />
                              </div>
                            </div>
                          </div>

                          {/* {this.state.showDistributorData ? */}
                          <div className="mt-1">
                            <Button color="primary"
                              size="sm"
                              className="p-1 ml-auto mr-4 mt-4 mb-0"
                              onClick={() => this.isopenFormModal()}
                            >
                              <FontAwesomeIcon
                                icon='plus'
                                className='pl-1' size='lg'
                                data-toggle="tool-tip" title={("Add")}
                                onClick={() => this.isopenFormModal()}
                              />
                            </Button>
                          </div>
                          {/* :null}  */}


                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="align_table">
                            <Table>
                              <thead className="align_modal_header">
                                <tr>
                                  <th>S.No</th>
                                  <th>Product Name</th>
                                  <th>Description</th>
                                  <th>Dosage</th>
                                  <th>Need To Order</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody style={{ maxHeight: 80 }}>
                                {this.state.productTablefields && this.state.productTablefields.length > 0 ?
                                  this.state.productTablefields.map((item, i) => (
                                    <tr key={i}>
                                      <td>{item.productName ? i + 1 : ""}</td>
                                      <td>{item.productName}</td>
                                      <td>{item.description ? item.description : ""}</td>
                                      <td>{item.volume ? item.volume : '-'}</td>
                                      <td>
                                        {
                                          this.props.screnType === 'Missing Items' ? (
                                            <input
                                              style={{ width: '7rem' }}
                                              type="text"
                                              value={item.required ? item.required : item.needed}
                                              onChange={(e) => {
                                                const newValue = e.target.value;
                                                // Updating state properly using setState
                                                this.setState(prevState => {
                                                  const updatedFields = [...prevState.productTablefields];
                                                  updatedFields[i].required = newValue;
                                                  return { productTablefields: updatedFields };
                                                });
                                              }}
                                            />
                                          ) : (
                                            item.required
                                          )
                                        }
                                      </td>
                                      <td>
                                        <FontAwesomeIcon
                                          className='genderIconAlignment'
                                          color='white'
                                          icon='trash-alt'
                                          title="Delete"
                                          style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                          onClick={() => this.seleteConfirmationFun(item)}
                                        />
                                      </td>
                                    </tr>
                                  )) : null} {/* Replace empty string with null to avoid warning */}
                              </tbody>
                            </Table>

                          </div>
                        </div>
                      </div>
                    }
                    {this.props.type == "Required Inventories" || this.props.type === "Recommended Inventories" ?
                      <div style={{ width: "100%" }}>
                        <div>
                          <div className='row ml-2'>
                            <div className='serachAlignment' >
                              <b style={{ marginTop: "0.4rem", marginRight: "0.4rem" }}>Category Type</b>
                              <div className="p-inputgroup">
                                {/* <Dropdown
                                  style={{ minWidth: "100px", height: "100%", lineHeight: 1.25, textAlign: 'center' }} appendTo={document.body}
                                  name={"categoryType"}
                                  value={this.state.categoryType}
                                  options={[{ label: " ", value: null }, { label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]}
                                  onChange={(e) => this.onFilterChange(e)}
                                /> */}
                                <Dropdown
                                  style={{ minWidth: "140px", height: "100%", lineHeight: 1.25, textAlign: 'center' }}
                                  appendTo={document.body}
                                  name={"categoryType"}
                                  value={this.state.categoryType}
                                  // placeholder="Medicine"
                                  options={[{ label: " ", value: null }, { label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]}
                                  onChange={(e) => this.onFilterChange(e, "dropDown")}
                                />

                              </div>
                              <div>
                                {/* <FontAwesomeIcon
                                  icon={faTimes}
                                  size="lg"
                                  className="categoryAddIcon mt-1"
                                  data-toggle="tool-tip"
                                  title="Click to remove Filter"
                                  style={{
                                    color: "red"
                                  }}
                                  onClick={() => this.onFilterChange()}
                                /> */}
                                {/* <Button color="danger"
                                  size="sm"
                                  style={{ backgroundColor: "#ef4444", color: "white", marginTop: "0.2rem", marginLeft: "0.3rem", border: "none", outline: "none", padding: "0.4rem 1rem", borderRadius: "5px", fontWeight: "bold" }}
                                  className="px-3"
                                  onClick={() => this.onFilterChange()}>
                                  Clear
                                </Button> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{ width: "100%" }}>
                          <div className="col-12 modalTbody pl-0 pr-0" >
                            <Table style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Product Name</th>
                                  <th>Description</th>
                                  <th>Dosage</th>
                                  <th>Required</th>
                                  <th>Inventory Type</th>
                                  <th>Location Type</th>
                                  <th>Category Name</th>
                                  <th>Category Type</th>
                                  <th style={{ textAlign: "center" }}>Actions</th>
                                </tr>
                              </thead>
                              <tbody style={{ maxHeight: 80 }}>
                                {this.state.productTablefields && this.state.productTablefields.length > 0 ?
                                  this.state.productTablefields.map((item, i) => {
                                    return <tr>
                                      <td>{item.productName ? i + 1 : ''}</td>
                                      <td className="word_align">{item.productName || '-'}</td>
                                      <td className="word_align">{item.description || '-'}</td>
                                      <td className="text-center">{item.dosage || '-'}</td>
                                      <td className="text-center">{item.required || '-'}</td>
                                      <td>{item.inventoryType || '-'}</td>
                                      <td className="textCapitalize">{item.locationType || '-'}</td>
                                      <td>{item.categoryName || '-'}</td>
                                      <td>{item.categoryType || '-'}</td>
                                      {this.props.formType == "add" ?
                                        <td>
                                          {item.productName ?
                                            <div>
                                              <FontAwesomeIcon
                                                className='genderIconAlignment'
                                                color='white'
                                                icon='trash-alt'
                                                data-toggle="tool-tip"
                                                title="Delete"
                                                style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                                onClick={() => { this.seleteConfirmationFun(i, this.props.type) }} />
                                              {/* this.deleteproductAction(i, this.props.type) */}
                                              <FontAwesomeIcon
                                                className='genderIconAlignment'
                                                color='white'
                                                icon='edit'
                                                data-toggle="tool-tip"
                                                title="Edit"
                                                style={{ color: '#285a83', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                                onClick={() => this.openInventoryModal(item, "edit", i)} /></div>
                                            : ""}

                                        </td> :
                                        <td>
                                          <div className="d-flex justify-content-around">
                                            <FontAwesomeIcon
                                              className='genderIconAlignment'
                                              color='white'
                                              icon='trash-alt'
                                              data-toggle="tool-tip"
                                              title="Delete"
                                              style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                              onClick={() => this.seleteConfirmationFun(i, "edit", this.props.formType)} />
                                            {/* //  onClick={() => this.deleteproductAction(i, "edit", this.props.formType)} />  */}
                                            <FontAwesomeIcon
                                              className='genderIconAlignment'
                                              color='white'
                                              icon='edit'
                                              data-toggle="tool-tip"
                                              title="Edit"
                                              style={{ color: '#285a83', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                              onClick={() => this.openInventoryModal(item, "edit", i)} />
                                          </div>
                                        </td>}
                                    </tr>


                                  }) : ""}
                              </tbody>

                            </Table>
                          </div>
                          <div className="col-12 mt-2">
                            <Button color="primary"
                              size="sm"
                              className="p-1"
                              onClick={() => { this.openInventoryModal(this.state.productTablefields, "add") }}>
                              <FontAwesomeIcon
                                icon='plus'
                                className='pl-1' size='lg'
                                data-toggle="tool-tip" title="Add Product"
                                onClick={() => { this.openInventoryModal(this.state.productTablefields, "add") }}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                      : null}

                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        <DeleteRowModal
          openDeleteModal={this.state.openDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          deleteSelectedRow={this.deleteSelectedRow}
          message={
            this.props.type === "Facilities"
              ? configMessages.FacilitiesDeleteMsg
              : configMessages.DeleteMsg
          }
          type={this.props.type}
        />


        {this.state.isopenFormModal ?
          <FormModalNew
            // formFields={this.props.formFields1}
            // allUsersData={this.props.allUsersData}
            // formType={this.props.formType}
            // openFormModal = {this.state.isopenFormModal}
            // closeFormModal={this.props.closeFormModal}
            // getDataFromServer={this.props.getDataFromServer}  
            // setIsOpenFormModal = {this.props.isOpenFormModal}
            // type = {this.props.screnType}
            // apiUrl={this.props.apiUrl1}  
            openFormModal={this.props.isOpenFormModal}
            productTablefields={this.props.productTablefields}
            formType={this.props.formType}
            displayViewOfForm={true}
          />
          : null
        }

        {/* Add Category Modal in Products */}
        {
          this.state.isOpenCategoryModal ? (
            <AddCategoryModal
              onRef={(ref) => (this.addCategoryModalRef = ref)}
              isOpenCategoryModal={this.state.isOpenCategoryModal}
              closeCategoryModal={this.closeCategoryModal}
              saveDataToServer={this.saveDataToServer}
              categoryDropDownValues={this.categoryDropDownValues}
              type={this.props.type}
            />
          ) : null
        }

        {
          this.props.type == "Storage Unit Items" ||
            this.props.type == "DashInventory" ? (
            <AddProductModal
              onRef={(ref) => (this.addProductModalRef = ref)}
              isProductsModalOpen={this.state.isProductsModalOpen}
              closeAddProductsModal={this.closeAddProductsModal}
              saveDataToServer={this.saveDataToServer}
              categoryDropDownValues={this.categoryDropDownValues}
              type={this.props.type}
              inventoryTypes={this.props.inventoryTypes}
            />
          ) : null
        }

        {/* Session Expiration Modal */}
        <SessionModal openSessionModal={this.state.openSessionModal} />
        {
          this.props.type == "Required Inventories" || this.props.type == "Recommended Inventories" ? (
            <AddRecommendedInventoryModal
              onRef={(ref) => (this.inventoryModalRef = ref)}
              handleinventoryTable={this.handleinventoryTable}
              selectedRowDataID={this.state.rowData}
              isOpenInventoryModal={this.state.openInventoryModal}
              closeInventoryModal={this.closeInventoryModal}
              clearFormFields={this.clearFormFields}
              type={this.state.inventorytype}
              formtype={this.props.type == "Required Inventories" ? "Required Inventory" : "Recommended Inventory"}
              productTablefields={this.state.productTablefields ? this.state.productTablefields : []}

            />
          ) : null
        }
        <RecommendedDetails RecommendedDetailsModal={this.state.isSelectRecommendOption} closeRecommendedModal={this.closeRecommendedModal} isSelectedData={this.state.isSelectedData} />
        {/* Email sent confirmation modal */}
        <MailSentConfirmationModal
          isEmailConfirmationModal={this.state.isEmailConfirmationModal}
          closeModal={this.closeMailConfirmationModal}
          mailSentMessage={this.state.mailSentMessage}
        />
        {/* <OverlayPanel ref={(el) => this.op = el}>
            // Content
        </OverlayPanel> */}
      </div >




    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormModal = reduxForm({
  form: "Common Form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
  // onSubmitFail: errors => ////console.log(errors),
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),

  { load: loadAccount } // bind account loading action creator
)(FormModal);

export default FormModal;
