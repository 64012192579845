import React from "react";

export default class DefaultInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHintMessage: "",
      showHint: false,
      ValidationError: false
    }
  }
  onChange = (event) => {
    if (event && event.target) {
      const { onChange, name } = this.props.input;
      if (this.props.type == "number") {
        if (event.target.value) {
          let num = event.target.value;
          if (name == "noofDrawers" && num > 10) {
            num = 10;
          } else if (num <= 0) {
            num = 0;
          }
          onChange(num);
          // this.props.getNumberBeingAdded(event.target.value)
        }
      } else {
        onChange(event.target.value);
      }
    }
  };
  removeHint = async (value) => {
    var passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]+$/;
    if (!passwordRegexp.test(value)) {
      await this.setState({
        ValidationError: true
      })
    } else {
      await this.setState({
        ValidationError: false
      })
    }
    await this.setState({
      showHint: false
    })
  }

  highlightHint = async () => {
    await this.setState({
      showHint: true
    })
  }

  render() {
    const {
      input,
      placeholder,
      type,
      isDisable,
      meta: { touched, error },
      validate,
      screen,
    } = this.props;
    let disable;
    if (screen == "Required Inventory") {
      let name = input.name;
      if (
        name == "description" ||
        name == "categoryType" ||
        name == "categoryName" ||
        name == "dosage" ||
        name == "inventoryType"
      ) {
        disable = true;
      }
    } else {
      disable = isDisable;
    }
    return (
      <div className="form__form-group-input-wrap">
        <div className="form__form-group-field">
          <input
            style={
              isDisable
                ? { background: "lightgray", border: "none" }
                : { background: "white" }
            }
            {...input}
            disabled={disable}
            placeholder={placeholder}
            type={type}
            onChange={(e) => this.onChange(e)}
            maxLength={this.props.maxLength ? this.props.maxLength : null}
            onBlur={(e) => this.removeHint(e.target.value)}
            onFocus={() => this.highlightHint()}
          />
        </div>
        {this.props.hintMesssage && this.state.showHint ?
          <span>{this.props.showHintMessage}</span>
          : null
        }
        {this.props.passwordValidation && this.state.ValidationError && !this.state.showHint ?
          <span className="form__form-group-error">{"Please Enter Valid Password."}</span>
          : null
        }



        {touched && error && (
          <span className="form__form-group-error">{error}</span>
        )}
      </div>
    );
  }
}
