import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Inventory from './components/Quantities'


const InventoryList = (props) => (

    <Container>
        <Inventory {...props} />
    </Container>
);

InventoryList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(InventoryList);