import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StaffTable from './components/StaffTable'

const StaffTableList = (props) => (

    <Container>
        <StaffTable {...props} />
    </Container>
);

StaffTableList.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(StaffTableList);

