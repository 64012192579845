import React from 'react';
import TableRender from '../../../CommonDataTable/TableRender';
import config from '../../../../../config/config';
import RolePermissions from '../../../Permissions/Permissions';
import fetchMethodRequest from '../../../../../config/service'
import apiCalls from '../../../../../config/apiCalls';

export default class TicketsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketSubjectCategoryTypes: []
        };
    }
    componentDidMount = async () => {
        return fetchMethodRequest('GET', apiCalls.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0]) {
                        let ticketSubjectCategoryTypes = this.getticketTypeDrodownValues(response.settings[0]);
                        this.setState({
                            ticketSubjectCategoryTypes: ticketSubjectCategoryTypes,
                        });
                    }
                }
            });
    }

    getticketTypeDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.ticketSubjectcategory) {
            let ticketSubjectCategoryTypes = settingInfo.ticketSubjectcategory;
            let modifiedticketSubjectCategoryTypes = [];
            for (let ticket of ticketSubjectCategoryTypes) {
                modifiedticketSubjectCategoryTypes.push({
                    label: ticket,
                    value: ticket
                })
            }
            return modifiedticketSubjectCategoryTypes;
        }
    }

    getTableFields = () => {
        let statusTypes = [
            { label: 'All', value: null },
            { label: 'New', value: 'New' },
            { label: 'Assigned', value: 'Assigned' },
            { label: 'InProgress', value: 'InProgress' },
            { label: 'Pending', value: 'Pending' },
            { label: 'Answered', value: 'Answered' },
            { label: 'Reopened', value: 'Reopened' },
            { label: 'Not an issue', value: 'Not an issue' },
            { label: 'Closed', value: 'Closed' }
        ];
        let categoryTypes = [
            { label: 'All', value: null },
            { label: 'Technical', value: 'Technical' },
            { label: 'Support', value: 'Support' },
            { label: 'Payments', value: 'Payments' },

        ];
        let data = [
            { textAlign: 'center', width: 85, field: 'ticketId', header: 'Ticket ID', filter: true, sortable: true, },
            { textAlign: 'center', width: 100, field: 'subject', header: 'Subject', filter: true, sortable: true, },
            { textAlign: 'left', width: 100, field: 'emailId', header: 'EmailID', filter: true, sortable: true, },
            { textAlign: 'center', width: 100, field: 'userName', header: 'UserName', filter: true, sortable: true, },
            { textAlign: 'center', width: 85, field: 'category', header: 'Category', filterElement: categoryTypes, filter: true, sortable: false },
            { textAlign: 'center', width: 80, field: 'created', header: 'Created On', filter: true, sortable: true },
            { textAlign: 'left', width: 95, field: 'message', header: 'Message', filter: true, sortable: true },
            { textAlign: 'center', width: 80, field: 'updated', header: 'Updated On', filter: true, sortable: true, placeholder: config.dateDayMonthFormat },
            { textAlign: 'center', width: 80, field: 'status', header: 'Status', filterElement: statusTypes, filter: true, sortable: false },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
        ];

        let screenPermissions = RolePermissions.screenPermissions('Help');
        if (screenPermissions && screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            { required: true, value: '', type: 'dropDown', name: 'category', label: 'Category', id: 'category', options: this.state.ticketSubjectCategoryTypes, placeholder: 'Select' },

            {
                required: true, value: '', type: 'autoComplete', name: 'subject', label: 'Subject', id: 'subject',
                placeholder: 'Search Subject', searchApi: apiCalls.TicketSubjects, searchField: 'subject'
            },
            { required: true, value: '', type: 'textarea', name: 'message', label: 'Message', id: 'message', placeholder: 'Enter Message' },
        ]);
    }

    render() {
        return (
            <div>
                <TableRender type='Help'
                    globalSearch='UserName/Email/Subject'
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}