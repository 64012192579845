import React from "react";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { Redirect } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import Icon from "mdi-react/ArrowLeftIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Config
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";

// Modals
import AddInventoryModal from "../../Modals/AddInventoryModal";
import OrderModal from "../../Modals/OrderModal";
import DeleteRowModal from "../../Modals/DeleteRowModal";
import OrderConfirmation from "../../Modals/OrderConfirmationModal";
import FormModal from "../../../Form/FormModal";

// Table
import TableRender from "../../CommonDataTable/TableRender";

// Toaster Message
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";

// Permissions
import RolePermissions from "../../Permissions/Permissions";

import PaginatorComponent from "../../CommonDataTable/PaginatorComponent";

// Loader
import Loader from "../../../App/Loader";
import configMessages from "../../../../config/configMessages";

export default class InventoryChecksModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: {},
            label: "",
            status: "",
            ShiftType: null,
            isOpenSuggestModal: false,
            suggestGroupInfo: [],
            selectedDate: null,
            type: null,
            openOrderModal: false,
            showInfo: true,
            openInventoryModal: false,
            delete: false,
            openDeleteModal: false,
            type: "Inventory Checks",
            isLoading: true,
            storageUnit: {},
            locationType: null,
            cartViewItems: [],
            duplicatecartViewItems: [],
            previousData: {},
            selectedOption: 'ENTIRE CART',
            headerInfo: [],
            totalRecordsLength: 0,

            first: 0,
            rows: 10,
            isWeb: true,
            filterCriteria: { limit: 20, page: 1, criteria: [] },
            cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }]
        };


    }

    componentDidMount = async () => {
        // console.log("location", window.location.pathname)
        // let path = window.location.pathname

        // console.log('path is', path)
        if (localStorage.getItem('StorageUnitItems')) {
            localStorage.removeItem('StorageUnitItems');
        }
        this.getClinicDropDownValues();

        let screenPermissions = RolePermissions.screenPermissions(
            "Inventory Check List"
        );
        if (screenPermissions && screenPermissions != "edit") {
            await this.setState({
                noAdd: true,
            });
        }
        if (this.props.match.params.locationId) {
            if (this.props.match.params) {
                if (
                    this.props.match.params.locationId &&
                    this.props.match.params.cart &&
                    this.props.match.params.clinic
                ) {
                    this.getDrawerDropdownValues(
                        this.props.match.params.locationId,
                        this.props.match.params.cart,
                        this.props.match.params.clinic,
                        this.props.match.params.excel,
                        this.props.match.params.area,
                        this.props.match.params.building,
                        this.props.match.params.room,
                        this.props.match.params.floor,
                        this.props.match.params.email,
                        this.props.match.params.company,
                        this.props.match.params.companyId
                    );
                }
            }
            this.setState({
                isLoading: false,
            });
        } else if (this.props.location) {
            if (this.props.location.pathname !== "/storageunititems") {
                this.setState({
                    isLoading: true,
                });
                let cartName = this.props.location.pathname.slice(
                    20,
                    this.props.location.pathname.length
                );
                return fetchMethodRequest("GET", `${apiCalls.CartLocations}`).then(
                    async (response) => {
                        if (response && response.locations) {
                            for (let value of response.locations) {
                                console.log('CartLocations:', response.locations);
                                if (value.cart == cartName) {
                                    this.getDrawerDropdownValues(
                                        value._id,
                                        value.cart,
                                        value.clinicName
                                    );
                                }
                            }
                            this.setState({
                                isLoading: false,
                            });
                        }
                    }
                );
            } else if (this.props.location.pathname == "/storageunititems") {
                await this.getData();
                this.setState({
                    isLoading: false,
                });
            }
        }
    };


    handleSelectChange = async (event) => {
        let array = []
        array.push(event.value)
        await this.setState({ selectedOption: event.value, headerInfo: array });
        let data = { first: 0, page: 1, rows: this.state.rows }
        await this.onPageChange(data)
    };

    getPaginator() {
        return (
            <PaginatorComponent
                totalRecords={this.state.totalRecordsLength}
                first={this.state.first}
                rows={this.state.rows}
                onPageChange={this.onPageChange}
                isWeb={true}
            />
        )
    }


    getClinicDropDownValues = async () => {
        let ls = JSON.parse(localStorage.loginCredentials);
        if (ls.clinics && ls.clinics.length > 0) {
            let modifiedClinics = [];
            ls.clinics.map((item, i) => {
                modifiedClinics.push({
                    label: item.name,
                    value: item.clinicId,
                });
            });
            await this.setState({
                clinics: modifiedClinics,
            });
        } else {
            return fetchMethodRequest("GET", apiCalls.Clinics).then(
                async (response) => {
                    if (response && response.clinics && response.clinics[0]) {
                        if (response.clinics[0].name) {
                            let clinics = response.clinics;
                            let modifiedClinicNames = [];
                            for (let clinic of clinics) {
                                modifiedClinicNames.push({
                                    label: clinic.name,
                                    value: clinic._id,
                                });
                            }

                            await this.setState({
                                clinics: modifiedClinicNames,
                            });
                        }
                    }
                }
            );
        }
    };

    getRowData = async (rowData) => {
        if (this.props.type == "Storage Unit") {
            this.props.change("inventoryType", "EXTERNAL");
            await this.getDrawerDropdownValues();
        }
        this.setState({
            rowData: rowData,
        });
    };

    getData = () => {
        let arrData = {};
        return fetchMethodRequest("GET", apiCalls.StorageUnitItemsCount).then(
            async (response) => {
                if (response) {

                    arrData = {
                        crashcart: response.crashcart,
                        storage: response.storage,
                        operatingRoom: response.operatingRoom,

                    };
                    console.log("data", arrData)
                    this.setState({
                        storageUnit: arrData,
                        showInfo: false,
                    });
                }
            }
        );
    };

    openFormModal = (item, cart, cartSubLocation, project) => {
        let { clinics, storageUnit } = this.state;
        let oldLocationId,
            oldClinicId,
            oldCartName = project.cartName,
            oldLocationType = project.locationType,
            locations = [];
        if (clinics && clinics.length) {
            clinics.forEach((item, index) => {
                if (item && project && item.label == project.facilityName) {
                    oldClinicId = item.value;
                }
            });
        }
        if (storageUnit && oldLocationType && storageUnit[oldLocationType]) {
            storageUnit[oldLocationType].forEach((data, index) => {
                if (data && project && data.facilityName == project.facilityName && data.cartName === project.cartName) {
                    oldLocationId = data._id;
                }
            });
        }
        let previousData = {
            oldLocationId: oldLocationId,
            oldClinicId: oldClinicId,
            oldLocationType: oldLocationType,
            oldCartName: oldCartName,
        };
        this.setState({
            productName: item,
            cart: cart,
            cartSubLocation: cartSubLocation,
            isOpenFormModal: true,
            previousData: previousData,
            project: project
        });
    };

    closeDeleteModal = () => {
        this.setState({
            openDeleteModal: false,
        });
    };

    deleteConfirmAction = (item, cart, cartSubLocation, description) => {
        let body = {
            title1: item,
            cart: cart,
            SubLocation: cartSubLocation,
            description: description,
        };
        this.setState({
            openDeleteModal: true,
            body: body,
        });
    };

    deleteSelectedRow = (type) => {
        return fetchMethodRequest(
            "POST",
            `${apiCalls.DeleteQuantity}?type=${type}`,
            this.state.body
        )
            .then(async (response) => {
                this.setState({
                    openDeleteModal: false,
                });
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, "success");
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, "error");
                }
                this.getDrawerDropdownValues(
                    this.state.id,
                    this.state.cartNumber,
                    this.state.facilityName,
                    this.state.excel,
                    this.state.area,
                    this.state.building,
                    this.state.room,
                    this.state.floor,
                    this.state.email,
                    this.state.company,
                    this.state.companyId
                );
            })
            .catch((err) => {
                return err;
            });
    };

    makeFalse = () => {
        this.setState({
            delete: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    closeFormModal = () => {
        this.setState({
            isOpenFormModal: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    openInventoryModal = async (cart, rowData) => {
        let data = {}
        if (rowData !== undefined) {
            data.productName = rowData.productName
            data.cartSubLocation = ""
            data.description = rowData.description
            data.volume = rowData.volume
            data.locationType = rowData.locationType
            data.expirationDate = rowData.expirationDate
            data.expirationDate1 = rowData.expirationDate1
            data.productId = rowData._id.productId
            data.title1 = rowData.title1
            data.volume = rowData.volume
            data.categoryType = rowData.categoryType
            // data.alternateProductNames = rowData.alternateProductNames[0]

        }
        if (!this.state.noAdd) {
            await this.setState({
                openInventoryModal: true,
                inventoryCartName: cart,
                rowData: data
            });
        } else if (this.state.noAdd) {
            showToasterMessage("You are not authorized", "error");
        }
    };


    onPageChange = async (event) => {
        let totalRecordsLength
        let duplicatecartViewItems = this.state.duplicatecartViewItems
        let firtIndex = parseInt(event.first)
        let totalRows = parseInt(event.rows)
        let lastIndex = firtIndex + totalRows
        var sliced = duplicatecartViewItems.slice(firtIndex, lastIndex);
        if (this.state.selectedOption && this.state.selectedOption !== "ENTIRE CART") {
            let selectedOptionRecords = duplicatecartViewItems.filter(
                (item) => item && item._id.cartSubLocation === this.state.selectedOption
            );
            totalRecordsLength = selectedOptionRecords.length
        } else {
            totalRecordsLength = duplicatecartViewItems.length
        }
        this.setState({
            rows: event.rows,
            page: event.page,
            first: event.first,
            cartViewItems: sliced,
            totalRecordsLength: totalRecordsLength
        })
    }

    closeInventoryModal = () => {
        this.setState({
            openInventoryModal: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    goBack = () => {
        if (!this.state.company) {
            this.setState({
                showInfo: false,
                headerInfo: ["ENTIRE CART"],
                cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }],
                selectedOption: "ENTIRE CART"
            });
        } else {
            this.setState({
                locations: true,
                headerInfo: ["ENTIRE CART"],
                cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }],
                selectedOption: "ENTIRE CART"
            });
        }
        this.getData();
    };

    openOrderConfirmation = async () => {
        this.setState({
            openOrderModal: true,
            openOrderConfirmation: false,
        });
    };

    closeOrderConfirmation = async () => {
        this.setState({
            openOrderConfirmation: false,
        });
    };

    openOrderModal = async (
        product,
        subcart,
        description,
        needed,
        concentration,
        ordered,
        facilityName
    ) => {
        await this.setState({
            orderProductName: product,
            orderFrom: "Inventory Check List",
            orderCart: this.state.cartNumber,
            orderSubCart: subcart,
            ordercompanyName: this.state.cartViewItems[0].companyName,
            orderDescription: description,
            orderConcentration: concentration,
            orderNeeded: needed,
            orderFacilityName: facilityName
        });
        if (ordered) {
            await this.setState({
                openOrderConfirmation: true,
            });
        } else {
            this.setState({
                openOrderModal: true,
            });
        }
    };

    closeOrderModal = () => {
        this.setState({
            openOrderModal: false,
        });
        this.getDrawerDropdownValues(
            this.state.id,
            this.state.cartNumber,
            this.state.facilityName,
            this.state.excel,
            this.state.area,
            this.state.building,
            this.state.room,
            this.state.floor,
            this.state.email,
            this.state.company,
            this.state.companyId
        );
    };

    getDrawerDropdownValues = async (
        id,
        cartName,
        facilityName,
        locationType,
        excel,
        area,
        building,
        room,
        floor,
        email,
        company,
        companyId,
        clinicName,
        headerdata
    ) => {
        if (id) {
            if (!localStorage.getItem('StorageUnitItems')) {
                localStorage.setItem('StorageUnitItems', JSON.stringify({
                    locationId: id,
                    facilityName: facilityName,
                    cartName: cartName
                }))
            }
            this.setState({
                id: id,
                cartNumber: cartName,
                facilityName,
                excel: excel,
                area: area,
                building: building,
                room: room,
                floor: floor,
                email: email,
                company: company,
                companyId: companyId,
                type: "Not",
                isLoading: true,
                isREdirectExpirationData: true,
            });

            if (area == "crashcart" || area == "storage" || area == "operatingRoom") {
                this.setState({
                    locationType:
                        area == "crashcart"
                            ? "Cart Location"
                            : area == "storage"
                                ? "Supply Cabinet"
                                : "OR Suite",
                });
            } else if (
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.locationType
            ) {
                if (
                    this.props.location.state.locationType == "crashcart" ||
                    this.props.location.state.locationType == "storage" ||
                    this.props.location.state.locationType == "operatingRoom"
                ) {
                    let type = this.props.location.state.locationType;
                    this.setState({
                        locationType:
                            type == "crashcart"
                                ? "Cart Location"
                                : type == "storage"
                                    ? "Supply Cabinet"
                                    : "OR Suite",
                    });
                } else {
                    this.setState({
                        locationType: this.props.location.state.locationType.replace(
                            /s$/,
                            ""
                        ),
                    });
                }
            }


            let apiUrl = `${apiCalls.StorageUnitItems}?locationId=${id}`;
            return fetchMethodRequest(
                "GET",
                apiUrl
            ).then(async (response) => {
                response = response.quantities
                let storageUnit = this.state.storageUnit
                for (let object in storageUnit) {
                    var cartOptions = this.state.cartOptions
                    if (response && response.quantities) {
                        storageUnit[object].forEach((data, index) => {
                            if (data && data.cartName === cartName) {
                                console.log("cartParts", data.cartParts)
                                for (var recordItem of data.cartParts) {
                                    cartOptions.push({
                                        label: recordItem, value: recordItem
                                    })
                                }
                                this.setState({
                                    cartOptions: cartOptions
                                })
                            }
                        });
                    }
                }
                if (response && response.length > 0) {
                    let cartViewItems = [],
                        arr = [];
                    response.map((project) => {
                        if (project && project.data && project.data.length) {
                            project.data.map((item) => cartViewItems.push(item));
                        }
                    });

                    cartViewItems.sort((a, b) =>
                        a.productName.toUpperCase() > b.productName.toUpperCase()
                            ? 1
                            : b.productName.toUpperCase() > a.productName.toUpperCase()
                                ? -1
                                : 0
                    );
                    if (cartViewItems) {
                        for (let item of cartViewItems) {
                            if (item && item._id && item._id.cartSubLocation) {
                                if (arr.indexOf(item._id.cartSubLocation) === -1) {
                                    arr.push(item._id.cartSubLocation);

                                }
                            }
                        }
                    }
                    let headerInfo = arr.sort();
                    for (let item of headerInfo) {
                        let old_index = headerInfo.indexOf(item);
                        if (item == "TOP OF CART") {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") == -1 &&
                            item == "SIDE OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") != -1 &&
                            item == "SIDE OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") == -1 &&
                            headerInfo.indexOf("SIDE OF CART") == -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("SIDE OF CART") == -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") == -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        } else if (
                            headerInfo.indexOf("TOP OF CART") != -1 &&
                            headerInfo.indexOf("SIDE OF CART") != -1 &&
                            item == "BACK OF CART"
                        ) {
                            headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
                        }
                    }


                    await this.setState({
                        headerInfo: this.state.headerInfo && this.state.headerInfo.length && this.state.headerInfo.length > 0 ? this.state.headerInfo : ["ENTIRE CART"],
                        isLoading: false,
                        cartViewItems,
                        totalRecordsLength: cartViewItems.length,
                        duplicatecartViewItems: cartViewItems,
                        // storageUnit: cartViewItems,
                        showInfo: true,
                    });
                    let event = { first: 0, rows: this.state.rows, page: 1 }
                    await this.onPageChange(event)
                } else {
                    this.setState({
                        cartViewItems: [],
                        headerInfo: [],
                        isLoading: false,
                        showInfo: true,
                    });
                }
            });
        }
    };

    render() {
        let unitsWithData = 0;

        return (
            <Container className="dashboard">
                <Loader loader={this.state.isLoading} />
                <Row>
                    <Col lg={12} xl={12}>
                        <Card className="inventoryCard">
                            <CardBody>
                                <div className="card__title">
                                    <b style={{ fontWeight: 'bold', color: '#007ad9' }}>Storage Unit Items</b>
                                </div>

                                {this.state.storageUnit &&
                                    Object.keys(this.state.storageUnit).length > 0 &&
                                    Object.keys(this.state.storageUnit).map((item, index) => {
                                        if (
                                            this.state.storageUnit[item] &&
                                            this.state.storageUnit[item].length > 0
                                        ) {
                                            unitsWithData++;
                                            return (
                                                <Table
                                                    className="table--bordered table--head-accent mb-4"
                                                    hover
                                                    key={index}
                                                >
                                                    <thead className="inventory-check-thead">
                                                        <tr style={{ background: config.templateColor }}>
                                                            <th
                                                                style={{
                                                                    width: '50%',
                                                                    textAlign: 'center',
                                                                    color: 'whitesmoke',
                                                                    borderRight: '1px solid white',
                                                                }}
                                                            >
                                                                {item === 'crashcart'
                                                                    ? 'Cart Location'
                                                                    : item === 'storage'
                                                                        ? 'Supply Cabinet'
                                                                        : 'OR Suite'}
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '50%',
                                                                    textAlign: 'center',
                                                                    color: 'whitesmoke',
                                                                }}
                                                            >
                                                                No of Items
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="inventory-check-tbody">
                                                        {this.state.storageUnit[item].map(
                                                            (location, locationIndex) => (
                                                                <tr key={locationIndex}>
                                                                    <td
                                                                        className="inventoryCheckRowTextColor storageTypeCount_hover"
                                                                        style={{ width: '50%' }}
                                                                        onClick={() =>
                                                                            this.getDrawerDropdownValues(
                                                                                location['_id'],
                                                                                location.cartName,
                                                                                location.facilityName,
                                                                                location.excel,
                                                                                item,
                                                                            )
                                                                        }
                                                                    >
                                                                        <span
                                                                            color="info"
                                                                            style={{ textTransform: 'capitalize' }}
                                                                        >
                                                                            <div className="row alignItemsCenter">
                                                                                <span>
                                                                                    Facility &nbsp;:&nbsp;
                                                                                </span>{' '}
                                                                                <span className="inventoryCheckListCountsFacility">
                                                                                    {location.facilityName}
                                                                                </span>
                                                                            </div>
                                                                            <span> Cart &nbsp;:&nbsp;</span>{' '}
                                                                            <span style={{ textTransform: 'capitalize' }}>
                                                                                {location &&
                                                                                    location.cartName &&
                                                                                    location.cartName
                                                                                        .split(' ')
                                                                                        .map(
                                                                                            word =>
                                                                                                word.charAt(0).toUpperCase() +
                                                                                                word.slice(1)
                                                                                        )
                                                                                        .join(' ')}
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        className="inventoryCheckRowTextColor"
                                                                        style={{ width: '50%', fontWeight: 'bold' }}
                                                                    >
                                                                        {location.count}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </Table>
                                            );
                                        }
                                        return null;
                                    })}
                                {unitsWithData === 0 && (
                                    <p className="text-center">No Data Found.</p>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.isREdirectExpirationData ? (
                    <Redirect to="/storageunititemsTable" />
                ) : null}
            </Container>
        );
    }
}











