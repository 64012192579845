import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Button, Card, CardBody, Col, Container, Row, Table, Badge } from "reactstrap";
import { Redirect } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import Icon from "mdi-react/ArrowLeftIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import Cart from 'mdi-react/CartIcon';
// import DataTables from "../../../CommonDataTable/DataTableNew";
import DataTables from "../../CommonDataTable/DataTable";

// Config
import fetchMethodRequest from "../../../../config/service";
import apiCalls from "../../../../config/apiCalls";
import config from "../../../../config/config";

// Modals
import AddInventoryModal from "../../Modals/AddInventoryModal";
import OrderModal from "../../Modals/OrderModal";
import DeleteRowModal from "../../Modals/DeleteRowModal";
import OrderConfirmation from "../../Modals/OrderConfirmationModal";
import FormModal from "../../../Form/FormModal";
import FormModalNew from "../../../Form/FormModalNew";

// Table
import TableRender from "../../CommonDataTable/TableRender";

// Toaster Message
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";

// Permissions
import RolePermissions from "../../Permissions/Permissions";

import PaginatorComponent from "../../CommonDataTable/PaginatorComponent";

// Loader
import Loader from "../../../App/Loader";
import configMessages from "../../../../config/configMessages";

export default class InventoryChecksModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      label: "",
      status: "",
      ShiftType: null,
      isOpenSuggestModal: false,
      suggestGroupInfo: [],
      selectedDate: null,
      type: null,
      openOrderModal: false,
      // showInfo: true,
      openInventoryModal: false,
      delete: false,
      openDeleteModal: false,
      type: "Inventory Checks",
      isLoading: true,
      storageUnit: {},
      locationType: null,
      cartViewItems: [],
      duplicatecartViewItems: [],
      previousData: {},
      selectedOption: 'ENTIRE CART',
      headerInfo: [],
      totalRecordsLength: 0,
      count: 0,
      first: 0,
      rows: 10,
      isWeb: true,
      openCartDeleteConf: false,
      filterCriteria: { limit: 20, page: 1, criteria: [] },
      cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }],
      categoryType: "Medicine",
      redirect: false,
      directTo: '',
      // categoryType: null,
      categoryTypeOptions: [{ label: "Medicine", value: "Medicine" }, { label: "Non-Medicine", value: "Non-Medicine" }]
    };


  }

  componentDidMount = async () => {


    let path = window.location.pathname;
    await this.setState({
      path: path
    });

    if (path.includes('storageUnitNew')) {
      let parts = path.split('/');
      let indexOfInventoryChecklist = parts.indexOf('inventorychecklist');
      if (indexOfInventoryChecklist !== -1 && indexOfInventoryChecklist < parts.length - 3) {
        let id = decodeURIComponent(parts[indexOfInventoryChecklist + 1]);
        let facilityName = decodeURIComponent(parts[indexOfInventoryChecklist + 2]);
        let cartName = decodeURIComponent(parts[indexOfInventoryChecklist + 3]);
        this.getDrawerDropdownValues(id, cartName, facilityName);
      }
    }



    this.getClinicDropDownValues();
    let screenPermissions = RolePermissions.screenPermissions(
      "Inventory Check List"
    );
    if (screenPermissions && screenPermissions != "edit") {

      await this.setState({
        noAdd: true,
      });
    }
    if (this.props.match.params.locationId) {
      if (this.props.match.params) {
        if (
          this.props.match.params.locationId &&
          this.props.match.params.cart &&
          this.props.match.params.clinic
        ) {
          await this.getData("type");
          this.getDrawerDropdownValues(
            this.props.match.params.locationId,
            this.props.match.params.cart,
            this.props.match.params.clinic,
            this.props.match.params.excel,
            this.props.match.params.area,
            this.props.match.params.building,
            this.props.match.params.room,
            this.props.match.params.floor,
            this.props.match.params.email,
            this.props.match.params.company,
            this.props.match.params.companyId
          );
        }
      }
      this.setState({
        isLoading: false,
      });
    } else if (this.props.location) {

      if (this.props.location.pathname !== "/inventorychecklist") {

        await this.getData("type");
        this.setState({
          isLoading: true,
        });
        let cartName = this.props.location.pathname.slice(
          20,
          this.props.location.pathname.length
        );
        return fetchMethodRequest("GET", `${apiCalls.CartLocations}`).then(
          async (response) => {
            if (response && response.locations) {
              for (let value of response.locations) {
                if (value.cart == cartName) {
                  this.getDrawerDropdownValues(
                    value._id,
                    value.cart,
                    value.clinicName
                  );
                }
              }
              this.setState({
                isLoading: false,
              });
            }
          }
        );
      } else if (this.props.location.pathname == "/inventorychecklist") {

        await this.getData();
        this.setState({
          isLoading: false,

        });
      }
    }
  };
  getFormFields = () => {
    return ([
      {
        "value": "",
        "name": "firstName",
        "label": "POC First Name",
        "id": "firstName",
        "placeholder": "FirstName",
        "type": "text",
        "required": true
      },
      {
        "value": "",
        "name": "lastName",
        "label": "LastName",
        "id": "lastName",
        "placeholder": "POC Last Name",
        "type": "text",
        "required": true
      },

      {
        "value": "",
        "name": "email",
        "label": "POC Email",
        "id": "email",
        "placeholder": "Email",
        "type": "email",
        "required": true
      },
      {
        "value": "",
        "name": "phone",
        "label": "Phone Number",
        "id": "phoneNumber",
        "placeholder": "Phone Number",
        "type": "text",
        "normalize": false,
        "required": true
      },
      {
        "value": "",
        "name": "status",
        "label": "Status",
        "id": "status",
        "placeholder": "Status",
        "type": "dropDown",
        "options": config.statusTypes,
        "required": true
      },

    ]);
  }


  handleSelectChange = async (event) => {
    // debugger
    let array = []
    array.push(event.value)
    await this.setState({ selectedOption: event.value, headerInfo: array });
    let data = { first: 0, page: 1, rows: this.state.rows }
    await this.onPageChange(data)
  };

  handleSelectCategoryChange = async (event) => {
    // debugger
    const selectedCategoryType = event.value;
    const filteredItems = this.state.allViewItems.filter((item) => {
      if (selectedCategoryType === "Medicine") {
        return item.categoryType === "Medicine";
      } else if (selectedCategoryType === "Non-Medicine") {
        return item.categoryType === "Non-Medicine";
      }
      return true;
    });

    await this.setState({ categoryType: selectedCategoryType, cartViewItems: filteredItems });
  };
  getPaginator() {
    return (
      <PaginatorComponent
        totalRecords={this.state.totalRecordsLength}
        first={this.state.first}
        rows={this.state.rows}
        onPageChange={this.onPageChange}
        isWeb={true}
        type={true}
      />
    )
  }
  passDataToTable = () => {
    let apiUrl = `${apiCalls.LocationsCartView}?locationId=${this.props.locationId}`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response && response.quantity) {
          await this.setState({
            cartcount: response.wishlistCount
          })
        } else {
        }
      });
  }

  getClinicDropDownValues = async () => {
    let ls = JSON.parse(localStorage.loginCredentials);
    if (ls.clinics && ls.clinics.length > 0) {
      let modifiedClinics = [];
      ls.clinics.map((item, i) => {
        modifiedClinics.push({
          label: item.name,
          value: item.clinicId,
        });
      });
      await this.setState({
        clinics: modifiedClinics,
      });
    } else {
      return fetchMethodRequest("GET", apiCalls.Clinics).then(
        async (response) => {
          if (response && response.clinics && response.clinics[0]) {
            if (response.clinics[0].name) {
              let clinics = response.clinics;
              let modifiedClinicNames = [];
              for (let clinic of clinics) {
                modifiedClinicNames.push({
                  label: clinic.name,
                  value: clinic._id,
                });
              }

              await this.setState({
                clinics: modifiedClinicNames,
              });
            }
          }
        }
      );
    }
  };

  getRowData = async (rowData) => {
    if (this.props.type == "Storage Unit") {
      this.props.change("inventoryType", "EXTERNAL");
      await this.getDrawerDropdownValues();
    }
    this.setState({
      rowData: rowData,
    });
  };

  getData = (type) => {
    let arrData = [];
    return fetchMethodRequest("GET", apiCalls.QuantityItemsCount).then(
      async (response) => {
        if (response) {
          this.setState({
            storageUnit: response.totalItems,
            showInfo: type === "type" ? true : false,

          });
        }
      }
    );
  };

  openFormModal = (item, cart, cartSubLocation, project) => {
    let { clinics, storageUnit } = this.state;
    let oldLocationId,
      oldClinicId,
      oldCartName = project.cartName,
      oldLocationType = project.locationType,
      locations = [];
    if (clinics && clinics.length) {
      clinics.forEach((item, index) => {
        if (item && project && item.label == project.facilityName) {
          oldClinicId = item.value;
        }
      });
    }
    if (storageUnit && oldLocationType && storageUnit[oldLocationType]) {
      storageUnit[oldLocationType].forEach((data, index) => {
        if (data && project && data.facilityName == project.facilityName && data.cartName === project.cartName) {
          oldLocationId = data._id;
        }
      });
    }
    let previousData = {
      oldLocationId: oldLocationId,
      oldClinicId: oldClinicId,
      oldLocationType: oldLocationType,
      oldCartName: oldCartName,
    };
    this.setState({
      productName: item,
      cart: cart,
      cartSubLocation: cartSubLocation,
      isOpenFormModal: true,
      previousData: previousData,
      project: project
    });
  };

  closeDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
    });
  };

  deleteConfirmAction = (item, cart, cartSubLocation, description) => {
    let body = {
      title1: item,
      cart: cart,
      SubLocation: cartSubLocation,
      description: description,
    };
    this.setState({
      openDeleteModal: true,
      body: body,
    });
  };

  deleteSelectedRow = (type) => {

    return fetchMethodRequest(
      "POST",
      `${apiCalls.DeleteQuantity}?type=${type}`,
      this.state.body
    )
      .then(async (response) => {
        this.setState({
          openDeleteModal: false,
        });
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, "error");
        }
        this.getDrawerDropdownValues(
          this.state.id,
          this.state.cartNumber,
          this.state.facilityName,
          this.state.excel,
          this.state.area,
          this.state.building,
          this.state.room,
          this.state.floor,
          this.state.email,
          this.state.company,
          this.state.companyId
        );
        this.state.cartNumber,
          this.state.facilityName,
          this.state.excel,
          this.state.area,
          this.state.building,
          this.state.room,
          this.state.floor,
          this.state.email,
          this.state.company,
          this.state.companyId
      })
      .catch((err) => {
        return err;
      });
  };

  makeFalse = () => {
    this.setState({
      delete: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName,
      this.state.excel,
      this.state.area,
      this.state.building,
      this.state.room,
      this.state.floor,
      this.state.email,
      this.state.company,
      this.state.companyId
    );
  };

  closeFormModal = () => {
    this.setState({
      isOpenFormModal: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName,
      this.state.excel,
      this.state.area,
      this.state.building,
      this.state.room,
      this.state.floor,
      this.state.email,
      this.state.company,
      this.state.companyId
    );
  };


  openConfModal = async () => {
    await this.setState({
      openCartDeleteConf: true
    });
    // this.openOrderConfirmation();
  }

  openInventoryModal = async (cart, rowData) => {
    let data = {}
    if (rowData !== undefined) {
      data.productName = rowData.productName
      data.cartSubLocation = ""
      data.description = rowData.description
      data.volume = rowData.volume
      data.locationType = rowData.locationType
      data.expirationDate = rowData.expirationDate
      data.expirationDate1 = rowData.expirationDate1
      data.productId = rowData._id.productId
      data.title1 = rowData.title1
      data.volume = rowData.volume
      data.categoryType = rowData.categoryType
      // data.alternateProductNames = rowData.alternateProductNames[0]

    }
    if (!this.state.noAdd) {
      await this.setState({
        openInventoryModal: true,
        inventoryCartName: cart,
        rowData: data
      });
    } else if (this.state.noAdd) {
      showToasterMessage("You are not authorized", "error");
    }
  };


  onPageChange = async (event) => {
    let totalRecordsLength
    let duplicatecartViewItems = this.state.duplicatecartViewItems
    var sliced
    if (this.state.selectedOption && this.state.selectedOption !== "ENTIRE CART") {
      let selectedOptionRecords = duplicatecartViewItems.filter(
        (item) => item && item._id.cartSubLocation === this.state.selectedOption && item.categoryType === this.state.categoryType
      );
      let firtIndex = parseInt(event.first)
      let totalRows = parseInt(event.rows)
      let lastIndex = firtIndex + totalRows
      sliced = selectedOptionRecords.slice(firtIndex, lastIndex);
      totalRecordsLength = selectedOptionRecords.length
    } else {

      let filteredUsers = duplicatecartViewItems.filter((user) => {
        return user.categoryType === this.state.categoryType;
      });
      let firtIndex = parseInt(event.first)
      let totalRows = parseInt(event.rows)
      let lastIndex = firtIndex + totalRows
      sliced = filteredUsers.slice(firtIndex, lastIndex);
      totalRecordsLength = filteredUsers.length
    }
    this.setState({
      rows: event.rows,
      page: event.page,
      first: event.first,
      cartViewItems: sliced,
      allViewItems: sliced,
      totalRecordsLength: totalRecordsLength
    })
  }

  closeInventoryModal = () => {
    this.setState({
      openInventoryModal: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName,
      this.state.excel,
      this.state.area,
      this.state.building,
      this.state.room,
      this.state.floor,
      this.state.email,
      this.state.company,
      this.state.companyId
    );
  };

  goBack = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.redirect) {
      this.state.directTo = 'home';

    } else if (this.props.location && this.props.location.state && this.props.location.state.locationType === "StorageUnit") {
      this.state.directTo = 'unit';

    }
    // else if (this.props.location.state.locationType == "StorageUnit") {
    //   alert('hellow')
    //   this.setState({ directToo: true })

    // }
    if (!this.state.company) {
      this.setState({
        showInfo: false,
        headerInfo: ["ENTIRE CART"],
        cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }],
        selectedOption: "ENTIRE CART",
        categoryType: "Medicine"
      });
    } else {
      this.setState({
        locations: true,
        headerInfo: ["ENTIRE CART"],
        cartOptions: [{ label: "ENTIRE CART", value: "ENTIRE CART" }],
        selectedOption: "ENTIRE CART",
        categoryType: "Medicine"
      });
    }

    this.getData();
    // }
  };

  openOrderConfirmation = async () => {
    this.setState({
      openOrderModal: true,
      openOrderConfirmation: false,
    });
  };

  closeOrderConfirmation = async () => {
    this.setState({
      openOrderConfirmation: false,
    });
  };

  openOrderModal = async (
    product,
    title,
    subcart,
    description,
    needed,
    volume,
    ordered,
    facilityName
  ) => {
    await this.setState({
      orderProductName: product,
      title: title,
      orderFrom: "Inventory Check List",
      orderCart: this.state.cartNumber,
      orderSubCart: subcart,
      ordercompanyName: this.state.cartViewItems[0].companyName,
      // orderProductId: this.state.cartViewItems.map(project => project._id.productId),
      orderDescription: description,
      orderConcentration: volume,
      orderNeeded: needed,
      orderFacilityName: facilityName
    });
    if (ordered) {
      await this.setState({
        openOrderConfirmation: true,
      });
    } else {
      this.setState({
        openOrderModal: true,
      });
    }
  };

  closeOrderModal = () => {
    this.setState({
      openOrderModal: false,
    });
    this.getDrawerDropdownValues(
      this.state.id,
      this.state.cartNumber,
      this.state.facilityName,
      this.state.excel,
      this.state.area,
      this.state.building,
      this.state.room,
      this.state.floor,
      this.state.email,
      this.state.company,
      this.state.companyId
    );
  };


  handleSelectCategoryChange = async (event) => {
    // debugger
    const selectedCategoryType = event.value ? event.value : "All";
    const filteredItems = this.state.duplicatecartViewItems.filter((item) => {
      if (selectedCategoryType === "Medicine") {
        return item.categoryType === "Medicine";
      } else if (selectedCategoryType === "Non-Medicine") {

        return item.categoryType === "Non-Medicine";
      } else {
        return this.state.allViewItems
      }
      return true;
    });
    await this.setState({ categoryType: selectedCategoryType, cartViewItems: filteredItems, totalRecordsLength: filteredItems.length });
    let data = { first: 0, page: 1, rows: this.state.rows }
    await this.onPageChange(data)
  };


  getDrawerDropdownValues = async (
    id,
    cartName,
    facilityName,
    excel,
    area,
    building,
    room,
    floor,
    email,
    company,
    companyId,
    headerdata
  ) => {
    console.log("getDrawerDropdownValues",
      id,
      cartName,
      facilityName,
      excel,
      area,
      building,
      room,
      floor,)

    if (id) {
      this.setState({
        id: id,
        cartNumber: cartName,
        facilityName,
        excel: excel,
        area: area,
        building: building,
        room: room,
        floor: floor,
        email: email,
        company: company,
        companyId: companyId,
        type: "Not",
        isLoading: true,
      });
      if (area == "crashcart" || area == "storage" || area == "operatingRoom") {
        this.setState({
          locationType:
            area == "crashcart"
              ? "Cart Location"
              : area == "storage"
                ? "Supply Cabinet"
                : "OR Suite",
        });
      } else if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.locationType
      ) {
        if (
          this.props.location.state.locationType == "crashcart" ||
          this.props.location.state.locationType == "storage" ||
          this.props.location.state.locationType == "operatingRoom"
        ) {
          let type = this.props.location.state.locationType;
          this.setState({
            locationType:
              type == "crashcart"
                ? "Cart Location"
                : type == "storage"
                  ? "Supply Cabinet"
                  : "OR Suite",
          });
        } else {
          this.setState({
            locationType: this.props.location.state.locationType.replace(
              /s$/,
              ""
            ),
          });
        }
      }



      let apiUrl = `${apiCalls.LocationsCartView}?locationId=${id}`;
      return fetchMethodRequest(
        "GET",
        apiUrl,
      ).then(async (res) => {
        let response = res.quantity;
        let count = res.wishlistCount;
        let path = window.location.pathname;
        if (path.includes('storageUnitNew')) {
          let storedData = localStorage.getItem('storageUnitData');
          if (storedData) {
            let storedStorageUnit = JSON.parse(storedData);

            await this.setState({
              storageUnit: storedStorageUnit
            });
          }
          // localStorage.removeItem('storageUnitData');
        }
        let storageUnit = this.state.storageUnit
        for (let object in storageUnit) {
          var cartOptions = this.state.cartOptions
          if (object == area || storageUnit[object]) {
            storageUnit[object].forEach((data, index) => {
              if (data && (data.cartName === cartName || data.cart === cartName)) {
                for (var recordItem of data.cartParts) {
                  const index = cartOptions.findIndex(object => object.label === recordItem);
                  if (index === -1) {
                    cartOptions.push({
                      label: recordItem, value: recordItem
                    })
                  }
                }
                this.setState({
                  cartOptions: cartOptions,
                  count
                })
              }
            });
          }
        }

        this.setState({
          cartOptions: cartOptions,
          count
        })

        if (response && response.length > 0) {
          let cartViewItems = [], headerInfo = [],
            arr = [];
          response.map((project) => {
            if (project && project.data && project.data.length) {
              project.data.map((item) => cartViewItems.push(item));
            }
          });

          //adding soring for data 
          cartViewItems.sort((a, b) =>
            a.productName.toUpperCase() > b.productName.toUpperCase()
              ? 1
              : b.productName.toUpperCase() > a.productName.toUpperCase()
                ? -1
                : 0
          );
          if (cartViewItems) {
            for (let item of cartViewItems) {
              if (item && item._id && item._id.cartSubLocation) {
                if (arr.indexOf(item._id.cartSubLocation) === -1) {
                  arr.push(item._id.cartSubLocation);

                }
              }
            }
          }
          headerInfo = arr.sort();
          for (let item of headerInfo) {
            let old_index = headerInfo.indexOf(item);
            if (item == "TOP OF CART") {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            } else if (
              headerInfo.indexOf("TOP OF CART") == -1 &&
              item == "SIDE OF CART"
            ) {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            } else if (
              headerInfo.indexOf("TOP OF CART") != -1 &&
              item == "SIDE OF CART"
            ) {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            } else if (
              headerInfo.indexOf("TOP OF CART") == -1 &&
              headerInfo.indexOf("SIDE OF CART") == -1 &&
              item == "BACK OF CART"
            ) {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            } else if (
              headerInfo.indexOf("SIDE OF CART") == -1 &&
              item == "BACK OF CART"
            ) {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            } else if (
              headerInfo.indexOf("TOP OF CART") == -1 &&
              item == "BACK OF CART"
            ) {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            } else if (
              headerInfo.indexOf("TOP OF CART") != -1 &&
              headerInfo.indexOf("SIDE OF CART") != -1 &&
              item == "BACK OF CART"
            ) {
              headerInfo.splice(0, 0, headerInfo.splice(old_index, 1)[0]);
            }
          }
          await this.setState({
            headerInfo: this.state.headerInfo && this.state.headerInfo.length && this.state.headerInfo.length > 0 ? this.state.headerInfo : ["ENTIRE CART"],
            isLoading: false,
            cartViewItems,
            totalRecordsLength: cartViewItems.length,
            duplicatecartViewItems: cartViewItems,
            showInfo: true,
          });
          let event = { first: 0, rows: this.state.rows, page: 1 }
          await this.onPageChange(event)
        } else {
          this.setState({
            cartViewItems: [],
            headerInfo: [],
            isLoading: false,
            showInfo: true,
          });
        }
      });
    }
  };

  openFormModal = () => {
    this.setState({
      isOpenFormModal: true
    })
  }

  toUpperCase = (str) => {
    let strr
    if (str && str != '') {
      strr = str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    }
    return strr

  }

  // deleteOrderProduct = (item) => {
  //   let pload = {};
  //   pload['locationId'] = this.props.locationId;
  //   pload['productId'] = item._id;
  //   let url = `wishlist/removeWishProduct`;
  //   return fetchMethodRequest("POST", url, pload)
  //     .then((res) => {
  //       if (res) {
  //         let productTablefields = this.state.productTablefields
  //         let pID = pload['productId']
  //         let newProductTablefields = productTablefields.filter(
  //           (product) => product._id !== pID
  //         );
  //         this.setState({
  //           productTablefields: newProductTablefields,
  //         });
  //         // this.refreshWishlist();
  //         showToasterMessage(res.respMessage, "success");
  //       }
  //     })
  // }
  handleGoBack = () => {
    this.setState({
      redirect: true
    })
  }


  render() {
    let path = window.location.pathname;
    // const { selectedOption, headerInfo } = this.state;
    return !this.state.showInfo ? (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />
        <Row>
          <Col lg={12} xl={12}>
            <Card className="inventoryCard">
              <CardBody>
                <div className="card__title">
                  <b style={{ fontWeight: 'bold', color: '#007ad9' }}>Inventory Check List</b>
                </div>
                {this.state.storageUnit &&
                  Object.keys(this.state.storageUnit).length > 0
                  ? Object.keys(this.state.storageUnit).map((item, index) => {
                    return (
                      <Table
                        className="table--bordered table--head-accent mb-4"
                        hover
                        key={index}
                      >
                        {this.state.storageUnit[item] &&
                          this.state.storageUnit[item].length > 0 ? (
                          <thead className="inventory-check-thead">
                            <tr style={{ background: config.templateColor }}>
                              <th
                                style={{
                                  width: 50 + "%",
                                  textAlign: "center",
                                  color: "whitesmoke",
                                  borderRight: "1px solid white",
                                }}
                              >
                                {item == "crashcart"
                                  ? "Cart Location"
                                  : item == "storage"
                                    ? "Supply Cabinet"
                                    : "OR Suite"}
                              </th>
                              <th
                                style={{
                                  width: 50 + "%",
                                  textAlign: "center",
                                  color: "whitesmoke",
                                }}
                              >
                                No of Items
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        <tbody className="inventory-check-tbody">
                          {this.state.storageUnit[item] &&
                            this.state.storageUnit[item].length > 0 ? (
                            this.state.storageUnit[item].map(
                              (location, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      className="inventoryCheckRowTextColor storageTypeCount_hover"
                                      style={{ width: 50 + "%" }}
                                      onClick={() =>
                                        this.getDrawerDropdownValues(
                                          location["_id"],
                                          location.cartName,
                                          location.facilityName,
                                          location.excel,
                                          item,

                                        )
                                      }
                                    >
                                      <span color="info" style={{ textTransform: 'capitalize' }}>
                                        {/* {location.cartName} */}
                                        <div className=" row alignItemsCenter">
                                          <span> Facility &nbsp;:&nbsp;</span>  <span className="inventoryCheckListCountsFacility">{location.facilityName}</span>
                                        </div>
                                        <span> Cart &nbsp;:&nbsp;</span>{" "}
                                        <span style={{ textTransform: 'capitalize' }}>
                                          {location && location.cartName && location.cartName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                        </span>
                                      </span>
                                    </td>
                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{ width: 50 + "%", fontWeight: "bold" }}
                                    >
                                      {location.count}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : null
                            // (
                            //   <tr>
                            //     <td className="text-center">No data found.</td>
                            //   </tr>
                            // )
                          }
                        </tbody>
                      </Table>
                    );
                  })
                  : (
                    <h5 className="text-center" style={{ marginTop: '20px' }}>No Data Found</h5>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    ) : (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />
        <Row>
          <Col lg={12} xl={12}>
            <Card className="inventoryCard">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text" style={{ color: "rgb(0, 122, 217)" }}>
                    <span className="mb-3" onClick={this.state.path === "/inmedtory/inventorychecklist" ? this.goBack : this.handleGoBack}>
                      <Icon />
                      <span style={{ verticalAlign: 'middle', marginLeft: '5px' }}>
                        {this.state.path === "/inmedtory/inventorychecklist" ? 'Inventory Check List' : 'Storage Unit'}
                      </span>
                    </span>
                  </h5>
                  {(this.state.redirect && (this.props.location && this.props.location.state && this.props.location.state.direct)) ? <Redirect to="/locations" /> : (this.state.redirect && (this.props.location && this.props.location.state && this.props.location.state.redirect)) ? <Redirect to="/home" /> : null}
                  <div className="row mt-2 align-items-center ml-auto " >
                    <div className="headerInfostyle">
                      <div className="m-1">
                        <div>
                          <span
                            style={{
                              color: config.templateColor,
                              fontWeight: 550,
                            }}
                          >
                            FACILITY
                          </span>{" "}
                          : <span style={{ textTransform: "uppercase" }}>{this.state.facilityName}</span>
                        </div>
                        {/* ) : null} */}
                        {/* headerInfo */}
                        <span
                          style={{
                            color: config.templateColor, fontWeight: 550, textTransform: "uppercase",
                          }}
                        >
                          CART NAME
                        </span>{" "}
                        : <span style={{ textTransform: "uppercase" }}>{this.state.cartNumber}</span>
                        <br />

                        {this.state.headerInfo
                          ?
                          this.state.headerInfo.map((item, index) =>
                            <div key={index}>
                              <span
                                className={
                                  this.state.company
                                    ? "checkListspan mt-8"
                                    : "checkListspan"
                                }
                                style={{
                                  marginTop: '0.2rem'
                                }}
                              >{item}
                              </span>
                            </div>
                          )
                          : null
                        }
                      </div>


                    </div>
                    {this.state.excel != "null" && this.state.excel ? (
                      <a
                        className="btn btn-primary outline"
                        href={`${config.imgUrl}xlsx/${this.state.excel}`}
                      >
                        Template
                      </a>
                    ) : null}
                  </div>

                </div>
                <div className="checkListdiv">
                  <div className="d-flex ml-4">
                    <div className="mr-3">
                      <Dropdown
                        className="drop"
                        appendTo={document.body}
                        value={this.state.selectedOption}
                        onChange={(e) => this.handleSelectChange(e)}
                        options={this.state.cartOptions}
                      />

                      {/* </div>
                    <div> */}
                      <Dropdown
                        style={{ marginLeft: 5 }}
                        appendTo={document.body}
                        options={this.state.categoryTypeOptions}
                        // className="categorytype"
                        value={this.state.categoryType}
                        placeholder=""
                        onChange={(e) => this.handleSelectCategoryChange(e)}
                      />
                    </div>
                    <div className="ml-3 mr-3">
                      {!this.state.noAdd ? (
                        <Button color="primary"
                          size="sm"
                          className="p-1"
                          onClick={() => { this.openInventoryModal(this.state.cartNumber) }}>
                          <FontAwesomeIcon
                            icon='plus'
                            className='pl-1' size='lg'
                            data-toggle="tool-tip" title="Add Product"
                            onClick={() => { this.openInventoryModal(this.state.cartNumber) }}
                          />
                        </Button>
                      ) : null}

                    </div>
                    <div className="cartDisplay">
                      <div>
                        <Cart
                          className="cart"
                          onClick={() => { this.openFormModal(); this.setState({ a: true }); }}
                          size={30}
                          style={{
                            background: "white",
                            color: "blue",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <Badge
                        className="cart_badge badge badge-warning badge-pill ml-n1"
                        color="success"
                        pill
                      >
                        {this.state.count}
                      </Badge>
                    </div>
                  </div>
                  <div className="paginatorDisplay">
                    {this.getPaginator()}
                  </div>
                </div>



                {this.state.headerInfo
                  ? this.state.headerInfo.map((item, index) => (
                    <div key={index}>
                      <Table
                        className="table--bordered table--head-accent mb-4"
                      // hover
                      >
                        <thead className="inventory-check-thead">
                          <tr style={{ background: config.templateColor }}>
                            <th
                              className="inventoryCheckRow"
                              style={{ width: "4%" }}
                            >
                              Sno
                            </th>
                            <th
                              className="inventoryCheckRow"
                              style={{ width: "12%" }}
                            >
                              Product
                            </th>
                            <th
                              className="inventoryCheckRow"
                              style={{ width: "12%" }}
                            >
                              Description
                            </th>
                            <th
                              className="inventoryCheckRow"
                              style={{ width: "10%" }}
                            >
                              Volume
                            </th>
                            {/* <th
                              className="inventoryCheckRow"
                              style={{ width: "14%" }}
                            >
                              Required Inventory
                            </th> */}
                            <th
                              className="inventoryCheckRow"
                              style={{ width: "13%" }}
                            >
                              Current Inventory
                            </th>

                            <th
                              className="inventoryCheckRow"
                              style={{ width: "14%" }}
                            >
                              Required Inventory
                            </th>

                            <th
                              className="inventoryCheckRow"
                              style={{ width: "8%" }}
                            >
                              Need To Order
                            </th>
                            {/*<th
                              className="inventoryCheckRow"
                              style={{ width: "8%" }}
                            >
                              Alternate  Product Count
                            </th>*/}
                            <th
                              className="inventoryCheckRow"
                              style={{ width: "15%" }}
                            >
                              Expired
                            </th>

                            {!this.state.noAdd ? (
                              <th
                                className="inventoryCheckRow"
                                style={{ width: "10%" }}
                              >
                                Actions
                              </th>
                            ) : null}

                            <th
                              className="inventoryCheckRow"
                              style={{ width: "10%" }}
                            >
                              Order
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          // className="inventory-check-tbody"
                          className="d-block"
                        >
                          {this.state.cartViewItems &&
                            this.state.cartViewItems.length > 0
                            ? this.state.cartViewItems.map((project, i) => {
                              if (
                                project &&
                                project._id &&
                                project._id.cartSubLocation !== null && project._id.cartSubLocation && ((item === "ENTIRE CART" && project.categoryType === this.state.categoryType) ||
                                  (project._id.cartSubLocation == item && project.categoryType === this.state.categoryType))
                              ) {
                                return (

                                  <tr key={i} class="d-flex">
                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "4%",
                                      }}
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "12%", textAlign: "left"
                                      }}
                                    >
                                      {project.productName}
                                    </td>
                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "12%",
                                      }}
                                    >
                                      {project.description
                                        ? project.description
                                        : "-"}
                                    </td>
                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "10%",
                                      }}
                                    >
                                      {project.volume
                                        ? project.volume
                                        : "-"}
                                    </td>
                                    {/* <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "14%",
                                      }}
                                    >
                                      {project.limit}
                                    </td> */}

                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "13%",
                                      }}
                                    >
                                      {(project.count != null ||
                                        project.count != undefined) &&
                                        (project.fourWeeksCount != null ||
                                          project.fourWeeksCount != undefined)
                                        ? project.count +
                                        project.fourWeeksCount
                                        : null}
                                    </td>

                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "14%",
                                      }}
                                    >
                                      {project.limit}
                                    </td>

                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "8%",
                                      }}
                                    >
                                      {project.limit != null && project.count != null
                                        ?
                                        project.limit - project.count >= 0
                                          ?
                                          (project.limit - project.count) : 0 :
                                        null}
                                    </td>
                                    {/* <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "8%",
                                      }}
                                    >
                                      {project.alternateProductCount}
                                    </td> */}
                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "15%",
                                      }}
                                    >
                                      {project.expiringInFiveWeeks}
                                    </td>

                                    {!this.state.noAdd ? (
                                      <td
                                        className="inventoryCheckRowTextColor"
                                        style={{
                                          width: "10%",
                                        }}
                                      >
                                        {
                                          <div>
                                            {" "}
                                            {/* <FontAwesomeIcon
                                              className="genderIconAlignment"
                                              color="white"
                                              icon="edit"
                                              data-toggle="tool-tip"
                                              title="Edit"
                                              style={{
                                                color: "#024a88",
                                                width: "15",
                                              }}
                                              onClick={() => {
                                                this.openFormModal(
                                                  project.title1,
                                                  this.state.cartNumber,
                                                  item,
                                                  project
                                                );
                                              }}
                                            /> */}
                                            <FontAwesomeIcon
                                              className="genderIconAlignment"
                                              color="white"
                                              icon="trash-alt"
                                              data-toggle="tool-tip"
                                              title="Delete"
                                              style={{
                                                color: "#bf1725",
                                                width: "13",
                                                marginRight: '2rem',
                                              }}
                                              onClick={() =>
                                                this.deleteConfirmAction(
                                                  project.title1,
                                                  this.state.cartNumber,
                                                  item,
                                                  project.description
                                                )
                                              }
                                            />

                                            <FontAwesomeIcon
                                              className="genderIconAlignment"
                                              color="white"
                                              icon="plus"
                                              data-toggle="tool-tip"
                                              title="Add"
                                              style={{
                                                color: "#024a88",
                                                width: "13",
                                                marginLeft: '1rem',
                                              }}
                                              onClick={() =>
                                                this.openInventoryModal(this.state.cartNumber, project)
                                              }
                                            />
                                          </div>
                                        }
                                      </td>
                                    ) : null}

                                    <td
                                      className="inventoryCheckRowTextColor"
                                      style={{
                                        width: "10%",
                                      }}
                                    >
                                      <Button
                                        className="add_to_orders_btn"
                                        color={
                                          project.isOrdered
                                            ? "danger"
                                            : "primary"
                                        }
                                        onClick={() => {
                                          this.openOrderModal(
                                            project.productName,
                                            project.title1,
                                            item,
                                            project.description,
                                            project.limit - project.count,
                                            project.volume,
                                            project.isOrdered,
                                            project.facilityName

                                          ), this.setState({ productId: this.state.cartViewItems[i]._id.productId })
                                        }
                                        }
                                        outline
                                      >
                                        <div>
                                          <span
                                            class="font-size-12 d-block mb-2"
                                          >
                                            Add&nbsp;To
                                          </span>
                                          <span
                                            class="font-size-12 d-block"
                                          >
                                            Orders&nbsp;List
                                          </span>
                                        </div>

                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }
                            })
                            : (
                              <h5 className="text-center" style={{ marginTop: '20px' }}>No Records Found</h5>
                            )}
                        </tbody>
                      </Table>
                    </div>
                  ))
                  : null}
              </CardBody>
            </Card>
          </Col>
        </Row>


        <DeleteRowModal
          openDeleteModal={this.state.openDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          deleteSelectedRow={this.deleteSelectedRow}
          type={"Storage Unit Items"}
          message={configMessages.DeleteMsg}
        />
        {/* //Inventory Check List */}
        {this.state.openOrderModal ? (
          <OrderModal
            onRef={(ref) => (this.confirmRef = ref)}
            openOrderModal={this.state.openOrderModal}
            locationId={this.state.id}
            productId={this.state.productId}
            closeOrderModal={this.closeOrderModal}
            updateOrderWishlist={this.passDataToTable}
            product={this.state.orderProductName}
            title={this.state.title}
            orderFrom={this.state.orderFrom}
            ordercompanyName={this.state.cartViewItems[0].companyName}
            orderCart={this.state.orderCart}
            orderDescription={this.state.orderDescription}
            orderConcentration={this.state.orderConcentration}
            orderClinicName={this.state.orderFacilityName}
            orderNeeded={
              this.state.orderNeeded >= 0 ? this.state.orderNeeded : 0
            }
          />
        ) : null}
        {this.state.openInventoryModal ? (
          <div>
            <FormModal cart={this.state.inventoryCartName} />
            <AddInventoryModal
              onRef={(ref) => (this.confirmRef = ref)}
              rowData={this.state.rowData}
              headerInfo={this.state.headerInfo}
              openInventoryModal={this.state.openInventoryModal}
              closeInventoryModal={this.closeInventoryModal}
              // product={this.state.orderProductName}
              cartId={this.state.id}
              locationType={this.state.locationType}
              locationId={this.state.id}
              cart={this.state.inventoryCartName}
            />
          </div>
        ) : null}


        {/* formmodal */}
        {this.state.isOpenFormModal ? (
          <FormModal
            onRef={(ref) => (this.formModalRef = ref)}
            openFormModal={this.state.isOpenFormModal}
            closeFormModal={this.closeFormModal}
            openConfModal={this.openConfModal}
            // type="Recommended Inventories"
            productTablefields={this.state.productTablefields}
            facilityName={this.state.facilityName}
            type="Wishlist"
            inventoryValid={true}
            apiUrl={"orders"}
            formType="add"
            locationId={this.state.id}
            productId={this.state.productId}
            companyName={this.state.company}
            setIsOpenFormModal={(e) => { this.setState({ openNewFormModal: e }) }}
            screnType="inventoryChecks"
          // formFields = {this.getFormFields}
          // openInventoryModal = {true}
          />
        ) : null}

        {this.state.openNewFormModal ?
          <FormModalNew

            displayViewOfForm='modal'
            formFields={this.getFormFields}
            openFormModal={true}
            // apiUrl = {apiCalls.Distributors}
            distributorsEmail={apiCalls.Distributors}
            isEmail={true}
            setIsOpenFormModal={(e) => this.setState({ openNewFormModal: e })}
            screnType="inventoryChecks"
          />
          : null}
        {this.state.delete ? (
          <div>
            <TableRender
              id={"checks"}
              title={this.state.productName}
              lotId={this.state.lotId}
              makeFalse={this.makeFalse}
            />
          </div>
        ) : null}
        {this.state.openOrderConfirmation ? (
          <OrderConfirmation
            Order={this.state.openOrderConfirmation}
            openOrderConfirmation={this.openOrderConfirmation}
            closeOrderConfirmation={this.closeOrderConfirmation}
          />
        ) : null}
        {this.state.directTo == "home" && <Redirect to="/home" />}
      </Container>
    );
  }
}
