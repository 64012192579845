
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, CardBody, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';

// show message 
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import DefaultInput from '../../shared/components/form/DefaultInput';
import { withTranslation } from 'react-i18next';

//validations
let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

class UserPasswordResetModal extends React.Component {
  constructor(props) {
    super(props);
    this.buttonActionType = null;
    this.state = {
      isLoginSuccess: false,
      isLoading: true
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  // on value change in input
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  showConfirmPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }));
  }

  // clear input data
  clearInputFields = () => {
    this.props.reset();
  }

  submit = (values) => {

  }

  cancelReset = async () => {
    // await this.props.reset();
    await this.props.closeRecommendedModal();
  }

  render() {
    const { handleSubmit, RecommendedDetailsModal, t, isSelectedData } = this.props;
    return (
      <Modal isOpen={RecommendedDetailsModal} centered
        className={`modal-dialog-centered modal-dialog--primary modal-dialog--header logout_modal_width `}
        style={{ maxWidth: '90%', width: 'auto', maxHeight: '80vh' }}
      >
        <ModalHeader className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button"
            onClick={this.cancelReset} />
          <p className="bold-text  modal__title"> {isSelectedData ? `${isSelectedData.name}  Details` : ""} </p>
        </ModalHeader>
        <ModalBody className='p-2'>
          <form className="form " onSubmit={handleSubmit(this.submit)}>
            <div style={{ width: "100%" }}>
              <div className='mx-1 mt-3'><span ><b>Name :</b></span>{isSelectedData ? isSelectedData.name : ""}</div>
              <div className='mx-1 mt-3'><span><b>State :</b></span>{isSelectedData ? isSelectedData.state : ""}</div>

              <div className='row mx-1 mt-3' style={{ width: "100%" }}>
                <div className="col-12 modalTbody pl-0 pr-0" style={{ overflow: 'hidden', maxHeight: '500px', overflowY: 'auto' }}>
                  <Table>
                    <thead style={{ position: 'sticky', top: 0, background: '#3475aa' }}>
                      <tr>
                        <th className="text-white">S.No</th>
                        <th className="text-white">Product Name</th>
                        <th className="text-white" > Description</th>
                        <th className="text-white">Category Name</th>
                        <th className="text-white" style={{ textAlign: 'center', paddingRight: '1rem' }}>Dosage</th>
                        <th className="text-white">Inventory Type</th>
                        <th className="text-white" h>Location Type</th>
                        <th className="text-white" style={{ textAlign: 'center' }}>Required</th>
                      </tr>
                    </thead>
                    <tbody style={{ maxHeight: 80 }}>
                      {isSelectedData && isSelectedData.recommendedInventory && isSelectedData.recommendedInventory.length > 0 ?
                        isSelectedData.recommendedInventory.map((item, i) => {
                          return <tr>
                            <td > {item.productName ? i + 1 : ""}</td>
                            <td>{item.productName}</td>
                            <td> {item.description ? "-" + item.description : ""}</td>
                            <td>{item.categoryName ? item.categoryName : '-'}</td>
                            <td style={{ textAlign: 'center' }}>{item.dosage ? item.dosage : "-"}</td>
                            <td>{item.inventoryType ? item.inventoryType : '-'}</td>
                            <td>{item.locationType ? item.locationType.charAt(0).toUpperCase() + item.locationType.slice(1) : '-'}</td>
                            <td style={{ textAlign: 'center' }}>{item.required ? item.required : '-'}</td>

                          </tr>
                        }) : ""}
                    </tbody>

                  </Table>
                </div>
              </div>
              <div className='col-sm-12 text-center pt-3'>
                <div >
                  <Button outline color="primary" type='buttom' onClick={this.props.closeRecommendedModal}>Cancel</Button>

                  <Button className='d-none' color="primary" type='submit'>Submit</Button>
                </div>
              </div>
            </div>

          </form >
        </ModalBody >
      </Modal >
    );
  }
}

UserPasswordResetModal = reduxForm({
  form: 'User Password Reset Form', // a unique identifier for this form
})(UserPasswordResetModal);

export default withTranslation('common')(UserPasswordResetModal);
