import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
// import configImages from "./../../../config/configImages";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import { Redirect } from "react-router";
import PropTypes from "prop-types";

import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import { load as loadAccount } from "../../../redux/reducers/commonReducer";
import { loginUserInfo } from "../../../redux/actions/authActions";
import CompanySuccessModal from "../../Cruds/List/FacilityStaff/components/MailSentConfirmationModal";
// Validate input
import validate from "../../Validations/validate";

// Input field
import DefaultInput from "../../../shared/components/form/DefaultInput";
import DatePicker from "../../../shared/components/form/DatePicker";

// Config
import configMessage from "../../../config/configMessages";
import fetchRequest from "../../../config/service";

// Toaster message
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";

// Loader
import Loader from "../../App/Loader";
import apiCalls from "../../../config/apiCalls";
import moment from "moment";
// import OTPInput, { ResendOTP } from "otp-input-react";
import OtpInput from 'react-otp-input';


const required = (value) =>
  value || typeof value === "string" ? undefined : "Please Fill above field";
const required1 = (value) =>
  value || typeof value === "string" ? undefined : "Please Enter Your Password";

class OtpVerification extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmitDisabled: false,
      otp: null,
      emailSentTo: null,
      autoValidate: false,
      showError: false,
      isRegisterSuccess: false,
      isLoading: false,
      isEmailConfirmationModal: false,
    }
  }

  componentDidMount() {
    let email = localStorage.getItem("otpMail");
    this.setState({ emailSentTo: email })
    this.getSettings()
  }
  getSettings =() =>{
    return fetchRequest("GET", apiCalls.Settings)
        .then(async (response) => {
            if(response.settings && response['settings'].length>0){
              if(response.settings[0].paymentType =='paid'){
              this.setState({
                payment: true,
                amount: response.settings[0].paymentAmount
              })
            }
            }
        })
  }

  submit = async (values) => {
    if (this.state.otp == null || this.state.otp.length < 6) {
      await this.setState({
        showError: true
      })
    } else {

      this.handleRegisterCompany(values);

      await this.setState({
        showError: false
      })
    }

    this.setState({
      autoValidate: true
    });
  };

  resend = () => {
    this.setState({
      isLoading: true
    })
    var url = 'users/resendOtp?email=' + this.state.emailSentTo;
    return fetchRequest("GET", url)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 200) {
          this.setState({
            isLoading: false,
            isSubmitDisabled: false,
          });
          showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              isSubmitDisabled: false,
              isLoading: false,
            }),
              250;
          });
          showToasterMessage(response.errorMessage, "error");
        } else {
          this.setState({
            isSubmitDisabled: false,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitDisabled: false,
          isLoading: false,
        });
        console.log(err);
      });
  }

  handleRegisterCompany = async (values) => {
    this.setState({
      isLoading: true,
      isSubmitDisabled: true,
    });
    return fetchRequest("GET", apiCalls.OtpVerification + '?otp=' + this.state.otp)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 200) {
          localStorage.removeItem("otpMail")
          if(!this.state.payment){
          this.setState({
            isLoading: false,
            isSubmitDisabled: false,
            isEmailConfirmationModal: true,

          });
        }else if(this.state.payment){
          this.setState({
            redirectPayment:true
          })
        }
          // showToasterMessage(response.respMessage, "success");
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              isSubmitDisabled: false,
              isLoading: false,
            }),
              250;
          });
          showToasterMessage(response.errorMessage, "error");
        } else {
          this.setState({
            isSubmitDisabled: false,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isSubmitDisabled: false,
          isLoading: false,
        });
        console.log(err);
      });
  };


  handleChange = (otp) => {
    this.setState({ otp });
    if (otp.length < 6 && this.state.autoValidate) {
      this.setState({
        showError: true
      });
    }
    else if (otp.length == 6) {
      this.setState({
        showError: false
      });
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword, formFields } = this.state;

    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="fullWidth">
          <div className="row alignItemsCenter pb-3">Enter the code sent to &nbsp;<span className="letterBold">
            {this.state.emailSentTo}</span></div>
          <div className="row alignItemsCenter">
            <OtpInput
              value={this.state.otp}
              inputStyle={{
                width: '2.7rem',
                height: '2.7rem',
                margin: '10px 0.5rem',
                fontSize: '1rem',
                borderRadius: 4,
                border: '2px solid rgba(0,0,0,0.3)',
                textAlign: "center",
                display: "flex",
                justifyContent: "center"
              }}
              onChange={this.handleChange}
              numInputs={6}
              separator={<span>  </span>}
            />
          </div>
          {this.state.showError ?
            <div className="otpErrorStyle errorPadding">Please enter all fields</div> : null}
          <div className="row alignRight noOtpCodeStyle pr-5"> Didn't receive the code?
          <span className="resendColorStyle" onClick={this.resend} >&nbsp;Resend</span></div>

        </div>

        <div className="account__btns row alignItemsCenter mt-5 mb-5">
          <Button
            color="primary"
            disabled={this.state.isSubmitDisabled}
          >
            Submit
          </Button>
        </div>
        {this.state.isRegisterSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.redirectPayment ? <Redirect to={`/payments/${this.state.amount}`} /> : null}
        <CompanySuccessModal
          isEmailConfirmationModal={this.state.isEmailConfirmationModal}
          closeModal={(e) => {
            this.setState({
              isRegisterSuccess: true,
            });
          }}
          mailSentMessage={"Company created successfully."}
        />
      </form >
    );
  }
}

OtpVerification = reduxForm({
  form: "log_in_form", // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(OtpVerification);

// You have to connect() to any reducers that you wish to connect to yourself
OtpVerification = connect(
  (state) => ({
    initialValues: state.commonData.data, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(OtpVerification);

export default OtpVerification;
