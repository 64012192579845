import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';

import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      settingsInfo: {},
      companyName: null
    }
  }

  componentDidMount = () => {
    this.getSettingsInfo();
  }
  getSettingsInfo = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData && userData.companyName) {
      this.setState({
        companyName: userData.companyName,
      });
    }
  }


  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className='topbar__logo' to="/home" />
          </div>
          <div className="topbar__center" >{(this.state.companyName && this.state.companyName.charAt(0).toUpperCase() + this.state.companyName.slice(1))}</div>
          <div className="topbar__right">
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
