import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import RolePermissions from '../../../Permissions/Permissions'

export default class TicketsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount = () => {
        let screenPermissions = RolePermissions.screenPermissions('Facilities');
        this.setState({
            screenPermissions: screenPermissions
        })
    }
    getToolTip = (value) => {
        let v = "Street: " +
            value['address'].street +
            ", City: " +
            value['address'].city +
            ", State: " +
            value['address'].state +
            ", Zip: " +
            value['address'].zip
        return v
    }
    getTableFields = () => {
        const { t } = this.props;

        let data = [
            // {
            //     "show": true,
            //     "textAlign": 'center',
            //     "width": "50px",
            //     "selectionMode": 'multiple',
            //     "sortable": false,
            //     "filter": false,
            //     "mobile": false,
            // },
            {
                "show": true,
                "textAlign": "center",
                "width": "50px",
                "field": "Sno",
                "header_lang": "Sno",
                "header": "Sno",
                "sortable": false,
                "filter": false,
                "mobile": false,
                "fieldType": "Text",
            },
            {
                "show": false,
                "textAlign": "center",
                "width": "150px",
                "field": "companyName",
                "header": "Company Name",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "textCapitalize": true,
                "fieldStyle": "capitalize"
            },
            {
                "show": true,
                "textAlign": "center",
                "width": "150px",
                "field": "name",
                "header": "Facility Name",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Link",
                "textCapitalize": true,
                mandatory: true

            },
            {
                "show": true,
                "textAlign": "left",
                "width": "150px",
                "field": "email",
                "header": "Facility Email",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Email",
            },
            {
                "show": false,
                "textAlign": "left",
                "width": "160px",
                "field": "address1",
                "header": "Address",
                "toolTip": this.getToolTip,
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "toolTip",
            },
            {
                "show": true,
                "textAlign": "center",
                "width": "160px",
                "field": "createdByName",
                "header": "Created By",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Text",
                "fieldStyle": "capitalize"
            },
            // {
            //     "show": true,
            //     "textAlign": "center",
            //     "width": "100px",
            //     "field": "status",
            //     "header_lang": "status",
            //     "header": "Status",
            //     "sortable": true,
            //     "filter": false,
            //     "mobile": true,
            //     "fieldType": "Pill",
            //     "options": config.tablefieldStatusTypes,
            // },
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": "100px",
            //     "field": "Actions",
            //     "header_lang": "Actions",
            //     "header_def": "Actions",
            //     "sortable": false,
            //     "filter": false,
            //     "mobile": false,
            //     "fieldType": "Actions",
            // },
        ];
        return data;
    };

    // getFormFields = () => {
    //     let statusTypes = [
    //         { label: 'Pending', value: 'Pending' },
    //         { label: 'Active', value: 'Active' },
    //         { label: 'InActive', value: 'InActive' },
    //         { label: 'Dismissed', value: 'Dismissed' },
    //     ];
    //     return ([

    //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
    //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
    //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
    //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
    //         {
    //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
    //             options: statusTypes, placeholder: 'Select Status'
    //         },
    //     ]);
    // }

    getFormFields = () => {
        return ([
            {
                "value": "",
                "name": "name",
                "label": "Facility Name",
                "id": "name",
                "placeholder": "Facility Name",
                "type": "text",
                "required": true,
                "mandatory": true,

            },
            {
                "value": "",
                "name": "email",
                "label": "Facility Email",
                "id": "email",
                "placeholder": "Facility Email",
                "type": "text",
                "required": true,
                "mandatory": true,

            },

            // {
            //     "value": "",
            //     "name": "address.street",
            //     "label": "Street",
            //     "id": "street",
            //     "placeholder": "Street",
            //     "type": "text",
            //     "required": true
            // },
            // {
            //     "value": "",
            //     "name": "address.city",
            //     "label": "City",
            //     "id": "city",
            //     "placeholder": "City",
            //     "type": "text",
            //     "required": true
            // },
            // {
            //     "value": "",
            //     "name": "address.state",
            //     "label": "State",
            //     "id": "state",
            //     "placeholder": "State",
            //     "type": "text",
            //     "normalize": false,
            //     "required": true
            // },
            // {
            //     "value": "",
            //     "name": "address.zip",
            //     "label": "Zip",
            //     "id": "zip",
            //     "placeholder": "Zip",
            //     "type": "text",
            //     "normalize": false,
            //     "required": true
            // },
            // {
            //     "value": "",
            //     "name": "status",
            //     "label": "Status",
            //     "id": "status",
            //     "placeholder": "Status",
            //     "type": "dropDown",
            //     "options": config.statusTypes,
            //     "required": true
            // },

        ]);
    }

    viewFields = () => {
        let data = {
            "displayName": "",
            "email": "",
            "phoneNumber": "",
            "address": "",
            "city": "",
            "state": "",
            "createdByName": "",
            "created": "",
            "role": "",
            "status": "",
        }
        return data;
    };

    render() {
        const { t } = this.props;
        return (
            <DataTables
                getTableFields={this.getTableFields}
                viewFields={this.viewFields}
                formFields={this.getFormFields}
                type="Facilities"
                apiUrl={api.Clinics}
                apiResponseKey='clinics'
                tableTitle={"Facilities"}
                formTitle={"Facilities"}
                detailsTitle={"Facility"}
                globalSearch={`Company Name / Facility Name / Email`}
                globalSearchRequired={true}
                addRequried={this.state.screenPermissions === 'edit' ? true : false}
                editRequired={this.state.screenPermissions === 'edit' ? true : false}
                deleteRequired={this.state.screenPermissions === 'edit' ? true : false}
                viewRequired={true}
                settingsRequired={true}
                exportToCsvRequired={false}
                bulkUploadRequired={false}
                multipleDeleteRequired={false}
                actionsTypes={[{
                    'name': 'Delete',
                    "options": [
                        { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
                    ]
                }]}
                displayViewOfForm='modal'
                routeTo
            // multipleDeleteApi='/multipleUpdate?type=Delete'
            // multipleDeleteMethod='POST'
            // multipleDeleteBodyKey='clients'
            />
        );
    }
}