import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RecallItems from './components/ItemsRecalled';

const RecallTable = ({ t }) => (
  <Container>
    <RecallItems />
  </Container>
);

RecallTable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(RecallTable);
