
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetch from "../../../../../config/service";
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage';
import RolePermissions from '../../../Permissions/Permissions'

class SupplyCabinets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount = () => {
    let screenPermissions = RolePermissions.screenPermissions('Storage Unit');
    this.setState({
      screenPermissions: screenPermissions
    })
    console.log(screenPermissions)
  }
  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": 'center',
        width: 45,
        selectionMode: 'multiple',
        field: 'New',
        filter: false,
        sortable: false,
        placeholder: 'Search'
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "companyName",
        "mobile": true,
        "header": "Company Name",
        "filter": false,
        "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "cart",
        "mobile": true,
        "header": "Cart",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "clinicName",
        "mobile": true,
        "header": "Facility Name",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "address",
        "mobile": true,
        "header": "Address",
        "filter": false,
        "sortable": true,
      },
      //   {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 180,
      //     "field": "status",
      //     "mobile": true,
      //     "header": "Status",
      //     "filter": false,
      //     "sortable": true,
      //   },

      //   {
      //     "show": true,
      //     "textAlign": "center",
      //     "width": 140,
      //     "field": "role",
      //     "mobile": true,
      //     "header": "Role",
      //     "filter": false,
      //     "sortable": true,
      //     "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //     "fieldType": 'Role',
      //   },


      {
        "show": true,
        textAlign: 'center',
        width: 80,
        field: 'status',
        "mobile": true,
        fieldType: 'Badge',
        header: 'Status',
        filter: false,
        sortable: false,
        options: [{ "label": "Active", "value": 'Active', 'color': 'success' }, { "label": "Pending", "value": 'Pending', 'color': 'danger' }, { "label": "Inactive", "value": 'Inactive', 'color': 'warning' }],
      },

    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {
    let days = [
      { label: 'Sunday', value: 'Sunday' },
      { label: 'Monday', value: 'Monday' },
      { label: 'Tuesday', value: 'Tuesday' },
      { label: 'Wednesday', value: 'Wednesday' },
      { label: 'Thursday', value: 'Thursday' },
      { label: 'Friday', value: 'Friday' },
      { label: 'Saturday', value: 'Saturday' }
    ]
    return ([
      {
        value: '',
        type: 'autoComplete',
        searchApi: api.Clinics,
        searchField: 'name',
        name: 'clinics',
        // filterValue: 'cart',
        // filterField: "cart",
        label: 'Facility',
        show: true,
        id: 'Facility',
        options: [{
          'clinicId': '_id'
        }],
        placeholder: 'Facility'
      },
      {
        "value": "",
        "name": "cart",
        "label": "Supply Cabinet Name",
        "id": "Supply Cabinet Name",
        "placeholder": "Supply Cabinet Name",
        "type": "text",
        "required": true
      },
      {
        "value": "",
        "name": "cartLocation.building",
        "label": "Building",
        "id": "Building",
        "placeholder": "Building",
        "type": "text",
        "required": true
      }, {
        "value": "",
        "name": "cartLocation.area",
        "label": "Area",
        "id": "Area",
        "placeholder": "Area",
        "type": "text",
        "required": true
      }, {
        "value": "",
        "name": "cartLocation.floor",
        "label": "Floor",
        "id": "Floor",
        "placeholder": "Floor",
        "type": "text",
        "required": true
      }, {
        "value": "",
        "name": "cartLocation.room",
        "label": "Room",
        "id": "Room",
        "placeholder": "Room",
        "type": "text",
        "required": true
      },
      {
        "value": "",
        "name": "cartCheckDay",
        "label": "Weekly Notified On",
        "id": "email",
        "placeholder": "Weekly Notified On",
        "type": "dropDown",
        "options": days,
        "required": true
      },
      {
        "value": "",
        "name": "noofDrawers",
        "label": "No.of Drawers",
        "id": "noofDrawers",
        "placeholder": "No.of Drawers",
        "type": "text",
        "required": true
      },

    ]);
  }

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        formFields={this.getFormFields}
        addRequried={this.state.screenPermissions === 'edit' ? true : false}
        editRequired={this.state.screenPermissions === 'edit' ? true : false}
        deleteRequired={true}
        viewRequired={true}
        settingsRequired={true}
        globalSearch={'Company / Cart / Facility Name'}
        type="Supply Cabinets"
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        apiResponseKey='locations'
        filterExtension={'storageType=storage'}
        apiUrl={api.CartLocations}
        displayViewOfForm='modal'
        routeTo
      />
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(SupplyCabinets)