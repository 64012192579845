import React from "react";
import FormModal from "../../Form/FormModal";
import apiCalls from "../../../config/apiCalls";
import fetchMethodRequest from "../../../config/service";

// config file
export default class AddCalendarEventModal1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useTypes: [],
      categoryTypes: [],
    };
  }

  componentDidMount = () => {
    // debugger
    console.log("OrderModal", this.props)
    const currentURL = window.location.href;
    const indexOfLocationId = currentURL.indexOf("locationIdFromExpiredItems=");
    if (indexOfLocationId !== -1) {
      let locationId = currentURL.substring(indexOfLocationId + "locationIdFromExpiredItems=".length);
      this.setState({
        locationId: locationId,
      });
      console.log("location id in ordermodal 26", this.state.locationId)
    }
    if (this.props.orderClinicName) {
      return fetchMethodRequest("GET", apiCalls.Clinics).then(
        async (response) => {
          if (response && response.clinics) {
            if (response.clinics) {
              for (let clinic of response.clinics) {
                if (clinic.name == this.props.orderClinicName) {
                  this.setState({
                    orderClinicId: clinic._id,
                  });
                }
              }
            }
          }
        }
      );
    }
  };

  getFormFields = async () => {
    return [
      {
        required: true,
        value: "",
        type: "DefaultInput",
        name: "productName",
        label: "Product Name",
        disable: true,
        mandatory: true,
        required: true,


      },
      {
        value: "",
        type: "text",
        name: "description",
        label: "Description",
        id: "required",
        placeholder: "Description",
        disable: true,
        mandatory: true,
        required: true,


      },
      {
        value: "",
        type: "text",
        name: "volume",
        label: "Volume",
        id: "required",
        placeholder: "Volume",
        disable: true,
        required: true,
        mandatory: true

      },
      // {
      //   value: "",
      //   type: "autoComplete",
      //   searchApi: apiCalls.ClinicStaff,s
      //   searchField: "email",
      //   name: "distributorEmail",
      //   label: "Distributor Email",
      //   id: "email",
      //   placeholder: "Search Email",
      // },
      {
        value: "",
        type: "text",
        name: "required",
        label: "Need To Order",
        id: "required",
        placeholder: "Need To Order",
        required: true,
        mandatory: true
      },
    ];
  };

  render() {
    return this.props.openOrderModal && (
      <FormModal
        openFormModal={this.props.openOrderModal}
        closeFormModal={this.props.closeOrderModal}
        updateCartCount={this.props.updateOrderWishlist}
        openFormInventoryModal={this.props.openFormInventoryModal}
        locationId={this.props.locationId}
        productId={this.props.productId}
        type={"Order"}
        modalType={"takeOrder"}
        formType={"Order"}
        formFields={this.getFormFields}
        orderProductName={this.props.product}
        orderTitle={this.props.title}
        ordercompanyName={this.props.ordercompanyName}
        orderFrom={this.props.orderFrom}
        orderCart={this.props.orderCart}
        orderClinic={this.props.orderClinic}
        orderClinicId={this.state.orderClinicId}
        volume={this.props.volume}
        orderDescription={this.props.orderDescription}
        facilityName={this.props.facilityName}
        orderConcentration={this.props.orderConcentration}
        orderNeeded={this.props.orderNeeded}
        orderProductId={this.props.orderProductId}
        orderlocationId={this.props.orderlocationId}
        orderClinicName={this.props.orderClinicName}
        companyName={this.props.companyName}
        updateProductRequired={this.props.updateProductRequired}
        apiUrl='wishlist/createOrderList'
      />
    );

  }
}
