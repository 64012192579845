// import React from 'react';
// import TableRender from '../../../CommonDataTable/TableRender';
// import RolePermissions from "../../../Permissions/Permissions";
// import fetchMethodRequest from '../../../../../config/service';
// import apiCalls from '../../../../../config/apiCalls';



// export default class CategoyTable extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         };
//     }
//     componentDidMount = async () => {
//         return fetchMethodRequest('GET', apiCalls.Settings)
//             .then(async (response) => {
//                 if (response && response.settings && response.settings[0]) {
//                     if (response.settings[0]) {
//                         let categoryTypes = this.getCategoryDrodownValues(response.settings[0]);
//                         let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
//                         this.setState({
//                             categoryTypes: categoryTypes,
//                             useTypes: useTypes,

//                         });
//                     }
//                 }
//             });
//     }

//     getCategoryDrodownValues = (settingInfo) => {
//         if (settingInfo && settingInfo.categoryType) {
//             let categoryTypes = settingInfo.categoryType;
//             let modifiedCategoryTypes = [];
//             for (let category of categoryTypes) {
//                 modifiedCategoryTypes.push({
//                     label: category,
//                     value: category
//                 })
//             }
//             return modifiedCategoryTypes;
//         }
//     }

//     getUseTypeDrodownValues = (settingInfo) => {
//         if (settingInfo && settingInfo.useType) {
//             let useTypes = settingInfo.useType;
//             let modifiedUseTypes = [];
//             for (let category of useTypes) {
//                 modifiedUseTypes.push({
//                     label: category,
//                     value: category
//                 })
//             }
//             return modifiedUseTypes;
//         }
//     }

//     getTableFields = () => {
//         let statusTypes = [
//             { label: 'All', value: null },
//             { label: 'Active', value: 'Active' },
//             { label: 'InActive', value: 'InActive' },
//             { label: 'Pending', value: 'Pending' }
//         ];

//         let data = [

//             { textAlign: 'left', width: 135, field: 'categoryName', header: 'Category Name', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 135, field: 'categoryType', header: 'Category Type', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'left', width: 135, field: 'useType', header: 'Use Type', filter: true, sortable: true, placeholder: 'Search' },
//             { textAlign: 'center', width: 105, field: 'status', header: 'Status ', filterElement: statusTypes, filter: true, sortable: false },
//             // { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
//         ];
//         let screenPermissions = RolePermissions.screenPermissions('Categories');
//         if (screenPermissions && screenPermissions != 'edit') {
//             data.pop();
//         }
//         return data;
//     };


//     getFormFields = async () => {
//         return ([
//             {
//                 required: true, value: '', type: 'text', name: 'categoryName', label: 'Category Name',
//                 id: 'categoryName', placeholder: 'Category Name'
//             },
//             {
//                 required: true, value: '', type: 'dropDown', name: 'categoryType', label: 'Select Category Type',
//                 id: 'categoryType', placeholder: 'Category Type', options: this.state.categoryTypes
//             },
//             {
//                 required: true, value: '', type: 'dropDown', name: 'useType', label: 'Select Use Type',
//                 id: 'useType', placeholder: 'Use Type', options: this.state.useTypes
//             }

//         ]);
//     }
//     render() {

//         return (
//             <div>
//                 <TableRender type='Categories'
//                     globalSearch='Category Name/Type/Use Type'
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields} {...this.props}
//                 />
//             </div>
//         );
//     }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import fetchMethodRequest from "../../../../../config/service";
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount = async () => {
        let screenPermissions = RolePermissions.screenPermissions('Categories');
        this.setState({
            screenPermissions: screenPermissions
        })
        return fetchMethodRequest('GET', api.Settings)
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    if (response.settings[0]) {
                        let categoryTypes = this.getCategoryDrodownValues(response.settings[0]);
                        let useTypes = this.getUseTypeDrodownValues(response.settings[0]);
                        this.setState({
                            categoryTypes: categoryTypes,
                            useTypes: useTypes,

                        });
                    }
                }
            });
    }

    getCategoryDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.categoryType) {
            let categoryTypes = settingInfo.categoryType;
            let modifiedCategoryTypes = [];
            for (let category of categoryTypes) {
                modifiedCategoryTypes.push({
                    label: category,
                    value: category
                })
            }
            return modifiedCategoryTypes;
        }
    }

    getUseTypeDrodownValues = (settingInfo) => {
        if (settingInfo && settingInfo.useType) {
            let useTypes = settingInfo.useType;
            let modifiedUseTypes = [];
            for (let category of useTypes) {
                modifiedUseTypes.push({
                    label: category,
                    value: category
                })
            }
            return modifiedUseTypes;
        }
    }
    getTableFields = () => {
        const { t } = this.props;

        let data = [
            // {
            //     "show": true,
            //     "textAlign": 'center',
            //     "width": "40px",
            //     "selectionMode": 'multiple',
            //     "sortable": false,
            //     "filter": false,
            //     "mobile": false,
            // },
            {
                "show": true,
                "textAlign": "center",
                "width": "40px",
                "field": "Sno",
                "header_lang": "Sno",
                "header": "Sno",
                "sortable": false,
                "filter": false,
                "mobile": false,
                "fieldType": "Text",
            },
            {
                "show": true,
                "textAlign": "left",
                "width": "150px",
                "field": "categoryName",
                "header_lang": "Context",
                "header": "Category Name",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Link",
                "textCapitalize": true,
            },
            {
                "show": true,
                "textAlign": "center",
                "width": "150px",
                "field": "categoryType",
                "header_lang": "Context Type",
                "header": "Category Type",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Link",
                "textCapitalize": true,
            },
            {
                "show": true,
                "textAlign": "center",
                "width": "150px",
                "field": "useType",
                "header_lang": "Use Type",
                "header": "Use Type",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Email",
            },
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": "160px",
            //     "field": "created",
            //     "header_lang": "Created",
            //     "header_def": "Created",
            //     "sortable": true,
            //     "filter": false,
            //     "mobile": true,
            //     "fieldType": "Text",
            // },
            {
                "show": true,
                "textAlign": "center",
                "width": "100px",
                "field": "status",
                "header_lang": "status",
                "header": "Status",
                "sortable": true,
                "filter": false,
                "mobile": true,
                "fieldType": "Pill",
                "options": config.tablefieldStatusTypes,
            },
            // {
            //     "show": true,
            //     "textAlign": "left",
            //     "width": "100px",
            //     "field": "Actions",
            //     "header_lang": "Actions",
            //     "header": "Actions",
            //     "sortable": false,
            //     "filter": false,
            //     "mobile": false,
            //     "fieldType": "Actions",
            // },
        ];
        return data;
    };
    // getFormFields = () => {
    //     let statusTypes = [
    //         { label: 'Pending', value: 'Pending' },
    //         { label: 'Active', value: 'Active' },
    //         { label: 'InActive', value: 'InActive' },
    //         { label: 'Dismissed', value: 'Dismissed' },
    //     ];
    //     return ([

    //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
    //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
    //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
    //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
    //         {
    //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
    //             options: statusTypes, placeholder: 'Select Status'
    //         },
    //     ]);
    // }

    getFormFields = () => {
        return ([
            {
                "value": "",
                "name": "categoryName",
                "label": "Category Name",
                "id": "categoryName",
                "placeholder": "Catgeory Name",
                "type": "text",
                "required": true,
                mandatory: true

            },
            {
                "value": "",
                "name": "categoryType",
                "label": "Category Type",
                "id": "Category Type",
                "placeholder": "Category Type",
                "type": "dropDown",
                "required": true,
                options: this.state.categoryTypes,
                mandatory: true

            },

            {
                "value": "",
                "name": "useType",
                "label": "Use Type",
                "id": "useType",
                "placeholder": "Use Type",
                "type": "dropDown",
                "required": true,
                options: this.state.useTypes,
                mandatory: true


            },
            // {
            //     "value": "",
            //     "name": "phone",
            //     "label": "Phone Number",
            //     "id": "phoneNumber",
            //     "placeholder": "Phone Number",
            //     "type": "text",
            //     "normalize": false,
            //     "required": true
            // },
            // {
            //     "value": "",
            //     "name": "status",
            //     "label": "Status",
            //     "id": "status",
            //     "placeholder": "Status",
            //     "type": "dropDown",
            //     "options": config.statusTypes,
            //     "required": true
            // },

        ]);
    }

    viewFields = () => {
        let data = {
            "displayName": "",
            "email": "",
            "phoneNumber": "",
            "address": "",
            "city": "",
            "state": "",
            "createdByName": "",
            "created": "",
            "role": "",
            "status": "",
        }
        return data;
    };

    render() {
        const { t } = this.props;
        return (
            <DataTables
                getTableFields={this.getTableFields}
                viewFields={this.viewFields}
                formFields={this.getFormFields}
                type="Categories"
                apiUrl={api.Categories}
                apiResponseKey='categories'
                tableTitle={"Categories"}
                formTitle={"Categories"}
                detailsTitle={"Categories"}
                globalSearch={`Category Name /Type / Use Type`}
                globalSearchRequired={true}
                addRequried={this.state.screenPermissions === 'edit' ? true : false}
                editRequired={false}
                deleteRequired={false}
                viewRequired={true}
                settingsRequired={true}
                exportToCsvRequired={false}
                bulkUploadRequired={false}
                multipleDeleteRequired={false}
                actionsTypes={[{
                    'name': 'Delete',
                    "options": [
                        { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
                    ]
                }]}
                displayViewOfForm='modal'
                routeTo
            // multipleDeleteApi='/multipleUpdate?type=Delete'
            // multipleDeleteMethod='POST'
            // multipleDeleteBodyKey='clients'
            />
        );
    }
}

export default withTranslation('common')(Employees)
