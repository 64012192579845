import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
// import configImages from "./../../../config/configImages";
import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
// import validate from "../Validations/validate";

import renderCheckBoxField from "./../../../shared/components/form/CheckBox";
import { load as loadAccount } from "./../../../redux/reducers/commonReducer";
import { loginUserInfo } from "./../../../redux/actions/authActions";
import CompanySuccessModal from "./../../../containers/Cruds/List/FacilityStaff/components/MailSentConfirmationModal";
// Validate input
import validate from "../../Form/validate";

// Input field
import DefaultInput from "./../../../shared/components/form/DefaultInput";
import DatePicker from "./../../../shared/components/form/DatePicker";
import AutoComplete from "./../../../containers/Form/components/AutoComplete";
import Select from "./../../../shared/components/form/Select";
// Config
import configMessage from "./../../../config/configMessages";
import fetchRequest from "./../../../config/service";
// Toaster message
import showToasterMessage from "./../../UI/ToasterMessage/toasterMessage";
const options = require('../../../shared/components/states/options.json')
import configMessages from "./../../../config/configMessages";
import config from "../../../config/config";
// Loader
import Loader from "./../../App/Loader";
import apiCalls from "./../../../config/apiCalls";
import moment from "moment";
const required = (value) =>
  value || typeof value === "string" ? undefined : "Please Fill above field";
const required1 = (value) =>
  value || typeof value === "string" ? undefined : "Please Enter Your Password";
let passval = value => (value ? config.passwordRegex.test(value) ? undefined : configMessage.passwordValidation : configMessage.fillField)
const numberrequired = (value) =>
  value || typeof value === 'number' ? undefined : 'please fill valid number'


const normalizenumber = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  return `${onlyNums}`;
};

class CompanyRegistrationForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      isLoginDisabled: false,
      isLoading: false,
      loginRememberInfo: null,
      captchaValue: null,
      isRobot: false,
      isRegisterSuccess: false,
      isLoginSuccess: false,
      isLoading: false,
      isLoginDisabled: true,
      termsAccepted: false,
      isEmailConfirmationModal: false,
      labels: [],

      formFields: [
        {
          required: true,
          value: "",
          type: "text",
          name: "name",
          label: "Company Name",
          id: "companyName",
          placeholder: "Company Name",
          mandatory: true
        },
        {
          required: true,
          value: "",
          type: "input",
          name: "email",
          label: "Company Email",
          id: "email",
          placeholder: "email@yopmail.com",
          mandatory: true

        },
        {
          required: true,
          value: "",
          type: "text",
          name: "contatctPersonName",
          label: "Contact Person Name",
          id: "contatctPersonName",
          placeholder: "Contact Person Name",
        },
        {
          value: "",
          type: "input",
          name: "contactPersonEmail",
          label: "Contact Person Email",
          id: "Contact Person Email",
          placeholder: "email@yopmail.com",
          mandatory: false

        },
        {
          value: "",
          type: "input",
          name: "contactPersonPhone",
          label: "Contact Person Phone Number",
          id: "email",
          placeholder: "Contact Person Phone",
          mandatory: false
        },
        // {
        //   value: "",
        //   type: "input",
        //   name: "address",
        //   label: "Company Address",
        //   id: "address",
        //   placeholder: "Address",
        //   mandatory: false

        // },
        // {
        //   value: "",
        //   type: "input",
        //   name: "city",
        //   label: "City",
        //   id: "city",
        //   placeholder: "City",
        //   placeholder: 'City',
        //   mandatory: false,
        // },
        {
          value: "",
          type: "dropdown",
          name: "state",
          label: "State",
          id: "state",
          placeholder: "State",
          placeholder: 'State',
          mandatory: false,
          options: options,
        },
        // {
        //   value: "",
        //   type: "input",
        //   name: "zip",
        //   label: "Zip",
        //   id: "zip",
        //   placeholder: "Zip",
        //   placeholder: 'Zip',
        //   mandatory: false,
        // },
        {
          value: "",
          type: "input",
          name: "maxCarts",
          label: "Maximum Number of carts",
          id: "maxCarts",
          placeholder: "Maximum Number of carts",
          mandatory: false

        },
        {
          required: true,
          value: "",
          type: "password",
          name: "newPassword",
          label: "Create Password",
          id: "newPassword",
          placeholder: "New Password",
          mandatory: true,
          passwordValidation: true

        },
        {
          required: true,
          value: "",
          type: "password",
          name: "confirmPassword",
          label: "Confirm Password",
          id: "confirmPassword",
          placeholder: "Confirm Password",
          mandatory: true,
          passwordValidation: true
        },
        // {
        //   value: "",
        //   type: "dropdown",
        //   name: "selectPlanType",
        //   label: "Select Plan Type",
        //   id: "selectPlanType",
        //   placeholder: "Select",
        //   mandatory: false,
        //   // options: options
        // },





        // {
        //   value: "",
        //   type: "input",
        //   name: "state",
        //   label: "State",
        //   id: "state",
        //   placeholder: "State",
        //   mandatory: false

        // },


        // {
        //   value: "",
        //   type: "input",
        //   name: "paymentAmount",
        //   label: "Payment Amount",
        //   id: "amount",
        //   placeholder: "Amount",
        //   disabled: true,
        //   mandatory: false

        // },
      ],
    };

  }
  componentDidMount = async () => {
    // this.getPlanTypeData()
    this.props.load({})
    this.getAmount()

  }

  getAmount = async () => {
    return fetchRequest("GET", apiCalls.Settings)
      .then(async (response) => {
        if (response.settings && response['settings'].length > 0) {
          if (response.settings[0].paymentAmount) {
            await this.setState({
              amount: response.settings[0].paymentAmount
            })
            this.props.change('paymentAmount', response.settings[0].paymentAmount)
          }
          const paymentLabel = response.settings[0].paymentTypes;
          paymentLabel.forEach(item => {
            item.label = (
              <div>
                <span className="d-flex">
                  {`${item.label}`}
                </span>
                {/* <span>{"Cancel anytime if not satisfied"}</span> */}
              </div>

            );
            item.value = "";
          });
          await this.setState({
            labels: paymentLabel
          });
        }
      })
  }

  showPassword = async (e) => {
    e.preventDefault();
    await this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  //link to open the terms and condition 

  openTermsConditions = () => {
    window.open('https://inmedtory.com/terms/', '_blank');
  };
  //link to open the privacy and policy 

  openPrivacyPolicy = () => {
    window.open('https://inmedtory.com/privacy-policy/', '_blank');
  };

  //handleling the check box status 
  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isCheckboxChecked: !prevState.isCheckboxChecked,
      isLoginDisabled: prevState.isCheckboxChecked,
    }));
  };






  showCfPassword = async (e) => {
    e.preventDefault();
    await this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  submit = (values) => {
    //setting the trialperiod duration as two months one day
    values.trialPeriod = moment().add(2, 'months').add(1, 'day').toDate();
    this.handleRegisterCompany(values);


  };

  //login after the New Company is created
  handleLoginUser = async (values) => {
    console.log(values)
    let userBody = {
      email: values.email,
      password: values.newPassword,
      entityType: "user",
      loginSite: "cch",
    }
    console.log(userBody)
    localStorage.setItem("loginBody", JSON.stringify(userBody));
    return fetchRequest("POST", apiCalls.loginUser, userBody)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 200) {
          this.setState({
            isLoading: true,
          });
          let userDetails = response.details;
          if (userDetails && userDetails._id) {
            if (response.accessToken) {
              let tokenInfo = {
                accessToken: response.accessToken,
                refreshToken: response.refreshToken,
                tokenExpires: response.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              // save user credentials in storage
              localStorage.setItem(
                "loginCredentials",
                JSON.stringify(userDetails)
              );
            }
            if (userDetails && userDetails.rolePermissions) {
              // save user permissions in storage
              localStorage.setItem(
                "rolePermissions",
                JSON.stringify(userDetails.rolePermissions)
              );
            }
          }
          showToasterMessage(response.respMessage, "success");
          await this.setState({ isLoginSuccess: true });
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              //isLoginDisabled: false,
              isLoading: false,
            }),
              250;
          });
          showToasterMessage(response.errorMessage, "error");
        } else {
          this.setState({
            //isLoginDisabled: false,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          //isLoginDisabled: false,
          isLoading: false,
        });
        console.log(err);
      });
  }

  //getPlanType Data 
  //For Register the New Company
  // getPlanTypeData = async () => {
  //   const response = await fetchRequest("GET", apiCalls.Settings);

  //   if (response) {
  //     console.log("response>>>>>>>>>>>>>>>>>>>.....", response)
  //     const paymentLabel = response.settings[0].paymentTypes[0].label;
  //     this.setState({
  //       labels: paymentLabel
  //     });
  //     // return paymentLabel;
  //     console.log("Label set to:", this.state.labels);
  //   }
  // };

  //For Register the New Company
  handleRegisterCompany = async (values) => {
    this.setState({
      isLoading: true,
    });
    return fetchRequest("POST", apiCalls.Companies, values)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 204) {
          localStorage.setItem("otpMail", values.email)
          this.setState({
            isLoading: false,
            isEmailConfirmationModal: true,
            isRegisterSuccess: true,
          });
          showToasterMessage(response.respMessage, "success");
          this.handleLoginUser(values)
        } else if (response && response.errorMessage) {
          setTimeout(() => {
            this.setState({
              //   isLoginDisabled: false,
              isLoading: false,
            }),
              250;
          });
          showToasterMessage(response.errorMessage, "error");
        } else {
          this.setState({
            isLoginDisabled: false,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoginDisabled: false,
          isLoading: false,
        });
        console.log(err);
      });
  };

  onChange = (value) => {
    if (value == "remember" && this.state.remember_me) {
      this.setState({
        remember_me: false,
      });
    }
  };

  getActiveClass(item) {
    const { showPassword, showConfirmPassword } = this.state;
    if (showPassword && (item && item.name === 'newPassword')) {
      return 'active';
    } else {
      if (showConfirmPassword && (item && item.name != 'newPassword')) {
        return 'active';
      }
    }
    return false;
  }

  render() {
    const { handleSubmit } = this.props;
    const { showPassword, showConfirmPassword, formFields } = this.state;

    return (
      <form className="form" autocomplete="off" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div>
          <div className="row">
            {formFields.map((item, index) => {
              console.log("item", item, "index", index)
              if (index < 5) {
                return (
                  item.type !== 'dropdown' ? (
                    <div className="col-sm-6" key={index}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {item.label}
                          {item.mandatory ?
                            <span className="mandatoryStyle">&nbsp;*</span> : null}</span>
                        <div className="form__form-group-field">
                          <Field
                            name={item.name}
                            component={
                              item.type === "date" ? DatePicker : item.type === "autoComplete" ? AutoComplete : DefaultInput
                            }
                            type={item.type}
                            isDisable={item.disabled}
                            placeholder={item.placeholder}
                            validate={item.required ? required : ""}
                            normalize={item.name === 'phone' || item.name === 'contactPersonPhone' ? normalizenumber : undefined}
                            screen="register"
                          />
                          {item.type === "date" ? (
                            <div className="iconstyle form__form-group-icon">
                              <CalendarBlankIcon />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-sm-6" key={index}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {item.label}
                        </span>
                        <div className="form__form-group-field registerselect">
                          <Field
                            name={"state"}
                            placeholder="State"
                            component={Select}
                            options={item.options}
                            screen={this.props.type}
                          />
                        </div>
                      </div>
                    </div>
                  )
                );
              }
              console.log("index >= 9 && item && item.name === 'newPassword'", index, item.name == 'newPassword')
              if (index >= 5 && item && item.name === 'newPassword') {
                return (
                  <div className="col-sm-6">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {item.label}
                        {item.mandatory ?
                          <span className="mandatoryStyle">&nbsp;*</span> : null}</span>
                      <div className="form__form-group-field">
                        <Field
                          name={item.name}
                          component={
                            DefaultInput
                          }
                          // type={item.type}
                          type={`${(item.name === 'newPassword' && showPassword) ? "text" : "password"}`}
                          isDisable={item.disabled}
                          placeholder={item.placeholder}
                          // validate={item.required ? required : ""}
                          screen="register"
                          showHintMessage={config.register.newUserpasswordValidationMessage}
                          hintMesssage={item.name === 'newPassword' ? true : false}
                          passwordValidation={item.passwordValidation ? true : false}
                        />
                        {item.type === "password" ? (
                          <button
                            className={`form__form-group-button ${this.getActiveClass(item)}`}
                            onClick={(e) => this.showPassword(e)}
                          >
                            <EyeIcon />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>

                );
              } else {
                if (index >= 5 && item) {
                  return (
                    item.type !== 'dropdown' ? (

                      <div className="col-sm-6">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {item.label}
                            {item.mandatory ?
                              <span className="mandatoryStyle">&nbsp;*</span> : null}</span>
                          <div className="form__form-group-field">
                            <Field
                              name={item.name}
                              component={
                                DefaultInput
                              }
                              type={`${((item.name === 'confirmPassword' && showConfirmPassword) || item.type === 'input') ? "text" : "password"}`}
                              isDisable={item.disabled}
                              placeholder={item.placeholder}
                              // validate={item.required ? required : ""}
                              screen="register"
                            // showHintMessage={config.register1.conformPasswordError}
                            // hintMesssage={item.name === 'confirmPassword' ? true : false}
                            // passwordValidation={item.passwordValidation ? true : false}
                            />
                            {item.type === "password" ? (
                              <button
                                className={`form__form-group-button ${this.getActiveClass(item)}`}
                                onClick={(e) => this.showCfPassword(e)}
                              >
                                <EyeIcon />
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-sm-6" key={index}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {item.label}
                          </span>
                          <div className="form__form-group-field registerselect">
                            <Field
                              name={item.name}
                              placeholder={item.placeholder}
                              component={Select}
                              options={item.name === 'selectPlanType' ? this.state.labels.map(item => ({ value: item.value, label: item.label })) : item.options}
                              screen={this.props.type}
                            />


                          </div>
                        </div>
                      </div>
                    )
                  );
                }
              }
            })}
          </div>
        </div>

        <div className="form__form-group-field" style={{ marginRight: '1rem' }}>
          <input
            type="checkbox"
            style={{
              marginTop: '2px',
              marginRight: '8px',
              height: '20px',
              width: '20px',
            }}
            onChange={this.handleCheckboxChange}

          />
          <div style={{ paddingTop: '4px' }}>
            <label>
              I have read and accepted the{' '}
              <span onClick={this.openTermsConditions} className="custom-link">
                Terms & Conditions{' '}
              </span>
              and{' '}
              <span onClick={this.openPrivacyPolicy} className="custom-link">
                Privacy Policy{' '}
              </span>
            </label>
          </div>
        </div>
        <div className="account__btns" style={{ display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
          <div>
            <p>
              Back to <Link to="/log_in">Login</Link>
            </p>
          </div>
          <div>
            <Button
              className="ml-auto"
              color="primary"
              disabled={this.state.isLoginDisabled}
              type="submit"
            >
              Register
            </Button>
          </div>
        </div>


        {/* Navigate to Home */}
        {this.state.isLoginSuccess && this.state.isRegisterSuccess ? <Redirect to="/home" /> : null}
        {/* <CompanySuccessModal
          isEmailConfirmationModal={this.state.isEmailConfirmationModal}
          closeModal={(e) => {
            this.setState({
              isRegisterSuccess: true,
            });
          }}
          mailSentMessage={"Company created successfully."}
        /> */}
      </form>
    );
  }
}

CompanyRegistrationForm = reduxForm({
  form: "company_registration_form", // a unique identifier for this form
  validate,
  enableReinitialize: false,
})(CompanyRegistrationForm);

// You have to connect() to any reducers that you wish to connect to yourself
CompanyRegistrationForm = connect(
  (state) => ({
    initialValues: {}, // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(CompanyRegistrationForm);

export default CompanyRegistrationForm;
