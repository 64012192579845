
import React from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ThumbUpOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import AddInventoryModal from '../Cruds/Modals/AddInventoryModal'
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apiCalls';
import CountDisplay from './components/CountDisplay';
import RolePermissions from '../Cruds/Permissions/Permissions';
// Loader
import showToasterMessage from '../UI/ToasterMessage/toasterMessage'
import Loader from '../App/Loader';
import FormModal from '../Form/FormModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      isLoading: false,
      expirationsList: [],
      isMissingItem: false,
      selectedLocationIdOrCart: null,
      storageType: null,
      isBatteryCheck: false,
      isRecall: false,
      checkItems: false,
      isExpirationItems: false,
      isTrackItems: false,
      openInventoryModal: false,
      openInventoryChecks: false,
      noAdd: false,
      navigateList: ['isBatteryCheck', 'isRecall', 'checkItems', 'isExpirationItems', 'isTrackItems', 'isMissingItem']
    };
  }

  componentDidMount = async () => {
    await this.getExpirationsList();
    await this.getCountData();
    await this.getFacilitiesList();
    let screenPermissions = RolePermissions.screenPermissions('Storage Unit Items');
    if (screenPermissions && screenPermissions != 'edit') {
      await this.setState({
        noAdd: true
      })
    }
  }

  // Get dashboard counts
  getCountData() {
    this.setState({ isLoading: true });
    fetchMethodRequest('GET', apiCalls.DashboardCounts)
      .then(async (response) => {
        if (response && response.details) {
          this.setState({
            dashboardData: response.details
          });
        }
        this.setState({ isLoading: false });
      }).catch((err) => {
        return err;
      });
  }


  // Get Last 30 days expirations list
  getExpirationsList = async () => {
    this.setState({ isLoading: true });
    fetchMethodRequest('GET', apiCalls.DashboardInventoryFlags)
      .then(async (response) => {
        if (response) {
          await this.setState({
            tableData: response
          })
        }
        this.setState({
          isLoading: false,
        });
      }).catch((err) => {
        return err;
      });
  }
  getFacilitiesList = async () => {
    fetchMethodRequest('GET', apiCalls.CartLocations)
      .then(async (response) => {
        if (response && response.locations) {
          await this.setState({
            facilityData: response.locations
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  openInventoryModal = async (cart, id) => {
    await this.setState({
      openInventoryModal: true,
      inventoryCartName: cart,
      CartId: id
    })
  }

  closeInventoryModal = async (type) => {
    if (type == 'save') {
      await this.getExpirationsList();
    }
    this.setState({
      openInventoryModal: false
    })
  }

  openInventoryChecks = async (cart, storageType) => {

    this.setState({
      ChecksCart: cart,
      openInventoryChecks: true,
      locationType: storageType
    })
  }

  handleSelectedItemForCartLocation = (name, locationIdOrCart, project, clinicName, cartName) => {
    if (locationIdOrCart) {
      for (let item of this.state.navigateList) {
        if (item == name) {
          this.setState({
            [item]: true,
            selectedLocationIdOrCart: locationIdOrCart,
            storageType: project != null && project.storageType != null ? project.storageType : ''
          })
        } else {
          this.setState({
            [item]: false
          })
        }
      }
    }
    if (clinicName) {
      this.setState({
        clinicName: clinicName
      })
    }
    if (cartName) {
      this.setState({
        cartName: cartName
      })
    }
  }

  render() {
    return (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />
        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{ marginBottom: 20, color: '#007ad9' }}>Home</h3>
          </Col>
        </Row>

        {/* Counts */}
        <CountDisplay
          dashboardData={this.state.dashboardData} />

        {/* Expirations Table */}
        <Row>
          <Col lg={12} xl={12} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Inventory Status</h5>
                </div>
                <Table className="table--bordered table--head-accent" hover responsive>
                  <thead className='inventory-check-thead'>
                    <tr>
                      <th className='table_column_width1' style={{ textAlign: "left", paddingLeft: "40px" }}>Facility</th>
                      <th className='table_column_width1' style={{ textAlign: "left" }}>Storage Unit</th>
                      <th className='table_column_width2'>Expiration</th>
                      <th className='table_column_width2'>Missing Items</th>
                      <th className='table_column_width2'>Recalls</th>
                      <th className='table_column_width2'>Weekly / Monthly Checks</th>
                      <th className='table_column_width2'>Battery Check</th>
                      <th className='table_column_width2'>Track</th>
                      {!this.state.noAdd ?
                        <th className='table_column_width2'>Add</th>
                        : null}
                    </tr>
                  </thead>
                  {this.state.tableData && this.state.tableData.length > 0 ?
                    <tbody className='inventory-check-tbody'
                      style={{ maxHeight: 'calc(100vh - 450px)' }}
                    >

                      {this.state.tableData.map((project, i) => {
                        console.log("clinicName", project)
                        return <tr key={i}>
                          {this.state.facilityData ? this.state.facilityData.map((event, index) => event.cart == project.cart ?
                            <td className='table_column_width1 dashboardLocationName' style={{ textAlign: "left", paddingLeft: "40px" }} key={index}>
                              <span color="info" className="alert--colored" />{event.clinicName}
                            </td> : null) :
                            <td className='table_column_width1 dashboardLocationName'>
                              <span color="info" className="alert--colored" />{null}
                            </td>
                          }
                          <td className='table_column_width1 dashboardLocationName storageTypeCount_hover' style={{ color: "#3475aa", fontWeight: 'bold', textAlign: "left" }}
                            onClick={() => this.openInventoryChecks(project.cart, project.storageType)}>
                            <span>{project.cart}</span>
                          </td>
                          {project.expired ?
                            <td className='table_column_width2 dashboardSuccessIconColor'><ThumbUpOutlineIcon color='green' /></td>
                            :
                            <td className='table_column_width2 dashboardWarningColor'
                              onClick={() => this.handleSelectedItemForCartLocation('isExpirationItems', project.locationId, "", project.facilityName, project.cart)}>
                              <span style={{ marginTop: 30 }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', fontSize: '15', marginTop: 10 }} />
                              </span>
                            </td>
                          }
                          {project.missingItems ?
                            <td className='table_column_width2 dashboardSuccessIconColor'><ThumbUpOutlineIcon color='green' /></td>
                            :
                            <td className='table_column_width2 dashboardWarningColor'
                              onClick={() => this.handleSelectedItemForCartLocation('isMissingItem', project.locationId, "", project.facilityName, project.cart)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', fontSize: '15', marginTop: 10 }} />
                            </td>
                          }
                          {project.recall ?
                            <td className='table_column_width2 dashboardSuccessIconColor'><ThumbUpOutlineIcon color='green' /></td>
                            :
                            <td className='table_column_width2 dashboardWarningColor'
                              onClick={() => this.handleSelectedItemForCartLocation('isRecall', project.cart)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', fontSize: '15', marginTop: 10 }} />
                            </td>
                          }

                          {project.check ?
                            <td className='table_column_width2 dashboardSuccessIconColor'><ThumbUpOutlineIcon color='green' /></td>
                            :
                            <td className='table_column_width2 dashboardWarningColor'
                              onClick={() => this.handleSelectedItemForCartLocation('checkItems', project.cart)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', fontSize: '15', marginTop: 10 }} />
                            </td>
                          }
                          {project.batteryCheck ?
                            <td className='table_column_width2 dashboardSuccessIconColor'><ThumbUpOutlineIcon color='green' /></td>
                            :
                            <td className='table_column_width2 dashboardWarningColor'
                              onClick={() => this.handleSelectedItemForCartLocation('isBatteryCheck', project.cart)}>
                              <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: 'red', fontSize: '15', marginTop: 10 }} />
                            </td>
                          }
                          <td className='table_column_width2 dashboardWarningColor'
                            onClick={() => this.handleSelectedItemForCartLocation('isTrackItems', project.locationId)}
                          >
                            <Button className="ml-4 mb-0" color="primary" outline>Track</Button>
                          </td>
                          {!this.state.noAdd ?
                            <td className='table_column_width2 dashboardWarningColor'
                              onClick={() => this.openInventoryModal(project.cart, project.locationId)}>
                              <Button className="ml-4 mb-0" color="primary" outline >Add</Button>
                            </td>
                            : null}

                        </tr>
                      })}
                    </tbody>
                    : null}
                </Table>
              </CardBody>
            </Card>
          </Col>

          {/* Missing Items */}
          {this.state.isMissingItem ?
            <Redirect to={`/missingItemsTable?locationId=${this.state.selectedLocationIdOrCart}&clinicName=${this.state.clinicName}&cartName=${this.state.cartName}`} />
            : null}

          {/* Battery Checks */}
          {this.state.isBatteryCheck ?
            <Redirect to={`/batterychecks?cart=${this.state.selectedLocationIdOrCart}`} />
            : null}

          {/* Recalls */}
          {this.state.isRecall ?
            <Redirect to={`/recall?cart=${this.state.selectedLocationIdOrCart}`} />
            : null}

          {/* Weekly/Monthly checks */}
          {this.state.checkItems ?
            <Redirect to={`/checks?cart=${this.state.selectedLocationIdOrCart}`} />
            : null}

          {/* Track Items */}
          {this.state.isTrackItems ?
            <Redirect to={`/track?locationId=${this.state.selectedLocationIdOrCart}`} />
            : null}

          {/* Expiration Items */}
          {this.state.isExpirationItems ?
            <Redirect to={`/expirationdatesTable?locationId=${this.state.selectedLocationIdOrCart}&clinicName=${this.state.clinicName}&cartName=${this.state.cartName}`} />
            : null}
          {
            this.state.openInventoryModal ?
              <div>
                <FormModal cart={this.state.inventoryCartName}
                />
                <AddInventoryModal
                  onRef={(ref) => (this.confirmRef = ref)}
                  openInventoryModal={this.state.openInventoryModal}
                  closeInventoryModal={this.closeInventoryModal}
                  cart={this.state.inventoryCartName}
                  cartId={this.state.CartId}
                />
              </div>
              : null
          }
          {
            this.state.openInventoryChecks ?
              <Redirect to={{
                pathname: `/inventorychecklist/${this.state.ChecksCart}`,
                state: {
                  locationType: this.state.locationType,
                  redirect: true
                }
              }} />
              : null
          }
        </Row>
      </Container>
    );
  }
}

export default withTranslation('common')(Dashboard);