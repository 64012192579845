
// import React from 'react';
// import RolePermissions from '../../../Permissions/Permissions';

// // Table 
// import TableRender from '../../../CommonDataTable/TableRender';
// import configImages from '../../../../../config/configImages'
// import apiCalls from '../../../../../config/apiCalls';
// import fetchMethodRequest from '../../../../../config/service'

// export default class Quantities extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             clinics: [],
//             products: [],
//             locations: [],
//             inventoryTypes: []
//         };
//     }
//     componentDidMount = () => {
//         this.getClinicDropDownValues();
//         this.getProductNames();
//         this.getInventoryDropDownValues();
//     }



//     getFormFields = () => {
//         return [];
//     }

//     getClinicDropDownValues = () => {
//         let ls = JSON.parse(localStorage.loginCredentials)
//         if (ls.clinics && ls.clinics.length > 0) {
//             let modifiedClinics = []
//             ls.clinics.map((item, i) => {
//                 modifiedClinics.push({
//                     label: item.name,
//                     value: item.clinicId
//                 })
//             })
//             this.setState({
//                 clinics: modifiedClinics
//             })
//         } else {
//             return fetchMethodRequest('GET', apiCalls.Clinics)
//                 .then(async (response) => {
//                     if (response && response.clinics && response.clinics[0]) {
//                         if (response.clinics[0].name) {
//                             let clinics = response.clinics;
//                             let modifiedClinicNames = [];
//                             for (let clinic of clinics) {

//                                 modifiedClinicNames.push({
//                                     label: clinic.name,
//                                     value: clinic._id
//                                 })
//                             }

//                             this.setState({
//                                 clinics: modifiedClinicNames
//                             })
//                         }
//                     }
//                 });
//         }
//     }

//     getProductNames = () => {
//         return fetchMethodRequest('GET', apiCalls.Products)
//             .then(async (response) => {
//                 if (response && response.products && response.products[0]) {
//                     let products = response.products;
//                     let modifiedClinicNames = [];
//                     for (let product of products) {

//                         modifiedClinicNames.push({
//                             label: product.title1 ? product.title1 : product.title,
//                             value: product.title1 ? product.title1 : product.title
//                         })
//                     }

//                     this.setState({
//                         products: modifiedClinicNames
//                     })
//                 }
//             });
//     }

//     getInventoryDropDownValues = () => {
//         return fetchMethodRequest('GET', apiCalls.Settings)
//             .then(async (response) => {
//                 if (response && response.settings && response.settings[0]) {
//                     this.setState({
//                         inventoryTypes: response.settings[0].inventoryTypes
//                     })
//                 }
//             });
//     }

//     render() {
//         return (
//             <div>
//                 <TableRender
//                     {...this.props}
//                     type='Storage Unit Items'
//                     globalSearch='Lot Id/Product Name/Sub Location'
//                     tableFields={this.getTableFields}
//                     formFields={this.getFormFields}
//                     clinicvalues={this.state.clinics}
//                     sample={configImages.quantitySampleXL}
//                     bulkApi='quantity'
//                     addType={'Quantity'}
//                     inventoryTypes={this.state.inventoryTypes}
//                 />
//             </div>
//         );
//     }
// }
import React from "react";
import DataTables from "../../../CommonDataTable/DataTableNew";

// config file
import config from "../../../../../config/config";
import api from "../../../../../config/apiCalls";
import { withTranslation } from "react-i18next";
import RolePermissions from '../../../Permissions/Permissions'

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copyCartFields: [
        "clinicId",
        "locationId",
        "cartSubLocation",
        "description",
        "lotId",
        "expirationDate",
        "cartName",
        "addedInventory",
        "locationType",
        "isAlternateProduct",
        "alternateProductId",
        "productId",
        "productName",
        "alternateProductName",
        "alternateDescription"]
    }
  }
  componentDidMount = () => {
    let screenPermissions = RolePermissions.screenPermissions('Storage Unit Items');
    this.setState({
      screenPermissions: screenPermissions
    })
  }
  //     getTableFields = () => {
  //         let data = [
  //             { textAlign: 'left', width: 135, field: 'productName', header: 'Product Name', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'center', width: 135, field: 'description', header: 'Description', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'center', width: 105, field: 'volume', header: 'Volume', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 135, field: 'expirationDate', header: 'Expiration Date', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 135, field: 'clinicName', header: 'Facilities', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 135, field: 'cartName', header: 'Storage Unit', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'left', width: 135, field: 'cartSubLocation', header: 'Sub Location', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAling: 'center', width: 90, field: 'qrcode', header: 'QR Code' },
  //             { textAlign: 'center', width: 135, field: 'lotId', header: 'Lot Id', filter: true, sortable: true, placeholder: 'Search' },
  //             { textAlign: 'center', width: 90, field: 'Actions', header: 'Actions', filter: false, sortable: false },
  //         ];
  //         let screenPermissions = RolePermissions.screenPermissions('Storage Unit Items');
  //         if (screenPermissions && screenPermissions != 'edit') {
  //             data.pop();
  //         }
  //         return data;
  //     };
  getTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 200,
        "field": "productName",
        "mobile": true,
        "header": "Product Name",
        // "fieldType": 'Link',
        "filter": false,
        // "fieldType": 'Link',
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 120,
        "field": "description",
        "mobile": true,
        "header": "Description",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": false,
        "textAlign": "center",
        "width": 100,
        "field": "volume",
        "mobile": true,
        "header": "Volume",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 150,
        "field": "expirationDate",
        "mobile": true,
        "header": "Expiration Date",
        "filter": false,
        "fieldType": "Date",
        // type:'date',
        'dateFormat': 'DD-mm-YYYY',
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 160,
        "field": "clinicName",
        "mobile": true,
        "header": "Facilities",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      // { "show": true, textAlign: 'center', width: 100, field: 'isCopyExistingRec', header: 'isCopyExistingRec' },

      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "cartName",
        "mobile": true,
        "header": "Storage Unit",
        "filter": false,
        "sortable": true,
        "fieldStyle": "capitalize"
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 180,
        "field": "cartSubLocation",
        "mobile": true,
        "header": "Sub Location",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "field": "lotId",
        "mobile": true,
        "header": "Lot Id",
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        // fieldType: 'isCopyExistingRec',
        "field": "isCopyExistingRec",
        "header": 'Actions',
        "filter": false,
        "sortable": false,
      },


    ]
    return data;
  };
  // getFormFields = () => {
  //     let statusTypes = [
  //         { label: 'Pending', value: 'Pending' },
  //         { label: 'Active', value: 'Active' },
  //         { label: 'InActive', value: 'InActive' },
  //         { label: 'Dismissed', value: 'Dismissed' },
  //     ];
  //     return ([

  //         { required: true, value: '', name: 'firstName', label: 'POC First Name', id: 'firstname', placeholder: 'First Name', },
  //         { required: true, value: '', name: 'lastName', label: 'POC Last Name', id: 'lastname', placeholder: 'Last Name' },
  //         { required: true, value: '', type: 'input', name: 'email', label: 'POC Email', id: 'email', placeholder: 'email@gmail.com', placeholder: 'email@gmail.com' },
  //         { required: true, value: '', type: 'text', name: 'phone', label: 'Phone', id: 'phone', placeholder: 'Phone Number' },
  //         {
  //             required: true, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status',
  //             options: statusTypes, placeholder: 'Select Status'
  //         },
  //     ]);
  // }

  getFormFields = () => {
    return ([
      // {
      //     "value": "",
      //     "name": "productName",
      //     "label": "Products",
      //     "id": "productName",
      //     "placeholder":"Product Name",
      //     "type": "autoComplete",
      //     filterValue: 'title1',
      //     filterField: "title1",
      //     "searchApi":"products/productList",
      //     "searchField":"title1",
      //     "required": true
      // },
      {
        value: '',
        type: 'autoComplete',
        searchApi: `products/productList`,
        searchField: 'title1',
        name: 'productName',
        filterValue: 'title1',
        filterField: "title1",
        searchApi: 'products',
        label: 'Product Name',
        id: 'products',
        "required": true,
        mandatory: true,
        dependent: [
          { description: "description" },
        ],
        options: [{
          'productId': '_id'

        }],
        placeholder: 'Product Name'
      },
      {
        value: '',
        show: false,
        type: 'autoComplete',
        searchField: 'description',
        call: 'POST',
        name: 'description',
        filterValue: 'description',
        filterField: "description",
        searchApi: 'products/descriptionList',
        label: 'Description',
        id: 'products',
        mandatory: true,
        "required": true,
        options: [{
          'description': 'description'

        }],
        dependent: [
          { volume: "volume" },
        ],
        placeholder: 'Description'
      },

      {
        "value": "",
        "name": "volume",
        "label": "Volume",
        "id": "volume",
        "placeholder": "volume",
        "type": "text",
        mandatory: true,
        "normalize": false,
        "disable": true,
        "show": false
      },

      // {
      //     value: '',
      //     type: 'autoComplete',
      //     searchApi: `${apiCalls.User}`,
      //     searchField: 'name',
      //     name: 'user_name',
      //     filterValue: 'name',
      //     filterField: "name",
      //     label: 'User Name',
      //     id: 'name',
      //     options: [{
      //       'user_email': 'email'
      //     }, {
      //       'user_name': 'name'
      //     }],
      //     placeholder: 'User Name'
      //   },
      {
        "value": "",
        "name": "lotId",
        "label": "Lot Id",
        "id": "lotId",
        "placeholder": "Lot Id",
        "type": "text",
        "required": true,
        mandatory: true

      },

      {
        "value": "",
        "name": "expirationDate",
        "label": "Expiration Date",
        "id": "date",
        "placeholder": "Expiration Date",
        "type": "date",
        "required": true,
        mandatory: true

      },
      // {
      //   value: '',
      //   type: 'autoComplete',
      //   searchApi: 'clinics',
      //   searchField: 'name',
      //   name: 'clinicId',
      //   filterValue: 'name',
      //   filterField: "name",
      //   label: 'Facility',
      //   id: 'clinic',
      //   dependent: [
      //     { locationId: "locationId" },
      //   ],
      //   options: [{
      //     'clinicId': '_id'

      //   }],
      //   placeholder: 'Facility Name'
      // },

      {
        "value": "",
        "name": "clinicName",
        "label": "Facility",
        "id": "clinic",
        "placeholder": "Facility Name",
        "type": "text",
        "normalize": false,
        "disable": true,
        "show": true,
        "required": true,
        mandatory: true


      },
      // {
      //   value: '',
      //   type: 'autoComplete',
      //   searchApi: 'locations',
      //   searchField: 'cart',
      //   name: 'locationId',
      //   filterValue: 'cart',
      //   options: [{
      //     'cartName': 'cart',
      //   },
      //   { 'locationType': 'storageType' },
      //   { 'locationId': '_id' }],
      //   filterField: "cart",
      //   label: 'Storage Unit',
      //   show: false,
      //   id: 'Storage Unit',
      //   dependent: [
      //     { cartSubLocation: "cartSubLocation" },
      //   ],

      //   placeholder: 'Storage Unit'
      // },

      {
        "value": "",
        "name": "cartName",
        "label": "Storage Unit",
        "id": "Storage Unit",
        "placeholder": "Storage Unit",
        "type": "text",
        "normalize": false,
        "disable": true,
        "show": true,
        "required": true,
        mandatory: true


      },

      {
        value: '',
        type: 'autoComplete',
        searchApi: 'locations',
        searchField: 'cartParts',
        name: 'cartSubLocation',
        filterValue: 'cartParts',
        filterField: "cartParts",
        label: 'Cart SubLocations',
        show: true,
        "required": true,

        options: [{
          'cartSubLocation': 'cartParts'

        }],
        id: 'subLocations',
        placeholder: 'Search Cart SubLocation',
        mandatory: true

      },
      // name={"cartSubLocation"}
      // component={AutoComplete}
      // id={"subLocations"}
      // searchApi={`locations`}
      // apiUrl={"locations"}
      // screen={this.props.type}
      // searchField={"cartParts"}
      // call={"GET"}
      // cartName={this.state.cartName}
      // handleAutoCompleteData={
      //   this.handleAutoCompleteData
      // }
      // placeholder="Search Cart SubLocation"
      // validate={required}
      // {
      //   "value": "",
      //   "name": "count2",
      //   "label": "Current Inventory",
      //   "id": "count2",
      //   "placeholder": "Currents Inventory",
      //   "type": "text",
      //   "normalize": false,
      //   "disable": true,
      // },
      // {
      //   "value": "",
      //   "name": "limit",
      //   "label": "Required Inventory",
      //   "id": "limit",
      //   isAddFormHidden: true,
      //   isEditFormHidden: false,
      //   "placeholder": "Required",
      //   "type": "text",
      //   "disable": true,
      //   "required": true
      // },
      {
        "value": "",
        "name": "addedInventory",
        "label": "Inventory Being Added",
        "id": "addedInventory",
        isAddFormHidden: false,
        isEditFormHidden: true,
        "placeholder": "Inventory Being Added",
        "type": "text",
        "required": true,
        mandatory: true

      },
      // {
      //   "value": "",
      //   "name": "isAlternateProduct",
      //   "label": "Alternate Product",
      //   "id": "addedInventory",
      //   "placeholder": "Inventory Being Added",
      //   dependent: [
      //     { alternateProductName: "alternateProductName" },
      //   ],
      //   "type": "checkbox",
      //   "required": false
      // },
      // {
      //   value: '',
      //   show: false,
      //   type: 'autoComplete',
      //   searchApi: `products/productList`,
      //   searchField: 'title1',
      //   name: 'alternateProductName',
      //   filterValue: 'title1',
      //   filterField: "title1",
      //   searchApi: 'products',
      //   label: 'Alternate Product Name',
      //   id: 'products',
      //   dependent: [
      //     { alternateDescription: "alternateDescription" },
      //   ],
      //   options: [{
      //     'alternateProductId': '_id'

      //   }],
      //   placeholder: 'Product Name'
      // },
      // {
      //   value: '',
      //   show: false,
      //   type: 'autoComplete',
      //   searchField: 'description',
      //   call: 'POST',
      //   name: 'alternateDescription',
      //   filterValue: 'description',
      //   filterField: "description",
      //   searchApi: 'products/descriptionList',
      //   label: 'Description',
      //   id: 'products',
      //   options: [{
      //     'alternateDescription': 'description'

      //   }],
      //   placeholder: 'Description'
      // },
    ]);
  }

  viewFields = () => {
    let data = {
      "displayName": "",
      "email": "",
      "phoneNumber": "",
      "address": "",
      "city": "",
      "state": "",
      "createdByName": "",
      "created": "",
      "role": "",
      "status": "",
    }
    return data;
  };

  render() {
    const { t } = this.props;
    return (
      <DataTables
        getTableFields={this.getTableFields}
        copyCartFields={this.state.copyCartFields}
        formFields={this.getFormFields}
        addRequried={this.state.screenPermissions === 'edit' ? true : false}
        editRequired={this.state.screenPermissions === 'edit' ? true : false}
        deleteRequired={this.state.screenPermissions === 'edit' ? true : false}
        viewRequired={true}
        hasQueryInCall={true}
        settingsRequired={true}
        globalSearch={'Product Name / Description / Volume'}
        type="Storage Unit Items"
        apiResponseKey='quantities'
        apiUrl={api.Quantities}
        getEditInfoKeys={[{ 'label': 'productName', 'value': 'productName' }, { 'label': 'clinicId', 'value': 'clinicName' }, { 'label': 'locationId', 'value': 'cartName' }]}
        actionsTypes={[{
          'name': 'Delete',
          "options": [
            { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
          ]
        }]}
        displayViewOfForm='modal'
        routeTo='hi'
      />
      //     productName={this.state.productName}
      // clinicName={this.state.clinicId}
      // cartName={this.state.locationId}
      // <DataTables
      //     getTableFields={this.getTableFields}
      //     viewFields={this.viewFields}
      //     formFields={this.getFormFields}
      //     type="Distributors"
      //     apiUrl={api.Distributors}
      //     apiResponseKey='distributors'
      //     tableTitle={"Distributors"}
      //     formTitle={"Distributors"}
      //     detailsTitle={"Distributor"}
      //     globalSearch={`${t("email")} / ${t("phoneNumber")} / ${t("displayName")}`}
      //     globalSearchRequired={true}
      //     addRequried={true}
      //     editRequired={true}
      //     deleteRequired={true}
      //     viewRequired={true}
      //     settingsRequired={true}
      //     exportToCsvRequired={false}
      //     bulkUploadRequired={false}
      //     multipleDeleteRequired={false}
      //     // multipleDeleteApi='/multipleUpdate?type=Delete'
      //     // multipleDeleteMethod='POST'
      //     // multipleDeleteBodyKey='clients'
      // />
    );
  }
}

export default withTranslation('common')(Employees)
