import React from "react";
import TableRender from "../../../CommonDataTable/TableRender";
import RolePermissions from '../../../Permissions/Permissions';

// config
import apiCalls from "../../../../../config/apiCalls";
import fetchMethodRequest from "../../../../../config/service";

// config file
export default class StorageUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartSublocationsList: [],
      clinicNames: [],
      days: [
        { label: 'Sunday', value: 'Sunday' },
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' }
      ],
      statusTypes: [
        { label: 'All', value: null },
        { label: 'Pending', value: 'Pending' },
        { label: 'Active', value: 'Active' },
        { label: 'InActive', value: 'InActive' },
      ],
      tabType: 'Cart Locations'
    };
  }

  componentDidMount = () => {
    this.getClinicNamesValues();
    fetchMethodRequest('GET', 'activities/track');
  }

  getTableFields = async (type) => {
    if (type) {
      this.setState({
        tabType: type
      })
    }
    let data = [];
    if (type == 'Supply Cabinets') {
      data = this.getCabinetTableFields();
    } else if (type == 'OR Suites') {
      data = this.getOperatingRoomTableFields();
    } else {
      data = this.getCartLocationTableFields();
    }

    let screenPermissions = RolePermissions.screenPermissions('Storage Unit');
    if (screenPermissions && screenPermissions != 'edit') {
      data.pop();
    }
    return [...data];
  };

  getCartLocationTableFields = () => {
    let data = [
      { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 120, field: 'clinicName', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search', fieldStyle: "capitalize" },
      { textAlign: 'left', width: 100, field: 'cart', header: 'Cart', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 120, field: 'cartLocation', header: 'Address', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 60, field: 'status', header: 'Status ', filterElement: this.state.statusTypes, filter: true, sortable: false },
      { textAlign: 'center', width: 60, field: 'file', header: 'Files' },
      { textAlign: 'left', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    return data;
  }

  getCabinetTableFields = () => {
    let data = [
      { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 100, field: 'cart', header: 'Supply Cabinet', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 120, field: 'clinicName', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 120, field: 'cartLocation', header: 'Address', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 60, field: 'status', header: 'Status ', filterElement: this.state.statusTypes, filter: true, sortable: false },
      { textAlign: 'center', width: 60, field: 'file', header: 'Files' },
      { textAlign: 'left', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    return data;
  }

  getOperatingRoomTableFields = () => {
    let data = [
      { textAlign: 'left', width: 100, field: 'companyName', header: 'Company', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 100, field: 'cart', header: 'OR Suite', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 100, field: 'surgeryType', header: 'Surgery Type', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 120, field: 'clinicName', header: 'Facility Name', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'left', width: 120, field: 'cartLocation', header: 'Address', filter: true, sortable: true, placeholder: 'Search' },
      { textAlign: 'center', width: 60, field: 'status', header: 'Status ', filterElement: this.state.statusTypes, filter: true, sortable: false },
      { textAlign: 'center', width: 60, field: 'file', header: 'Files' },
      { textAlign: 'left', width: 60, field: 'Actions', header: 'Actions', filter: false, sortable: false },
    ];
    return data;
  }

  getFormFields = (type) => {
    let fields = [];
    if (type == 'Supply Cabinets') {
      fields = this.getCabinetFormFields();
    } else if (type == 'OR Suites') {
      fields = this.getOperatingRoomFormFields();
    } else {
      fields = this.getCartLocationFormFields();
    }
    return fields;
  }

  getCartLocationFormFields = () => {
    let options = [
      { label: 'Required', value: 'required' },
      { label: 'Recommended', value: 'recommended' },

    ]
    return ([
      {
        required: true, value: '', type: 'autoComplete', searchApi: apiCalls.Clinics, searchField: 'name',
        name: 'clinics', label: 'Facility', id: 'clinic', placeholder: 'Search Facility Name', mandatory: true
      },
      { required: true, value: '', type: 'text', name: 'cart', label: 'Cart Name', id: 'Cart', placeholder: 'Cart Name', mandatory: true },

      // { value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building' },
      // { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
      // { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },

      // { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
      {
        required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On', id: 'Day',
        placeholder: 'Select Day', options: this.state.days, mandatory: true
      },
      {
        required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many drawers you want to choose?',
        id: 'noofDrawers', placeholder: 'Enter No.of Drawers', mandatory: true
      },
    ]);
    // {
    //   required: true, value: '', type: 'radio', name: 'selectedInventoryType', label: 'Inventory Type',
    //   id: 'selectedInventoryType', placeholder: 'Inventory Type', options: options
    // },
  }

  getCabinetFormFields = () => {
    return ([
      {
        required: true, value: '', type: 'autoComplete', searchApi: apiCalls.Clinics, searchField: 'name',
        name: 'clinics', label: 'Facility', id: 'clinic', placeholder: 'Search Facility Name', mandatory: true
      },
      { required: true, value: '', type: 'text', name: 'cart', label: 'Supply Cabinet Name', id: 'Supply Cabinet', placeholder: 'Supply Cabinet Name', mandatory: true },

      // {
      //   value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building', show: false
      // },
      // { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
      // { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },

      // { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
      {
        required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On', id: 'Day',
        placeholder: 'Select Day', options: this.state.days, mandatory: true
      },
      {
        required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many shelves you want to choose?',
        id: 'noofDrawers', placeholder: 'Enter No.of Shelves', mandatory: true
      },
    ]);
  }

  getOperatingRoomFormFields = () => {
    return ([
      {
        required: true, value: '', type: 'autoComplete', searchApi: apiCalls.Clinics, searchField: 'name',
        name: 'clinics', label: 'Facility', id: 'clinic', placeholder: 'Search Facility Name', mandatory: true
      },
      { required: true, value: '', type: 'text', name: 'cart', label: 'OR Suite Name', id: 'operatingRoom', placeholder: 'OR Suite Name', mandatory: true },
      { required: true, value: '', type: 'text', name: 'surgeryType', label: 'Surgery Type', id: 'surgery', placeholder: 'Surgery Type', mandatory: true },
      // { value: '', type: 'text', name: 'building', label: 'Building', id: 'Building', placeholder: 'Building' },
      // { required: true, value: '', type: 'text', name: 'area', label: 'Area', id: 'Area', placeholder: 'Area' },
      // { value: '', type: 'text', name: 'floor', label: 'Floor', id: 'Floor', placeholder: 'Floor Number' },

      // { value: '', type: 'text', name: 'room', label: 'Room', id: 'Room', placeholder: 'Room' },
      {
        required: true, value: '', type: 'dropDown', name: 'cartCheckDay', label: 'Weekly Notified On', id: 'Day',
        placeholder: 'Select Day', options: this.state.days, mandatory: true
      },
      {
        required: true, value: '', type: 'number', name: 'noofDrawers', label: 'How many storage units you want to choose?',
        id: 'noofDrawers', placeholder: 'Enter No.of Storage Units', mandatory: true
      },
    ]);
  }

  getClinicNamesValues = async () => {
    return fetchMethodRequest('GET', apiCalls.Clinics)
      .then(async (response) => {

        if (response && response.clinics && response.clinics[0]) {
          if (response.clinics[0].name) {
            let clinicNames = response.clinics;
            let modifiedClinicNames = [];
            for (let clinic of clinicNames) {
              modifiedClinicNames.push({
                label: clinic.name,
                value: clinic._id
              })
            }
            this.setState({
              clinicNames: modifiedClinicNames
            });
          }
        }
      });
  }

  render() {
    return (
      <div>
        <TableRender
          type='Storage Unit'
          globalSearch={this.state.tabType == 'Cart Locations' ? 'Cart Name/Facility Name' :
            this.state.tabType == 'Supply Cabinets' ? 'Supply Cabinet Name/Facility Name' :
              'OR Suite/Surgery Type/Facility Name'}
          tableFields={this.getTableFields}
          formFields={this.getFormFields}
          tabType={this.state.tabType}
        />
      </div>
    );
  }
}
