
const apiCalls = {
    // Login
    loginUser: 'auth/login',
    loginChangePassword: 'auth/loginChangePassword',
    loginCheck: 'auth/loginCheck',

    ChangeRecoveryPassword: 'auth/changeRecoverPassword?active=true',
    ChangePassword: 'auth/changePassword',
    ForgotPassword: 'auth/forgotPassword',
    OtpVerification: 'users/verifyOtp',
    wishlist: 'api/wishlist/getWishByLocationId',

    MissingItemsDashboard: 'quantities/missingCount',
    BatteryCheckDashboard: 'dashboard/batteryCheck',
    ExpirationDatesDashboard: 'dashboard/expiredQuantities',
    RecallsDashboard: 'dashboard/recalls',
    TrackDashboard: 'dashboard/track',
    WeeklyCheckDashboard: 'dashboard/checks',
    DashboardCounts: 'dashboard/counts',
    DashboardInventoryFlags: 'dashboard/inventoryFlags',

    SuperUsers: 'users',
    Products: 'products',
    ProductsList: 'products/productList',
    Settings: 'settings',
    Categories: 'categories',
    MenuList: 'menus',
    MissingItemsSidebar: 'quantities/missingItems',
    Track: 'activities/track',
    DashBoardExpiredItems: 'dashboard/expiredQuantities',

    //Tables
    Staff: 'employees',
    Distributors: 'distributors',
    Companies: 'companies',
    ActiveCompanies: 'companies/companiesList',
    CartLocations: 'locations',
    Quantities: 'quantities',
    ClinicStaff: 'distributors',
    RequiredInventory: 'requiredInventory',


    // Uploads: 'uploads'
    removeWishList: '/wishlist/removeWishProduct',
    orderWishlist: '/orders',
    LocationImagePath: 'uploads?uploadWhileCreate=true&uploadPath=location',
    Roles: 'staffRoles',
    Clinics: 'clinics',
    Orders: 'createOrderList',
    Descriptions: 'products/descriptionList',
    RequiredCount: 'products/requiredCount',
    // Payment: 'payments',
    Payment: 'cartpayments',
    ExpiredItems: 'quantities/expiredQuantities',
    WeeklyChecks: 'checks',
    BatteryChecks: 'checks?checkType=daily',
    Payments: 'transactions',
    History: '',
    Tickets: 'tickets',
    // Orders: 'orders?type=unchecked',
    // Orders: 'orders/orderlist',
    // SentOrders: 'orders',
    // FulfilledOrders: 'orders',
    Orders: 'orders/orderlist?type=unchecked',
    SentOrders: 'orders?type=checked',
    FulfilledOrders: 'orders?type=fulfilled',
    RecommendedInventories: 'recommendedInventory',
    TicketSubjects: 'ticketSubjects',
    Activity: 'activities',
    Track: 'activities/track',
    MissingItems: 'missingItems',
    Recalls: 'recalls',
    storageUnitSubmit: 'quantities/useItem',
    storageunitDelete: 'quantities',
    DeleteQuantity: 'quantities/deleteQuantity',
    QuantityItemsCount: 'quantities/cartItemscount',
    ExpiredItemsCount: 'quantities/expiredCount',
    MissingItemsCount: 'quantities/missingCount',
    RequiredItemsCount: 'requiredInventory/newMobileList',
    storageUnitsCount: 'locations/mobileList',
    StorageUnitItemsCount: 'quantities/mobileCartItemsCount',
    LocationsCartView: 'locations/cartViewNew',
    StorageUnitItems: 'quantities',
    ReasonCartItem: 'quantities/useItem',
    ScanFacility: 'quantities/scanFacility',
    States: 'states',
    Payment: 'bills',
    multiOrders: 'wishlist/multiWishlist',
    ordersFrontScreen: 'orders/getOrdersListCount'

};
export default apiCalls;
