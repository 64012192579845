import React from 'react';
import {
    Nav, NavItem, NavLink, Button
} from 'reactstrap';
import fetch from '../../../../../config/service'
import classnames from 'classnames';
import showToasterMessage from '../../../../UI/ToasterMessage/toasterMessage'
export default class PaymentTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            orderTypes: [
                { name: 'Orders List', value: 'Orders List' },
                { name: 'Placed Orders', value: 'Placed Orders' },
                { name: 'Fulfilled Orders', value: 'Fulfilled Orders' }
            ],
            currentShift: null
        };
    }
    // Handle Selected Group
    handleOrderTypes = (tab, OrderTabType) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
                currentShift: OrderTabType
            });
        }
        this.props.getSelectedOrderTypeInfo(OrderTabType);
    };
    sendEmail = () => {
        let userBody = this.props.selectedRows
        if (userBody) {
            return fetch('POST', 'orders/sendEmail', userBody)
                .then((response) => {
                    if (response && response.respCode && response.respCode === 200) {
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                    this.setState({
                        isLoading: false,
                    });
                    this.props.refresh()
                }).catch((err) => {
                    return err;
                });
        }
    }

    render() {
        const { activeTab, orderTypes } = this.state;
        return (
            <div>
                <div className="tabs tabs--justify tabs--bordered-top">
                    <div className="tabs__wrap">
                        {activeTab == 0 ?
                            <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'right' }}>
                                <Button className="ml-auto my-auto" color="primary"
                                    onClick={() => this.sendEmail()}
                                    outline>Submit</Button>
                            </div> : null}
                        <Nav tabs>

                            {orderTypes && orderTypes.length > 0 ?
                                orderTypes.map((orderTabType, index) => {
                                    return (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({ active: activeTab === index })}
                                                onClick={() => {
                                                    this.handleOrderTypes(index, orderTabType.name);
                                                }}><span style={{ fontSize: 15 }}>{orderTabType.value}</span></NavLink>
                                        </NavItem>
                                    )
                                })
                                : null}
                        </Nav>
                    </div>
                </div>
            </div>
        );
    }
}