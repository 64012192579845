import React from 'react';
import { Col, Card, CardBody, Container, Row, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FaciltiyTable from './components/FacilityTable'

const BasicTables = (props) => (

    <Container>
        <FaciltiyTable {...props} />
    </Container>
);

BasicTables.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicTables);

